import FalconCardHeader from "components/common/FalconCardHeader";
import React from "react";
import { Card, Button } from "react-bootstrap";
import coverSrc from "assets/img/building.jpg";

const ProfileImageProperty = ({
  propertyProfileImage,
  CancelButton,
  showButton,
  uploadedFileName,
  handleClick,
  handleCancel,
  handleSave,
  propertyProfile,
  userData,
  handleDelete,
}) => {
  return (
    <Card className="mb-3" style={{ height: "96.3%" }}>
      <FalconCardHeader title="Property Logo" />
      <Card.Body className="bg-light">
        <div className="text-center">
          <div className=" d-flex justify-content-center">
            <img
              src={
                propertyProfileImage.logo_url ||
                propertyProfileImage.img ||
                coverSrc
              }
              className="rounded-circle mb-3 shadow-sm"
              style={{
                width: "150px",
                height: "145px",
                border: "5px solid #fff",
                padding: "5px",
                objectFit: "contain",
                // maxHeight:'100%',
                // maxWidth:'100%',
              }}
              alt="Avatar"
            />
          </div>
          <br />
          <h5 className="mb-5">
            <strong>{propertyProfile.property_name || "Name here"}</strong>
          </h5>

          {(userData.role === "Site-Administrators" ||
            userData.role === "Condozak-Basic-Users") && (
            <div className="d-flex align-items-center justify-content-center">
              <button
                style={{ marginRight: "8px", padding: "4px 8px" }}
                className={`btn btn-outline-${
                  uploadedFileName ? "success" : "primary"
                }`}
                onClick={handleClick}
              >
                Upload
              </button>

              {(propertyProfileImage.logo_url || propertyProfileImage.img) &&
                !showButton &&
                !CancelButton && (
                  <Button variant="warning" onClick={handleDelete}>
                    Delete
                  </Button>
                )}
              {showButton && (
                <button
                  style={{ marginRight: "8px", padding: "4px 8px" }}
                  className={`btn btn-outline-${
                    uploadedFileName ? "success" : "primary"
                  }`}
                  onClick={handleSave}
                >
                  Save
                </button>
              )}
              {CancelButton && <Button onClick={handleCancel}>Cancel</Button>}
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfileImageProperty;
