import React from "react";
// import ImageUnit from './ImageUnit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../../components/common/SoftBadge";
import { Table, Dropdown } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import CardDropdown from "../../../../components/common/CardDropdown";
// import Avatar from '../../../../components/common/Avatar';
// import './UserTable.css';
// import { isArray } from 'lodash';
// import BSSliderCustom from '../../../../components/common/BSSliderCustom';
// import GroupMember from '../../../../components/app/kanban/GroupMember';

// import img1 from 'assets/img/products/poolroom.jpg';
// import img2 from 'assets/img/products/partyroom.jpg';
// import img3 from 'assets/img/products/gymroom.jpg';
import StackedImagesComponent from "../../../../components/common/StackedImagesComponent";

const TableRow = ({
  data,
  // removeAmenityImages,
  funcShowAmenityImagesModal,
  handleShowEditAmenityTab,
  getAmenitiesData,
  handleEnableDisableAmenity,
  propLength,
}) => {
  try {
    // let sampleAmenities = [img1, img2, img3];
    return (
      <tr
        className="align-middle"
        style={{ height: propLength === 1 ? "100px" : "" }}
      >
        <td className="text-nowrap text-center">{data.amenityname}</td>
        <td className="d-flex justify-content-center">
          <span onClick={() => funcShowAmenityImagesModal(data.amenityimages)}>
            <StackedImagesComponent images={data.amenityimages} />
          </span>

          {/* </div> */}
          {/* </div>
        ))} */}
        </td>
        {data.status === undefined && (
          <>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
          </>
        )}
        {data.status === "Active" && (
          <>
            <td className="text-center">
              <SoftBadge pill bg="success">
                {data.status}
                <FontAwesomeIcon icon={"check"} className="ms-2" />
              </SoftBadge>
            </td>
            <td className="text-center">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    onClick={() => handleShowEditAmenityTab(data)}
                    className="text-info"
                  >
                    Edit
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    // onClick={() => removeAmenityImages(data)}
                    className="text-warning"
                  >
                    Out of Service
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() =>
                      handleEnableDisableAmenity({
                        id: data._id,
                        amenity_name: data.amenityname,
                        loadType: "Disable Amenity",
                        updatorFunction: getAmenitiesData,
                      })
                    }
                    className="text-danger"
                  >
                    Disable
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </td>
          </>
        )}
        {data.status === "Disabled" && (
          <>
            <td className="text-center">
              <SoftBadge pill bg="secondary">
                {data.status}
                <FontAwesomeIcon icon={"ban"} className="ms-2" />
              </SoftBadge>
            </td>
            <td className="text-center">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    onClick={() => handleShowEditAmenityTab(data)}
                    className="text-info"
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handleEnableDisableAmenity({
                        id: data._id,
                        amenity_name: data.amenityname,
                        loadType: "Enable Amenity",
                        updatorFunction: getAmenitiesData,
                      })
                    }
                    className="text-success"
                  >
                    Enable
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </td>
          </>
        )}
        {data.status === "Payment Pending" && (
          <>
            <td className="text-center">
              <SoftBadge pill bg="info">
                {data.status}
                <FontAwesomeIcon icon={"redo"} className="ms-2" />
              </SoftBadge>
            </td>
            <td className="text-center">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    // onClick={() => handleShowEditAmenityTab(data)}
                    className="text-info"
                  >
                    Option 1
                  </Dropdown.Item>

                  <Dropdown.Item
                    // onClick={() => showAlert(data)}
                    className="text-danger"
                  >
                    Option 3
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </td>
          </>
        )}
        {data.status === "Confirmed" && (
          <>
            <td className="text-center">
              <SoftBadge pill bg="success">
                {data.status}
                <FontAwesomeIcon icon={"redo"} className="ms-2" />
              </SoftBadge>
            </td>
            <td className="text-center">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    // onClick={() => handleShowEditAmenityTab(data)}
                    className="text-info"
                  >
                    Option 1
                  </Dropdown.Item>

                  <Dropdown.Item
                    // onClick={() => showAlert(data)}
                    className="text-danger"
                  >
                    Option 2
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </td>
          </>
        )}
        {data.status === "Cancelled" && (
          <>
            <td className="text-center">
              <SoftBadge pill bg="warning">
                {data.status}
                <FontAwesomeIcon icon={"redo"} className="ms-2" />
              </SoftBadge>
            </td>
            <td className="text-center text-danger">Canceled</td>
          </>
        )}
      </tr>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={4}>Nothing Found!</td>
    </tr>
  );
};

const AmenityBookingsTable = ({
  list,
  removeAmenityImages,
  funcShowAmenityImagesModal,
  handleShowEditAmenityTab,
  getAmenitiesData,
  handleEnableDisableAmenity,
}) => {
  try {
    return (
      <Table responsive striped bordered style={{ fontSize: "14px" }}>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col" className="text-center">
              Images
            </th>
            <th scope="col" className="text-center">
              Status
            </th>
            <th scope="col" className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list?.map((amenity) => (
              <TableRow
                data={amenity}
                key={amenity?._id}
                removeAmenityImages={removeAmenityImages}
                funcShowAmenityImagesModal={funcShowAmenityImagesModal}
                handleShowEditAmenityTab={handleShowEditAmenityTab}
                getAmenitiesData={getAmenitiesData}
                handleEnableDisableAmenity={handleEnableDisableAmenity}
                propLength={list?.length}
              />
            ))
          ) : (
            <EmptyRow />
          )}
        </tbody>
      </Table>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default AmenityBookingsTable;
