import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
import SoftBadge from "../../../common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Badge, Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardDropdown from "../../../common/CardDropdown";
import { Avatar } from "@mui/material";
import { startCase } from "lodash";
import { formatLogInfo } from "helpers/utils";

const TableRow = ({ data, propLength }) => {
  // const TableRow = ({ data, selectBuilding, propLength }) => {
  // console.log(data.status.type);

  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td className="text-center">{data.property_name || "name"}</td>
      <td className="text-center">
        <Link
          to={`/${data.p_id}/${data.property_name}/${data.unit_number}/${data._id}/attached-units`}
        >
          {data.unit_number || "number"}
        </Link>
      </td>
      <td className="text-nowrap">
        <div className="d-flex align-items-center">
          <div className="me-2">
            <Avatar
              sx={{
                bgcolor: data?.bgColor,
                fontSize: "16px",
                // width: "100px",
                // height: "100px",
              }}
              className="img-thumbnail"
            >
              {data && data?.firstname && startCase(data?.firstname[0])}
              {data && data?.lastname && startCase(data?.lastname[0])}
            </Avatar>
          </div>
          <div>
            <div className="ms-1">
              <Link to={"#!"}>
                {data.firstname || "First Name"} {data.lastname || "Last Name"}
              </Link>

              <Badge className={`ms-2 ${data.role.type}`}>
                {data.role.title}
              </Badge>
            </div>
            <div className="ms-1">
              {data.email?.toLowerCase() || "info@gmail.domain"}
            </div>
          </div>
        </div>
      </td>
      <td className="text-center">
        <SoftBadge pill bg={data.status.type}>
          {data.status.title}
          <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
        </SoftBadge>
      </td>
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item>
              <Link
                to={`/${data.p_id}/${data.property_name}/${data.unit_number}/${data._id}/attached-units`}
                className="d-block"
              >
                Edit
              </Link>
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

const OwnerDirectoryTable = ({ properties = [] }) => {
  // console.log(properties);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Property Name
          </th>
          <th scope="col" className="text-center">
            Unit Number
          </th>
          <th scope="col" className="text-center">
            Resident
          </th>
          <th scope="col" className="text-center">
            Status
          </th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {properties?.map((property, index) => (
          <TableRow
            data={property}
            key={index + properties._id}
            propLength={properties.length}
          />
        ))}
        {properties.length < 1 && (
          <tr>
            <td colSpan={5} className="text-center">
              No Matches Found.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default OwnerDirectoryTable;
