import CardDropdown from "../../common/CardDropdown";
import FalconCardHeader from "../../common/FalconCardHeader";
import Flex from "../../common/Flex";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import * as echarts from "echarts/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";

import { months } from "../../../data/common";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { capitalize, getColor, rgbaColor } from "../../../helpers/utils";
import img1 from "../../../assets/img/products/partyroom.jpg";
import img2 from "../../../assets/img/products/gymroom.jpg";
import img3 from "../../../assets/img/products/poolroom.jpg";
import moment from "moment";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
]);

const getOptions = (month, data) => ({
  color: getColor("gray-100"),
  tooltip: {
    trigger: "axis",
    padding: [7, 10],
    backgroundColor: getColor("gray-100"),
    borderColor: getColor("gray-100"),
    textStyle: { color: getColor("dark") },
    borderWidth: 1,
    formatter: (params) => {
      const { name, value } = params[0];
      return `${month} ${name} : ${value}`;
    },
    transitionDuration: 0,
  },
  xAxis: {
    type: "category",
    data: Array.from(
      { length: moment().daysInMonth(month) },
      (_, i) => i + 1
    ) || [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 29, 31],
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor("gray-300"),
        type: "dashed",
      },
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: getColor("gray-300"),
        type: "dashed",
      },
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor("gray-400"),
      formatter: (value) => `${capitalize(month.slice(0, 3))} ${value}`,
      margin: 15,
    },
  },
  yAxis: {
    type: "value",
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor("gray-300"),
        type: "dashed",
      },
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor("gray-400"),
      margin: 15,
    },
    axisTick: { show: false },
    axisLine: { show: false },
  },
  series: [
    {
      type: "line",
      data,
      lineStyle: { color: getColor("primary") },
      itemStyle: {
        borderColor: getColor("primary"),
        borderWidth: 2,
      },
      symbol: "circle",
      symbolSize: 10,
      smooth: false,
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor("primary"), 0.2),
            },
            {
              offset: 1,
              color: rgbaColor(getColor("primary"), 0),
            },
          ],
        },
      },
    },
  ],
  grid: { right: 10, left: 0, bottom: 0, top: 10, containLabel: true },
});

const AmenitiesComp = ({ data=[], fromowner }) => {
  const [month, setMonth] = useState(0);

  // console.log(data);

  let AmenitiesData = [
    {
      heading: "Party Room",
      price: "150.00",
      rule: "Flat Rate Per Reservation",
      img: img1,
    },

    {
      heading: "Gym Area",
      price: "110.00",
      rule: "Flat Rate Per Reservation",
      img: img2,
    },

    {
      heading: "Pool Area",
      price: "180.00",
      rule: "Flat Rate Per Reservation",
      img: img3,
    },
  ];

  if (fromowner)
    return (
      <Card className="h-100">
        <FalconCardHeader
          title="Amenities"
          titleTag="h6"
          className="pb-0"
          endEl={
            <Flex>
              <Form.Select
                size="sm"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                className="me-2"
              >
                {months.map((month, index) => (
                  <option value={index} key={month}>
                    {month}
                  </option>
                ))}
              </Form.Select>
              <CardDropdown />
            </Flex>
          }
        />

        <Card.Body>
          {/* <ReactEChartsCore
            echarts={echarts}
            option={getOptions(months[month], data[month])}
            style={{ height: '18.4375rem' }}
          /> */}
          {AmenitiesData?.map((v, i) => (
            <div className="d-flex" key={i + v.price}>
              <div
                style={{
                  height: "80px",
                  width: "140px",
                  backgroundColor: "silver",
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                {v.img !== "no img" ? (
                  <img
                    src={v.img}
                    // className="img-fluid"
                    style={{
                      borderRadius: "8px",
                      boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 8px",
                      width: "100%",
                    }}
                    alt="img for amenity"
                  />
                ) : (
                  "no img"
                )}
              </div>
              <div className="ms-5">
                <h6 className="text-900 fw-semi-bold">{v.heading}</h6>
                <p className="text-400 fw-semi-bold">$ {v.price}</p>
                <p className="text-600 fw-semi-bold">{v.rule}</p>
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>
    );
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Amenities Revenue"
        titleTag="h6"
        className="pb-0"
        endEl={
          <Flex>
            <Form.Select
              size="sm"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              className="me-2"
            >
              {months.map((month, index) => (
                <option value={index} key={month}>
                  {month}
                </option>
              ))}
            </Form.Select>
            <CardDropdown />
          </Flex>
        }
      />

      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(months[month], data[month])}
          style={{ height: "18.4375rem" }}
        />
      </Card.Body>
    </Card>
  );
};

export default AmenitiesComp;
