import FalconCardHeader from "components/common/FalconCardHeader";
import React from "react";
import { Card, Row, Col, Form as BSForm } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Avatar } from "@mui/material";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

export default function UnitDetailsComp({ unit, fromOwner = false }) {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id } = useParams();

  console.log(unit);

  const updateUnitDetails = (values) => {
    const unit_data_to_send = {
      unit_id: unit._id,
      unit_number: values.unit_number,
      cef: values.cef,
      description: values.description,
      p_id: property_id,
    };
    // console.log(unit_data_to_send);
    axios
      .post(
        `${hostname}/api/property/UpdateUnit`,
        {
          ...unit_data_to_send,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);

        toast.success("unit updated.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);

        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const getUserName = () => {
    if (
      !fromOwner &&
      typeof unit === "object" &&
      unit.resident_status === "Active"
    )
      return unit.firstname + " " + unit.lastname;

    if (fromOwner) return userData.firstname + " " + userData.lastname;
  };

  return (
    <Row className="g-3 pe-lg-0">
      <Col md={8}>
        <Card
          style={{
            minHeight: userData.role !== "Site-Administrators" ? "" : "300px",
          }}
        >
          <FalconCardHeader title="Unit Details" className="" />
          <Card.Body className="bg-light">
            <Formik
              initialValues={{
                unit_number: unit?.unit_number || "",
                unit_type: unit?.unit_type || "",
                cef: unit?.cef || "",
                Inviteduser: unit?.Owneremail || "",
                description: unit?.description || "",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object().shape({
                unit_number: Yup.string().required(
                  "please enter unit number to continue!"
                ),
                cef: Yup.string().required("condo element fee is required!"),
                description: Yup.string("must be valid value!"),
                // Inviteduser: Yup.string()
                //   .email("Must be a valid email")
                //   .max(255, "long email!"),
                unit_type: Yup.string().required("please select unit type!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // if (headerData.cef + +values.cef > 100) {
                //   setIsFull(true);
                //   return;
                // } else {
                //   setIsFull(false);
                // }
                // props.addUnitInfo(values);
                updateUnitDetails(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, handleChange, handleBlur, values }) => (
                <Form>
                  <Row className="mb-3">
                    <Col md={6} className="pe-lg-0">
                      <BSForm.Label>
                        Unit Number <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        disabled={fromOwner}
                        name="unit_number"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="unit_number"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                    <Col md={6}>
                      <BSForm.Label>
                        Unit Type <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        // component="select"
                        // id="unit_type"
                        name="unit_type"
                        className="form-control"
                        disabled
                      >
                        {/* <option value="">Select unit type</option> */}
                        {/* <option value="condo">Condo</option>
                        <option value="Parking Spot">Parking Spot</option>
                        <option value="Locker">Locker</option> */}
                      </Field>
                      <ErrorMessage
                        name="unit_type"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} className="pe-lg-0">
                      <BSForm.Label>
                        % CEF <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        name="cef"
                        className="form-control"
                        disabled={fromOwner}
                      />
                      <ErrorMessage
                        name="cef"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                    <Col md={6}>
                      <BSForm.Label>
                        Primary Owner Email
                        <span className="text-danger"></span>
                      </BSForm.Label>
                      <Field
                        type="email"
                        name="Inviteduser"
                        className="form-control"
                        disabled
                        // hidden
                      />
                      <ErrorMessage
                        name="Inviteduser"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Notes <span className="fs--1"></span>
                      </BSForm.Label>

                      <BSForm.Control
                        as={"textarea"}
                        type="text"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        disabled={fromOwner}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  {/* {(values.unit_type === "Parking Spot" ||
                    values.unit_type === "Locker") && (
                    <Row className="mb-3">
                      <Col>
                        <BSForm.Label>
                          Attach to Condo Unit
                          <span className="text-danger"></span>
                        </BSForm.Label>
                        <Field
                          component="select"
                          id="attachtoUnit"
                          name="attachtoUnit"
                          className="form-select"
                          // multiple={true}
                        >
                          <option value="">Select</option>
                          {unitsForCondo}
                        </Field>
                        
                        <ErrorMessage
                          name="attachtoUnit"
                          component="div"
                          className="text-danger m-2 fs--1"
                        />
                      </Col>
                    </Row>
                  )} */}
                  <br />
                  {(userData.role === "Site-Administrators" ||
                    userData.role === "Condozak-Basic-Users") && (
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary float-end"
                    >
                      Update
                      {/* {payload.loadType?.split(" ")[0]} */}
                    </button>
                  )}
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card
          style={{
            minHeight:
              userData.role === "Site-Administrators" ? "426px" : "391px",
          }}
        >
          <FalconCardHeader title="Unit Number" />
          <Card.Body className="bg-light text-center d-flex flex-column justify-content-center align-items-center">
            <div className=" d-flex justify-content-center">
              <Avatar
                sx={{
                  bgcolor: "#000",
                  fontSize: "2rem",
                  width: "100px",
                  height: "100px",
                }}
                className="img-thumbnail"
              >
                {unit?.unit_number}
              </Avatar>
            </div>
            <br />
            <h5 className="mb-3 fw-bold">{getUserName()}</h5>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
