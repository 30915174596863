import React from "react";

import { Col, Row } from "react-bootstrap";

import axios from "axios";

import Loader from "components/common/Loader";

import InvitationSingle from "./InvitationSingle";

// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

export default function NotificationCenter() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const navigate = useNavigate();

  const [list, setList] = React.useState([]);

  const [loader, setLoader] = React.useState(false);

  const [ownerInvitesAccepted, setOwnerInvitesAccepted] = React.useState([]);
  // const [ownerInvitesIgnored, setOwnerInvitesIgnored] = React.useState(0);

  React.useEffect(() => {
    setLoader(true);
    if (userData === "undefined" || userData === undefined) {
      window.location.href = "/login";
      return;
    }

    getInvitationData();
  }, []);

  const getInvitationData = () => {
    let email = userData?.email;

    let link = `${hostname}/api/auth/ShowInvites`;
    if (userData.role === "No role Assigned") {
      //nothing, link already set
    }
    if (userData.role === "none" || userData.role === "unit_owner") {
      link = `${hostname}/api/owner/OwnerInvites`;
    }

    axios
      .post(
        link,
        {
          email,
          property_url: window.location.hostname,
          company_url: window.location.hostname,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (userData.role === "none" || userData.role === "unit_owner") {
          if (res.data?.data?.length < 1 && userData.role === "none") {
            navigate("/errors/401/not-invited/property_invited_user");
            //window.location.href = "/errors/401/not-invited/login";
          } else if (
            res.data?.data?.length < 1 &&
            userData.role === "unit_owner"
          ) {
            navigate("/errors/401/not-invited/unit_owner_multi_invited");
            //window.location.href = "/errors/401/not-invited/login";
          } else {
            setList(res.data?.data);
          }
        } else {
          if (res.data?.data?.length < 1) {
            navigate("/errors/401/not-invited/company_invited_user");
            //window.location.href = "/errors/401/not-invited/login";
          } else {
            setList(res.data?.data[0]);
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const acceptInvitationData = async (
    c_idUser,
    groupUser,
    bgcolor,
    company_name
  ) => {
    try {
      // if (inviteStatus) {
      let user_id = userData.data;
      let email = userData.email;
      let c_id = c_idUser;
      let group = groupUser;
      let bgColor = bgcolor;
      // console.log(user_id, email, c_id, group);
      // console.log(invitationData);
      let inviteData = [...list];
      // console.log(inviteData);
      inviteData = inviteData.filter((v) => {
        return v.c_id !== c_idUser;
      });
      // console.log(user_id, email, c_id, group, bgColor);
      // console.log('okok');
      // return;
      //
      const res = await axios.post(
        `${hostname}/api/auth/AcceptInvite`,
        {
          user_id,
          email,
          c_id,
          group,
          bgColor,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      toast.success("Successfully accepted.", { theme: "colored" });

      // let user = { ...userData };
      // user.property_name = res.data.property_name;
      // user.company_name = company_name;
      // user.role = groupUser;
      // user.compid = c_id;

      const res2 = await axios.post(
        `${hostname}/api/property/tokenRefresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(res2.data);
      let newt = res2.data.Data;
      if (newt && newt !== jwtToken) {
        cookies.set("_expire_t", newt, { path: "/" });
        cookies.set("_expire_l", "/login", { path: "/" });

        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 400);
      } else {
        toast.error("invalid token !");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const ignoreInvitationData = (
    c_idUser,
    groupUser,
    bgcolor,
    invitee_email
  ) => {
    // if (inviteStatus) {
    let user_id = userData.data;
    let email = userData.email;
    let c_id = c_idUser;
    let group = groupUser;
    let bgColor = bgcolor;
    // console.log(user_id, email, c_id, group);
    // console.log(invitationData);
    let inviteData = [...list];
    // console.log(inviteData);
    inviteData = inviteData.filter((v) => {
      return v.c_id !== c_idUser;
    });
    // console.log(user_id, email, c_id, group, bgColor, invitee_email);
    // console.log('okok');

    // return;

    axios
      .post(
        `${hostname}/api/auth/ignoreinvite`,
        {
          user_id,
          email,
          c_id,
          group,
          bgColor,
          invitee_email,
          // inviteStatus
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        setList(inviteData);

        // setTimeout(() => {
        toast.info(<span className="text-white">{`Invitation Ignored.`}</span>);
        setTimeout(() => {
          navigate("/errors/401/not-invited/login");
        }, 2000);
        // }, 300);
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          <span className="text-white">{err.response || "Error occured!"}</span>
        );
      });
    // }
  };

  const acceptOwnerInvite = async (invite = {}) => {
    try {
      let data = {};
      data.invite_id = invite._id;

      data.user_id = userData.data;
      data.unit_id = invite.unit_id;
      data.group = invite.group;
      console.log(data);
      console.log(invite);
      // return;

      // let tmpList = [];
      // tmpList = list.filter((v) => v.unit_id !== invite.unit_id);
      // setList(tmpList);

      // let acceptedInvites = [...ownerInvitesAccepted] || [];

      // acceptedInvites.push({ invite, data: {} });

      // setOwnerInvitesAccepted(acceptedInvites);

      // console.log(tmpList);
      // console.log(acceptedInvites);

      // if (tmpList.length === 0 || tmpList.length < 1) {
      //   console.log("tmpList has length ", tmpList.length);
      //   if (acceptedInvites.length > 1) {
      //     console.log("ownerupgrade and login");
      //     console.log(invite);
      //     // console.log(res.data);
      //     // ownerUpgradeAndLogin(invite, res.data);
      //   } else {
      //     console.log("navigate property_invited_user");
      //     // navigate("/errors/401/not-invited/property_invited_user");
      //   }
      // }else{
      //   console.log('nnot working');
      // }

      // return;

      const res = await axios.post(
        `${hostname}/api/owner/AcceptOwner`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(res.data);
      toast.success("Accepted.", { theme: "colored" });
      let tmpList = [];
      tmpList = list.filter((v) => v.unit_id !== invite.unit_id);
      setList(tmpList);
      //setList(() => list.filter((v) => v.unit_id !== invite.unit_id));
      // toast.success(
      //   <span className="text-white">{`Successfully accepted.`}</span>
      // );
      let acceptedInvites = [...ownerInvitesAccepted] || [];

      acceptedInvites.push({ invite, data: res.data });

      setOwnerInvitesAccepted(acceptedInvites);

      console.log(tmpList);
      console.log(acceptedInvites);

      if (tmpList.length === 0 || tmpList.length < 1) {
        if (acceptedInvites.length > 0) {
          await ownerUpgradeAndLogin(invite, res.data);
        } else navigate("/errors/401/not-invited/property_invited_user");
      }

      // cookies.set("userData", JSON.stringify(user));
    } catch (e) {
      toast.error("Something went wrong!.", { theme: "colored" });
      console.log(e);
    }
  };

  const ignoreOwnerInvite = async (invite) => {
    try {
      let data = {};
      data.invite_id = invite._id;
      data.user_id = userData.data;
      data.unit_id = invite.unit_id;
      data.group = invite.group;
      console.log(data);
      console.log(invite);

      // return;

      // let tmpList = [];
      // tmpList = list.filter((v) => v.unit_id !== invite.unit_id);
      // console.log(tmpList);
      // setList(tmpList);

      // toast.info("invitation ignored!", { theme: "colored" });

      // let acceptedInvites = ownerInvitesAccepted || [];
      // console.log(acceptedInvites);

      // if (tmpList.length === 0 || tmpList.length < 1) {
      //   console.log("tmpList show", tmpList.length);
      //   if (acceptedInvites.length > 0) {
      //     console.log("acceptedInvites has length");
      //     if (acceptedInvites.length === 1) {
      //       console.log("acceptedInvites has length 1 & go to owner db");
      //       console.log(acceptedInvites[0].invite);
      //       console.log(acceptedInvites[0].data);
      //       // ownerUpgradeAndLogin(
      //       //   acceptedInvites[0].invite,
      //       //   acceptedInvites[0].data
      //       // );
      //     } else {
      //       console.log("acceptedInvites has length many & go to owner db");
      //       console.log(acceptedInvites[acceptedInvites.length - 1].invite);
      //       console.log(acceptedInvites[acceptedInvites.length - 1].data);
      //       // ownerUpgradeAndLogin(
      //       //   acceptedInvites[acceptedInvites.length - 1].invite,
      //       //   acceptedInvites[acceptedInvites.length - 1].data
      //       // );
      //     }
      //   } else {
      //     console.log("navigate property_invited_user");
      //     // navigate("/errors/401/not-invited/property_invited_user");
      //   }
      // }

      // return;

      const res = await axios.post(
        `${hostname}/api/owner/IgnoreResidentInfo`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(res.data);
      let tmpList = [];
      tmpList = list.filter((v) => v.unit_id !== invite.unit_id);
      setList(tmpList);
      toast.info("invitation ignored!", { theme: "colored" });

      let acceptedInvites = ownerInvitesAccepted || [];

      if (tmpList.length === 0 || tmpList.length < 1) {
        console.log(tmpList);
        if (acceptedInvites.length > 0) {
          if (acceptedInvites.length === 1)
            await ownerUpgradeAndLogin(
              acceptedInvites[0].invite,
              acceptedInvites[0].data
            );
          await ownerUpgradeAndLogin(
            acceptedInvites[acceptedInvites.length - 1].invite,
            acceptedInvites[acceptedInvites.length - 1].data
          );
        } else {
          if (userData.role === "unit_owner") {
            setTimeout(() => {
              window.location.href = `/dashboard/${userData.UnitsOwner[0]._id}/${userData.UnitsOwner[0].unit_number}`;
            }, 400);
          }
          navigate("/errors/401/not-invited/property_invited_user");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!.", { theme: "colored" });
    }
  };

  const ownerUpgradeAndLogin = async (invite, data) => {
    try {
      let user = { ...userData };
      user.role = "unit_owner";
      user.property_name = invite.property_name;
      user.propid = data.propid || invite.propid;
      user.UnitsOwner = [
        {
          _id: invite.unit_id,
          unit_number: invite.unit_number,
        },
      ];
      user.UnitsOwner = data.UnitsOwner;
      user.owner_unit_id = invite.unit_id;
      user.owner_unit_number = invite.unit_number;

      const resp = await axios.post(
        `${hostname}/api/property/tokenRefresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      console.log(resp.data);
      let newtowner = resp.data.Data;
      if (newtowner && jwtToken !== newtowner) {
        cookies.set("_expire_t", newtowner, { path: "/" });
        cookies.set("_expire_l", "/property/login", { path: "/" });

        setTimeout(() => {
          window.location.href = `/dashboard/${invite.unit_id}/${invite.unit_number}`;
        }, 400);
      }
    } catch (e) {
      toast.error("something went wrong!");
    }
  };

  return (
    <Row>
      {loader && <Loader noHeight />}
      {!loader &&
        list?.map((invitation, index) => (
          <Col md={12} key={invitation?._id || index + "22f"}>
            <InvitationSingle
              invitation={invitation}
              acceptInvitationData={acceptInvitationData}
              ignoreInvitationData={ignoreInvitationData}
              acceptOwnerInvite={acceptOwnerInvite}
              ignoreOwnerInvite={ignoreOwnerInvite}
              userData={userData}
            />
          </Col>
        ))}
      {!loader && list?.length < 1 && <p className="text-center">Loading</p>}
    </Row>
  );
}
