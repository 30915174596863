import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

const EmailNotificationsSettings = () => {
  return (
    <Card>
      <FalconCardHeader title="Email Notifications" />
      <Card.Body className="bg-light">
        <Flex justifyContent="between" alignItems='center' className='ms-4 me-4'>
          <div>
            <h5 className='mb-2'>Special Offers</h5>
            <h6 style={{'color':'grey'}}>Get Updates on Promotions, sales and discounts</h6>
          </div>
          <Form.Check
            type="switch"
            id="custom-switch"
            // label="Make your phone number visible"
            className="form-label-nogutter"
            name="numberVisibility"
            // onChange={handleChange}
            // checked={formData.numberVisibility}
          />
        </Flex>
        <br />
        <Flex justifyContent="between" alignItems='center' className='ms-4 me-4'>
          <div>
            <h5 className='mb-2'>Contests & Events</h5>
            <h6 style={{'color':'grey'}}>Find out when we are having a contest or event near you</h6>
          </div>
          <Form.Check
            type="switch"
            id="custom-switch2"
            // label="Make your phone number visible"
            className="form-label-nogutter"
            name="numberVisibility"
            // onChange={handleChange}
            // checked={formData.numberVisibility}
          />
        </Flex>
        <br />
        <Flex justifyContent="between" alignItems='center' className='ms-4 me-4'>
          <div>
            <h5 className='mb-2'>Special Offers</h5>
            <h6 style={{'color':'grey'}}>Get Updates on Promotions, sales and discounts</h6>
          </div>
          <Form.Check
            type="switch"
            id="custom-switch3"
            // label="Make your phone number visible"
            className="form-label-nogutter"
            name="numberVisibility"
            // onChange={handleChange}
            // checked={formData.numberVisibility}
          />
        </Flex>
        <br />
        <Flex justifyContent="between" alignItems='center' className='ms-4 me-4'>
          <div>
            <h5 className='mb-2'>Special Offers</h5>
            <h6 style={{'color':'grey'}}>Get Updates on Promotions, sales and discounts</h6>
          </div>
          <Form.Check
            type="switch"
            id="custom-switch4"
            // label="Make your phone number visible"
            className="form-label-nogutter"
            name="numberVisibility"
            // onChange={handleChange}
            // checked={formData.numberVisibility}
          />
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default EmailNotificationsSettings;
