import React, { useContext } from 'react';

import { AuthWizardContext } from 'context/Context';

import { ErrorMessage, Field, Formik } from 'formik';

import FalconCardHeader from 'components/common/FalconCardHeader';

import { Card } from 'react-bootstrap';

import BookingReview from './BookingReview';


export default function DefinedRules({ selectedAmenity }) {
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  console.log(user);
  try {

    return (
      <div>
        <BookingReview selectedAmenity={selectedAmenity} />
        <br />
        <Formik
          initialValues={{
            rules_accepted: user.rules_accepted || true
          }}
          validate={values => {
            let errors = {};
            if (!values.rules_accepted) {
              errors.rules_accepted = 'Please Check to Continue!';
            }

            console.log(errors);
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            // return;
            console.log(values);
            setUser(() => Object.assign({ ...values }, user));
            setStep(step + 1);
            setSubmitting(false);
          }}
        >
          {({
            // values,
            // handleChange,
            // handleBlur,
            handleSubmit
            // isSubmitting,
            // setFieldValue
            /* and other goodies */
          }) => (
            <div className="">
              {/* <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6"> */}
              <div className="">
                <form onSubmit={handleSubmit}>
                  <Card>
                    <FalconCardHeader title="Terms & Conditions :" />
                    <Card.Body className="bg-light">
                      <br />
                      <p className="text-dark">
                        The following are general terms and conditions that may
                        apply.:
                      </p>
                      <br />
                      <p style={{whiteSpace:'pre-wrap'}}>
                        {selectedAmenity.terms}
                      </p>
                      <br />
                      <br />
                      <div className="mt-3">
                        <label>
                          <Field type="checkbox" name="rules_accepted" />
                          <span className="ms-2 fw-bold">
                            I accept these terms and conditions.
                          </span>
                        </label>
                        <ErrorMessage
                          name="rules_accepted"
                          className="text-danger fs--1"
                          component={'div'}
                        />
                        {/* <div>tst</div> */}
                      </div>
                      <br />
                      <div className="mt-3">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          // disabled={isSubmitting}
                          onClick={() => setStep(step - 1)}
                        >
                          Previous
                        </button>
                        <button
                          className="btn btn-info float-end"
                          type="submit"
                          // disabled={isSubmitting}
                        >
                          Next
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
