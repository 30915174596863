import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

export default function Loader({ noHeight }) {
  try {
    return (
      <div
        className={`d-flex justify-content-center align-items-center ${
          !noHeight && 'min-vh-100'
        }`}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="25"
          visible={true}
        />
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
