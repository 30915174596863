import React from "react";
import { Modal } from "react-bootstrap";
import Wizard from "./wizardRemastered/Wizard";
import FalconCloseButton from "components/common/FalconCloseButton";

export default function BookAmenityModal(props) {
  try {
    return (
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        // className='bg-info'
        style={{ backdropFilter: "blur(3px)" }}
        keyboard={false}
        size="xl"
        // fullscreen
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Book {props.selectedAmenity.amenityname || "Amenity"}
          </Modal.Title>
          <FalconCloseButton onClick={props.handleClose} />
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <Wizard
            handleClose={props.handleClose}
            validation={true}
            progressBar={true}
            selectedAmenity={props.selectedAmenity}
            getAmenitiesData={props.getAmenitiesData}
            userData={props.userData}
            hostname={props.hostname}
            jwtToken={props.jwtToken}
          />
        </Modal.Body>
      </Modal>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
