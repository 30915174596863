import { useState, useEffect } from "react";

import Flex from "components/common/Flex";
import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import CreatePropertyModal from "./CreatePropertyModal";
import { capitalize } from "lodash";
import axios from "axios";
import Cookies from "universal-cookie";

import { Link, useNavigate } from "react-router-dom";
import PropertyTable from "./PropertyTable";
import PaginateComponentCustom from "components/common/PaginateComponentCustom";

import CustomSearchbar from "components/common/CustomSearchbar";
import Loader from "components/common/Loader";
import jwtDecode from "jwt-decode";
import { getHostName } from "helpers/utils";

const Properties = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const navigate = useNavigate();

  // create property modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getAllPropertiesData(userData.compid);
  }, []);

  const getAllPropertiesData = (c_id) => {
    if (c_id) {
      axios
        .post(
          `${hostname}/api/property/Allproperties`,
          {
            c_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          // console.log(res.data);
          let properties = res?.data?.data.map((p) => {
            return Object.assign(
              { address: p.address || "-" },
              { owner_linked: p.owner_linked, total_units: p.total_units },
              p.property_info
            );
          });
          properties = properties.map((p) => {
            p.property_name = capitalize(p.property_name);
            return Object.assign(
              {
                status: {
                  title: "Active",
                  type: "success",
                  icon: "check",
                },
              },
              p
            );
          });
          // console.log(properties);
          setPropertiesData(properties);
          setPropertiesDataTemp(properties);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const addPropertyToRecents = (p_id, path, name) => {
    if (!p_id) {
      return;
    }

    navigate(`/property/management/buildings/${p_id}/${name}/${path}`);

    axios
      .post(
        `${hostname}/api/property/AddRecentproperties`,
        {
          c_id: userData.compid,
          user_id: userData.data,
          p_id: p_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Link className="text-secondary" to={"/"}>
                  Home
                </Link>
                {" / "}
                <Link to={"#!"}>Properties</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>Properties</h4>

              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") && (
                <Button variant="primary" size="sm" onClick={handleShow}>
                  Add Property
                </Button>
              )}
            </Flex>

            <Row>
              <Col md="6">
                <p>
                  Manage properties to make them more valuable and useful for
                  owners and tenants.
                </p>
              </Col>
            </Row>
            <Row className="flex-end-center">
              <Col xs="auto" sm={6} lg={4}>
                <CustomSearchbar
                  searchFlags={[
                    "property_id",
                    "property_name",
                    "address",
                    "property_url",
                  ]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  placeholders={["id", " name"]}
                />
              </Col>
            </Row>

            <br />
            <PaginateComponentCustom
              itemsPerPage={10}
              dataList={propertiesData}
              TableElement={PropertyTable}
              userData={userData}
              addPropertyToRecents={addPropertyToRecents}
            />
            {/* <PaginateComponentCustom /> */}
            <CreatePropertyModal
              show={show}
              handleClose={handleClose}
              userData={userData}
              getAllPropertiesData={getAllPropertiesData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default Properties;
