// import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import csc from "countries-states-cities";
// import Cookies from 'universal-cookie';

import { AuthWizardContext } from "context/Context";

import { Formik, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

const AddressPage = ({ from = "" }) => {
  // const cookies = new Cookies();
  // const userData = cookies.get('userData');

  const { step, setStep, user, setUser } = useContext(AuthWizardContext);
  console.log(user);

  const handleSubmit = (values) => {
    console.log(values);
    setStep(step + 1);

    return;
    
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({}, user, values));

    setStep(step - 1);
  };

  const handleSetCountry = (e, setFieldValue) => {
    const { value } = e.target;

    setFieldValue("country", +value);
    setFieldValue("city", "");
  };

  return (
    <div className={`${from === "Edit Event Page" && "card"}`}>
      {from === 'Edit Event Page' && <div className="card-header">Edit Location</div>}
      <div className={`${from === "Edit Event Page" && "card-body bg-light"}`}>
        <Formik
          initialValues={{
            country: user.country || "",
            state: user.state || "",
            city: user.city || "",
            timezone: user.timezone || "",
            venue: user.venue || "",
            address: user.address || "",
          }}
          validationSchema={Yup.object({
            country: Yup.string().required("country is required!"),
            state: Yup.string().required("state is required!"),
            city: Yup.string().required("city is required!"),
            timezone: Yup.string().required("timezone is required!"),
            venue: Yup.string().required("venue is required!"),
            address: Yup.string().required("address is required!"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="country">Country</label>
                  <Field
                    component="select"
                    id="countryProperty"
                    name="country"
                    className="form-select"
                    onChange={(e) => handleSetCountry(e, setFieldValue)}
                  >
                    <option>select</option>
                    {csc.getAllCountries()?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="country"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="state">State/Province</label>
                  <Field
                    component="select"
                    id="stateProperty"
                    name="state"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {csc.getStatesOfCountry(+values.country)?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="state"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="city">City</label>
                  <Field
                    component="select"
                    id="cityProperty"
                    name="city"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {csc.getCitiesOfState(+values.state)?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="city"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="timezone">Timezone</label>
                  <Field
                    component="select"
                    id="timezoneProperty"
                    name="timezone"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {csc.getAllCountries()?.map((v, i) => (
                      <option key={v.id} value={v.iso2}>
                        {v.iso2}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="timezone"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="venue">Venue</label>
                  <Field
                    name="venue"
                    className="form-control"
                    placeholder="enter 2nd address here"
                  />
                  <ErrorMessage
                    name="venue"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <label htmlFor="address">Address</label>
                  <Field
                    name="address"
                    className="form-control"
                    placeholder="enter address"
                  />
                  <ErrorMessage
                    name="address"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              {from === "Edit Event Page" && (
                <div className="">
                  <Button
                    variant="primary"
                    className="float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </div>
              )}
              {from !== "Edit Event Page" && (
                <div className="">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => handleStepMinus(values)}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    className="float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddressPage;
