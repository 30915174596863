import React from "react";

import { Table } from "react-bootstrap";

import { formatLogInfo } from "helpers/utils";
import user from "assets/img/user.png";

const TableRow = ({ data, propLength, viewUserDetails }) => {
  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td
        className="text-center text-primary cursor-pointer"
        // onClick={() => viewUserDetails(data)}
        onClick={() => viewUserDetails(data)}
      >
        {data?._id?.slice(0, 8)}
      </td>
      <td>
        <div className="d-flex justify-content-start">
          <div style={{ height: "50px", width: "50px" }} className="bg-light">
            <img
              src={data.picture || user}
              style={{ height: "100%", width: "100%" }}
              alt="cz-user"
            />
          </div>
          <div className="ms-3 d-flex flex-column justify-content-center">
            <span>{data?.name}</span>
            <span>{data?.email}</span>
          </div>
        </div>
      </td>
      <td className="text-center">{data.unit_number}</td>

      <td className="text-center">
        {formatLogInfo(data?.arrival_date, true)}
        <br />
        {formatLogInfo(data?.arrival_time)?.split("-")[1]}
      </td>
      <td className="text-center">
        {formatLogInfo(data.dep_date, true)}
        <br />
        {formatLogInfo(data?.dep_time)?.split("-")[1]}
      </td>
      <td className="text-center">{data?.category}</td>
      {/* <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              className="text-primary"
              onClick={() => printPermit(data)}
            >
              Print Pass
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </td> */}
    </tr>
  );
};

const VisitorsTable = ({ list = [], viewUserDetails }) => {
  // console.log(properties);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Visit ID
          </th>
          <th scope="col" className="text-center">
            Visitor
          </th>
          <th scope="col" className="text-center">
            Unit Visited
          </th>
          <th scope="col" className="text-center">
            Arrival Date & Time
          </th>
          <th scope="col" className="text-center">
            Departure Date & Time
          </th>
          <th scope="col" className="text-center">
            Visitor Category
          </th>
          {/* <th scope="col" className="text-center">
            Actions
          </th> */}
        </tr>
      </thead>
      <tbody>
        {list?.map((item, index) => (
          <TableRow
            data={item}
            key={index}
            propLength={list?.length}
            viewUserDetails={viewUserDetails}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default VisitorsTable;
