import React from "react";

import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Form as BSForm, Button } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

export default function VoilationTrackingSettingsModel({
  show,
  handleClose,
  payload,
  getAllData,
  userData,
  hostname,
  jwtToken,
}) {
  const addNewTypeDB = (values = {}, updateMode = false) => {
    // setLoader(true);
    console.log(values);

    let link = `${hostname}/api/property/AddViolationSetting`;
    if (updateMode) link = `${hostname}/api/property/UpdateViolationSetting`;

    let reqData = {
      updated_by_id: payload.userData.data,
      created_by_id: payload.userData.data,
      p_id: payload.p_id,
      violation_type: values.v_type,
      violation_description: values.description,
    };
    if (updateMode) {
      reqData.violation_setting_id = payload._id;
      delete reqData.created_by_id;
      delete reqData.p_id;
    }

    console.log(reqData);

    axios
      .post(
        link,
        {
          ...reqData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        getAllData();
        // setLoader(false);
        handleClose();
        if (!updateMode) toast.success("Type Added.", { theme: "colored" });
        else toast.info("Type Updated.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        // setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };
  const deleteTypeDB = () => {
    // setLoader(true);

    axios
      .post(
        `${hostname}/api/property/DeleteViolationSetting`,
        {
          violation_setting_id: payload._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        getAllData();
        // setLoader(false);
        handleClose();
        toast.warning("Type Deleted.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        // setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "Add New Type" && (
            <div>
              <Formik
                initialValues={{ v_type: "", description: "" }}
                validationSchema={Yup.object({
                  v_type: Yup.string().required("type is required!"),
                  description: Yup.string().required(
                    "description is required!"
                  ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  addNewTypeDB(values);
                }}
              >
                {({ isSubmitting, handleBlur, handleChange, values }) => (
                  <Form>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>Violation Type</BSForm.Label>
                      <Field
                        type="text"
                        name="v_type"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="v_type"
                        component="div"
                        className="text-danger fs--1"
                      />
                    </BSForm.Group>
                    <BSForm.Group>
                      <BSForm.Label>Description</BSForm.Label>
                      <BSForm.Control
                        as={"textarea"}
                        rows={5}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        name="description"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger fs--1"
                      />
                    </BSForm.Group>
                    <div className="text-end mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Add
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {payload.loadType === "Edit Type" && (
            <div>
              <Formik
                initialValues={{
                  v_type: payload.violation_type,
                  description: payload.violation_description,
                }}
                validationSchema={Yup.object({
                  v_type: Yup.string().required("type is required!"),
                  description: Yup.string().required(
                    "description is required!"
                  ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  addNewTypeDB(values, true);
                }}
              >
                {({ isSubmitting, handleBlur, handleChange, values }) => (
                  <Form>
                    <BSForm.Group className="mb-3">
                      <BSForm.Label>Violation Type</BSForm.Label>
                      <Field
                        type="text"
                        name="v_type"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="v_type"
                        component="div"
                        className="text-danger fs--1"
                      />
                    </BSForm.Group>
                    <BSForm.Group>
                      <BSForm.Label>Description</BSForm.Label>
                      <BSForm.Control
                        as={"textarea"}
                        rows={5}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        name="description"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger fs--1"
                      />
                    </BSForm.Group>
                    <div className="text-end mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {payload.loadType === "Delete Type" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to delete this type ?
              </p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={deleteTypeDB}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
