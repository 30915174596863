import FalconCardHeader from '../../../../../common/FalconCardHeader';
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
export default function DailyNew({ user }) {
  try {
    return (
      <Card className='mb-3'>
        <FalconCardHeader title={user.booking_type} />
        <Card.Body className="bg-light p-3">
          <Row className='ms-3'>
            <Col>
              <p>Start Time</p>
            </Col>
            <Col>
              <p>
                {user.check_in_time_for_hotel_style}{' '}
                {user.check_in_time_zone_for_hotel_style}
              </p>
            </Col>
          </Row>
          <Row className='ms-3'>
            <Col>
              <p>End Time</p>
            </Col>
            <Col>
              <p>
                {user.check_out_time_for_hotel_style}{' '}
                {user.check_out_time_zone_for_hotel_style}
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <p>Maximum length of stay</p>
            </Col>
            <Col>
              <p>{user.max_length_of_stay_hotel_style} Night</p>
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <p>Unavailable Day</p>
            </Col>
            <Col>
              <p>{user.amenity_unavailable_for_hotel_style}</p>
            </Col>
          </Row> */}
          <br />
          <Row className="mb-3 ms-3">
            <Col>Minimum Booking Lead Time</Col>
            <Col>{user.min_book_lead_time}</Col>
          </Row>
          <Row className="mb-3 ms-3">
            <Col>Maximum Booking Lead Time</Col>
            <Col>{user.max_book_lead_time}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
