import ViolationsTrakingPM from "components/pages/Features/VoilationTracking/VoilationTracking";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Loader from "components/common/Loader";
import { Card, Row, Col } from "react-bootstrap";
import { getHostName } from "helpers/utils";
import Cookies from "universal-cookie";

export default function UnitViolationsTracking() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");

  const { unit_id, property_name, property_id, unit_number } = useParams();

  const [unitDetailsData, setUnitDetailsData] = useState({});
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getUnitsDetailData(unit_id);
  }, []);

  const getUnitsDetailData = (unit_id) => {
    setLoader(true);
    axios
      .post(
        `${hostname}/api/property/UnitDetails`,
        { unit_id },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let cloneObj = Object.assign({}, res.data);
        if ("userid" in cloneObj) cloneObj.User_type = "Registered";
        else if ("Inviteduser" in cloneObj) cloneObj.User_type = "Invited";
        else cloneObj.User_type = "Pending";
        setUnitDetailsData({ ...cloneObj });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loader) return <Loader />;
  if (unitDetailsData.User_type === "Registered")
    return <ViolationsTrakingPM fromSingleUnit />;

  return (
    <div>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="d-flex align-items-center scrollbar">
              <Link className="me-2 text-secondary" to="/dashboard">
                Home
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to="/property/management/buildings"
              >
                Properties
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/property/management/buildings/${property_id}/${property_name}/profile`}
              >
                {property_name}
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/property/management/buildings/${property_id}/${property_name}/units`}
              >
                Units
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-primary"
                to="#!"
                // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/attached_units`}
              >
                {unit_number}
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-primary"
                // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                to={"#!"}
              >
                Voilations Tracking
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body
          className="d-flex justify-content-center align-items-center bg-light"
          style={{ minHeight: "90vh" }}
        >
          <h3>Add Unit Owner in this Unit to View/Track Violations.</h3>
        </Card.Body>
      </Card>
    </div>
  );
}
