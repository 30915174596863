import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
// import SoftBadge from 'components/common/SoftBadge';
// import { Badge, Table, Dropdown } from 'react-bootstrap';
// import { Dropdown } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import CardDropdown from 'components/common/CardDropdown';

const TableRow = ({ data, handleShowDetail }) => {
  // const TableRow = ({ data, selectBuilding, propLength }) => {
  // console.log(data.status.type);

  return (
    <tr
      className="align-middle"
      // style={{ 'height': '100px' }}
    >
      <td
        className="text-center text-primary cursor-pointer"
        onClick={handleShowDetail}
      >
        {data.name || 'id'}
      </td>
      <td className="text-center">
        {/* <Link */}
        {/* to={`/property/management/buildings/${data._id}/${data.property_name}/units`} */}
        {/* // onClick={() => selectBuilding(data.b_id)} */}
        {/* > */}
        {data.start || 'start'}
        {/* </Link> */}
      </td>
      {/* <td className="text-center">
        <SoftBadge pill bg={data.status.type}>
          {data.status.title}
          <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
        </SoftBadge>
      </td> */}
      {/* <td className="text-center">{data.end || 'end'}</td>
      <td className="text-center">{data.plate || 'plate'}</td>
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item>
              <Link
                // to={`/property/management/buildings/${data._id}/${data.property_name}/profile`}
                to={`#!`}
                className="d-block"
              >
                Print
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              style={{'fontWeight':'bold'}}
              // onClick={() => inviteUser(data)}
            >
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </td> */}
    </tr>
  );
};

const MailOutsTable = ({ properties, selectBuilding, handleShowDetail }) => {
  // console.log(properties);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: '14px' }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Title
          </th>
          <th scope="col" className="text-center">
            Created On
          </th>
        </tr>
      </thead>
      <tbody>
        {properties?.map((property, index) => (
          <TableRow
            data={property}
            key={index}
            selectBuilding={selectBuilding}
            propLength={properties.length}
            handleShowDetail={handleShowDetail}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default MailOutsTable;
