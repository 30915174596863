import React from "react";

import { Form as BSForm, Button, Row, Col, Image } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CalendarToday } from "@mui/icons-material";
import DatePicker from "react-datepicker";

import axios from "axios";
import { toast } from "react-toastify";

import { AuthWizardContext } from "context/Context";
import { CloudUpload } from "@mui/icons-material";
import {
  addDays,
  getDaysFromDatesRange,
  getDaysInMonth,
  getPureDate,
  isDateOfCurrentYearAndMonth,
} from "helpers/utils";

const VisitorParkingBookingDetails = ({ payload, hostname, jwtToken }) => {
  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  // console.log(user);

  const forInputrefHSS = React.useRef(null);
  const handeInputRefHSS = () => {
    forInputrefHSS.current?.setFocus();
  };

  const forInputrefHSE = React.useRef(null);
  const handeInputRefHSE = () => {
    forInputrefHSE.current?.setFocus();
  };

  const [parkingInfo, setParkingInfo] = React.useState({
    remainingUsersOvernightPermits: 0,
    BookedDates: [],
  });
  const [nextMonthLimit, setNextMonthLimit] = React.useState({
    remainingUsersOvernightPermits: 0,
    BookedDates: [],
  });
  const [permitsRangeError, setPermitsRangeError] = React.useState({
    value: false,
    err_mg: "",
  });

  const handleSubmit = (data) => {
    console.log(data);
    setUser(Object.assign({}, user, data));
    setStep(step + 1);
  };

  const diffInMonths = (end, start) => {
    var timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.round(timeDiff / (2e3 * 3600 * 365.25));
  };

  const handleDateUpdate = async (date, setFieldValue, isStartDate, values) => {
    date = getPureDate(false, date); // set time to 0
    let nextNightDate = addDays(date, 1);

    let days = 0; // total days of start to end (use only for same month)

    console.log(values); // current date values (1st time empty)
    if (values.start_date)
      values.start_date = getPureDate(false, values.start_date);
    if (values.end_date) values.end_date = getPureDate(false, values.end_date);

    if (isStartDate) {
      setFieldValue("start_date", date); // start date entry

      if (!values.end_date || date > values.end_date) {
        setFieldValue("end_date", nextNightDate); // end date entry
      }

      days = getDaysFromDatesRange(date, values.end_date || nextNightDate);

      // get updated values
      let monthData = await getMonthAvailablity(date, true);
      setParkingInfo({
        remainingUsersOvernightPermits:
          monthData.remainingUsersOvernightPermits,
        // BookedDates: monthData.BookedDates,
      });
    }

    if (!isStartDate) {
      setFieldValue("end_date", date);
      days = getDaysFromDatesRange(values.start_date, date);
      let monthData = await getMonthAvailablity(date, true);
      setNextMonthLimit({
        remainingUsersOvernightPermits:
          monthData.remainingUsersOvernightPermits,
      });
    }

    console.log("returned total days are =" + days);

    // limit calculation

    let startDateLimit = 0;
    let endDateLimit = 0;
    let increment = 1;
    let startDateDays = 0;
    let endDateDays = 0;

    if (
      isStartDate &&
      isDateOfCurrentYearAndMonth(date, values.end_date || nextNightDate) ===
        true
    ) {
      // start date
      // no need to get next month as we are in same month
      startDateLimit = await getMonthAvailablity(date);
      startDateLimit += increment;
      // console.log(startDateLimit);

      console.log("start date and same month");
      if (days > startDateLimit) setPermitsRangeError({ value: true });
      else setPermitsRangeError({ value: false });
      return;
    }

    if (
      isStartDate &&
      isDateOfCurrentYearAndMonth(date, values.end_date || nextNightDate) ===
        false
    ) {
      // start date
      // need to get next month as we are in difference month
      startDateLimit = await getMonthAvailablity(date);
      endDateLimit = await getMonthAvailablity(
        values.end_date || nextNightDate
      );

      console.log("start date and difference in month");

      // start days
      startDateDays = getDaysInMonth(date) - date.getDate();

      startDateDays = deductBookedDatesIfInRange(
        // subtract booked dates if lies in selectednights range
        date.getDate(),
        getDaysInMonth(date),
        startDateDays
      );

      if (startDateDays > startDateLimit) {
        console.log("error in start date!");
        displayErrorMessageOfPermitsLimit(
          startDateDays,
          startDateLimit,
          "start"
        );
        return;
      } else {
        setPermitsRangeError({ value: false, err_mg: "" });
      }

      // end days
      endDateDays =
        getDaysInMonth(values.end_date || nextNightDate) - values.end_date
          ? values.end_date
            ? values.end_date.getDate()
            : nextNightDate.getDate()
          : nextNightDate.getDate();

      endDateDays = deductBookedDatesIfInRange(
        // subtract booked dates if lies in selectednights range
        1,
        date.getDate(),
        endDateDays
      );

      if (endDateDays > endDateLimit) {
        console.log("error in end date!");
        displayErrorMessageOfPermitsLimit(endDateDays, endDateLimit, "end");
        return;
      } else {
        setPermitsRangeError({ value: false, err_mg: "" });
      }

      return;
    }

    if (
      !isStartDate &&
      isDateOfCurrentYearAndMonth(values.start_date, date) === true
    ) {
      // end date
      // no need to get next month as we are in same month
      startDateLimit = await getMonthAvailablity(values.start_date);
      startDateLimit += increment;

      console.log("end date and same month");
      if (days > startDateLimit) setPermitsRangeError({ value: true });
      else setPermitsRangeError({ value: false });
      return;
    }

    if (
      !isStartDate &&
      isDateOfCurrentYearAndMonth(values.start_date, date) === false
    ) {
      // end date
      // need to get next month as we are in difference month
      startDateLimit = await getMonthAvailablity(values.start_date);
      endDateLimit = await getMonthAvailablity(date);
      endDateLimit++;

      console.log("end date and difference in month");

      // start days
      startDateDays =
        getDaysInMonth(values.start_date) - values.start_date.getDate();

      startDateDays = deductBookedDatesIfInRange(
        // subtract booked dates if lies in selectednights range
        values.start_date.getDate(),
        getDaysInMonth(values.start_date),
        startDateDays
      );

      if (startDateDays > startDateLimit) {
        console.log("error in start date!");
        displayErrorMessageOfPermitsLimit(
          startDateDays,
          startDateLimit,
          "start"
        );
        return;
      } else {
        setPermitsRangeError({ value: false, err_mg: "" });
      }

      // end days
      //endDateDays = Math.abs(getDaysInMonth(date) - date.getDate());
      endDateDays = date.getDate();
      console.log("end days sp", endDateDays);

      endDateDays = deductBookedDatesIfInRange(
        // subtract booked dates if lies in selectednights range
        1,
        date.getDate(),
        endDateDays
      );

      if (endDateDays > endDateLimit) {
        console.log("error in end date!");
        displayErrorMessageOfPermitsLimit(endDateDays, endDateLimit, "end");
        return;
      } else {
        setPermitsRangeError({ value: false, err_mg: "" });
      }
    }
  };

  const deductBookedDatesIfInRange = (start = 0, end = 0, daysOfMonth = 0) => {
    let bookedDates = [];
    bookedDates = parkingInfo.BookedDates;
    let daysToDeduct = 0;
    if (bookedDates?.length > 0) {
      bookedDates?.forEach((v) => {
        let d = getPureDate(v);
        if (d.getDate >= start && d.getDate() <= end) daysToDeduct++;
      });

      daysOfMonth = Math.abs(daysOfMonth - daysToDeduct);
      console.log(daysOfMonth);
      return daysOfMonth;
    } else return daysOfMonth;
  };

  const displayErrorMessageOfPermitsLimit = (
    selectedDays = 0,
    permitsLimit = 0,
    msgFor = ""
  ) => {
    console.log(selectedDays);
    console.log(permitsLimit);
    setPermitsRangeError({
      value: true,
      err_mg: `limit reached for ${msgFor} date!"`,
    });
    toast.error(
      `selected nights = ${selectedDays}\n\n
        permits limit = ${permitsLimit}`,
      { theme: "colored", autoClose: false }
    );
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({ ...values }, user));
    setStep(step - 1);
  };

  React.useEffect(() => {
    getParkingInfo();
  }, []);

  const getParkingInfo = () => {
    axios
      .post(
        `${hostname}/api/property/getParkingInfo`,
        {
          p_id: payload.userData.propid,
          unit_id: payload.userData.UnitsOwner[0]._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let obj = {};
        obj.remainingUsersOvernightPermits =
          res.data.Data.remainingUsersOvernightPermits;
        obj.BookedDates = res.data.Data.BookedDates?.map((v) => {
          let date = getPureDate(false, v, false);
          return date;
        });
        obj.BookedDates = obj.BookedDates.map((v) => {
          let d = addDays(v, 1);
          return d;
        });
        console.log(obj);
        setParkingInfo(obj);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMonthAvailablity = async (ref_date, returnFullData = false) => {
    let response = await axios.post(
      `${hostname}/api/property/MonthAvailablityCheck`,
      {
        p_id: payload.userData.propid,
        unit_id: payload.userData.UnitsOwner[0]._id,
        reference_date: ref_date,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    // .then((res) => {
    console.log(response.data);
    // setMonthAvailablity(res.data.Data);
    if (returnFullData) return response.data.Data;
    else return response.data.Data.remainingUsersOvernightPermits;
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  return (
    <div>
      <Formik
        initialValues={{
          start_date: user.start_date
            ? getPureDate(false, user.start_date)
            : "",
          end_date: user.end_date ? getPureDate(false, user.end_date) : "",
          parking_spot: user.parking_spot || "Visitor Parking",
          send_confirmation_mail: user.send_confirmation_mail || "No",
        }}
        validationSchema={Yup.object({
          start_date: Yup.string().required("this option is required!"),
          end_date: Yup.string().required("this option is required!"),
          parking_spot: Yup.string().required("this option is required!"),
          send_confirmation_mail: Yup.string().required(
            "this option is required!"
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <BSForm.Group>
                  <BSForm.Label>Start Date</BSForm.Label>
                  <div className=" position-relative">
                    <DatePicker
                      ref={forInputrefHSS}
                      // showIcon
                      placeholderText="Select Start Date"
                      selected={values.start_date}
                      name="start_date"
                      type="date"
                      onChange={(e) =>
                        handleDateUpdate(e, setFieldValue, true, values)
                      }
                      onBlur={handleBlur}
                      value={values.start_date}
                      className="form-control"
                      minDate={new Date()}
                      // maxDate={values.event_end_date}
                      excludeDates={parkingInfo.BookedDates}
                    />
                    <span
                      className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                      onClick={handeInputRefHSS}
                    >
                      <CalendarToday fontSize="small" />
                    </span>
                  </div>
                  <ErrorMessage
                    name="start_date"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
              <Col md={6}>
                <BSForm.Group>
                  <BSForm.Label>End Date</BSForm.Label>

                  <div className=" position-relative">
                    <DatePicker
                      ref={forInputrefHSE}
                      // showIcon
                      placeholderText="Select End Date"
                      selected={values.end_date}
                      name="end_date"
                      type="date"
                      onChange={(e) =>
                        handleDateUpdate(e, setFieldValue, false, values)
                      }
                      onBlur={handleBlur}
                      value={values.end_date}
                      className="form-control"
                      minDate={addDays(values.start_date, 1)}
                      // excludeDates={parkingInfo.BookedDates?.map((v) =>
                      //   getPureDate(false, v)
                      // )}
                      excludeDates={parkingInfo.BookedDates}
                      disabled={!values.start_date}
                    />
                    <span
                      className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                      onClick={handeInputRefHSE}
                    >
                      <CalendarToday fontSize="small" />
                    </span>
                  </div>

                  <ErrorMessage
                    name="end_date"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
            </Row>
            {permitsRangeError.value && (
              <div className="text-danger text-center">
                selected date range exceeds remaining overnight permits!
                <br />
                please adjust the range and try again...
              </div>
            )}
            <BSForm.Group className="mb-3">
              <BSForm.Label>Parking Spot</BSForm.Label>
              <Field
                component="select"
                id="parking_spot"
                name="parking_spot"
                className="form-select"
                disabled
              >
                <option value="">Select</option>

                {["Visitor Parking"]?.map((v) => (
                  <option value={v} key={v}>
                    {v}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="parking_spot"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>

            <BSForm.Group className="mb-2 mt-4">
              <div className="d-flex">
                <p>Send Confirmation Mail: </p>

                <label className="mx-3 ">
                  <span className="h6">Yes</span>{" "}
                  <Field
                    type="radio"
                    className="form-check-input"
                    name="send_confirmation_mail"
                    value="Yes"
                  />
                </label>
                <label className="mx-3">
                  {" "}
                  <span className="h6">No</span>{" "}
                  <Field
                    type="radio"
                    className="form-check-input"
                    name="send_confirmation_mail"
                    value="No"
                  />
                </label>
              </div>
              <ErrorMessage
                name="send_confirmation_mail"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>

            <div>
              {!values.start_date &&
                parkingInfo?.remainingUsersOvernightPermits < 1 && (
                  <p className="text-warning">
                    You have used your monthly quota for visitor parking. <br />
                    Select a different month and try again...{" "}
                  </p>
                )}
              {!values.start_date &&
                parkingInfo?.remainingUsersOvernightPermits > 0 && (
                  <p>
                    Remaining Monthly Overnight Permits :{" "}
                    <span className="fw-bold ms-3">
                      {parkingInfo?.remainingUsersOvernightPermits || 0}
                    </span>
                  </p>
                )}
              {values.start_date &&
                parkingInfo?.remainingUsersOvernightPermits > 0 && (
                  <p>
                    Remaining Monthly Overnight Permits :{" "}
                    <span className="fw-bold ms-3">
                      {parkingInfo?.remainingUsersOvernightPermits || 0}
                    </span>
                  </p>
                )}
              {values.end_date &&
                !isDateOfCurrentYearAndMonth(
                  values.start_date,
                  values.end_date
                ) &&
                nextMonthLimit?.remainingUsersOvernightPermits > 0 && (
                  <p>
                    Remaining Monthly Overnight Permits Next :{" "}
                    <span className="fw-bold ms-3">
                      {nextMonthLimit?.remainingUsersOvernightPermits || 0}
                    </span>
                  </p>
                )}
              {values.end_date &&
                parkingInfo?.remainingUsersOvernightPermits < 1 && (
                  <p className="text-warning">
                    You have used your monthly quota for visitor parking of next
                    month!. <br />
                    Select a different month and try again...{" "}
                  </p>
                )}
            </div>
            <br />
            <div className="">
              <Button
                variant="secondary"
                type="button"
                onClick={() => handleStepMinus(values)}
              >
                Previous
              </Button>
              {!permitsRangeError.value &&
                // values.start_date &&
                parkingInfo?.remainingUsersOvernightPermits > 0 && (
                  <Button
                    variant="primary"
                    className="float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                )}

              {(permitsRangeError.value ||
                parkingInfo?.remainingUsersOvernightPermits < 1) && (
                <span className="text-danger float-end">
                  {permitsRangeError.err_mg || "Permits limit reached!"}
                </span>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VisitorParkingBookingDetails;
