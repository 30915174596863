import React from 'react';
import { Card } from 'react-bootstrap';
import CustomTitle from './CustomTitle';

export default function OnlinePayment({ handleSelectTab }) {
  return (
    <div>
      <Card>
        <CustomTitle
          heading="Online Payment"
          handleSelectTab={handleSelectTab}
        />
        <Card.Body className="bg-light">
          content will be here
        </Card.Body>
      </Card>
    </div>
  );
}
