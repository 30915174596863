import Flex from "components/common/Flex";
// import PropTypes from 'prop-types';
import React from "react";
// import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getIconClassNames = (type) => {
  switch (type) {
    case "youtube":
      return [["fab", "youtube"], "text-youtube"];
    case "zip":
      return ["file-archive", "text-warning"];
    case "doc":
      return ["file-alt", "text-primary"];
    case "application/octet-stream":
      return ["file-alt", "text-primary"];
    case "image/png":
      return ["image", "text-danger"];
    case "image/jpg":
      return ["image", "text-danger"];
    case "image/jpeg":
      return ["image", "text-danger"];
    case "application/pdf":
      return ["file-pdf", "text-danger"];
    default:
      return ["file-alt", "text-primary"];
  }
};

const EmailAttachment = ({
  attachment,
  // previewAttachment,
  displaySingleEmailData,
  email,
}) => {
  // const { name, icon, mimetype, src } = attachment;
  const { name, mimetype } = attachment;
  // console.log(attachment);

  return (
    <Flex
      inline
      alignItems="center"
      className="border rounded-pill px-3 py-1 me-2 mt-2 inbox-link cursor-pointer"
      onClick={() => displaySingleEmailData(email)}
      // onClick={() => previewAttachment(attachment)}
    >
      {/* {type === 'img' && isOpen && (
        <Lightbox
          mainSrc={src}
          reactModalStyle={{ overlay: { zIndex: 999999 } }}
          onCloseRequest={() => setIsOpen(!isOpen)}
        />
      )} */}
      <FontAwesomeIcon
        icon={
          mimetype !== undefined ? getIconClassNames(mimetype)[0] : "file-alt"
        }
        transform="grow-4"
        className={getIconClassNames(mimetype)[1]}
      />
      <span className="ms-2">{name}</span>
    </Flex>
  );
};

export default EmailAttachment;
