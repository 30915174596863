import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../common/Flex";
import { PieChart } from "echarts/charts";
import * as echarts from "echarts/core";
import { getColor } from "../../../helpers/utils";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import BasicECharts from "../../common/BasicEChart";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
]);

const getOptions = (data, radius, total = 0) => ({
  color: [
    getColor(data[0].color || 'primary'),
    getColor(data[1].color || 'info'),
    getColor(data[2].color || 'light'),
    getColor(data[3].color || 'success'),
  ],

  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor("gray-100"),
    borderColor: getColor("gray-300"),
    textStyle: { color: getColor("dark") },
    borderWidth: 1,
    formatter: (params) =>
      `<strong>${params.data.name}:</strong> ${params.percent}%`,
  },
  series: [
    {
      name: total || "12500",
      type: "pie",
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false,
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor("gray-100"),
      },
      label: {
        show: true,
        position: "center",
        formatter: "{a}",
        fontSize: 23,
        color: getColor("dark"),
      },
      data,
    },
  ],
});

const PropertyUnitItem = ({ item, index, total }) => {
  const { name, color, value } = item;

  //const percentage = ((value * 100) / total).toFixed(0);
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index == 0 && "mt-3"}`}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2 text-${color.split("-")[1] || color || "text-info"}`}
        />
        {name}
      </p>
      <div className="d-xxl-none">{value}</div>
    </Flex>
  );
};

const PropertiesUnits = ({
  data,
  radius,
  fromowner,
  propertiesUnitsData = {},
}) => {
  let { self_occupied, vacant, rented, locker_parking } = propertiesUnitsData;

  // const total = data.reduce((acc, val) => val.value + acc, 0);
  let total = self_occupied + vacant + rented + locker_parking;

  let PMData = [
    {
      id: 1,
      value: self_occupied,
      name: "Self Occupied Units",
      color: "primary",
    },
    { id: 2, value: rented, name: "Rented Units", color: "info" },
    {
      id: 3,
      value: locker_parking,
      name: "Locker & Parkings",
      color: "success",
    },
    { id: 4, value: vacant, name: "Vacant Units", color: "gray-300" },
  ];

  if (fromowner)
    return (
      <Card className="h-md-100">
        <Card.Body>
          <Row className="justify-content-between g-0">
            <Col xs={5} sm={6} xxl className="pe-2">
              {/* <h6 className="mt-1">Market Share</h6> */}
              <h6 className="mt-1">Residents</h6>

              {data.map((item, index) => (
                <PropertyUnitItem
                  item={item}
                  index={index}
                  key={index}
                  total={total}
                />
              ))}
            </Col>
            <Col xs="auto">
              <div className="ps-0">
                {/* <BasicECharts
                  echarts={echarts}
                  options={getOptions(data, radius)}
                  style={{ width: "6.625rem", height: "6.625rem" }}
                /> */}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );

  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
            {/* <h6 className="mt-1">Market Share</h6> */}
            <h6 className="mt-1">Properties Units</h6>

            {PMData.map((item, index) => (
              <PropertyUnitItem
                item={item}
                index={index}
                key={index}
                total={total}
              />
            ))}
          </Col>
          <Col xs="auto">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(PMData, radius, total)}
                style={{ width: "6.625rem", height: "6.625rem" }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PropertiesUnits;
