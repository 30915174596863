import React from 'react';
// import img1 from 'assets/img/products/poolroom.jpg';
// import img2 from 'assets/img/products/partyroom.jpg';
// import img3 from 'assets/img/products/gymroom.jpg';
import './StackedImagesComponent.css';

export default function StackedImagesComponent({ images = [] }) {
  try {
    // let images = [img1, img2, img3];
    // images = [images[0]];
    if (images?.length > 0) {
      if (images.length === 1)
        return (
          <div className="" style={{ width: '150px', height: '100px' }}>
            <img
              src={images[0]}
              className="img-fluid w-100 h-100 img-single imgs"
              style={{ borderRadius: '8px' }}
            />
          </div>
        );
      else
        return (
          <>
            <div
              className="img-base cursor-pointer"
              style={{ width: '150px', height: '100px' }}
            >
              <img
                src={images[0]}
                className="img-fluid w-100 h-100 imgs img-single"
                style={{ borderRadius: '8px' }}
              />

              {images.slice(1)?.map((v, i) => (
                <div
                  key={i + 'hl0s'}
                  className={`img-over-${i + 1}`}
                  style={{ width: '150px', height: '100px' }}
                >
                  <img
                    src={v}
                    className="w-100 h-100 imgs img-single"
                    style={{ borderRadius: '8px' }}
                  />
                </div>
              ))}
            </div>
          </>
        );
    } else return <p className="text-info">No images provided!</p>;
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
