import CustomSearchbar from "components/common/CustomSearchbar";
import Flex from "components/common/Flex";
import React from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  Dropdown,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import Cookies from "universal-cookie";
import CardDropdown from "components/common/CardDropdown";
import {
  getFileType,
  formatLogInfo,
  getFileExtData,
  getHostName,
} from "helpers/utils";
import Loader from "components/common/Loader";
import { DeleteForever, Undo } from "@mui/icons-material";
import IconButton from "components/common/IconButton";
import axios from "axios";
import { toast } from "react-toastify";

export default function DocumentDriveTrash() {
  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  let hostname = getHostName();
  const navigate = useNavigate();

  const { property_name, property_id } = useParams();

  const [loader, setLoader] = React.useState(false);

  const [DriveTrashFilesList, setDriveTrashFilesList] = React.useState([]);
  const [DriveTrashFilesListTemp, setDriveTrashFilesListTemp] = React.useState(
    []
  );
  const [bulkFiles, setBulkFiles] = React.useState([]);

  const [showModal, setShowModal] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  console.log(payload);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  React.useEffect(() => {
    getDriveFilesBaseFolderData();
  }, []);

  const getDriveFilesBaseFolderData = async () => {
    setLoader(true);

    axios
      .post(
        `${hostname}/api/property/GetTrash`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        setDriveTrashFilesList(res.data.data);
        setDriveTrashFilesListTemp(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const addBulkAction = (v) => {
    console.log(v);
    if (bulkFiles.some((j) => j._id === v._id)) {
      let state = bulkFiles?.filter((j) => j._id !== v._id);
      setBulkFiles(state);
    } else {
      setBulkFiles(bulkFiles?.concat(v));
    }
  };

  const applyBulkAction = async (actionType = "") => {
    setPayload({});

    if (bulkFiles.length < 1) return;

    if (actionType === "recover") {
      let obj = {};
      obj.loadType = "Bulk Recover";
      obj.bulkFiles = bulkFiles;
      obj.setBulkFiles = setBulkFiles;

      setPayload({ ...obj });
      handleShow();
    }

    if (actionType === "p-delete") {
      let obj = {};
      obj.loadType = "Bulk Permanent Delete";
      obj.bulkFiles = bulkFiles;
      obj.setBulkFiles = setBulkFiles;
      setPayload({ ...obj });
      handleShow();
    }

    setBulkFiles([]);
  };

  const handle_soft_recover = (obj = {}) => {
    setPayload({});
    obj.loadType = "Recover";
    obj.p_id = property_id;
    setPayload({ ...obj });
    handleShow();
  };

  const handle_permanent_delete = (obj = {}) => {
    setPayload({});

    obj.loadType = "Permanent Delete";
    obj.p_id = property_id;
    setPayload({ ...obj });
    handleShow();
  };

  const recover_from_soft_delete = (bulkMode = false) => {
    let file_id_recover = [];
    file_id_recover.push(payload._id);

    if (bulkMode) file_id_recover = payload.bulkFiles?.map((v) => v._id);

    if (!file_id_recover)
      toast.error("entry id not found", { theme: "colored" });

    axios
      .post(
        `${hostname}/api/property/RecoverDocument`,
        {
          doc_id: file_id_recover,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        handleClose();
        toast.success("Recover Success!", { theme: "colored" });
        getDriveFilesBaseFolderData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const recover_from_soft_delete_bulk = () => {
    for (let i = 0; i < payload.bulkFiles.length; i++) {
      const element = payload.bulkFiles[i];
      recover_from_soft_delete(element._id, true);
    }
  };

  const permanently_delete = (bulkMode = false) => {
    let file_id_perma_del = [];
    file_id_perma_del.push(payload._id);
    console.log(file_id_perma_del);
    if (bulkMode) file_id_perma_del = payload.bulkFiles?.map((v) => v._id);
    console.log(file_id_perma_del);

    if (!file_id_perma_del)
      toast.error("entry id not found", { theme: "colored" });

    axios
      .post(
        `${hostname}/api/property/PermanentDeleteFiles`,
        {
          doc_id: file_id_perma_del,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.warning("Delete Success!", { theme: "colored" });
        handleClose();
        getDriveFilesBaseFolderData();
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong!", { theme: "colored" });
        // setLoader(false);
      });
  };

  const permanently_delete_bulk = () => {
    for (let i = 0; i < payload.bulkFiles.length; i++) {
      const element = payload.bulkFiles[i];
      permanently_delete(element._id, true);
    }
  };

  if (loader) return <Loader />;
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link className="text-secondary" to={"/"}>
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    className="text-secondary"
                    to={"/property/management/buildings"}
                  >
                    Properties
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="text-secondary" to={"#!"}>
                    {property_name}
                  </Link>
                </Breadcrumb.Item>{" "}
                <Breadcrumb.Item active>
                  <Link to={"#!"}>Document Drive Trash</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>
              <ItemButton
                tootltip="Back to inbox"
                icon="arrow-left"
                onClick={() => navigate(-1)}
              />
              <span className="ms-3">Document Drive Trash</span>
            </h4>
            <div className="d-flex">
              {/* <Button variant="primary" size="sm" className="ms-3">
                Empty Bin
              </Button> */}
            </div>
          </Flex>
          <br />

          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              {bulkFiles.length > 1 && (
                <div>
                  <Button
                    variant="falcon-success"
                    onClick={() => applyBulkAction("recover")}
                    disabled={bulkFiles.length < 1}
                    className="me-3"
                  >
                    Recover All
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => applyBulkAction("p-delete")}
                    disabled={bulkFiles.length < 1}
                    className="me-3"
                  >
                    Delete All
                  </Button>
                </div>
              )}
              <CustomSearchbar
                dataListTemp={DriveTrashFilesListTemp}
                stateUpdator={setDriveTrashFilesList}
                searchFlags={["name"]}
                placeholders={["file name"]}
              />
            </Col>
          </Row>

          <div style={{ minHeight: "90vh" }}>
            <Table
              responsive
              //striped
              hover
              //bordered
              style={{ fontSize: "14px" }}
              className="pb-5"
            >
              <thead>
                <tr>
                  <th scope="col" className="fw-bold"></th>
                  <th scope="col" className="fw-bold">
                    Name
                  </th>
                  <th scope="col" className="text-center fw-bold">
                    Deleted On
                  </th>
                  <th scope="col" className="text-center fw-bold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {DriveTrashFilesList?.map((item, index) => (
                  <tr
                    key={index}
                    className="align-middle"
                    style={{
                      height: DriveTrashFilesList.length === 1 ? "100px" : "",
                    }}
                  >
                    <td className="fw-bold">
                      <Form.Check
                        type="checkbox"
                        id={item._id}
                        onChange={() =>
                          addBulkAction({
                            _id: item._id,
                            name: item.name,
                          })
                        }
                        checked={bulkFiles?.some((v) => v._id === item._id)}
                      />
                    </td>
                    <td className="fw-bold">
                      <div className="d-flex align-items-center">
                        {item.type === "file" && (
                          <span className="me-3" style={{ width: "25px" }}>
                            {getFileExtData(
                              item.name.substr(item.name.lastIndexOf(".") + 1)
                            )}
                          </span>
                        )}
                        {item.type === "folder" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {getFileType({ mimetype: "folder" }, true)}
                          </span>
                        )}

                        {item.name}
                      </div>
                    </td>
                    <td className="text-center">
                      {formatLogInfo(item.createdAt, true)}
                    </td>

                    <td className="text-center">
                      <CardDropdown>
                        <div className="py-2">
                          <Dropdown.Item
                            // className="text-primary"
                            onClick={() => handle_soft_recover(item)}
                          >
                            <span className="me-3">
                              <Undo fontSize="small" />
                            </span>
                            Recover
                          </Dropdown.Item>
                          <Dropdown.Item
                            // className="text-danger"
                            onClick={() => handle_permanent_delete(item)}
                          >
                            <span className="me-3">
                              <DeleteForever fontSize="small" />
                            </span>
                            Premanent Delete!
                          </Dropdown.Item>
                        </div>
                      </CardDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "Recover" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to Recover <code>"{payload.name}"</code> ?
              </p>
              <div className="text-end">
                <Button
                  variant="primary"
                  className="me-3 "
                  size="md"
                  onClick={() => recover_from_soft_delete()}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {payload.loadType === "Bulk Recover" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to Recover All ?
              </p>
              <p>
                {payload?.bulkFiles?.map((v) => (
                  <span key={v._id}>
                    <code>{v.name} </code>
                    <br />
                  </span>
                ))}
              </p>
              <div className="text-end">
                <Button
                  variant="primary"
                  className="me-3 "
                  size="md"
                  onClick={() => recover_from_soft_delete(true)}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {payload.loadType === "Permanent Delete" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to Permanently delete{" "}
                <code>"{payload.name}"</code> ?
              </p>
              <div className="text-end">
                <Button
                  variant="danger"
                  className="me-3 "
                  size="md"
                  onClick={() => permanently_delete()}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {payload.loadType === "Bulk Permanent Delete" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to Permanently delete All ?
              </p>
              <p>
                {payload?.bulkFiles?.map((v) => (
                  <span key={v._id}>
                    <code>{v.name} </code>
                    <br />
                  </span>
                ))}
              </p>
              <div className="text-end">
                <Button
                  variant="danger"
                  className="me-3 "
                  size="md"
                  onClick={() => permanently_delete(true)}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

const ItemButton = ({ tootltip, icon, className, onClick }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="overlay-trigger-example">{tootltip}</Tooltip>}
    >
      <div className="d-inline-block">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon={icon}
          className={className}
          onClick={onClick}
        />
      </div>
    </OverlayTrigger>
  );
};
