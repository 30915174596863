import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import Loader from "components/common/Loader";

import LinkedUnitsDetails from "components/pages/BuildingManagement/BuildingInfoFolder/UnitInfo/unitDetails/AttachedUnits/AttachedUnits";

import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

export default function UnitProfile() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");

  const { unit_id } = useParams();

  const [loader, setLoader] = useState(false);
  const [unitData, setUnitData] = useState({});
  
  useEffect(() => {
    getUnitsDetailData();
  }, []);

  const getUnitsDetailData = () => {
    setLoader(true);
    axios
      .post(
        `${hostname}/api/property/UnitDetails`,
        { unit_id },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let unitDataTmp = [
          res.data,
          ...res.data?.lockers,
          ...res.data?.parkings,
        ];
        setUnitData(unitDataTmp);

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div>
        <LinkedUnitsDetails unitData={unitData} fromOwner />
      </div>
    );
  }
}
