import React from "react";
import "./ImageUser.css";

function ImageUser({ src, firstname, lastname, bgColor }) {
  if (!src) {
    let l1 = firstname[0].toUpperCase();
    let l2 = lastname[0].toUpperCase();

    let colors = [
      "purple",
      "black",
      "red",
      "blue",
      "orange",
      "crimson",
      "blueviolet",
      "brown",
      "slateblue",
      "chocolate",
      "coral",
      "cornflowerblue",
      "sienna",
      "rebeccapurple",
    ];

    let rn = Math.floor(Math.random() * colors.length);

    return (
      <div
        className="bg-nameU text-white mb-5 "
        style={{
          backgroundColor: bgColor || colors[rn],
          border:'5px solid whitesmoke',
          boxShadow:'0px 0px 2px 2px silver'
        }}
      >
        <span className="bg-dataU">{`${l1}${l2}`}</span>
      </div>
    );
  }

  return (
    <img
      src={src}
      className="img-fluid rounded-circle"
      alt="sample"
      width="35"
    />
  );
}

export default ImageUser;
