import React from 'react';
import generic6 from '../../assets/img/generic/6.jpg';
import generic7 from '../../assets/img/generic/7.jpg';
import generic8 from '../../assets/img/generic/8.jpg';
import generic5 from '../../assets/img/generic/5.jpg';
import generic9 from '../../assets/img/generic/9.jpg';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BSSliderCustom.css';
import { RotatingLines } from 'react-loader-spinner';
export default function BSSliderCustom({ items = [], fromShowAmenities,fromReviewAmenity }) {
  const [loader, setLoader] = React.useState(false);
  
  React.useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);
  
  try {
    // console.log(items);
    let images = [generic6, generic7, generic5, generic8, generic9];

    if (loader)
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '100%' }}
        >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      );
    if (fromReviewAmenity)
      return (
        <Carousel
          className="theme-slider text-dark"
          nextIcon={<FontAwesomeIcon icon="angle-right" />}
          prevIcon={<FontAwesomeIcon icon="angle-left" />}
        >
          {items.length > 0
            ? items?.map((v, i) => (
                <Carousel.Item key={i} className="">
                  <img
                    className="d-block w-100 h-100 rounded-3 img-backdrop"
                    src={
                      fromShowAmenities
                        ? v
                        : URL.createObjectURL(v.img) || 'img here'
                    }
                    alt="First slide"
                  />
                </Carousel.Item>
              ))
            : images.map((v, i) => (
                <Carousel.Item key={i}>
                  <img className="d-block w-100" src={v} alt="First slide" />
                </Carousel.Item>
              ))}
        </Carousel>
      );
    return (
      <Carousel
        className="theme-slider text-dark"
        nextIcon={<FontAwesomeIcon icon="angle-right" />}
        prevIcon={<FontAwesomeIcon icon="angle-left" />}
      >
        {items.length > 0
          ? items?.map((v, i) => (
              <Carousel.Item key={i} className="" style={{ height: '300px' }}>
                <img
                  className="d-block w-100 h-100 rounded-3 img-backdrop"
                  src={
                    fromShowAmenities
                      ? v
                      : URL.createObjectURL(v.img) || 'img here'
                  }
                  alt="First slide"
                />
              </Carousel.Item>
            ))
          : images.map((v, i) => (
              <Carousel.Item key={i}>
                <img className="d-block w-100" src={v} alt="First slide" />
              </Carousel.Item>
            ))}
      </Carousel>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
