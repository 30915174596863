import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { AuthWizardContext } from "context/Context";

import { ErrorMessage, Formik, Field } from "formik";
import Success from "./Success";
import Loader from "components/common/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function AmenityPayment({
  selectedAmenity,
  reset,
  handleClose,
  getAmenitiesData,
  userData,
  hostname,
  jwtToken,
}) {
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  console.log(user);

  const [type, setType] = useState("payment");

  const [loader, setLoader] = useState(false);
  const { unit_id } = useParams();

  let hotelStyleDays = 0;
  if (selectedAmenity.type === "Hotel Style") {
    console.log(user.start_date);
    console.log(user.end_date);
    hotelStyleDays = user.end_date - user.start_date;
    hotelStyleDays = hotelStyleDays / 8.64e7;
    // hotelStyleDays += 1;
  }
  if (selectedAmenity.type === "Daily") {
    console.log(user.start_date);
    console.log(user.end_date);
    hotelStyleDays = user.end_date - user.start_date;
    hotelStyleDays = hotelStyleDays / 8.64e7;
    hotelStyleDays += 1;
  }
  console.log(hotelStyleDays);
  let minutes;

  if (selectedAmenity.payment_type === "Per Hour") {
    function getTimeDifference(startTime, endTime) {
      const difference = endTime - startTime;
      const differenceInMinutes = difference / 1000 / 60;
      let hours = Math.floor(differenceInMinutes / 60);
      if (hours < 0) {
        hours = 24 + hours;
      }
      let minutes = Math.floor(differenceInMinutes % 60);
      if (minutes < 0) {
        minutes = 60 + minutes;
      }
      const hoursAndMinutes = hours + ":" + (minutes < 10 ? "0" : "") + minutes;
      return hoursAndMinutes;
    }

    function get24hTime(str) {
      str = String(str).toLowerCase().replace(/\s/g, "");
      var has_am = str.indexOf("am") >= 0;
      var has_pm = str.indexOf("pm") >= 0;
      // first strip off the am/pm, leave it either hour or hour:minute
      str = str.replace("am", "").replace("pm", "");
      // if hour, convert to hour:00
      if (str.indexOf(":") < 0) str = str + ":00";
      // now it's hour:minute
      // we add am/pm back if striped out before
      if (has_am) str += " am";
      if (has_pm) str += " pm";
      // now its either hour:minute, or hour:minute am/pm
      // put it in a date object, it will convert to 24 hours format for us
      var d = new Date("1/1/2011 " + str);
      // make hours and minutes double digits
      var doubleDigits = function (n) {
        return parseInt(n) < 10 ? "0" + n : String(n);
      };
      return doubleDigits(d.getHours()) + ":" + doubleDigits(d.getMinutes());
    }

    let start_time = user.time_slot?.split("-")[0];
    let end_time = user.time_slot?.split("-")[1].trim();

    console.log(start_time);
    console.log(end_time);

    let t1 = start_time[start_time.length - 3];
    let t2 = end_time[start_time.length - 3];

    console.log(t1);
    console.log(t2);

    start_time = get24hTime(start_time);
    end_time = get24hTime(end_time);

    let timeTmp = start_time?.split(":");
    // timeTmp = get24hTime(timeTmp);
    let time1 = timeTmp[0];
    let time2 = timeTmp[1].split(" ")[0];
    console.log(time1, time2);

    let timeTmpE = end_time?.split(":");
    // timeTmpE = get24hTime(timeTmpE);
    let time3 = timeTmpE[0];
    let time4 = timeTmpE[1].split(" ")[0];
    console.log(time3, time4);

    console.log(get24hTime(start_time));
    console.log(get24hTime(end_time));

    const start = new Date(0, 0, 0, time1, time2);
    const end = new Date(0, 0, 0, time3, time4);

    let difference = getTimeDifference(start, end);

    console.log(difference);

    let p1 = difference?.split(":")[0];
    let p2 = difference?.split(":")[1];
    p1 = +p1;
    p2 = +p2;
    console.log(p1, p2);

    console.log(typeof +p2);

    if (p1 !== 0 && p2 !== 0) {
      let t = p1 * 60 + p2;
      minutes = t;
      console.log(`t is ${t} case 1`);
    }
    if (p1 !== 0 && p2 === 0) {
      let t = p1 * 60;
      minutes = t;
      console.log(`t is ${t} case 2`);
    }

    if (p1 === 0 && p2 !== 0) {
      let t = p2;
      minutes = t;
      console.log(`t is ${t} case 3`);
    }

    // return;
    // var startTime = moment(start_time, `HH:mm ${"a"}`);
    // var endTime = moment(end_time, `HH:mm ${"a"}`);
    // console.log(startTime);
    // console.log(endTime);
    // // calculate total duration
    // var duration = moment.duration(endTime.diff(startTime));
    // console.log(duration);
    // // duration in hours
    // // var minutes = parseInt(duration.asMinutes());
    // console.log(minutes);

    // let d1 = DateTime.
  }

  // find price
  let amenity_fee = 0.0;

  if (selectedAmenity.type === "Hotel Style") {
    amenity_fee = +selectedAmenity.amenity_price * hotelStyleDays;
  }
  if (selectedAmenity.type === "Daily") {
    if (user.isSingleDate) {
      amenity_fee = selectedAmenity.amenity_price;
    } else {
      amenity_fee = +selectedAmenity.amenity_price * hotelStyleDays;
    }
  }
  if (selectedAmenity.type === "Time Slots") {
    if (selectedAmenity.payment_type === "Per Hour") {
      // if (minutes < 60) {
      //   console.log("less than 60");
      //   amenity_fee = (+selectedAmenity.amenity_price * minutes) / 60;
      // } else {
      console.log("above 60");
      amenity_fee = +selectedAmenity.amenity_price * (minutes / 60);
      console.log(minutes);
      console.log(selectedAmenity.amenity_price);
      console.log(amenity_fee);
      // }
    } else amenity_fee = selectedAmenity.amenity_price;
  }

  const bookAmenity = (values) => {
    try {
      setLoader(true);

      console.log(userData);
      console.log(user);
      let bookingData = {};
      bookingData.amenity_id = selectedAmenity._id;
      bookingData.user_id = userData.data;
      bookingData.p_id = userData.propid;
      bookingData.payment_option = values.payment_method;
      bookingData.date = user.start_date;

      bookingData.status = "Booked";
      bookingData.email_remainder = user.email_remainder;
      bookingData.amenity_fee = amenity_fee;

      // if (selectedAmenity.type === "Hotel Style") {
      //   bookingData.amenity_fee =
      //     +selectedAmenity.amenity_price * hotelStyleDays;
      // }
      // if (selectedAmenity.type === "Daily") {
      //   if (user.isSingleDate) {
      //     bookingData.amenity_fee =
      //       +selectedAmenity.amenity_price * +user.days_for_daily;
      //   } else {
      //     bookingData.amenity_fee =
      //       +selectedAmenity.amenity_price * hotelStyleDays;
      //   }
      // }
      // if (selectedAmenity.type === "Time Slots") {
      //   if (selectedAmenity.payment_type === "Per Hour") {
      //     bookingData.amenity_fee = +selectedAmenity.amenity_price * minutes;
      //   } else bookingData.amenity_fee = +selectedAmenity.amenity_price;
      // }

      if (selectedAmenity.type === "Daily") {
        bookingData.start_time = user.start_date;

        if (user.isSingleDate) bookingData.end_time = user.start_date;
        else bookingData.end_time = user.end_date;

        bookingData.TimeSlot = user.time_slot;
      }
      if (selectedAmenity.type === "Time Slots") {
        bookingData.start_time = user.start_date;
        bookingData.end_time = user.start_date;
        bookingData.TimeSlot = user.time_slot;
      }
      if (selectedAmenity.type === "Hotel Style") {
        bookingData.start_time = user.start_date;
        bookingData.end_time = user.end_date;
        bookingData.TimeSlot = user.time_slot;
      }
      console.log(bookingData);
      bookingData.unit_id = unit_id;

      // return;
      axios
        .post(
          `${hostname}/api/amenity/CreateBooking`,
          {
            ...bookingData,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setLoader(false);
          setType("success");
          toast.success("Booking Successful", { theme: "colored" });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  if (type === "success")
    return (
      <Success
        user={user}
        reset={reset}
        handleClose={handleClose}
        getAmenitiesData={getAmenitiesData}
        selectedAmenity={selectedAmenity}
      />
    );

  if (loader) <Loader />;
  return (
    <div>
      <Formik
        initialValues={{
          payment_type: user.payment_type || selectedAmenity.payment_type,
          amenity_price: +selectedAmenity.amenity_price || "150",
          payment_method: user.payment_method || "",
        }}
        validate={(values) => {
          const errors = {};
          // if (!values.payment_type) {
          //   errors.payment_type = 'payment type is required!';
          // }
          if (values.payment_type && values.payment_type !== "No Fee") {
            // if (!values.amenity_price) {
            //   errors.amenity_price = 'price is required!';
            // }
            // if (!values.payment_method) {
            //   errors.payment_method = "plese select payment method!";
            // }
          }

          console.log(errors);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          //   setTimeout(() => {
          // alert(JSON.stringify(values, null, 2));
          console.log(values);
          // return;
          // setUser(() => Object.assign({ ...user, ...values }));
          setUser(() => Object.assign({ ...values }, user));

          bookAmenity(values);
          setSubmitting(false);
          // setStep(step + 1);
          //   }, 400);
        }}
      >
        {({
          values,
          // handleChange,
          // handleBlur,
          handleSubmit,
          isSubmitting,
          // setValues
          /* and other goodies */
        }) => (
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <form onSubmit={handleSubmit}>
                {/* <Card> */}
                {/* <FalconCardHeader title="Payment Confirmation" /> */}
                {/* <Card.Body className="bg-light"> */}
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <p>Payment Type </p>
                    <p className="fw-bold">{selectedAmenity.payment_type}</p>
                  </div>
                  {/* <Field
                    // component="select"
                    id="payment_type"
                    name="payment_type"
                    // multiple={false}
                    className="form-control"
                    disabled
                    value={selectedAmenity.payment_type}
                  >
                    
                  </Field>
                  <ErrorMessage
                    name="payment_type"
                    component={'div'}
                    className="text-danger fs--1"
                  /> */}
                </div>
                {values.payment_type !== "No Fee" && (
                  <>
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <p>Booking Amount </p>
                        <p className="fw-bold">
                          ${amenity_fee}
                          {/* {selectedAmenity.type === "Hotel Style" &&
                            +selectedAmenity.amenity_price * hotelStyleDays}
                          {selectedAmenity.type === "Daily" &&
                            // selectedAmenity.payment_type === "Flat Rate" &&
                            +selectedAmenity.amenity_price *
                              +user.days_for_daily}
                          {selectedAmenity.type === "Time Slots" &&
                            selectedAmenity.payment_type === "Flat Rate" &&
                            selectedAmenity.amenity_price}
                          {selectedAmenity.type === "Time Slots" &&
                            selectedAmenity.payment_type === "Per Hour" &&
                            +selectedAmenity.amenity_price * minutes} */}
                        </p>
                      </div>
                      {/* <input
                        type="text"
                        name="amenity_price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={'$' + values.amenity_price}
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        name="amenity_price"
                        component={'div'}
                        className="text-danger fs--1"
                      /> */}
                    </div>

                    {/* <div className="mb-3">
                      <label>Payment Method</label>
                      <Field
                        component="select"
                        id="payment_method"
                        name="payment_method"
                        multiple={false}
                        className="form-select"
                      >
                        <option value="">select type</option>
                        {selectedAmenity.payment_method?.map((v, i) => (
                          <option key={v + i} value={v}>
                            {v}
                          </option>
                        ))}
                      </Field>
                      <br />

                      {values.payment_method === "Cash" && (
                        <div>
                          <h5>Instructions for Cash Payment</h5>
                          <p>
                            {selectedAmenity.cash_description || "cash reqs"}
                          </p>
                        </div>
                      )}
                      
                      {values.payment_method === "Check" && (
                        <div>
                          <h5>Instructions for Check Payment</h5>

                          <p>
                            {selectedAmenity.check_description || "check reqs "}
                          </p>
                        </div>
                      )}

                      <ErrorMessage
                        name="payment_method"
                        className="fs--1 text-danger"
                        component={"div"}
                      />
                    </div> */}
                  </>
                )}
                {/* </Card.Body> */}
                {/* </Card> */}

                <br />
                <div>
                  <Button variant="secondary" onClick={() => setStep(step - 1)}>
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    className=" float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
