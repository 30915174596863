import React from "react";
import { Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import LikeInactive from "assets/img/illustrations/like-inactive.png";
import commentActive from "assets/img/illustrations/comment-active.png";

import commentInActive from "assets/img/illustrations/comment-inactive.png";
import likeActive from "assets/img/illustrations/like-active.png";

const FeedActionButtons = ({
  post = {},
  toggleLikeOrUnlike,
  userData = {},
}) => {
  // const [liked, setLiked] = useState(reactions?.like);
  // const { feeds, feedDispatch } = useContext(FeedContext);

  // const toggleLiked = () => {
  //   setLiked(!liked);
  //   const feed = feeds.find(item => item.id === id);
  //   feed.details.reactions.like = !liked;
  //   feed.details.countLCS.like = !liked
  //     ? feed.details.countLCS.like + 1
  //     : feed.details.countLCS.like - 1;

  //   feedDispatch({
  //     type: 'UPDATE',
  //     payload: { id, feed }
  //   });
  // };

  const checkIfLiked = () => {
    // console.log(post.likes)
    // console.log(userData);
    if (post?.likes?.includes(userData.data)) return true;
    else return false;
    // return false;
  };

  return (
    <Row className="g-0 fw-semi-bold text-center py-2 fs--1">
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
          // onClick={toggleLiked}
          onClick={() => toggleLikeOrUnlike(post._id)}
        >
          <img
            src={checkIfLiked() ? likeActive : LikeInactive}
            alt=""
            width="20"
          />
          <span className="ms-1">Like</span>
        </Flex>
      </Col>
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
        >
          <img src={true ? commentActive : commentInActive} alt="" width="20" />
          <span className="ms-1">comment</span>
        </Flex>
      </Col>
    </Row>
  );
};

export default FeedActionButtons;
