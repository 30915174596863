import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Profile from "./Profile";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

export default function UnitProfile() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, unit_id } = useParams();
  const [loader, setLoader] = useState(false);
  const [unitDetailsData, setUnitDetailsData] = useState({});

  useEffect(() => {
    getUnitsDetailData(unit_id);
  }, []);

  const getUnitsDetailData = (unit_id) => {
    setLoader(true);
    axios
      .post(`${hostname}/api/property/UnitDetail`, { unit_id },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        let cloneObj = Object.assign({}, res.data);
        if ("userid" in cloneObj) cloneObj.type = "Registered";
        else if ("Inviteduser" in cloneObj) cloneObj.type = "Invited";
        else cloneObj.type = "Pending";
        setUnitDetailsData({ ...cloneObj });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const updateUnitsDetailData = (unitDataObj) => {
    console.log(unitDataObj);
    setLoader(true);
    let updateData = {};
    updateData.unit_id = unit_id;
    updateData.unit_number = unitDataObj.unit_number;
    updateData.unit_type = unitDataObj.unit_type;
    updateData.cef = unitDataObj.cef;
    updateData.description = unitDataObj.notes;
    updateData.p_id = property_id;
    // updateData.Inviteduser = ''; ///Email of user we are inviting
    updateData.firstname = userData.firstname; ///Name of person who is inviting(Logged in user firstname)
    console.log(updateData);
    // return;

    axios
      .post(`${hostname}/api/property/UpdateUnit`, { ...updateData },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        // console.log(res.data);
        setUnitDetailsData(Object.assign({}, unitDetailsData, updateData));
        setLoader(false);
        toast.success(res.data.data, { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loader) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to="/property/management/buildings"
                >
                  Properties
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {property_name}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/units`}
                >
                  Units
                </Link>
                {"/"}
                <Link className="me-2 ms-2 " to={"!#"}>
                  {unitDetailsData.unit_number}
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Profile
          unitDetails={unitDetailsData}
          updateUnit={updateUnitsDetailData}
        />
      </div>
    );
  }
}
