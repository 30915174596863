import React, { useState, useEffect } from "react";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
import axios from "axios";
import { Button } from "react-bootstrap";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

function PreviewFileFullScreen({
  fileUrl = "",
  isVisible = false,
  handleClose = () => {},
}) {
  console.log(fileUrl);
  const cookies = new Cookies();
  let jwtToken = cookies.get('_expire_t');
  let hostname = getHostName();

  const { data, mimetype, name } = fileUrl;

  const [loading, setLoading] = useState(true);

  const [file, setFile] = useState("");

  React.useEffect(() => {
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 300);
    getFiledata();
  }, [data]);

  const getFiledata = () => {
    // if (!fileUrl.mimetype?.includes("image")) return;

    // if(fileUrl.downloadMode){
    //   downloadFile();
    //   handleClose();
    // }

    axios
      .post(
        `${hostname}/api/property/GetFileData`,
        {
          doc_id: fileUrl.doc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let typeData = "";
        typeData = `data:${fileUrl.mimetype};base64,`;
        setFile(typeData + res.data.Data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const downloadFile = () => {
    window.open(data);
  };

  const renderFile = () => {
    if (
      mimetype === "image/png" ||
      mimetype === "image/jpg" ||
      mimetype === "image/jpeg"
    ) {
      return <img src={file} alt="Document" className="img-fluid" />;
    }

    if (
      mimetype ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      mimetype ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      mimetype ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      let typeTmp = "";
      if (mimetype.includes("wordprocessingml")) typeTmp = "docx";
      if (mimetype.includes("presentationml")) typeTmp = "pptx";
      if (mimetype.includes("spreadsheetml")) typeTmp = "xlsx";
      if (mimetype.includes("pptx")) typeTmp = "ppt";
      return (
        <DocViewer
          documents={[
            { uri: fileUrl.data, fileName: fileUrl.name, fileType: typeTmp },
          ]}
          pluginRenderers={DocViewerRenderers}
          style={{ height: "85vh", width: "100%" }}
        />
      );
    }

    // if (mimetype.includes("presentationml")) {
    //   return (
    //     <iframe
    //       src={`${fileUrl.data}`}
    //       width="100%"
    //       height="600px"
    //       frameBorder="0"
    //     ></iframe>
    //   );
    // }

    // if (mimetype === "application/pdf") {
    //   return (
    //     <Worker
    //       workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
    //     >
    //       <Viewer fileUrl={file} />
    //     </Worker>
    //   );
    // }

    if (
      mimetype === "application/pdf" ||
      mimetype === "text/plain" ||
      //   mimetype === "application/zip" ||
      //   mimetype === "application/x-zip-compressed" ||
      mimetype === "application/octet-stream"
    ) {
      return (
        <object
          data={file}
          type={file.mimetype}
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
          }}
        />
      );
    }

    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ height: "100vh" }}
      >
        <p className="text-warning">No preview available! </p>
        <Button onClick={() => downloadFile()}>Download</Button>
      </div>
    );
  };

  if (!isVisible) return null;
  if (!fileUrl) return <div>no file to preview</div>;

  return (
    <div
      style={{
        width: "100vw",
        height: "200vh",
        position: "fixed",
        top: 0,
        left: 0,
        background: "black",
        zIndex: 100000,
        overflowY: "scroll",
      }}
    >
      <div className="text-end pt-3 px-3">
        <Button onClick={() => downloadFile()}>Download</Button>
        <Button variant="danger" className="ms-3" onClick={() => handleClose()}>
          Close
        </Button>
      </div>
      {!loading && <div className="px-5 w-100 h-100">{renderFile()}</div>}
    </div>
  );
}

export default PreviewFileFullScreen;
