let user_type = 'user';
if (window.location.pathname == '/login') {
  user_type = 'user';
}
// if (window.location.pathname == '/prop-mgmt-co/login') {
if (localStorage.getItem('Windows') !== 'Condozak') {
  user_type = 'prop-mgmt';
}
if (window.location.pathname == '/condo-corp/login') {
  user_type = 'condo-corp';
}
if (window.location.pathname == '/property/login') {
  user_type = 'unit-user';
}
if (window.location.pathname == '/truzak/login') {
  user_type = 'system-user';
}

export const version = '1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'card',
  user_type
};
// console.log(settings);
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
