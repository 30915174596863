import React, { useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { AuthWizardContext } from "../../../../../context/Context";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { ErrorMessage, Formik, Field } from "formik";
// import IconButton from 'components/common/IconButton';
// import { DeleteForever } from '@mui/icons-material';
import * as Yup from "yup";
import NoRestrictions from "./bookingtypes/NoRestrictions";
import Daily from "./bookingtypes/Daily";
import HotelStyle from "./bookingtypes/HotelStyle";
import TimeSlots from "./bookingtypes/TimeSlots";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import DailyNew from "./bookingtypes/DailyNew";

export default function BookingHours({
  fromEdit,
  updateAmenityTime,
  amenityCurrent,
  hostname,
  jwtToken,
}) {
  let timeRE = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/;

  // const timeRegix = new RegExp(timeRE);

  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  console.log(user);

  let obj = { ...user };
  console.log(obj);

  const handleMakeObj = (resObj) => {
    try {
      console.log(resObj);
      // let type = '';
      // if (resObj.booking_type === 'Daily') type = 'daily';

      // if (resObj.booking_type === 'Hotel Style') type = 'hotelstyle';

      // if (resObj.booking_type === 'Time Slots') type = 'timeslots';

      let input = {
        type: resObj.booking_type,
        duration: resObj.max_book_length,
        // duration: setTimeForMaxBookLength(resObj.max_book_length)
      };

      let availableday = {};
      let arrShort = [
        { day: "Sunday", short: "sun" },
        { day: "Monday", short: "mon" },
        { day: "Tuesday", short: "tue" },
        { day: "Wednesday", short: "wed" },
        { day: "Thursday", short: "thu" },
        { day: "Friday", short: "fri" },
        { day: "Saturday", short: "sat" },
      ];

      if (resObj.booking_type === "Daily") {
        arrShort.forEach((v) => {
          availableday[v.day] = {
            start: `${resObj[`daily_start_time_${v.short}`]} ${
              resObj[`daily_start_zone_${v.short}`]
            }`,
            end: `${resObj[`daily_end_time_${v.short}`]} ${
              resObj[`daily_end_zone_${v.short}`]
            }`,
          };
        });

        input = Object.assign(
          {
            availableday: { ...availableday },
            blackoutday: {},
          },
          input
        );
      }

      if (resObj.booking_type === "Time Slots") {
        let blackoutday = {}; //object
        // blackoutday.Monday = [{start: "", end :""}, {start:"",end:""}]
        // arrShort.forEach(v=>{
        //   let
        //   blackoutday[v.day] = [];
        // })
        // console.log(blackoutday);
        // return;
        arrShort.forEach((v) => {
          availableday[v.day] = {
            start: `${resObj[`start_time_${v.short}`]} ${
              resObj[`start_zone_${v.short}`]
            }`,
            end: `${resObj[`end_time_${v.short}`]} ${
              resObj[`end_zone_${v.short}`]
            }`,
          };
          let bldaysArr = [];
          [1, 2, 3, 4, 5].forEach((i) => {
            // console.log(resObj[`blackout_${v.short}_start_1`].length);
            if (resObj[`blackout_${v.short}_start_${i}`].length) {
              let objs = {};
              // let obj = {};
              // objs.short = v.short;
              objs.start = `${resObj[`blackout_${v.short}_start_${i}`]} ${
                resObj[`blackout_${v.short}_start_zone_${i}`]
              }`;
              objs.end = `${resObj[`blackout_${v.short}_end_${i}`]} ${
                resObj[`blackout_${v.short}_end_zone_${i}`]
              }`;
              bldaysArr.push(objs);
            }
          });
          // blackoutday = Object.assign({ ...objs }, blackoutday);
          // blackoutday[v.day] = { ...objs }; // blackouts'
          blackoutday[v.day] = [...bldaysArr]; // blackouts'
          bldaysArr = [];
        });
        console.log(blackoutday);
        // blackoutday.Sunday = blackoutday.Sunday.filter(v => v.start !== ' ');
        // blackoutday.Monday = blackoutday.Monday.filter(v => v.start !== ' ');
        // blackoutday.Tuesday = blackoutday.Tuesday.filter(
        //   v => v.start !== ' '
        // );
        // blackoutday.Wednesday = blackoutday.Wednesday.filter(
        //   v => v.start !== ' '
        // );
        // blackoutday.Thursday = blackoutday.Thursday.filter(
        //   v => v.start !== ' '
        // );
        // blackoutday.Friday = blackoutday.Friday.filter(v => v.start !== ' ');
        // blackoutday.Saturday = blackoutday.Sunday.filter(
        //   v => v.start !== ' '
        // );

        input = Object.assign(
          {
            availableday: { ...availableday },
            blackoutday: { ...blackoutday },
          },
          input
        );
      }

      console.log(input);
      return input;
    } catch (e) {
      console.log(e);
    }
  };

  // const [blackoutFields, setBlackoutFields] = useState([
  //   {
  //     start_time: `blackout_${'day'}_start_1`,
  //     start_zone: `blackout_${'day'}_start_zone_1`,
  //     end_time: `blackout_${'day'}_end_1`,
  //     end_zone: `blackout_${'day'}_end_zone_1`
  //   }
  // ]);

  const handleSubmit = (values) => {
    console.log(values);
    // console.log(user);
    // return;
    let input = handleMakeObj(values);

    // if (fromEdit) {
    console.log(input);
    //   return;
    // }
    console.log("all showslots api");
    axios
      .post(
        `${hostname}/api/amenity/showslots`,
        {
          ...input,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // console.log(Object.assign({...user, ...values}));
        // return;
        // toast.success(res.data.data, { theme: 'colored' });
        if (fromEdit)
          updateAmenityTime({
            // ...user,
            ...values,
            input: handleMakeObj(values),
            // timeslotsReviewUser: res.data
          });
        else {
          setUser(() =>
            Object.assign(
              {
                ...user,
                ...values,
                input: handleMakeObj(values),
                timeslotsReviewUser: res.data,
              }
              // user
            )
          );
          setStep(step + 1);
        }
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err.response.data.error, { theme: 'colored' });
      });
  };

  const customValidation = (values) => {
    console.log(values.booking_type);
    const errors = {};

    if (!values.booking_type) {
      errors.booking_type = "please select booking type!";
    }

    if (!values.min_book_lead_time) {
      errors.min_book_lead_time = "please select minimum lead time!";
    }
    if (!values.max_book_lead_time) {
      errors.max_book_lead_time = "please select maximum lead time!";
    }

    if (values.booking_type && values.booking_type === "No Restrictions") {
      // console.log('type no ret');
      // if (!values.start_time_for_no_restrictions)
      //   errors.start_time_for_no_restrictions = 'Enter start time';
      // if (!values.end_time_for_no_restrictions)
      //   errors.end_time_for_no_restrictions = 'Enter end time';
      // if (!values.start_time_zone_for_no_restrictions)
      //   errors.start_time_zone_for_no_restrictions = 'Enter time zone';
      // if (!values.end_time_zone_for_no_restrictions)
      //   errors.end_time_zone_for_no_restrictions = 'Enter time zone';
      // if (!values.amenity_unavailable_for_no_restrictions) {
      //   errors.amenity_unavailable_for_no_restrictions =
      //     'please select unavailable date!';
      // }
    }
    if (values.booking_type && values.booking_type === "Hotel Style") {
      if (!values.check_in_time_for_hotel_style)
        errors.check_in_time_for_hotel_style = "Enter check-in time";

      if (!values.check_out_time_for_hotel_style)
        errors.check_out_time_for_hotel_style = "Enter check-out time";

      if (!values.check_in_time_zone_for_hotel_style)
        errors.check_in_time_zone_for_hotel_style = "Enter time zone";

      if (!values.check_out_time_zone_for_hotel_style)
        errors.check_out_time_zone_for_hotel_style = "Enter time zone";

      if (!values.max_length_of_stay_hotel_style)
        errors.max_length_of_stay_hotel_style = "Required value!";

      // if (!values.amenity_unavailable_for_hotel_style) {
      //   errors.amenity_unavailable_for_hotel_style =
      //     'please select unavailable date!';
      // }
    }

    if (values.booking_type && values.booking_type === "Daily") {
      if (!values.check_in_time_for_hotel_style)
        errors.check_in_time_for_hotel_style = "Enter start time";

      if (!values.check_out_time_for_hotel_style)
        errors.check_out_time_for_hotel_style = "Enter end time";

      if (!values.check_in_time_zone_for_hotel_style)
        errors.check_in_time_zone_for_hotel_style = "Enter time zone";

      if (!values.check_out_time_zone_for_hotel_style)
        errors.check_out_time_zone_for_hotel_style = "Enter time zone";

      // if (!values.max_length_of_stay_hotel_style)
      //   errors.max_length_of_stay_hotel_style = 'Required value!';

      // if (!values.amenity_unavailable_for_hotel_style) {
      //   errors.amenity_unavailable_for_hotel_style =
      //     'please select unavailable date!';
      // }
    }

    if (values.booking_type && values.booking_type === "Time Slots") {
      if (!values.max_book_length) {
        errors.max_book_length = "please select time slot duration!";
      }
      // if (!values.amenity_unavailable_for_timeslots) {
      //   errors.amenity_unavailable_for_timeslots =
      //     'please select unavailable date!';
      // }
    }
    console.log(errors);
    return errors;
  };

  const yupCustomValidate = () => {
    try {
      let arr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

      let yupObj = {};
      arr.forEach((v) => {
        yupObj[`start_time_${v}`] = Yup.string().when("booking_type", {
          is: "Time Slots",
          then: () =>
            Yup.string()
              .required("starting time is required!")
              .matches(timeRE, "enter value in 00:00 format only!"),
        });
        yupObj[`end_time_${v}`] = Yup.string().when("booking_type", {
          is: "Time Slots",
          then: () =>
            Yup.string()
              .required("starting time is required!")
              .matches(timeRE, "enter value in 00:00 format only!"),
        });
        yupObj[`start_zone_${v}`] = Yup.string().when("booking_type", {
          is: "Time Slots",
          then: () => Yup.string().required("zone is required!"),
          // .matches(timeRE, 'enter value in 00:00 format only!')
        });
        yupObj[`end_zone_${v}`] = Yup.string().when("booking_type", {
          is: "Time Slots",
          then: () => Yup.string().required("zone is required!"),
          // .matches(timeRE, 'enter value in 00:00 format only!')
        });
        // blackouts
        [1, 2, 3, 4, 5].forEach((i) => {
          yupObj[`blackout_${v}_start_${i}`] = Yup.string().when(
            "booking_type",
            {
              is: "Time Slots",
              then: () =>
                Yup.string()
                  // .required('start time is required!')
                  .matches(timeRE, "enter value in 00:00 format only!"),
            }
          );
          yupObj[`blackout_${v}_end_${i}`] = Yup.string().when("booking_type", {
            is: "Time Slots",
            then: () =>
              Yup.string()
                // .required('end time is required!')
                .matches(timeRE, "enter value in 00:00 format only!"),
          });
          yupObj[`blackout_${v}_start_zone_${i}`] = Yup.string().when(
            "booking_type",
            {
              is: "Time Slots",
              then: () => Yup.string(),
              // .required('start zone is required!')
              // .matches(timeRE, 'enter value in 00:00 format only!')
            }
          );
          yupObj[`blackout_${v}_end_zone_${i}`] = Yup.string().when(
            "booking_type",
            {
              is: "Time Slots",
              then: () => Yup.string(),
              // .required('end zone is required!')
              // .matches(timeRE, 'enter value in 00:00 format only!')
            }
          );
          // fo daily
          // yupObj[`daily_start_time_${v}`] = Yup.string().when(
          //   'booking_type',
          //   {
          //     is: 'Daily',
          //     then: () =>
          //       Yup.string()
          //         .required('starting time is required!')
          //         .matches(timeRE, 'enter value in 00:00 format only!')
          //   }
          // );
          // yupObj[`daily_end_time_${v}`] = Yup.string().when('booking_type', {
          //   is: 'Daily',
          //   then: () =>
          //     Yup.string()
          //       .required('starting time is required!')
          //       .matches(timeRE, 'enter value in 00:00 format only!')
          // });
          // yupObj[`daily_start_zone_${v}`] = Yup.string().when(
          //   'booking_type',
          //   {
          //     is: 'Daily',
          //     then: () => Yup.string().required('zone is required!')
          //     // .matches(timeRE, 'enter value in 00:00 format only!')
          //   }
          // );
          // yupObj[`daily_end_zone_${v}`] = Yup.string().when('booking_type', {
          //   is: 'Daily',
          //   then: () => Yup.string().required('zone is required!')
          //   // .matches(timeRE, 'enter value in 00:00 format only!')
          // });
        });
      });
      // console.log(yupObj);
      return Yup.object({
        // showErrorsForType : Yup.boolean(),
        booking_type: Yup.string(),
        ...yupObj,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const maxBookLengthData = [
    {
      value: "30m",
      ans: 30,
    },
    {
      value: "45m",
      ans: 45,
    },
    {
      value: "1h",
      ans: 60,
    },
    {
      value: "3h",
      ans: 180,
    },
    {
      value: "5h",
      ans: 300,
    },
  ];

  const setTimeForMaxBookLength = (max_book_length) => {
    let ans = "";
    maxBookLengthData.forEach((v) => {
      if (v.value === max_book_length) ans = v.ans;
    });

    return `${ans}`;
  };

  const makeInitialState = () => {
    let availableday, type;

    if (fromEdit) {
      availableday = amenityCurrent.availableday;
      type = amenityCurrent.type;
    }

    let arr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let arrC = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let cloneObj = {};
    arr.forEach((v, i) => {
      // console.log(
      //   `${availableday[arrC[i]].start.split(' ')[0]} ${
      //     availableday[arrC[i]].start.split(' ')[1]
      //   }`
      // );

      cloneObj[`start_time_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].start.split(" ")[0]
          : obj[`start_time_${v}`] || "";
      cloneObj[`end_time_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].end.split(" ")[0]
          : obj[`end_time_${v}`] || "";
      cloneObj[`start_zone_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].start.split(" ")[1]
          : obj[`start_zone_${v}`] || "";
      cloneObj[`end_zone_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].end.split(" ")[1]
          : obj[`end_zone_${v}`] || "";

      // blackouts
      [1, 2, 3, 4, 5].forEach((i) => {
        cloneObj[`blackout_${v}_start_${i}`] =
          obj[`blackout_${v}_start_${i}`] || "";
        cloneObj[`blackout_${v}_end_${i}`] =
          obj[`blackout_${v}_end_${i}`] || "";
        cloneObj[`blackout_${v}_start_zone_${i}`] =
          obj[`blackout_${v}_start_zone_${i}`] || "";
        cloneObj[`blackout_${v}_end_zone_${i}`] =
          obj[`blackout_${v}_end_zone_${i}`] || "";
      });

      // for daily
      cloneObj[`daily_start_time_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].start.split(" ")[0]
          : obj[`daily_start_time_${v}`] || "";
      cloneObj[`daily_end_time_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].end.split(" ")[0]
          : obj[`daily_end_time_${v}`] || "";
      cloneObj[`daily_start_zone_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].start.split(" ")[1].toUpperCase()
          : obj[`daily_start_zone_${v}`] || "";
      cloneObj[`daily_end_zone_${v}`] =
        fromEdit && type !== "Hotel Style"
          ? availableday[arrC[i]].end.split(" ")[1].toUpperCase()
          : obj[`daily_end_zone_${v}`] || "";
    });
    return cloneObj;
  };

  return (
    <div>
      <Formik
        initialValues={{
          booking_type: fromEdit ? amenityCurrent.type : obj.booking_type || "",
          max_book_length: fromEdit
            ? amenityCurrent.duration
            : obj.max_book_length || "",
          min_book_lead_time: fromEdit
            ? amenityCurrent.minbookinglead
            : obj.min_book_lead_time || "",
          max_book_lead_time: fromEdit
            ? amenityCurrent.maxbookinglead
            : obj.max_book_lead_time || "",

          // no restrictions
          // start_time_for_no_restrictions: obj.start_time_for_no_restrictions,
          // end_time_for_no_restrictions: obj.end_time_for_no_restrictions,
          // start_time_zone_for_no_restrictions:
          //   obj.start_time_zone_for_no_restrictions,
          // end_time_zone_for_no_restrictions: obj.end_time_zone_for_no_restrictions,
          // amenity_unavailable_for_no_restrictions:
          //   obj.amenity_unavailable_for_no_restrictions || '',

          // hotel style
          check_in_time_for_hotel_style: fromEdit
            ? amenityCurrent.checkin_time?.split(" ")[0]
            : obj.check_in_time_for_hotel_style || "",
          check_out_time_for_hotel_style: fromEdit
            ? amenityCurrent.checkout_time?.split(" ")[0]
            : obj.check_out_time_for_hotel_style || "",
          check_in_time_zone_for_hotel_style: fromEdit
            ? amenityCurrent.checkin_time?.split(" ")[1]
            : obj.check_in_time_zone_for_hotel_style || "",
          check_out_time_zone_for_hotel_style: fromEdit
            ? amenityCurrent.checkout_time?.split(" ")[1]
            : obj.check_out_time_zone_for_hotel_style || "",
          max_length_of_stay_hotel_style: fromEdit
            ? amenityCurrent.max_stay_length
            : obj.max_length_of_stay_hotel_style || "",
          // amenity_unavailable_for_hotel_style:
          //   obj.amenity_unavailable_for_hotel_style || '',

          // timeslots
          // max_book_length: obj.max_book_length,
          // amenity_unavailable_for_timeslots:
          //   obj.amenity_unavailable_for_timeslots || '',

          // for daily booking

          // start_time_for_daily_style : '',
          // end_time_for_daily_style : '',
          // start_time_zone_for_daily_style : '',
          // end_time_zone_for_daily_style : '',

          ...makeInitialState(),
        }}
        validate={(values) => customValidation(values)}
        validationSchema={() => yupCustomValidate()}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          // return;
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          // handleChange,
          // handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
          /* and other goodies */
        }) => (
          <div
            className={fromEdit ? "card mb-3" : "d-flex justify-content-center"}
          >
            {fromEdit && <FalconCardHeader title="Amenity Booking Type" />}
            <div className={fromEdit ? "card-body bg-light" : ""}>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Booking Type *</label>
                  <Field
                    component="select"
                    id="booking_type"
                    name="booking_type"
                    multiple={false}
                    className="form-select"
                  >
                    <option value="">Select type</option>
                    {/* <option value="No Restrictions">No Restrictions</option> */}
                    <option value="Daily">Daily</option>
                    <option value="Hotel Style">Hotel Style</option>
                    <option value="Time Slots">Time Slots</option>
                  </Field>
                  <ErrorMessage
                    name="booking_type"
                    component={"div"}
                    className="text-danger fs--1"
                  />
                </div>
                {values.booking_type === "No Restrictions" && (
                  <NoRestrictions
                    values={values}
                    user={user}
                    setValues={setValues}
                  />
                )}
                {values.booking_type === "Daily" && (
                  <DailyNew values={values} user={user} setValues={setValues} />
                  // <Daily values={values} user={user} setValues={setValues} />
                )}
                {values.booking_type === "Hotel Style" && (
                  <HotelStyle
                    values={values}
                    user={user}
                    setValues={setValues}
                  />
                )}
                {values.booking_type === "Time Slots" && (
                  <TimeSlots
                    values={values}
                    user={user}
                    setValues={setValues}
                  />
                )}

                <Row className="mb-3">
                  <Col md={12} className="mb-3">
                    <label>Minimum Booking Lead Time *</label>
                    <Field
                      component="select"
                      id="min_book_lead_time"
                      name="min_book_lead_time"
                      multiple={false}
                      className="form-select"
                    >
                      <option value="">Select duration</option>
                      <option value="No Restrictions">No Restrictions</option>
                      <option value="24 Hours">24 Hours</option>
                      <option value="3 Days">3 Days</option>
                      <option value="5 Days">5 Days</option>
                      <option value="1 Week">1 Week</option>
                      <option value="1 Month">1 Month</option>
                      <option value="1 Months">6 Months</option>
                    </Field>
                    <ErrorMessage
                      name="min_book_lead_time"
                      component={"div"}
                      className="text-danger fs--1"
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <label>Maximum Booking Lead Time *</label>
                    <Field
                      component="select"
                      id="max_book_lead_time"
                      name="max_book_lead_time"
                      multiple={false}
                      className="form-select"
                    >
                      <option value="">Select duration</option>
                      <option value="No Restrictions">No Restrictions</option>
                      <option value="24 Hours">24 Hours</option>
                      <option value="3 Days">3 Days</option>
                      <option value="5 Days">5 Days</option>
                      <option value="1 Week">1 Week</option>
                      <option value="1 Month">1 Month</option>
                      <option value="1 Months">6 Months</option>
                    </Field>
                    <ErrorMessage
                      name="max_book_lead_time"
                      component={"div"}
                      className="text-danger fs--1"
                    />
                  </Col>
                </Row>

                {fromEdit ? (
                  <div>
                    <Button
                      variant="info"
                      className=" float-end"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      variant="secondary"
                      onClick={() => setStep(step - 1)}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="primary"
                      className=" float-end"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
