import React from "react";
import { Card, Dropdown } from "react-bootstrap";
// import DeleteGroup from "../DeleteGroup";

import Table from "react-bootstrap/Table";
import CardDropdown from "components/common/CardDropdown";
import { isArray } from "lodash";
import FalconCardHeader from "components/common/FalconCardHeader";

const UserGroups = ({ currentUser, addUserToGroup }) => {
  let adminsCount = localStorage.getItem("top") || null;
  
  return (
    <Card className="font-sans-serif">
      <FalconCardHeader title={`View Groups`} />
      <Card.Body className="bg-light" style={{ height: "90vh" }}>
        <h5 className="text-secondary mt-2 mb-3">
          Use groups to manage{" "}
          {/* <span className="text-primary">
            {currentUser.firstname || 'first name'}{' '}
            {currentUser.lastname || 'last name'}
          </span>{' '} */}
          permissions
        </h5>
        <br />
        <div className="table-responsive pb-5 text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="text-start">Group</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isArray(currentUser.groups) ? (
                currentUser.groups?.map((v, i) => (
                  <tr key={i}>
                    <td
                      style={{ width: "70%", textAlign: "start" }}
                      className="text-primary"
                    >
                      {v || "group here"}
                    </td>
                    <td>
                      {!currentUser.groups.includes("Site-Administrators") && (
                        <CardDropdown>
                          <div className="py-2">
                            <Dropdown.Item
                              className="text-primary"
                              onClick={() => addUserToGroup(currentUser, true)}
                            >
                              Change Group
                            </Dropdown.Item>
                          </div>
                        </CardDropdown>
                      )}
                      {currentUser.groups.includes("Site-Administrators") &&
                        adminsCount >= 2 && (
                          <CardDropdown>
                            <div className="py-2">
                              <Dropdown.Item
                                className="text-primary"
                                onClick={() =>
                                  addUserToGroup(currentUser, true)
                                }
                              >
                                Change Group
                              </Dropdown.Item>
                            </div>
                          </CardDropdown>
                        )}
                      {currentUser.groups.includes("Site-Administrators") &&
                        adminsCount < 2 &&
                        "Add a new Site Admin to Change/Revoke"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    style={{ width: "70%", textAlign: "start" }}
                    className="text-primary"
                  >
                    No Groups!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UserGroups;
