import React from 'react';
import { Card } from 'react-bootstrap';
import CustomTitle from './CustomTitle';
export default function AnnouncementDetail({handleCloseDetail}) {
  return (
    <div>
      <Card>
        <CustomTitle
          heading="Heading here"
          handleCloseDetail={handleCloseDetail}
        />
        <Card.Body className="bg-light">
          content will be here
        </Card.Body>
      </Card>
    </div>
  );
}
