import React from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
import { Form as BSForm, Button } from "react-bootstrap";
// import { AuthWizardContext } from "context/Context";
// import axios from "axios";
// import { toast } from "react-toastify";
import "./SendMail.css";

import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { PDFName, PDFPage } from "pdf-lib";
import { convert } from "html-to-text";
import { generatePdfByJsPdf } from "helpers/utils";
import {
  getHtmlWithImageActualData,
  handleCreatePurePdf,
} from "helpers/utils-pdf";
export default function SendMail({
  setModelSize,
  // userData,
  mailOutState,
  setMailOutState,

  sendDataToDB,
  payload,
}) {
  const printRef = React.useRef(null);

  const previewFile = async (type) => {
    // if (!payload.companyLetterHead) {
    //   toast.error("company letterhead not found!");
    //   return;
    // }
    // if (!payload.propertyLetterHead) {
    //   toast.error("property letterhead not found!");
    //   return;
    // }
    console.log(type);

    let existingPdfBytes = "";
    let textToWrite = "";

    if (type === "mail content") {
      // await generatePdfByJsPdf(printRef.current, "preview");
      // setPreviewContent({ isShown: true, type });
      let content = await getHtmlWithImageActualData(mailOutState.mail_content);

      console.log(content);

      await handleCreatePurePdf(content, {
        title: "mail content",
        author: "mail author",
      });

      return;
      // if (mailOutState.page_type === "Company LetterHead") {
      //   existingPdfBytes = payload.propertyLetterHead;
      //   textToWrite = mailOutState.mail_content;
      // }
      // if (mailOutState.page_type === "Property LetterHead") {
      //   existingPdfBytes = payload.companyLetterHead;
      //   textToWrite = mailOutState.mail_content;
      // }
    }
    if (type === "letter content") {
      // await generatePdfByJsPdf(printRef.current, "preview");
      // setPreviewContent({ isShown: true, type });
      if (mailOutState.page_type === "Company LetterHead") {
        existingPdfBytes = payload.companyLetterHead;
        textToWrite = mailOutState.letter_content;
      }
      if (mailOutState.page_type === "Property LetterHead") {
        existingPdfBytes = payload.propertyLetterHead;
        textToWrite = mailOutState.letter_content;
      }
      if (mailOutState.page_type === "Blank Page") {
        existingPdfBytes = "";
        textToWrite = mailOutState.letter_content;
      }
    }

    let content = await getHtmlWithImageActualData(textToWrite);

    console.log(content);

    await handleCreatePurePdf(
      content,
      { title: "letter content", author: "letter author" },
      existingPdfBytes !== "",
      existingPdfBytes
    );

    
  };

  const copyPage = (originalPage) => {
    const cloneNode = originalPage.node.clone();

    const { Contents } = originalPage.node.normalizedEntries();
    if (Contents) cloneNode.set(PDFName.of("Contents"), Contents.clone());

    const cloneRef = originalPage.doc.context.register(cloneNode);
    const clonePage = PDFPage.of(cloneNode, cloneRef, originalPage.doc);
    return clonePage;
  };

  const [previewContent, setPreviewContent] = React.useState({
    isShown: false,
    type: "",
    file: "",
  });

  const handleSubmit = () => {
    // setUser(Object.assign({}, user, values));
    sendDataToDB(false);
  };

  const handleStepMinus = () => {
    // setUser(Object.assign({}, user, values));
    if (mailOutState.will_draft_letter === "Yes") {
      // setStep(step - 1);
      setMailOutState({ ...mailOutState, step: mailOutState.step - 1 });
    } else {
      // setStep(step - 2);
      setMailOutState({ ...mailOutState, step: mailOutState.step - 2 });
    }
    setModelSize("xl");
  };

  if (previewContent.isShown && previewContent.type === "mail content") {
    return (
      <div>
        <div className="text-end">
          <Button
            className="me-3"
            variant="info"
            onClick={() => generatePdfByJsPdf(printRef.current, "preview")}
          >
            Print
          </Button>
          <Button
            onClick={() => setPreviewContent({ isShown: false, type: "" })}
          >
            Close
          </Button>
        </div>
        <div
          ref={printRef}
          style={{ minHeight: "90vh" }}
          dangerouslySetInnerHTML={{ __html: mailOutState.mail_content }}
        ></div>
      </div>
    );
  }
  if (previewContent.isShown && previewContent.type === "letter content") {
    return (
      <div>
        <div className="text-end">
          <Button
            className="me-3"
            variant="info"
            onClick={() => generatePdfByJsPdf(printRef.current, "preview")}
          >
            Print
          </Button>
          <Button
            className=""
            onClick={() => setPreviewContent({ isShown: false, type: "" })}
          >
            Close
          </Button>
        </div>
        <div
          ref={printRef}
          style={{ minHeight: "90vh" }}
          dangerouslySetInnerHTML={{ __html: mailOutState.letter_content }}
        ></div>
        {/* <object
          aria-labelledby="file preview"
          type="application/pdf"
          data={previewContent.file}
          style={{ width: "100%", height: "170vh" }}
          // height="800"
        ></object> */}
      </div>
    );
  }

  return (
    <div className="px-3" style={{ minHeight: "90vh" }}>
      <div>
        <div className="text-center custom-display">
          <Button
            variant="falcon-primary"
            onClick={() => previewFile("mail content")}
          >
            Preview Mail Content
          </Button>

          {mailOutState?.will_draft_letter === "Yes" && (
            <>
              <Button
                variant="falcon-info"
                className="ms-3 md-mt"
                onClick={() => previewFile("letter content")}
              >
                Preview Letter Content
              </Button>
            </>
          )}
        </div>
        <br />
        <div className="d-flex">
          <BSForm.Check
            type="checkbox"
            name="not_from_market"
            // className="form-check-input"
            // style={{ width: "35px", height: "20px" }}
            defaultChecked={mailOutState.not_from_market}
            onChange={() =>
              setMailOutState({
                ...mailOutState,
                not_from_market: !mailOutState.not_from_market,
              })
            }
          />
          <label className="ms-3 form-check-label">
            I agree to verify that this mail is not considered a commercial
            electronic message, (i.e. it does not encourage the recipient to
            engage in any commercial activities)
          </label>
        </div>
        {/* <div name="not_from_market" component="div" ></div> */}
        <br />
        <div>
          <Button variant="secondary" onClick={handleStepMinus}>
            Previous
          </Button>
          <Button
            variant="primary"
            className="float-end"
            type="button"
            // disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Send Mail
          </Button>
        </div>
      </div>
      {/* <Formik
        initialValues={{
          not_from_market: user.not_from_market || true,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="text-center">
              <Button
                variant="falcon-primary"
                onClick={() =>
                  setPreviewContent({ isShown: true, type: "mail content" })
                }
              >
                Preview Mail Content
              </Button>
              {user?.willDraftLetter === "Yes" && (
                <Button
                  variant="falcon-info"
                  className="ms-3"
                  onClick={() =>
                    setPreviewContent({ isShown: true, type: "letter content" })
                  }
                >
                  Preview Letter Content
                </Button>
              )}
            </div>
            <br />
            <div className="d-flex">
              <Field
                type="checkbox"
                name="not_from_market"
                className="form-check-input"
                style={{ width: "35px", height: "20px" }}
              />
              <label className="ms-3 form-check-label">
                I agree to verify that this mail is not considered a commercial
                electronic message, (i.e. it does not encourage the recipient to
                engage in any commercial activities)
              </label>
            </div>
            <ErrorMessage name="not_from_market" component="div" />
            <br />
            <div>
              <Button variant="dark" onClick={handleStepMinus}>
                Previous
              </Button>
              <Button
                variant="primary"
                className="float-end"
                type="submit"
                disabled={isSubmitting}
              >
                Finish
              </Button>
            </div>
          </Form>
        )}
      </Formik> */}
    </div>
  );
}
