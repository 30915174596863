import React from 'react';
import WizardLayout from './WizardLayout';
import AuthWizardProvider from './AuthWizardProvider';

const Wizard = props => {
  return (
    <AuthWizardProvider>
      <WizardLayout {...props} />
    </AuthWizardProvider>
  );
};

export default Wizard;
