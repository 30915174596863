import React from "react";
import { Modal } from "react-bootstrap";
// import { Offcanvas, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import Wizard from './wizardRemastered/Wizard';
import FalconCloseButton from "components/common/FalconCloseButton";
// import axios from 'axios';
import { toast } from "react-toastify";
import { Form as BSForm, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
let cars = [
  "Toyota",
  "Honda",
  "Hyundai",
  "Ford",
  "Nissan",
  "Chevrolet",
  "KIA",
  "Mazda",
  "Volkswagen",
];
export default function CreateNewMailModal({
  payload,
  show,
  handleClose,
  getAllData,
  setPayload,
  userData,
  hostname,
  jwtToken,
}) {
  // console.log(payload);
  //
  const adduser = (values) => {
    let entry = {
      user_id: userData.data,
      unit_id: payload.unit_id,
      car_make: values.make,
      car_model: values.model,
      color: values.color,
      license_plate: values.plate_no,
    };
    console.log(entry);
    axios
      .post(
        `${hostname}/api/unit/CreateVehicle`,
        {
          ...entry,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Vehicle Added.", { theme: "colored" });
        getAllData();
        handleClose();
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.err || "something went wrong", {
            theme: "colored",
          });
        else toast.error("something went wrong", { theme: "colored" });
      });
  };
  const updateuser = (values) => {
    let entry = {
      user_id: userData.data,
      unit_id: payload.unit_id,
      vehicle_id: payload._id,
      car_make: values.make,
      car_model: values.model,
      color: values.color,
      license_plate: values.plate_no,
    };
    console.log(entry);
    axios
      .post(
        `${hostname}/api/unit/UpdateVehicle`,
        {
          ...entry,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Vehicle Updated.", { theme: "colored" });
        getAllData();
        handleClose();
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.err || "something went wrong", {
            theme: "colored",
          });
        else toast.error("something went wrong", { theme: "colored" });
      });
  };
  const deleteuser = (values) => {
    let entry = {
      user_id: userData.data,
      unit_id: payload.unit_id,
      vehicle_id: payload._id,
    };
    console.log(entry);
    axios
      .post(
        `${hostname}/api/unit/DeleteVehicle`,
        {
          ...entry,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Vehicle Deleted.", { theme: "colored" });
        getAllData();
        handleClose();
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.err || "something went wrong", {
            theme: "colored",
          });
        else toast.error("something went wrong", { theme: "colored" });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Header>
        <Modal.Title>{payload.loadType}</Modal.Title>
        <FalconCloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {payload.loadType === "Add Vehicle" && (
          <div>
            <Formik
              initialValues={{ make: "", model: "", color: "", plate_no: "" }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object({
                make: Yup.string("").required("required option!"),
                model: Yup.string("").required("required option!"),
                color: Yup.string("").required("required option!"),
                plate_no: Yup.string("").required("required option!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                adduser(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Car Make</BSForm.Label>
                    <Field
                      component="select"
                      id="make"
                      name="make"
                      className="form-select"
                    >
                      <option value="">select</option>
                      {cars?.map((v, i) => (
                        <option key={v + i} value={v}>
                          {v}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="make"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Car Model</BSForm.Label>
                    <Field name="model" className="form-control" />
                    <ErrorMessage
                      name="model"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Color</BSForm.Label>
                    <Field name="color" className="form-control" />
                    <ErrorMessage
                      name="color"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Lisence Plate #</BSForm.Label>
                    <Field name="plate_no" className="form-control" />
                    <ErrorMessage
                      name="plate_no"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <br />
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {payload.loadType?.split(" ")[0]}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Update Vehicle" && (
          <div>
            <Formik
              initialValues={{
                make: payload.car_make || "",
                model: payload.car_model || "",
                color: payload.color || "",
                plate_no: payload.license_plate || "",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object({
                make: Yup.string("").required("required option!"),
                model: Yup.string("").required("required option!"),
                color: Yup.string("").required("required option!"),
                plate_no: Yup.string("").required("required option!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                updateuser(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Car Make</BSForm.Label>
                    <Field
                      component="select"
                      id="make"
                      name="make"
                      className="form-select"
                    >
                      <option value="">select</option>
                      {cars?.map((v, i) => (
                        <option key={v + i} value={v}>
                          {v}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="make"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Car Model</BSForm.Label>
                    <Field name="model" className="form-control" />
                    <ErrorMessage
                      name="model"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Color</BSForm.Label>
                    <Field name="color" className="form-control" />
                    <ErrorMessage
                      name="color"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Lisence Plate #</BSForm.Label>
                    <Field name="plate_no" className="form-control" />
                    <ErrorMessage
                      name="plate_no"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <br />
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {payload.loadType?.split(" ")[0]}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Delete Vehicle" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to delete this vehicle ?
              {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
            </p>
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={deleteuser}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
