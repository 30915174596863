import { useState, useEffect } from "react";

import Flex from "../../../../components/common/Flex";
import React from "react";
import ReactDOM from "react-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
// import UserAdvanceTable from './UserAdvanceTable';
// import PropertyTable from './PropertyTable';
import AnnoucementsTable from "./AnnoucementsTable";
// import CreateGroup from './CreateGroup';
// import CreatePropertyModal from './CreatePropertyModal';
import CreateNewMailModal from "./CreateNewMailModal";
import AddressDetailsModal from "./AddressDetailsModal";
import { capitalize } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { RotatingLines } from "react-loader-spinner";
// import ReactPaginate from 'react-paginate';
// import PropertyPaginate from './PropertyPaginate';
// import { handleSearchBar } from 'helpers/utils';
// import Logo from 'components/common/Logo';
// import LogoBottom from 'components/common/LogoBottom';
import { Link, useNavigate } from "react-router-dom";

const Annoucements = () => {
  // console.log(userData);

  const navigate = useNavigate();
  const cookies = new Cookies();

  // console.log(cookies.get('userData'));
  let userData = cookies.get("userData");

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // setLoader(true);
    // getAllPropertiesData(userData.compid);
    setPropertiesData(propertiesDataArray);
    // setPropertiesData(propertiesDataArray);
    // setPropertiesDataTemp(propertiesDataArray);
    // cookies.set('buildingData', propertiesDataArray);
    // Prompt confirmation when reload page is triggered
    // window.onbeforeunload = () => {
    //   return '';
    // };
    // Unmount the window.onbeforeunload event
    // return () => {
    //   window.onbeforeunload = null;
    // };
  }, []);

  const getAllPropertiesData = (c_id) => {
    console.log("working");
    console.log(c_id);
    // return;
    if (c_id) {
      axios
        .post(`http://localhost:5000/api/property/Allproperties`, {
          c_id,
        })
        .then((res) => {
          setLoader(false);
          console.log(res.data);
          let properties = res.data.data.map((p) => {
            // console.log(p.property_info);
            console.log(p.address);
            return Object.assign(
              { address: p.address || "-" },
              p.property_info
            );
          });
          // console.log(res.data.data[0].property_info[0].property_name);
          // console.log(res.data.data);
          // let properties = [];
          // res.data.data.forEach(({ property_info, address }) => {
          //   let obj = {};
          //   property_info.forEach(p => {
          //     // console.log(p);
          //     obj = Object.assign({ address }, p);
          //     properties.push(obj);
          //   });
          // });
          // console.log(properties);
          properties = properties.map((p) => {
            p.property_name = capitalize(p.property_name);
            return Object.assign(
              {
                status: {
                  title: "Active",
                  type: "success",
                  icon: "check",
                },
              },
              p
            );
          });
          console.log(properties);
          // cookies.set('buildingData', properties);
          setPropertiesData(properties);
          setPropertiesDataTemp(properties);
          // setGroupsData(res.data.data);
          // setGroupsDataTemp(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const selectBuilding = (id) => {
    let property = [...propertiesData].filter((v) => v._id === id);
    // console.log(id);
    cookies.set("propertyDetail", property);
    navigate("/properties/profile");
    // window.location.href = '/properties/profile'
  };

  let propertiesDataArray = [
    {
      title: "Annual Budget Summary",
      created_on: `12 June, 2022 9:15 PM`,
      sent: "12",
      addresses: "12",
      status: { title: "Completed", type: "success", icon: "check" },
    },
    {
      title: "Construction Noise",
      created_on: `12 June, 2022 5:00 PM`,
      sent: "5",
      addresses: "5",
      status: { title: "Draft", type: "info", icon: "redo" },
    },
    {
      title: "Invitation",
      created_on: `12 June, 2022 2:00 PM`,
      sent: "7",
      addresses: "9",
      status: { title: "Not Sent", type: "danger", icon: "ban" },
    },
  ];

  // create property modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //for AddressDetailsModal
  const [showAddressDetailsModal, setShowAddressDetailsModal] = useState(false);

  const handleCloseAddressDetailsModal = () =>
    setShowAddressDetailsModal(false);
  const handleShowAddressDetailsModal = () => setShowAddressDetailsModal(true);

  // search bar
  // const [groupsData, setGroupsData] = useState([...groups]);

  const showAddressDetails = () => {
    handleShowAddressDetailsModal();
  };

  const handleSearchBar = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    // console.log(searchValue);
    let propertyTemp = [...propertiesDataTemp];
    let ans = [];
    ans = propertyTemp.filter((p) => {
      // console.log(p.property_id);
      if (
        p.property_id.toLowerCase().indexOf(searchValue) !== -1 ||
        p.property_name.toLowerCase().indexOf(searchValue) !== -1 ||
        p.address.toLowerCase().indexOf(searchValue) !== -1
      )
        return (
          p.property_id.toLowerCase().indexOf(searchValue) !== -1 ||
          p.property_name.toLowerCase().indexOf(searchValue) !== -1 ||
          p.address.toLowerCase().indexOf(searchValue) !== -1
        );
      else return "";
    });

    // console.log(groupsTemp);
    // console.log(ans);

    setPropertiesData(ans);
  };

  // const selectBuilding = id => {
  // console.log(id);
  // let building = propertyData.filter(v => v.property_id === id);
  // console.log(building[0]);
  // cookies.set('buildingDetails', building[0]);
  // };

  if (loader) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
        {/* <LogoBottom /> */}
      </div>
    );
  } else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Link className="text-secondary" to={"/"}>
                  Home
                </Link>
                {" / "}
                <Link to={"#!"}>Annoucements</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>Annoucements</h4>
              {/* <Button variant="primary" size="sm" onClick={savePDF}>
                save pdf
              </Button> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              <span>
                <Button
                  variant="info"
                  size="sm"
                  onClick={handleShow}
                  className="me-3"
                >
                  Choose from our templates
                </Button>
                <Button variant="primary" size="sm" onClick={handleShow}>
                  New Mail
                </Button>
              </span>
            </Flex>
            <br />
            {/* <Row>
              <Col md="6">
                <p>long long text for property management</p>
              </Col>
            </Row> */}
            <Row className="flex-end-center">
              <Col xs="auto" sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white"
                      id="basic-addon1"
                    >
                      <FontAwesomeIcon icon={faSearch} color="grey" />
                    </span>
                    <input
                      type="text"
                      name="searchv"
                      onKeyUp={handleSearchBar}
                      className="form-control"
                      placeholder="Search by id, name ..."
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <AnnoucementsTable
              properties={propertiesData}
              selectBuilding={selectBuilding}
              getAllPropertiesData={getAllPropertiesData}
              showAddressDetails={showAddressDetails}
            />
            <br />
            {/* <CreateGroup show={show} handleClose={handleClose} /> */}
            <CreateNewMailModal
              show={show}
              handleClose={handleClose}
              userData={userData}
              getAllPropertiesData={getAllPropertiesData}
            />
            <AddressDetailsModal
              show={showAddressDetailsModal}
              handleClose={handleCloseAddressDetailsModal}
              userData={userData}
              getAllPropertiesData={getAllPropertiesData}
            />
            {/* <PropertyPaginate
              itemsPerPage={10}
              properties={propertiesData}
              selectBuilding={selectBuilding}
            /> */}
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default Annoucements;
