import React from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Table, Dropdown } from "react-bootstrap";

import SoftBadge from "components/common/SoftBadge";
import CardDropdown from "components/common/CardDropdown";

// import Image from "./Image";

import "./UserTable.css";
import { Avatar } from "@mui/material";
import { startCase } from "lodash";

const TableRow = ({
  data,
  // inviteUser,
  resendInvite,
  deleteInvitedUser,
  revokeUserAccess,
  grantUserAccess,
  // addUserToGroup,
  showUserDetails,
  adminCount,
  changeUserGroup,
  userData,
  // handleShowUserDetailTab
}) => {
  console.log(data);

  const calculateAdminRights = () => {
    let changeGroup = false;
    let grantAccess = false;
    let revokeAccess = false;
    let reinvite = false;
    let deleteUser = false;

    // if admin and not him in table
    // if (userData.role === "Site-Administrators" && userData.data !== data._id) {
    //   // if admin in table
    //   if (data.groups.includes("Site-Administrators")) {
    //     if (data.status.title === "Active") {
    //       changeGroup = true;
    //       revokeAccess = true;
    //     }
    //     if (data.status.title === "Invited") {
    //       changeGroup = true;
    //       revokeAccess = true;
    //       reinvite = true;
    //       deleteUser = true;
    //     }
    //     if (data.status.title === "Revoked") {
    //       changeGroup = true;
    //       grantAccess = true;
    //     }
    //     if (data.status.title === "Ignored") {
    //       changeGroup = true;
    //       revokeAccess = true;
    //       reinvite = true;
    //       deleteUser = true;
    //     }
    //   }

    //   //of non-admin in table
    //   if (!data.groups.includes("Site-Administrators")) {
    //     if (data.status.title === "Active") {
    //       changeGroup = true;
    //       revokeAccess = true;
    //     }
    //     if (data.status.title === "Invited") {
    //       changeGroup = true;
    //       revokeAccess = true;
    //       reinvite = true;
    //       deleteUser = true;
    //     }
    //     if (data.status.title === "Revoked") {
    //       changeGroup = true;
    //       grantAccess = true;
    //     }
    //     if (data.status.title === "Ignored") {
    //       changeGroup = true;
    //       revokeAccess = true;
    //       reinvite = true;
    //       deleteUser = true;
    //     }
    //   }
    // } else return [<span className="text-dark ps-3">You</span>];

    // if admin and not him in table
    if (userData.role === "Site-Administrators" && userData.data !== data._id) {
      // if admin in table
      if (data.groups.includes("Site-Administrators")) {
        if (data.status.title === "Active") {
          changeGroup = true;
          revokeAccess = true;
        }
        if (data.status.title === "Invited") {
          changeGroup = false;
          revokeAccess = false;
          reinvite = true;
          deleteUser = true;
        }
        if (data.status.title === "Revoked") {
          changeGroup = false;
          grantAccess = true;
        }
        if (data.status.title === "Ignored") {
          changeGroup = false;
          revokeAccess = false;
          reinvite = true;
          deleteUser = true;
        }
      }

      //of non-admin in table
      if (!data.groups.includes("Site-Administrators")) {
        if (data.status.title === "Active") {
          changeGroup = true;
          revokeAccess = true;
        }
        if (data.status.title === "Invited") {
          changeGroup = false;
          revokeAccess = false;
          reinvite = true;
          deleteUser = true;
        }
        if (data.status.title === "Revoked") {
          changeGroup = false;
          grantAccess = true;
        }
        if (data.status.title === "Ignored") {
          changeGroup = false;
          revokeAccess = false;
          reinvite = true;
          deleteUser = true;
        }
      }
    } else return [<span className="text-dark ps-3">You</span>];

    let returnValue = [];

    if (changeGroup)
      returnValue.push(
        <Dropdown.Item onClick={() => changeUserGroup(data)}>
          change group
        </Dropdown.Item>
      );

    if (grantAccess)
      returnValue.push(
        <Dropdown.Item
          className="text-dark"
          onClick={() => grantUserAccess(data)}
        >
          Reactivate
        </Dropdown.Item>
      );

    if (revokeAccess)
      returnValue.push(
        <Dropdown.Item
          className="text-danger"
          onClick={() => revokeUserAccess(data)}
        >
          Revoke Access
        </Dropdown.Item>
      );

    if (reinvite)
      returnValue.push(
        <Dropdown.Item className="text-dark" onClick={() => resendInvite(data)}>
          Resend Invite
        </Dropdown.Item>
      );

    if (deleteUser)
      returnValue.push(
        <Dropdown.Item
          className="text-danger"
          onClick={() => deleteInvitedUser(data)}
        >
          Delete User
        </Dropdown.Item>
      );

    return returnValue;
  };

  try {
    return (
      <tr className="align-middle">
        <td className="text-nowrap">
          <div className="d-flex align-items-center">
            <div className="me-2">
              <Avatar
                sx={{
                  bgcolor: data?.bgColor,
                  fontSize: "16px",
                  // width: "100px",
                  // height: "100px",
                }}
                className="img-thumbnail"
              >
                {startCase(data?.firstname[0]) + startCase(data?.lastname[0])}
              </Avatar>
            </div>
            <div>
              <div className="ms-1">
                <Link to={"#!"} onClick={() => showUserDetails(data)}>
                  {data.firstname || "First Name"}{" "}
                  {data.lastname || "Last Name"}
                </Link>

                <Badge className="ms-2 bg-primary">
                  {data.groups.includes("Site-Administrators")
                    ? "Site Admin"
                    : ""}
                </Badge>
              </div>
              <div className="ms-1">{data.email.toLowerCase()}</div>
            </div>
          </div>
        </td>
        <td className="text-nowrap text-center">
          {data.LogInfo === undefined ? "-" : data.LogInfo}
        </td>
        <td className="text-center">
          <SoftBadge pill bg={data.status.type}>
            {data.status.title}
            <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
          </SoftBadge>
        </td>
        <td className="text-center">
          <CardDropdown>
            <div className="py-2">
              {calculateAdminRights().map((v) => (
                <React.Fragment key={v._id}>{v}</React.Fragment>
              ))}
            </div>
          </CardDropdown>
        </td>
      </tr>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={4}>Nothing Found!</td>
    </tr>
  );
};

const UserTable = ({
  list,
  inviteUser,
  resendInvite,
  deleteInvitedUser,
  grantUserAccess,
  revokeUserAccess,
  addUserToGroup,
  showUserDetails,
  adminCount,
  handleShowUserDetailTab,
  changeUserGroup,
  userData,
}) => {
  try {
    return (
      <Table responsive striped hover bordered style={{ fontSize: "14px" }}>
        <thead>
          <tr>
            <th scope="col">User</th>
            <th scope="col" className="text-center">
              Last Active
            </th>
            <th scope="col" className="text-center">
              Status
            </th>
            <th scope="col" className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list?.map((user) => (
              <TableRow
                key={user.email}
                data={user}
                revokeUserAccess={revokeUserAccess}
                grantUserAccess={grantUserAccess}
                inviteUser={inviteUser}
                deleteInvitedUser={deleteInvitedUser}
                resendInvite={resendInvite}
                addUserToGroup={addUserToGroup}
                showUserDetails={showUserDetails}
                adminCount={adminCount}
                handleShowUserDetailTab={handleShowUserDetailTab}
                changeUserGroup={changeUserGroup}
                userData={userData}
              />
            ))
          ) : (
            <EmptyRow />
          )}
        </tbody>
      </Table>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default UserTable;

// {data.groups.includes("Site-Administrators") && adminCount < 2 ? (
//   data.status.title === "Invited" ? (
//     <CardDropdown>
//       <div className="py-2">
//         <Dropdown.Item
//           // onClick={() => handleAddUserToGroup(data)}
//           onClick={() => changeUserGroup(data)}
//         >
//           change group
//         </Dropdown.Item>
//         <Dropdown.Item
//           className="text-dark"
//           onClick={() => resendInvite(data)}
//         >
//           Resend Invite
//         </Dropdown.Item>
//         <Dropdown.Item
//           className="text-danger"
//           onClick={() => deleteInvitedUser(data)}
//         >
//           Delete User
//         </Dropdown.Item>
//       </div>
//     </CardDropdown>
//   ) : data.status.title === "Ignored" ? (
//     <CardDropdown>
//       <div className="py-2">
//         <Dropdown.Item
//           // onClick={() => handleAddUserToGroup(data)}
//           onClick={() => changeUserGroup(data)}
//         >
//           change group
//         </Dropdown.Item>
//         <Dropdown.Item
//           className="text-dark"
//           onClick={() => resendInvite(data)}
//         >
//           Resend Invite
//         </Dropdown.Item>
//         <Dropdown.Item
//           className="text-danger"
//           onClick={() => deleteInvitedUser(data)}
//         >
//           Delete User
//         </Dropdown.Item>
//       </div>
//     </CardDropdown>
//   ) : (
//     <span style={{ fontSize: "12px" }}>
//       Add a new Site Admin <br />
//       to Change/Revoke
//     </span>
//   )
// ) : data.status.title === "Invited" ||
//   data.status.title === "Ignored" ? (
//   <CardDropdown>
//     <div className="py-2">
//       {/* <Dropdown.Item onClick={() => handleAddUserToGroup(data)}> */}
//       <Dropdown.Item onClick={() => changeUserGroup(data)}>
//         change group
//       </Dropdown.Item>
//       <Dropdown.Item
//         className="text-dark"
//         onClick={() => resendInvite(data)}
//       >
//         Resend Invite
//       </Dropdown.Item>
//       <Dropdown.Item
//         className="text-danger"
//         onClick={() => deleteInvitedUser(data)}
//       >
//         Delete User
//       </Dropdown.Item>
//     </div>
//   </CardDropdown>
// ) : data.status.title === "Revoked" ? (
//   <CardDropdown>
//     <div className="py-2">
//       <Dropdown.Item
//         className="text-dark"
//         onClick={() => grantUserAccess(data)}
//       >
//         Reactivate
//       </Dropdown.Item>
//     </div>
//   </CardDropdown>
// ) : (
//   <CardDropdown>
//     <div className="py-2">
//       {/* <Dropdown.Item onClick={() => handleAddUserToGroup(data)}> */}
//       <Dropdown.Item onClick={() => changeUserGroup(data)}>
//         change group
//       </Dropdown.Item>
//       {data.isLoggedInUser === false ? (
//         <Dropdown.Item
//           className="text-danger"
//           onClick={() => revokeUserAccess(data)}
//         >
//           Revoke Access
//         </Dropdown.Item>
//       ) : null}
//     </div>
//   </CardDropdown>
// )}
