import React from 'react';
import CreateEvent from '../../../../components/app/events/create-an-event/CreateEvent';

export default function CreateEventPM() {
  return (
    <div>
      <CreateEvent />
    </div>
  );
}
