export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  children: [
    {
      name: "Dashboard",
      active: true,
      icon: "chart-pie",
      to: "/",
    },
  ],
};
// export const appRoutes = {
//   label: 'app',
//   children: [
//     {
//       name: 'Calendar',
//       icon: 'calendar-alt',
//       to: '/app/calendar',
//       active: true
//     },
//     {
//       name: 'Chat',
//       icon: 'comments',
//       to: '/app/chat',
//       active: true
//     },
//     {
//       name: 'Email',
//       icon: 'envelope-open',
//       active: true,
//       children: [
//         {
//           name: 'Inbox',
//           to: '/email/inbox',
//           active: true
//         },
//         {
//           name: 'Email detail',
//           to: '/email/email-detail',
//           active: true
//         },
//         {
//           name: 'Compose',
//           to: '/email/compose',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Events',
//       icon: 'calendar-day',
//       active: true,
//       children: [
//         {
//           name: 'Create an event',
//           to: '/events/create-an-event',
//           active: true
//         },
//         {
//           name: 'Event detail',
//           to: '/events/event-detail',
//           active: true
//         },
//         {
//           name: 'Event list',
//           to: '/events/event-list',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'E Commerce',
//       icon: 'shopping-cart',
//       active: true,
//       children: [
//         {
//           name: 'Product',
//           active: true,
//           children: [
//             {
//               name: 'Product list',
//               to: '/e-commerce/product/product-list',
//               active: true
//             },
//             {
//               name: 'Product grid',
//               to: '/e-commerce/product/product-grid',
//               active: true
//             },
//             {
//               name: 'Product details',
//               to: '/e-commerce/product/product-details',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Orders',
//           active: true,
//           children: [
//             {
//               name: 'Order list',
//               to: '/e-commerce/orders/order-list',
//               active: true
//             },
//             {
//               name: 'Order details',
//               to: '/e-commerce/orders/order-details',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Customers',
//           to: '/e-commerce/customers',
//           active: true
//         },
//         {
//           name: 'Customer details',
//           to: '/e-commerce/customer-details',
//           active: true
//         },
//         {
//           name: 'Shopping cart',
//           to: '/e-commerce/shopping-cart',
//           active: true
//         },
//         {
//           name: 'Checkout',
//           to: '/e-commerce/checkout',
//           active: true
//         },
//         {
//           name: 'Billing',
//           to: '/e-commerce/billing',
//           active: true
//         },
//         {
//           name: 'Invoice',
//           to: '/e-commerce/invoice',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Kanban',
//       icon: ['fab', 'trello'],
//       to: '/app/kanban',
//       active: true
//     },
//     {
//       name: 'Social',
//       icon: 'share-alt',
//       active: true,
//       children: [
//         {
//           name: 'Feed',
//           to: '/social/feed',
//           active: true
//         },
//         {
//           name: 'Activity log',
//           to: '/social/activity-log',
//           active: true
//         },
//         {
//           name: 'Notifications',
//           to: '/social/notifications',
//           active: true
//         },
//         {
//           name: 'Followers',
//           to: '/social/followers',
//           active: true
//         }
//       ]
//     }
//   ]
// };

// export const pagesRoutes = {
//   label: 'pages',
//   children: [
//     {
//       name: 'Option',
//       icon: 'flag',
//       to: '#!',
//       active: true
//     },
//     {
//       name: 'Option 1',
//       icon: 'globe',
//       to: '#!',
//       active: true
//     },
//     // {
//     //   name: 'Authentication',
//     //   icon: 'lock',
//     //   active: true,
//     //   children: [
//     //     {
//     //       name: 'Simple',
//     //       active: true,
//     //       children: [
//     //         {
//     //           name: 'Login',
//     //           to: '/authentication/simple/login',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Logout',
//     //           to: '/authentication/simple/logout',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Register',
//     //           to: '/authentication/simple/register',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Forgot password',
//     //           to: '/authentication/simple/forgot-password',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Confirm mail',
//     //           to: '/authentication/simple/confirm-mail',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Reset password',
//     //           to: '/authentication/simple/reset-password',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Lock screen',
//     //           to: '/authentication/simple/lock-screen',
//     //           active: true
//     //         }
//     //       ]
//     //     },
//     //     {
//     //       name: 'Card',
//     //       active: true,
//     //       children: [
//     //         {
//     //           name: 'Login',
//     //           to: '/authentication/card/login',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Logout',
//     //           to: '/authentication/card/logout',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Register',
//     //           to: '/authentication/card/register',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Forgot password',
//     //           to: '/authentication/card/forgot-password',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Confirm mail',
//     //           to: '/authentication/card/confirm-mail',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Reset password',
//     //           to: '/authentication/card/reset-password',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Lock screen',
//     //           to: '/authentication/card/lock-screen',
//     //           active: true
//     //         }
//     //       ]
//     //     },
//     //     {
//     //       name: 'Split',

//     //       active: true,
//     //       children: [
//     //         {
//     //           name: 'Login',
//     //           to: '/authentication/split/login',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Logout',
//     //           to: '/authentication/split/logout',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Register',
//     //           to: '/authentication/split/register',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Forgot password',
//     //           to: '/authentication/split/forgot-password',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Confirm mail',
//     //           to: '/authentication/split/confirm-mail',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Reset password',
//     //           to: '/authentication/split/reset-password',
//     //           active: true
//     //         },
//     //         {
//     //           name: 'Lock screen',
//     //           to: '/authentication/split/lock-screen',
//     //           active: true
//     //         }
//     //       ]
//     //     },
//     //     {
//     //       name: 'Wizard',
//     //       to: '/authentication/wizard',
//     //       active: true
//     //     },
//     //     {
//     //       name: 'Modal',
//     //       to: '/authentication-modal',
//     //       active: true
//     //     }
//     //   ]
//     // },
//     {
//       name: 'User',
//       icon: 'user',
//       active: true,
//       children: [
//         {
//           name: 'Profile',
//           to: '/user/profile',
//           active: true
//         },
//         {
//           name: 'Settings',
//           to: '/user/settings',
//           active: true
//         }
//       ]
//     },
//     // {
//     //   name: 'Pricing',
//     //   icon: 'tags',
//     //   active: true,
//     //   children: [
//     //     {
//     //       name: 'Pricing default',
//     //       to: '/pricing/pricing-default',
//     //       active: true
//     //     },
//     //     {
//     //       name: 'Pricing alt',
//     //       to: '/pricing/pricing-alt',
//     //       active: true
//     //     }
//     //   ]
//     // },
//     {
//       name: 'Dropdown',
//       icon: 'question-circle',
//       active: true,
//       children: [
//         {
//           name: 'option 1',
//           to: '/faq/faq-basic',
//           active: true
//         },
//         {
//           name: 'option 2',
//           to: '/faq/faq-alt',
//           active: true
//         },
//         {
//           name: 'option 3',
//           to: '/faq/faq-accordion',
//           active: true
//         }
//       ]
//     }
//     // {
//     //   name: 'Errors',
//     //   active: true,
//     //   icon: 'exclamation-triangle',
//     //   children: [
//     //     {
//     //       name: '404',
//     //       to: '/errors/404',
//     //       active: true
//     //     },
//     //     {
//     //       name: '500',
//     //       to: '/errors/500',
//     //       active: true
//     //     }
//     //   ]
//     // },
//     // {
//     //   name: 'Miscellaneous',
//     //   icon: 'thumbtack',
//     //   active: true,
//     //   children: [
//     //     {
//     //       name: 'Associations',
//     //       to: '/miscellaneous/associations',
//     //       active: true
//     //     },
//     //     {
//     //       name: 'Invite people',
//     //       to: '/miscellaneous/invite-people',
//     //       active: true
//     //     },
//     //     {
//     //       name: 'Privacy policy',
//     //       to: '/miscellaneous/privacy-policy',
//     //       active: true
//     //     }
//     //   ]
//     // }
//   ]
// };
// export const managementRoutes = {
//   label: 'Account Management',
//   children: [
//     {
//       name: 'Company Profile',
//       icon: 'user',
//       to: 'admin/management/company/12343545/profile',
//       active: true
//     },
//     {
//       name: 'Users',
//       icon: 'user',
//       to: 'admin/management/users',
//       active: true
//     },
//     {
//       name: 'Groups',
//       icon: 'globe',
//       to: 'admin/management/groups',
//       active: true
//     }
//   ]
// };
export const propertyRoutes = {
  label: "Property Management",
  children: [
    {
      name: "Properties",
      icon: "calendar-alt",
      to: "/property/management/buildings",
      active: true,
    },
  ],
};

// export const featuresRoutes = {
//   label: 'features',
//   children: [
//     {
//       name: 'Features',
//       to: '/faq/faq-basic',
//       icon: 'flag',
//       active: true,
//       children: [
//         {
//           name: 'Amenity Bookings',
//           icon: 'book',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Announcements & Bulletins',
//           icon: 'share-alt',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Complaints',
//           icon: 'exclamation-triangle',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Events Calendar',
//           icon: 'calendar-alt',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'File Library',
//           icon: 'file',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Mail-Outs',
//           icon: 'envelope-open',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Online Payments',
//           icon: 'shopping-cart',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Owner Directory',
//           icon: 'thumbtack',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Violation Tracking',
//           icon: 'question-circle',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Visitor Management',
//           icon: 'user',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Visitor Parking Permit',
//           icon: 'user',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Account Integration',
//           icon: 'map',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Maintaince Tracking',
//           icon: 'flag',
//           to: '#!',
//           active: true
//         },
//         {
//           name: 'Portfolio Management',
//           icon: 'fire',
//           to: '#!',
//           active: true
//         }
//       ]
//     }
//   ]
// };
let arr = [
  dashboardRoutes,
  // appRoutes,
  // pagesRoutes,
  // managementRoutes,
  propertyRoutes,
  // featuresRoutes
];
export default arr;
