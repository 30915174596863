import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
// import Avatar from 'components/common/Avatar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import FeedDropdown from "./FeedDropdown";
import { timeAgo } from "helpers/utils";
import { Avatar } from "@mui/material";

const FeedCardHeader = ({
  post,
  userData,
  updatePost,
  deletePost,
  from = "",
}) => {
  //console.log(updatePost);

  const { _id, firstname, lastname, bgColor, createdAt } = post.created_by_id;

  // console.log(userData.data);
  // console.log(_id);

  return (
    <Card.Header className="bg-light">
      <Row className="justify-content-between">
        <Col>
          <Flex>
            <Link className="d-flex" to="/user/profile">
              <Avatar
                sx={{
                  bgcolor: bgColor || "silver",
                  fontSize: "14px",
                }}
                className="img-thumbnail"
              >
                {firstname[0] + lastname[0]}
              </Avatar>
            </Link>
            <div className="flex-1 align-self-center ms-2">
              <p className="mb-1 lh-1">
                <Link className="fw-semi-bold" to="/user/profile">
                  <span>{`${firstname} ${lastname}`}</span>
                </Link>
              </p>
              <p className="mb-0 fs--1">
                {timeAgo(post.createdAt)}
                <span className="ms-2" />
                <FontAwesomeIcon icon={"globe-americas"} />
              </p>
            </div>
          </Flex>
        </Col>

        <Col xs="auto">
          {userData.data === _id && userData.role === "unit_owner" && (
            <FeedDropdown
              post={post}
              updatePost={updatePost}
              deletePost={deletePost}
            />
          )}

          {userData.data === _id && userData.role === "Site-Administrators" && (
            <FeedDropdown
              post={post}
              updatePost={updatePost}
              deletePost={deletePost}
            />
          )}

          {userData.data === _id &&
            userData.role === "Condozak-Basic-Users" && (
              <FeedDropdown
                post={post}
                updatePost={updatePost}
                deletePost={deletePost}
              />
            )}

          {userData.data !== _id && userData.role === "Site-Administrators" && (
            <FeedDropdown
              post={post}
              updatePost={updatePost}
              deletePost={deletePost}
            />
          )}

          {userData.data !== _id &&
            userData.role === "Condozak-Basic-Users" && (
              <FeedDropdown
                post={post}
                updatePost={updatePost}
                deletePost={deletePost}
              />
            )}

          {/* {(userData.data === _id || from === "manager") && (
            <FeedDropdown
              post={post}
              updatePost={updatePost}
              deletePost={deletePost}
            />
          )} */}
        </Col>
      </Row>
    </Card.Header>
  );
};

export default FeedCardHeader;
