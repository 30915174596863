import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
// import { Col, Row } from 'react-bootstrap';
import { Link ,useParams} from 'react-router-dom';

const AccountForm = ({ register, errors, watch }) => {
  const {EParameter}=useParams();

  return (
    <>
      {/* <Row className="g-2 mb-3"> */}
        <WizardInput
          label="First Name"
          name="firstName"
          errors={errors}
          formGroupProps={{ className : 'mb-2' }}
          formControlProps={{
            ...register('firstName', {
              required: 'First Name is required!'
            })
          }}
        />
        <WizardInput
          label="Last Name"
          name="lastName"
          errors={errors}
          formGroupProps={{ className : 'mb-2' }}
          formControlProps={{
            ...register('lastName', {
              required: 'Last Name is required!'
            })
          }}
        />
      {/* </Row> */}

      <WizardInput
        type="email"
        errors={errors}
        label="Email Address *"
        name="email"
        formGroupProps={{ className: 'mb-2' }}
        formControlProps={{
          ...register('email', {
            required: 'Email is required!',
            value:EParameter,
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            }
          })
        }}
      />

      {/* <Row className="g-2 mb-3"> */}
        <WizardInput
          type="password"
          errors={errors}
          label="Password*"
          name="password"
          formGroupProps={{ className : 'mb-2' }}
          formControlProps={{
            ...register('password', {
              required: 'You must specify a password',
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters'
              },
              pattern: {
                value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                message: 'Password Must have all the rules followed, displayed at right'
              }
            })
          }}
        />
        <WizardInput
          type="password"
          errors={errors}
          label="Confirm Password*"
          name="confirmPassword"
          formGroupProps={{ className : 'mb-2' }}
          formControlProps={{
            ...register('confirmPassword', {
              required: 'Confirm Password field is required',
              validate: value =>
                value === watch('password') || 'The password do not match'
            })
          }}
        />
      {/* </Row> */}

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            I accept the <Link to="#!"> terms</Link> and{' '}
            <Link to="#!"> privacy policy</Link>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'You need to agree the terms and privacy policy.'
          })
        }}
      />
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default AccountForm;
