import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
import SoftBadge from "components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Table } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import CardDropdown from "components/common/CardDropdown";
import {formatLogInfo} from 'helpers/utils'
const TableRow = ({ data, propLength,updateItem,deleteItem }) => {
  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td className="text-center">{data.car_make || "id"}</td>
      <td className="text-center">{data.car_model || "id"}</td>
      <td className="text-center">{data.color || "id"}</td>
      <td className="text-center">{data.license_plate || "id"}</td>
      {/* <td className="text-center">{formatLogInfo(data.createdAt) || "name"}</td> */}
      {/* <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              className="text-info"
              style={{ fontWeight: "bold" }}
              onClick={() => updateItem(data)}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="text-warning"
              style={{ fontWeight: "bold" }}
              onClick={() => deleteItem(data)}
            >
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </td> */}
    </tr>
  );
};

const MailOutsTable = ({ list,updateItem,deleteItem }) => {
  // console.log(list);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Car Make
          </th>
          <th scope="col" className="text-center">
            Car Model
          </th>
          <th scope="col" className="text-center">
            Color
          </th>
          <th scope="col" className="text-center">
            License Plate #
          </th>
          {/* <th scope="col" className="text-center">
            Actions
          </th> */}
        </tr>
      </thead>
      <tbody>
        {list?.map((item, index) => (
          <TableRow data={item} key={index} propLength={list.length} 
          updateItem={updateItem}
          deleteItem={deleteItem}/>
        ))}
      </tbody>
    </Table>
  );
};

export default MailOutsTable;
