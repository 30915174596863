import React from "react";

import Flex from "components/common/Flex";
import { Button, Card, Col, Row } from "react-bootstrap";

import axios from "axios";

import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "components/common/Loader";
import CustomFilterbar from "components/common/CustomFilterbar";
import CustomSearchbar from "components/common/CustomSearchbar";
import EditEventPage from "./EditEvent";
import EventsList from "./EventsList";
import EventsModal from "./EventsModal";
import { startCase } from "lodash";
import { formatLogInfo } from "helpers/utils";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const EventsCalender = () => {
  let hostname = getHostName();

  const navigate = useNavigate();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name } = useParams();

  const [eventsList, setEventsList] = React.useState([]);
  const [eventsListTemp, setEventsListTemp] = React.useState([...eventsList]);

  const [loader, setLoader] = React.useState(false);

  const [showEditEventPage, setEditEventPage] = React.useState(false);

  const handleShowEditEventPage = () => setEditEventPage(true);

  const handleCloseEditEventPage = () => {
    setEditEventPage(false);
  };

  const [showModel, setShowModel] = React.useState(false);

  const handleShow = () => setShowModel(true);

  const handleClose = () => setShowModel(false);
  
  const [payload, setPayload] = React.useState({});

  const [showPastEvents, setShowPastEvents] = React.useState(false);

  const [pastEventsData, setPastEventsData] = React.useState([]);

  // const [registeredTickets, setRegisteredTickets] = React.useState({});

  React.useEffect(() => {
    getAllEventsData();
    // getRegisteredTickets();
  }, []);

  const getAllEventsData = () => {
    setLoader(true);

    let p_id = "";
    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      p_id = property_id;
    }
    if (userData.role === "unit_owner") {
      p_id = userData.propid;
    }

    axios
      .post(
        `${hostname}/api/property/ReadEvents`,
        {
          p_id,
          user_id: userData.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let arr = res.data.Data?.map((v) => {
          let obj = { ...v };
          obj.event_days = obj.eventDays;
          let tmp_date_start = new Date(obj.event_start_date);
          let tmp_date_end = new Date(obj.event_end_date);
          let calendar = {
            day: tmp_date_start.getDate().toString(),
            month: months[tmp_date_start.getMonth()],
          };
          obj.calendar = calendar;
          obj.event_name = startCase(obj.event_name);
          obj.event_organizer = startCase(obj.event_organizer);
          obj.duration = `${
            months[tmp_date_start.getMonth()]
          } ${tmp_date_start.getDate()} - ${
            months[tmp_date_end.getMonth()]
          } ${tmp_date_end.getDate()}`;

          let tmped = obj.event_days[0];
          obj.time = `${tmped?.start_time} ${tmped?.start_timezone} - ${tmped?.end_time} ${tmped?.end_timezone}`;
          obj.event_registration_deadline = formatLogInfo(
            obj.event_registration_deadline,
            true
          );
          if (obj.event_ticket.event_ticket_type !== "Free Ticket") {
            obj.event_paid_price = obj.event_ticket.ticket_options?.filter(
              (v) => v.checked
            )[0]?.price;
          }

          return obj;
        });

        // console.log(arr);

        let upArr = [];
        let newArr = [];

        // let currentDate = new Date();

        arr?.forEach((v) => {
          if (checkIfItemExpired(v.event_end_date)) upArr.push(v);

          if (!checkIfItemExpired(v.event_end_date)) newArr.push(v);
        });

        // console.log(arr);
        setEventsList(newArr);
        setEventsListTemp(arr);
        console.log(newArr);

        setPastEventsData(upArr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const checkIfItemExpired = (expiry_date) => {
    if (!expiry_date) return false;

    // ####
    // Convert it to a Date object
    const dateFromBackend = new Date(expiry_date);

    // Get the current date (local time)
    const now = new Date();

    // Create a new Date object representing only the date portion (ignoring the time)
    const backendDateOnly = new Date(
      dateFromBackend.getFullYear(),
      dateFromBackend.getMonth(),
      dateFromBackend.getDate()
    );
    const todayOnly = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );

    // Compare the dates
    if (backendDateOnly < todayOnly) {
      console.log("The date is in the past.");
      return true;
    } else if (backendDateOnly > todayOnly) {
      console.log("The date is in the future.");
      return false;
    } else {
      console.log("The date is today.");
      return false;
    }
  };

  const createEvent = () => {
    // if(showModel)
    //   setShowModel(false);
    let obj = {};
    obj.loadType = "Create Event";
    setPayload(obj);
    handleShow();
  };

  // untill it ok

  const deleteEvent = (event) => {
    event.loadType = "Delete Event";
    setPayload(event);
    handleShow();
  };

  const editEvent = (event = {}) => {
    setPayload(event);
    handleShowEditEventPage();
  };

  const viewEvent = (event = {}) => {
    console.log("calling viewEvent function");

    console.log(event);

    let link = "";

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      link = `/property/features/${property_id}/${property_name}/events-calender/event-details/${event.event_id}`;
    }
    if (userData.role === "unit_owner") {
      link = `/owner-portal/events-calender/event-details/${event.event_id}`;
    }

    //using only event_id as details are fetched inside details tab

    navigate(link);

    // setPayload(event);

    // handleShowViewEventPage();
  };

  const handleShowPastEvents = () => {
    if (!showPastEvents) {
      // console.log(pastEventsData);
      setEventsList(pastEventsData);
    } else {
      // console.log(eventsListTemp);
      let upArr = [];
      let newArr = [];

      eventsListTemp?.forEach((v) => {
        if (checkIfItemExpired(v.event_end_date)) upArr.push(v);

        if (!checkIfItemExpired(v.event_end_date)) newArr.push(v);
      });

      setEventsList(newArr);
    }

    setShowPastEvents(!showPastEvents);
  };

  // const getRegisteredTickets = (e_id) => {
  //   let tdata = {
  //     user_id: userData.data,
  //     // p_id: userData.propid,
  //     event_id: e_id,
  //   };
  //   // console.log(tdata);
  //   axios
  //     .post(`${hostname}/api/property/ReadEventTickets`, { ...tdata })
  //     .then(
  //       (res) => {
  //         // console.log(res.data);
  //         setRegisteredTickets(res.data?.Data);
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}`,
  //         },
  //       }
  //     )
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  if (loader) return <Loader />;
  if (showEditEventPage)
    return (
      <EditEventPage
        handleCloseEditEventPage={handleCloseEditEventPage}
        event={payload}
        userData={userData}
        getAllData={getAllEventsData}
        property_id={property_id}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    );

  return (
    <>
      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="d-flex align-items-center">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to="/property/management/buildings"
                >
                  Properties
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {property_name}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2"
                  // to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                  to={"#!"}
                >
                  Events Calender
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {userData?.role === "unit_owner" && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="d-flex align-items-center">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}

                <Link className="me-2 ms-2" to={"#!"}>
                  Events Calender
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Events Calender</h4>
            {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
            {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
            {(userData.role === "Site-Administrators" ||
              userData.role === "Condozak-Basic-Users") && (
              <Button variant="primary" size="sm" onClick={createEvent}>
                + Create Event
              </Button>
            )}
          </Flex>
          <br />

          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <div className="me-3">
                <Button
                  variant={`falcon-${showPastEvents ? "success" : "warning"}`}
                  onClick={handleShowPastEvents}
                >
                  {showPastEvents ? "View Current Events" : "View Past Events"}
                </Button>
              </div>
              <CustomFilterbar
                filterFlags={[
                  "Free Tickets",
                  "Paid Tickets",
                  "Donation Tickets",
                ]}
                dataListTemp={eventsListTemp}
                stateUpdator={setEventsList}
                from="Events Table"
              />

              <CustomSearchbar
                searchFlags={[
                  "calendar.day",
                  "calendar.month",
                  "event_name",
                  "event_description",
                  "event_organizer",
                  "event_sponsor",
                  "venue",
                  "event_ticket.event_ticket_type",
                ]}
                dataListTemp={eventsListTemp}
                stateUpdator={setEventsList}
                placeholders={["name", "organizer", "time", "description"]}
              />
            </Col>
          </Row>

          <div className="px-5">
            <EventsList
              eventsList={eventsList}
              editEvent={editEvent}
              viewEvent={viewEvent}
              deleteEvent={deleteEvent}
              userData={userData}
            />
          </div>
          <br />

          <EventsModal
            show={showModel}
            handleClose={handleClose}
            payload={payload}
            setPayload={setPayload}
            getAllData={getAllEventsData}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default EventsCalender;

// const dayDiff = (date1: Date, date2: Date) => Math.ceil(Math.abs(date1.getTime() - date2.getTime()) / 86400000);
