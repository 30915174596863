import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown } from "react-bootstrap";

const FeedDropdown = ({ post, updatePost, deletePost }) => {
  return (
    <Dropdown className="font-sans-serif btn-reveal-trigger">
      <Dropdown.Toggle
        variant="falcon-primary"
        size="sm"
        className="px-1 text-600 btn-reveal"
      >
        <FontAwesomeIcon icon="ellipsis-h" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {/* <Dropdown.Item href="#!" onClick={() => updatePost(post)}>
          Edit
        </Dropdown.Item> */}

        <Dropdown.Item
          href="#!"
          onClick={() => deletePost(post)}
          className="text-danger"
        >
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FeedDropdown;
