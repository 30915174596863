import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { AuthWizardContext } from "../../../../../context/Context";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import axios from 'axios';
// import { toast } from 'react-toastify';
import { ErrorMessage, Formik, Field } from "formik";
// import Select from 'react-select';
export default function AmenityPayment() {
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);


  return (
    <div>
      <Formik
        initialValues={{
          payment_type: user.payment_type || "",
          amenity_price: user.amenity_price || "",
          payment_method:
            "payment_method" in user ? [...user.payment_method] : [],
          // allow_cancel: user.allow_cancel || '',
          payment_instructions_cash: user.payment_instructions_cash || "",
          payment_instructions_credit_card:
            // user.payment_instructions_online_payment ||
            "You will be requested the card details fot this type.",
          payment_instructions_check: user.payment_instructions_check || "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.payment_type) {
            errors.payment_type = "payment type is required!";
          }
          if (values.payment_type && values.payment_type !== "No Fee") {
            if (!values.amenity_price) {
              errors.amenity_price = "price is required!";
            }
            // let payment_method_arr = [];
            // payment_method_arr = [...values.payment_method];
            // if (payment_method_arr.length < 1) {
            //   errors.payment_method = "plese select payment method!";
            // }
            // payment_method_arr?.forEach(v => {
            //   // if (v === 'Credit Card')
            //   //   errors.payment_instructions_credit_card =
            //   //     'info for credit card is required!';
            //   if (v === 'Check')
            //     errors.payment_instructions_check =
            //       'info for check is required!';
            //   if (v === 'Credit Card')
            //     errors.payment_instructions_cash = 'info for cash is required!';
            // });
          }
          // if (!values.allow_cancel) {
          //   errors.allow_cancel = 'required option!';
          // }
          console.log(errors);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          //   setTimeout(() => {
          // alert(JSON.stringify(values, null, 2));
          console.log(values);
          // return;
          setUser(() => Object.assign({ ...user, ...values }));
          setSubmitting(false);
          setStep(step + 1);
          // }, 400);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          // setValues
          /* and other goodies */
        }) => (
          <div className="">
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Payment Type *</label>
                  <Field
                    component="select"
                    id="payment_type"
                    name="payment_type"
                    multiple={false}
                    className="form-select"
                  >
                    <option value="">select type</option>
                    <option value="No Fee">No Fee</option>
                    {user.booking_type === "Hotel Style" && (
                      <option value="Night Fee">Night Fee</option>
                    )}
                    {user.booking_type === "Daily" && (
                      <option value="Daily">Daily Rate</option>
                    )}
                    {user.booking_type === "Time Slots" && (
                      <>
                        {/* <option value="Flat Rate">Flat Rate</option> */}
                        <option value="Per Hour">Hourly Rate</option>
                      </>
                    )}
                    {/* {user.booking_type === 'Hotel Style' ? (
                      <option value="Night Fee">Night Fee</option>
                    ) : (
                      <>
                        <option value="Flat Rate">Flat Rate</option>
                        <option value="Per Hour">Hourly Rate</option>
                        <option value="Daily">Daily Rate</option>
                      </>
                    )} */}
                  </Field>
                  <ErrorMessage
                    name="payment_type"
                    component={"div"}
                    className="text-danger fs--1"
                  />
                </div>
                {values.payment_type !== "No Fee" && (
                  <>
                    <div className="mb-3">
                      <label>Amenity Price *</label>
                      <input
                        type="text"
                        name="amenity_price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.amenity_price}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="amenity_price"
                        component={"div"}
                        className="text-danger fs--1"
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label>Payment Method *</label>
                      <Field
                        component="select"
                        id="payment_method"
                        name="payment_method"
                        multiple={true}
                        className="form-select"
                      >
                        <option value="">Select method</option>
                        <option value="Cash">Cash</option>
                        <option value="Online Payment">Online Payment</option>
                        <option value="Certified Cheque">
                          Certified Cheque
                        </option>
                      </Field>
                      <ErrorMessage
                        name="payment_method"
                        component={'div'}
                        className="text-danger fs--1"
                      />
                    </div> */}
                    {/* <div className="mb-3">
                      <label>Payment Methods</label>
                      <br />

                      <div className="form-check form-check-inline">
                        <Field
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          value="Credit Card"
                          name="payment_method"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox2"
                        >
                          Credit Card
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Field
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox3"
                          value="Check"
                          name="payment_method"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox3"
                        >
                          Check
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Field
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="Cash"
                          name="payment_method"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          Cash
                        </label>
                      </div>
                      <ErrorMessage
                        name="payment_method"
                        className="fs--1 text-danger"
                        component={"div"}
                      />
                    </div> */}
                  </>
                )}
                {/* {values.payment_method?.map((v) => {
                  if (v !== "Credit Card")
                    return (
                      <div className="mb-3" key={v + ";83"}>
                        <label>Payment Instructions for {v}</label>
                        <textarea
                          rows={5}
                          type="text"
                          name={`payment_instructions${
                            v === "Cash"
                              ? "_cash"
                              : v === "Credit Card"
                              ? "credit_card"
                              : v === "Check"
                              ? "_check"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            values[
                              `payment_instructions${
                                v === "Cash"
                                  ? "_cash"
                                  : v === "Credit Card"
                                  ? "credit_card"
                                  : v === "Check"
                                  ? "_check"
                                  : ""
                              }`
                            ]
                          }
                          className="form-control"
                        />
                        <ErrorMessage
                          name={`payment_instructions${
                            v === "Cash"
                              ? "_cash"
                              : v === "Credit Card"
                              ? "_credit_card"
                              : v === "Check"
                              ? "_check"
                              : ""
                          }`}
                          component={"div"}
                          className="text-danger fs--1"
                        />
                      </div>
                    );
                })} */}

                {/* <div className="mb-3">
                  <label>Allow Cancel *</label>
                  <Field
                    component="select"
                    id="allow_cancel"
                    name="allow_cancel"
                    multiple={false}
                    className="form-select"
                  >
                    <option value="">Select </option>
                    <option value="Until Booking Begins">
                      Until Booking Begins
                    </option>
                    <option value="24 Hours">24 Hour</option>
                    <option value="48 Hours">48 Hours</option>
                  </Field>
                  <ErrorMessage
                    name="allow_cancel"
                    component={'div'}
                    className="text-danger fs--1"
                  />
                </div> */}
                <br />
                <div>
                  <Button variant="secondary" onClick={() => setStep(step - 1)}>
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    className=" float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
