import FalconCardHeader from "components/common/FalconCardHeader";
import { formatLogInfo } from "helpers/utils";
import React from "react";
import { Card, Table, Button } from "react-bootstrap";
import { AuthWizardContext } from "context/Context";

export default function VisitorReviewDetails({ handleNewVisitor }) {
  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const handleAdd = () => {
    console.log(user);
    handleNewVisitor(user);

    // setStep(step + 1)
  };

  return (
    <div>
      <Card>
        <FalconCardHeader title="Booking Details" />
        <Card.Body className="bg-light px-0">
          <Table striped bordered responsive>
            <tbody>
              <tr>
                <td>Visitor Name</td>
                <td className="fw-bold">{user.visitor_name}</td>
              </tr>
              <tr>
                <td>Visitor Category</td>
                <td className="fw-bold">{user.visitor_category}</td>
              </tr>
              <tr>
                <td>Visitor Email </td>
                <td className="fw-bold">{user.visitor_email}</td>
              </tr>
              <tr>
                <td>Visitor Contact #</td>
                <td className="fw-bold">{user.visitor_phone}</td>
              </tr>
              <tr>
                <td>Arrival Date</td>
                <td className="fw-bold">
                  {formatLogInfo(user.start_date, true)}
                </td>
              </tr>
              <tr>
                <td>Arrival Time</td>
                <td className="fw-bold">
                  {formatLogInfo(user.start_time)?.split("-")[1]}
                </td>
              </tr>
              <tr>
                <td>Departure Date</td>
                <td className="fw-bold">
                  {formatLogInfo(user.end_date, true)}
                </td>
              </tr>
              <tr>
                <td>Departure Time</td>
                <td className="fw-bold">
                  {formatLogInfo(user.end_time)?.split("-")[1]}
                </td>
              </tr>
              <tr>
                <td>Profile Image</td>
                <td>
                  {user.visitor_profile_img ? (
                    <img
                      src={URL.createObjectURL(user.visitor_profile_img)}
                      width={130}
                    />
                  ) : (
                    "not uploaded!"
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <br />
      <div className="">
        <Button
          variant="secondary"
          type="button"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          variant="primary"
          className="float-end"
          type="button"
          onClick={handleAdd}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
