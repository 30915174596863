// import FalconCardHeader from 'components/common/FalconCardHeader';
// import React from 'react';
// import { Card } from 'react-bootstrap';

// export default function DangerZone() {
//   return (
//     <div>
//       {' '}
//       <Card>
//         <FalconCardHeader title="Danger Zone" />
//         <Card.Body className="bg-light"></Card.Body>
//       </Card>
//     </div>
//   );
// }

// import { useConfirm } from "material-ui-confirm";
import FalconCardHeader from "../../../../../../components/common/FalconCardHeader";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import axios from "axios";

const DangerZone = ({
  amenityCurrent,
  getAmenitiesData,
  // setshowEditAmenityTab,
  handleConfirmation,
}) => {
  // const confirm = useConfirm();
  // const showAlert = () => {
  //   // let isDone = false;
  //   confirm({
  //     title: `confirm disable amenity ${amenityCurrent.amenityname}?`,
  //     description: "This action is permanent!",
  //   })
  //     .then(() => {
  //       axios
  //         .post("http://localhost:5000/api/amenity/DisableAmenity", {
  //           amenity_id: amenityCurrent._id,
  //         })
  //         .then((res) => {
  //           console.log(res.data);
  //           toast.success(res.data, { theme: "colored" });
  //           getAmenitiesData();
  //           setshowEditAmenityTab(false);

  //           // navigate(
  //           //   `${window.location.hostname}/property/features/${property_id}/${property_name}/amenities`
  //           // );
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       toast.error("action cancelled!", { theme: "colored" });
  //     });

  //   // if (isDone) {
  //   //   toast.success('operation successful.', { theme: 'colored' });
  //   //   getAmenitiesData();
  //   // }
  // };

  // // c
  // const showAlertDeactivateAmenity = () => {
  //   // let isDone = false;
  //   confirm({
  //     title: `confirm deactivate amenity ${amenityCurrent.amenityname}?`,
  //     description: "This action is permanent!",
  //   })
  //     .then(() => {
  //       axios
  //         .post("http://localhost:5000/api/amenity/DisableAmenity", {
  //           amenity_id: amenityCurrent._id,
  //         })
  //         .then((res) => {
  //           console.log(res.data);
  //           toast.success(res.data, { theme: "colored" });
  //           getAmenitiesData();
  //           setshowEditAmenityTab(false);

  //           // navigate(
  //           //   `${window.location.hostname}/property/features/${property_id}/${property_name}/amenities`
  //           // );
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       toast.error("action cancelled!", { theme: "colored" });
  //     });

  //   // if (isDone) {
  //   //   toast.success('operation successful.', { theme: 'colored' });
  //   //   getAmenitiesData();
  //   // }
  // };

  return (
    <Card>
      <FalconCardHeader title="Danger Zone" />
      <Card.Body className="bg-light">
        <h5 className="mb-0">Set Temporarily Disable</h5>
        <p className="fs--1">
          Make current amenity unavailable for a short period of time.
        </p>
        <Button
          as={Link}
          to="#!"
          variant="falcon-warning"
          className="w-100"
          onClick={() =>
            handleConfirmation({
              id: amenityCurrent._id,
              amenity_name: amenityCurrent.amenityname,
              loadType: "Disable Amenity",
              updaterFunction : getAmenitiesData
            })
          }
        >
          Temporary Disable
        </Button>
        <div className="border-dashed border-bottom my-4" />
        <h5 className="mb-0">Delete this Amenity</h5>
        <p className="fs--1">
          Once committed, there is no going back. Please be certain.
        </p>
        <Button
          as={Link}
          to="#!"
          variant="falcon-danger"
          className="w-100"
          onClick={() =>
            handleConfirmation({
              id: amenityCurrent._id,
              amenity_name: amenityCurrent.amenityname,
              loadType: "Deactivate Amenity",
              updaterFunction : getAmenitiesData
            })
          }
        >
          Deactivate Amenity
        </Button>
      </Card.Body>
    </Card>
  );
};

export default DangerZone;
