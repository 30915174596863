

import ServiceRequestsComp from 'components/pages/UnitOnwer&Tennent/Features/ServiceRequests/ServiceRequests'

import React from 'react'

export default function ServiceRequests() {
  return (
    <ServiceRequestsComp />
  )
}
