// import FalconCardHeader from "components/common/FalconCardHeader";
import React from "react";
import { Card, Image } from "react-bootstrap";

export default function EventDetailBanner({ event = {} }) {
  return (
    <Card className="mb-3 overflow-hidden">
      <Card.Body style={{ height: "300px" }} className="bg-light p-0 ">
        {event.image_url && (
          <Image
            src={event.image_url}
        
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
              boxShadow: "0px 0px 3px",
              
            }}
          />
        )}
        {!event.image_url && (
          <div className="d-flex justify-content-center align-items-center">
            NO Image Provided
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
