// import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import csc from 'countries-states-cities';
// import Cookies from 'universal-cookie';

import { Formik, Field, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import FalconCardHeader from 'components/common/FalconCardHeader';

const AddressCompany = ({ addressCompanyData, updateCompanyAddress }) => {
  try {
    const handleSubmit = values => {
      console.log(values);

      let addressData = {};

      addressData.country_id = values.country;
      addressData.state_id = values.state;
      addressData.city_id = values.city;

      addressData.country = csc.getCountryById(+values.country).name;
      addressData.state = csc.getStateById(+values.state).name;
      addressData.city = csc.getCityById(+values.city).name;

      addressData.address = values.address;
      addressData.address2 = values.address2;
      addressData.zipcode = values.zipcode;

      console.log(addressData);
      updateCompanyAddress(addressData);
      // return;
    };

    const handleSetCountry = (e, setFieldValue) => {
      const { value } = e.target;

      setFieldValue('country', +value);
      setFieldValue('city', '');
    };

    return (
      <div>
        <Formik
          initialValues={{
            country: +addressCompanyData.country_id || '',
            state: +addressCompanyData.state_id || '',
            city: +addressCompanyData.city_id || '',
            zipcode: addressCompanyData.zipcode || '',
            address: addressCompanyData.address || '',
            address2: addressCompanyData.address2 || ''
          }}
          validationSchema={Yup.object({
            country: Yup.string().required('country is required!'),
            state: Yup.string().required('state is required!'),
            city: Yup.string().required('city is required!'),
            zipcode: Yup.string().required('zipcode is required!'),
            address: Yup.string().required('address is required!'),
            address2: Yup.string('valid value is required!')
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className="mb-3">
                <FalconCardHeader title="Company Address" />
                <Card.Body className="bg-light">
                  <Row className="mb-3">
                    <Col md={6}>
                      <label htmlFor="country">Country </label>
                      <Field
                        component="select"
                        id="country"
                        name="country"
                        className="form-select"
                        onChange={e => handleSetCountry(e, setFieldValue)}
                      >
                        <option>select</option>
                        {csc.getAllCountries()?.map((v, i) => (
                          <option key={v.id} value={v.id}>
                            {v.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="country"
                        className="text-danger fs--1"
                        component={'div'}
                      />
                    </Col>
                    <Col md={6}>
                      <label htmlFor="state">State/Province</label>
                      <Field
                        component="select"
                        id="state"
                        name="state"
                        className="form-select"
                        // multiple={true}
                      >
                        <option value="">select</option>
                        {csc
                          .getStatesOfCountry(+values.country)
                          ?.map((v, i) => (
                            <option key={v.id} value={v.id}>
                              {v.name}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage
                        name="state"
                        className="text-danger fs--1"
                        component={'div'}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <label htmlFor="city">City</label>
                      <Field
                        component="select"
                        id="city"
                        name="city"
                        className="form-select"
                        // multiple={true}
                      >
                        <option value="">select</option>
                        {csc.getCitiesOfState(+values.state)?.map((v, i) => (
                          <option key={v.id} value={v.id}>
                            {v.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="city"
                        className="text-danger fs--1"
                        component={'div'}
                      />
                    </Col>
                    <Col md={6}>
                      <label htmlFor="zipcode">Zip/Postal Code</label>
                      <Field
                        name="zipcode"
                        className="form-control"
                        placeholder="enter zipcode"
                      />
                      <ErrorMessage
                        name="zipcode"
                        className="text-danger fs--1"
                        component={'div'}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} className="mb-3">
                      <label htmlFor="address">Address</label>
                      <Field
                        name="address"
                        className="form-control"
                        placeholder="enter address"
                      />
                      <ErrorMessage
                        name="address"
                        className="text-danger fs--1"
                        component={'div'}
                      />
                    </Col>
                    <Col md={12} className="mb-3">
                      <label htmlFor="address2">Address 2 (if any)</label>
                      <Field
                        name="address2"
                        className="form-control"
                        placeholder="enter 2nd address here"
                      />
                      <ErrorMessage
                        name="address2"
                        className="text-danger fs--1"
                        component={'div'}
                      />
                    </Col>
                  </Row>
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </form>
          )}
        </Formik>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default AddressCompany;
