import React from "react";
import { Card } from "react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from 'react-router-dom';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
import Cookies from "universal-cookie";

const Error401New = () => {
  const cookies = new Cookies();

  let email = cookies.get("email_not_auth") || "Email here";
  let link = cookies.get("link_auth") || "url here";
  console.log(link.split(",").length);
  if (link.split(",").length > 1) {
    link = link.split(",");
  }
  if (typeof link === "object") {
    link = link?.map((v) => {
      let res = v;
      if (!v.endsWith("/login")) res = v + "/login";
      return res;
    });
  } else {
    if (!link.endsWith("/login")) link = link + "/login";
  }

  let host = window.location.host;

  const handleURLChange = (link) => {
    window.location.href = link;
  };

  return (
    <Card className="text-center">
      <Card.Body className="px-5">
        <div className="display-4 text-400 mt-3">We Are Sorry !</div>
        {/* <div className=" text-500 ">401 Not Authorized</div> */}
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {/* {email} <br /> */}
          You don't have access to <br />
        </p>
        <p className="text-warning fw-semi-bold fs-1">{host}</p>
        <hr />
        <p>
          Your account is registered with following site
          {typeof link === "object" && "(s)"}:
        </p>
        <p
          className="text-info cursor-pointer"
          onClick={() => handleURLChange(link)}
        >
          {typeof link === "string" && link}
        </p>
        <p className="text-info cursor-pointer">
          {typeof link === "object" &&
            link?.map((v, i) => (
              <span
                key={v + i}
                className="d-block mb-3"
                onClick={() => handleURLChange(v)}
              >
                {v}
              </span>
            ))}
        </p>
        .
        {/* <Button
          className="btn btn-primary btn-sm mt-3"
          onClick={() => handleURLChange(link)}
        >
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Login to Your Dashboard
        </Button> */}
      </Card.Body>
    </Card>
  );
};

export default Error401New;
