import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  Button,
  Form as BSForm,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
// import Button from 'react-bootstrap/Button';
import classNames from "classnames";
import axios from "axios";
import { useParams } from "react-router-dom";
import Divider from "../../components/common/Divider";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { RotatingLines } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { getHostName } from "helpers/utils";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordResetForm = ({ hasLabel }) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const { user_from_reset_ps } = useParams();
  // console.log(user_from_reset_ps);

  let hostname = getHostName();

  let link = "/login";
  if (user_from_reset_ps === "Manager") link = "/login";
  if (user_from_reset_ps === "Owner") link = "/property/login";

  // State
  const [showLoader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  // const [psValue2, setPsValue2] = useState(false);

  const { resetToken } = useParams(); ///Reset token sent through mail retrieved in Parameters
  // const [error, setError] = useState("");
  // const [success, setSuccess] = useState("");

  // const [formData, setFormData] = useState({
  //   password: "",
  //   confirmpassword: "",
  // });
 // const { password } = formData;

  // Handler

  const handleSubmit = async (values) => {
    //e.preventDefault();
    // setLoader(true);
    //console.log(values);

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (values.password !== values.confirm_password) {
      values.password = "";
      values.confirm_password = "";
      toast.error(`Passwords do not match `, {
        theme: "colored",
      });
      // setTimeout(() => {
      //   setError("");
      // }, 5000);
      // setLoader(false);

      //return setError("Passwords don't match");
    }

    //return;
    try {
      const { data } = await axios.post(
        `${hostname}/api/auth/resetpassword/${resetToken}`,
        {
          password:values.password,
          siteName: window.location.hostname,
        },
        config
      );

      // setTimeout(() => {
      // setLoader(false);
      // setSuccess(data.data);

      toast.success("Login with your new password", {
        theme: "colored",
      });
      setTimeout(() => {
        //  navigate("/property/login");
        // else navigate("/login");
        window.location.href = link;
      }, 3000);
      // }, 2000);
    } catch (error) {
      console.log(error);

      // setTimeout(() => {
      //   setLoader(false);
      toast.error("Token Expired", {
        theme: "colored",
      });
      //setError(error.response.data.error);
      // }, 2000);
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirm_password: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, "Must be greater than 6")
          .matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
            "Password Must have all the rules followed, displayed at right"
          )
          .required("Password is required!"),
        confirm_password: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match!")
          .required("confirm password is required!"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <BSForm.Group className="mb-3">
            {/* <BSForm.Label>Create New Password</BSForm.Label> */}
            <OverlayTrigger
              placement="right"
              trigger={"focus"}
              overlay={
                <Popover id={`popover-positioned-right`}>
                  <Popover.Body>
                    <strong>
                      <div>
                        <h6>A valid password contains</h6>
                        <ul>
                          <li>an Uppercase letter.</li>
                          <li>a Lowercase letter.</li>
                          <li>a Special character.</li>
                          <li>a Number.</li>
                          <li>must be of 8 characters.</li>
                          <li>can't be guessed easily.</li>
                          <li>
                            Example: <em>Name@123#</em>
                          </li>
                        </ul>
                      </div>
                    </strong>
                  </Popover.Body>
                </Popover>
              }
            >
              <div className="position-relative">
                <Field
                  name="password"
                  type={show ? "text" : "password"}
                  className="form-control"
                  placeholder="create new password"
                />
                <span
                  className="translate-middle cursor-pointer"
                  onClick={() => setShow(!show)}
                  style={{ position: "absolute", top: "50%", left: "90%" }}
                >
                  {show ? <VisibilityOff /> : <Visibility />}
                </span>
              </div>
            </OverlayTrigger>
            <ErrorMessage
              name="password"
              component={"div"}
              className="fs--1 text-danger"
            />
          </BSForm.Group>
          <BSForm.Group className="mb-3">
            {/* <BSForm.Label>Confirm New Password</BSForm.Label> */}
            <OverlayTrigger
              placement="right"
              trigger={"focus"}
              overlay={
                <Popover id={`popover-positioned-right`}>
                  <Popover.Body>
                    <strong>
                      <p>Must be matched with your new password to continue!</p>
                    </strong>
                  </Popover.Body>
                </Popover>
              }
            >
              <div className="position-relative">
                <Field
                  name="confirm_password"
                  type={show ? "text" : "password"}
                  className="form-control"
                  placeholder="confirm new password"
                />
                <span
                  className="translate-middle cursor-pointer"
                  onClick={() => setShow(!show)}
                  style={{ position: "absolute", top: "50%", left: "90%" }}
                >
                  {show ? <VisibilityOff /> : <Visibility />}
                </span>
              </div>
            </OverlayTrigger>
            <ErrorMessage
              name="confirm_password"
              component={"div"}
              className="fs--1 text-danger"
            />
          </BSForm.Group>

          <Button
            type="submit"
            className="w-100"
            disabled={!values.password || !values.confirm_password}
          >
            {showLoader ? (
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              t("reset_ps.set_password")
            )}
          </Button>
          <br />
          <Divider>{t("reset_ps.or")}</Divider>
          <div className="text-center">
            <Link
              to="#!"
              onClick={() => (window.location.href = link)}
              className="btn btn-link"
            >
              {t("reset_ps.back_to_login")}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default PasswordResetForm;
