import React from "react";
import { Modal } from "react-bootstrap";

import FalconCloseButton from "components/common/FalconCloseButton";
// import axios from 'axios';
import { toast } from "react-toastify";
import { Form as BSForm, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

let pets = ["Cat", "Dog", "Other"];

export default function CreateNewMailModal({
  payload,
  show,
  handleClose,
  getAllData,
  setPayload,
  userData,
  hostname,
  jwtToken,
}) {
  // console.log(payload);
  //
  const adduser = (values) => {
    let entry = {
      user_id: userData.data,
      unit_id: payload.unit_id,

      pet_type: values.pet_type,
      name: values.name,
      description: values.description,
      weight: values.weight,
      comments: values.comments,
    };
    console.log(entry);
    axios
      .post(`${hostname}/api/unit/CreatePet`, {
        ...entry,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.success("Pet Added.", { theme: "colored" });
        getAllData();
        handleClose();
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.err || "something went wrong", {
            theme: "colored",
          });
        else toast.error("something went wrong", { theme: "colored" });
      });
  };
  const updateuser = (values) => {
    let entry = {
      user_id: userData.data,
      unit_id: payload.unit_id,
      pet_type: values.pet_type,
      name: values.name,
      description: values.description,
      weight: values.weight,
      comments: values.comments,
    };
    console.log(entry);
    axios
      .post(`${hostname}/api/unit/UpdatePet`, {
        ...entry,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.success("Pet Updated.", { theme: "colored" });
        getAllData();
        handleClose();
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.err || "something went wrong", {
            theme: "colored",
          });
        else toast.error("something went wrong", { theme: "colored" });
      });
  };
  const deleteuser = (values) => {
    let entry = {
      user_id: userData.data,
      unit_id: payload.unit_id,
      pet_id: payload._id,
    };
    console.log(entry);
    axios
      .post(`${hostname}/api/unit/DeletePet`, {
        ...entry,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.success("Pet Deleted.", { theme: "colored" });
        getAllData();
        handleClose();
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.err || "something went wrong", {
            theme: "colored",
          });
        else toast.error("something went wrong", { theme: "colored" });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Header>
        <Modal.Title>{payload.loadType}</Modal.Title>
        <FalconCloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {payload.loadType === "Add Pet" && (
          <div>
            <Formik
              initialValues={{
                pet_type: "",
                name: "",
                description: "",
                weight: "",
                comments: "",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object({
                pet_type: Yup.string("").required("required option!"),
                name: Yup.string("").required("required option!"),
                description: Yup.string("").required("required option!"),
                weight: Yup.string("").required("required option!"),
                comments: Yup.string("Invalid Value!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                adduser(values);
              }}
            >
              {({ isSubmitting, values, handleChange, handleBlur }) => (
                <Form>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Pet Type</BSForm.Label>
                    <Field
                      component="select"
                      id="pet_type"
                      name="pet_type"
                      className="form-select"
                    >
                      <option value="">select</option>
                      {pets?.map((v, i) => (
                        <option key={v + i} value={v}>
                          {v}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="pet_type"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Pet Name</BSForm.Label>
                    <Field name="name" className="form-control" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Pet Description</BSForm.Label>
                    <BSForm.Control
                      as={"textarea"}
                      rows={3}
                      className="form-control"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Weight (kg)</BSForm.Label>
                    <Field name="weight" className="form-control" />
                    <ErrorMessage
                      name="weight"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Comments</BSForm.Label>
                    <BSForm.Control
                      as={"textarea"}
                      rows={3}
                      className="form-control"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="comments"
                    />
                    <ErrorMessage
                      name="comments"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <br />
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {payload.loadType?.split(" ")[0]}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Update Pet" && (
          <div>
            <Formik
              initialValues={{
                pet_type: "",
                name: "",
                description: "",
                weight: "",
                comments: "",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object({
                pet_type: Yup.string("").required("required option!"),
                name: Yup.string("").required("required option!"),
                description: Yup.string("").required("required option!"),
                weight: Yup.string("").required("required option!"),
                comments: Yup.string("Invalid Value!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                updateuser(values);
              }}
            >
              {({ isSubmitting, values, handleChange, handleBlur }) => (
                <Form>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Pet Type</BSForm.Label>
                    <Field
                      component="select"
                      id="pet_type"
                      name="pet_type"
                      className="form-select"
                    >
                      <option value="">select</option>
                      {pets?.map((v, i) => (
                        <option key={v + i} value={v}>
                          {v}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="pet_type"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Pet Name</BSForm.Label>
                    <Field name="name" className="form-control" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Pet Description</BSForm.Label>
                    <BSForm.Control
                      as={"textarea"}
                      rows={3}
                      className="form-control"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Weight (kg)</BSForm.Label>
                    <Field name="weight" className="form-control" />
                    <ErrorMessage
                      name="weight"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Comments</BSForm.Label>
                    <BSForm.Control
                      as={"textarea"}
                      rows={3}
                      className="form-control"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="comments"
                    />
                    <ErrorMessage
                      name="comments"
                      component="div"
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                  <br />
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {payload.loadType?.split(" ")[0]}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Delete Pet" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to delete this Pet entry ?
              {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
            </p>
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={deleteuser}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
