let badwords = [
  "abbo",
  "abo",
  "abortion",
  "abuse",
  "addict",
  "addicts",
  "adult",
  "africa",
  "african",
  "alla",
  "allah",
  "alligatorbait",
  "amateur",
  "american",
  "anal",
  "analannie",
  "analsex",
  "angie",
  "angry",
  "anus",
  "arab",
  "arabs",
  "areola",
  "argie",
  "aroused",
  "arse",
  "arsehole",
  "asian",
  "ass",
  "assassin",
  "assassinate",
  "assassination",
  "assault",
  "assbagger",
  "assblaster",
  "assclown",
  "asscowboy",
  "asses",
  "assfuck",
  "assfucker",
  "asshat",
  "asshole",
  "assholes",
  "asshore",
  "assjockey",
  "asskiss",
  "asskisser",
  "assklown",
  "asslick",
  "asslicker",
  "asslover",
  "assman",
  "assmonkey",
  "assmunch",
  "assmuncher",
  "asspacker",
  "asspirate",
  "asspuppies",
  "assranger",
  "asswhore",
  "asswipe",
  "athletesfoot",
  "attack",
  "australian",
  "babe",
  "babies",
  "backdoor",
  "backdoorman",
  "backseat",
  "badfuck",
  "balllicker",
  "balls",
  "ballsack",
  "banging",
  "baptist",
  "barelylegal",
  "barf",
  "barface",
  "barfface",
  "bast",
  "bastard",
  "bazongas",
  "bazooms",
  "beaner",
  "beast",
  "beastality",
  "beastial",
  "beastiality",
  "beatoff",
  "beat-off",
  "beatyourmeat",
  "beaver",
  "bestial",
  "bestiality",
  "bi",
  "biatch",
  "bible",
  "bicurious",
  "bigass",
  "bigbastard",
  "bigbutt",
  "bigger",
  "bisexual",
  "bi-sexual",
  "bitch",
  "bitcher",
  "bitches",
  "bitchez",
  "bitchin",
  "bitching",
  "bitchslap",
  "bitchy",
  "biteme",
  "black",
  "blackman",
  "blackout",
  "blacks",
  "blind",
  "blow",
  "blowjob",
  "boang",
  "bogan",
  "bohunk",
  "bollick",
  "bollock",
  "bomb",
  "bombers",
  "bombing",
  "bombs",
  "bomd",
  "bondage",
  "boner",
  "bong",
  "boob",
  "boobies",
  "boobs",
  "booby",
  "boody",
  "boom",
  "boong",
  "boonga",
  "boonie",
  "booty",
  "bootycall",
  "bountybar",
  "bra",
  "brea5t",
  "breast",
  "breastjob",
  "breastlover",
  "breastman",
  "brothel",
  "bugger",
  "buggered",
  "buggery",
  "bullcrap",
  "bulldike",
  "bulldyke",
  "bullshit",
  "bumblefuck",
  "bumfuck",
  "bunga",
  "bunghole",
  "buried",
  "burn",
  "butchbabes",
  "butchdike",
  "butchdyke",
  "butt",
  "buttbang",
  "butt-bang",
  "buttface",
  "buttfuck",
  "butt-fuck",
  "buttfucker",
  "butt-fucker",
  "buttfuckers",
  "butt-fuckers",
  "butthead",
  "buttman",
  "buttmunch",
  "buttmuncher",
  "buttpirate",
  "buttplug",
  "buttstain",
  "byatch",
  "cacker",
  "cameljockey",
  "cameltoe",
  "canadian",
  "cancer",
  "carpetmuncher",
  "carruth",
  "catholic",
  "catholics",
  "cemetery",
  "chav",
  "cherrypopper",
  "chickslick",
  "children's",
  "chin",
  "chinaman",
  "chinamen",
  "chinese",
  "chink",
  "chinky",
  "choad",
  "chode",
  "christ",
  "christian",
  "church",
  "cigarette",
  "cigs",
  "clamdigger",
  "clamdiver",
  "clit",
  "clitoris",
  "clogwog",
  "cocaine",
  "cock",
  "cockblock",
  "cockblocker",
  "cockcowboy",
  "cockfight",
  "cockhead",
  "cockknob",
  "cocklicker",
  "cocklover",
  "cocknob",
  "cockqueen",
  "cockrider",
  "cocksman",
  "cocksmith",
  "cocksmoker",
  "cocksucer",
  "cocksuck",
  "cocksucked",
  "cocksucker",
  "cocksucking",
  "cocktail",
  "cocktease",
  "cocky",
  "cohee",
  "coitus",
  "color",
  "colored",
  "coloured",
  "commie",
  "communist",
  "condom",
  "conservative",
  "conspiracy",
  "coolie",
  "cooly",
  "coon",
  "coondog",
  "copulate",
  "cornhole",
  "corruption",
  "cra5h",
  "crabs",
  "crack",
  "crackpipe",
  "crackwhore",
  "crack-whore",
  "crap",
  "crapola",
  "crapper",
  "crappy",
  "crash",
  "creamy",
  "crime",
  "crimes",
  "criminal",
  "criminals",
  "crotch",
  "crotchjockey",
  "crotchmonkey",
  "crotchrot",
  "cum",
  "cumbubble",
  "cumfest",
  "cumjockey",
  "cumm",
  "cummer",
  "cumming",
  "cumquat",
  "cumqueen",
  "cumshot",
  "cunilingus",
  "cunillingus",
  "cunn",
  "cunnilingus",
  "cunntt",
  "cunt",
  "cunteyed",
  "cuntfuck",
  "cuntfucker",
  "cuntlick",
  "cuntlicker",
  "cuntlicking",
  "cuntsucker",
  "cybersex",
  "cyberslimer",
  "dago",
  "dahmer",
  "dammit",
  "damn",
  "damnation",
  "damnit",
  "darkie",
  "darky",
  "datnigga",
  "dead",
  "deapthroat",
  "death",
  "deepthroat",
  "defecate",
  "dego",
  "demon",
  "deposit",
  "desire",
  "destroy",
  "deth",
  "devil",
  "devilworshipper",
  "dick",
  "dickbrain",
  "dickforbrains",
  "dickhead",
  "dickless",
  "dicklick",
  "dicklicker",
  "dickman",
  "dickwad",
  "dickweed",
  "diddle",
  "die",
  "died",
  "dies",
  "dike",
  "dildo",
  "dingleberry",
  "dink",
  "dipshit",
  "dipstick",
  "dirty",
  "disease",
  "diseases",
  "disturbed",
  "dive",
  "dix",
  "dixiedike",
  "dixiedyke",
  "doggiestyle",
  "doggystyle",
  "dong",
  "doodoo",
  "doo-doo",
  "doom",
  "dope",
  "dragqueen",
  "dragqween",
  "dripdick",
  "drug",
  "drunk",
  "drunken",
  "dumb",
  "dumbass",
  "dumbbitch",
  "dumbfuck",
  "dyefly",
  "dyke",
  "easyslut",
  "eatballs",
  "eatme",
  "eatpussy",
  "ecstacy",
  "ejaculate",
  "ejaculated",
  "ejaculating",
  "ejaculation",
  "enema",
  "enemy",
  "erect",
  "erection",
  "ero",
  "escort",
  "ethiopian",
  "ethnic",
  "european",
  "evl",
  "excrement",
  "execute",
  "executed",
  "execution",
  "executioner",
  "explosion",
  "facefucker",
  "faeces",
  "fag",
  "fagging",
  "faggot",
  "fagot",
  "failed",
  "failure",
  "fairies",
  "fairy",
  "faith",
  "fannyfucker",
  "fart",
  "farted",
  "farting",
  "farty",
  "fastfuck",
  "fat",
  "fatah",
  "fatass",
  "fatfuck",
  "fatfucker",
  "fatso",
  "fckcum",
  "fear",
  "feces",
  "felatio",
  "felch",
  "felcher",
  "felching",
  "fellatio",
  "feltch",
  "feltcher",
  "feltching",
  "fetish",
  "fight",
  "filipina",
  "filipino",
  "fingerfood",
  "fingerfuck",
  "fingerfucked",
  "fingerfucker",
  "fingerfuckers",
  "fingerfucking",
  "fire",
  "firing",
  "fister",
  "fistfuck",
  "fistfucked",
  "fistfucker",
  "fistfucking",
  "fisting",
  "flange",
  "flasher",
  "flatulence",
  "floo",
  "flydie",
  "flydye",
  "fok",
  "fondle",
  "footaction",
  "footfuck",
  "footfucker",
  "footlicker",
  "footstar",
  "fore",
  "foreskin",
  "forni",
  "fornicate",
  "foursome",
  "fourtwenty",
  "fraud",
  "freakfuck",
  "freakyfucker",
  "freefuck",
  "fu",
  "fubar",
  "fuc",
  "fucck",
  "fuck",
  "fucka",
  "fuckable",
  "fuckbag",
  "fuckbuddy",
  "fucked",
  "fuckedup",
  "fucker",
  "fuckers",
  "fuckface",
  "fuckfest",
  "fuckfreak",
  "fuckfriend",
  "fuckhead",
  "fuckher",
  "fuckin",
  "fuckina",
  "fucking",
  "fuckingbitch",
  "fuckinnuts",
  "fuckinright",
  "fuckit",
  "fuckknob",
  "fuckme",
  "fuckmehard",
  "fuckmonkey",
  "fuckoff",
  "fuckpig",
  "fucks",
  "fucktard",
  "fuckwhore",
  "fuckyou",
  "fudgepacker",
  "fugly",
  "fuk",
  "fuks",
  "funeral",
  "funfuck",
  "fungus",
  "fuuck",
  "gangbang",
  "gangbanged",
  "gangbanger",
  "gangsta",
  "gatorbait",
  "gay",
  "gaymuthafuckinwhore",
  "gaysex",
  "geez",
  "geezer",
  "geni",
  "genital",
  "german",
  "getiton",
  "gin",
  "ginzo",
  "gipp",
  "girls",
  "givehead",
  "glazeddonut",
  "gob",
  "god",
  "godammit",
  "goddamit",
  "goddammit",
  "goddamn",
  "goddamned",
  "goddamnes",
  "goddamnit",
  "goddamnmuthafucker",
  "goldenshower",
  "gonorrehea",
  "gonzagas",
  "gook",
  "gotohell",
  "goy",
  "goyim",
  "greaseball",
  "gringo",
  "groe",
  "gross",
  "grostulation",
  "gubba",
  "gummer",
  "gun",
  "gyp",
  "gypo",
  "gypp",
  "gyppie",
  "gyppo",
  "gyppy",
  "hamas",
  "handjob",
  "hapa",
  "harder",
  "hardon",
  "harem",
  "headfuck",
  "headlights",
  "hebe",
  "heeb",
  "hell",
  "henhouse",
  "heroin",
  "herpes",
  "heterosexual",
  "hijack",
  "hijacker",
  "hijacking",
  "hillbillies",
  "hindoo",
  "hiscock",
  "hitler",
  "hitlerism",
  "hitlerist",
  "hiv",
  "ho",
  "hobo",
  "hodgie",
  "hoes",
  "hole",
  "holestuffer",
  "homicide",
  "homo",
  "homobangers",
  "homosexual",
  "honger",
  "honk",
  "honkers",
  "honkey",
  "honky",
  "hook",
  "hooker",
  "hookers",
  "hooters",
  "hore",
  "hork",
  "horn",
  "horney",
  "horniest",
  "horny",
  "horseshit",
  "hosejob",
  "hoser",
  "hostage",
  "hotdamn",
  "hotpussy",
  "hottotrot",
  "hummer",
  "husky",
  "hussy",
  "hustler",
  "hymen",
  "hymie",
  "iblowu",
  "idiot",
  "ikey",
  "illegal",
  "incest",
  "insest",
  "intercourse",
  "interracial",
  "intheass",
  "inthebuff",
  "israel",
  "israeli",
  "israel's",
  "italiano",
  "itch",
  "jackass",
  "jackoff",
  "jackshit",
  "jacktheripper",
  "jade",
  "jap",
  "japanese",
  "japcrap",
  "jebus",
  "jeez",
  "jerkoff",
  "jesus",
  "jesuschrist",
  "jew",
  "jewish",
  "jiga",
  "jigaboo",
  "jigg",
  "jigga",
  "jiggabo",
  "jigger",
  "jiggy",
  "jihad",
  "jijjiboo",
  "jimfish",
  "jism",
  "jiz",
  "jizim",
  "jizjuice",
  "jizm",
  "jizz",
  "jizzim",
  "jizzum",
  "joint",
  "juggalo",
  "jugs",
  "junglebunny",
  "kaffer",
  "kaffir",
  "kaffre",
  "kafir",
  "kanake",
  "kid",
  "kigger",
  "kike",
  "kill",
  "killed",
  "killer",
  "killing",
  "kills",
  "kink",
  "kinky",
  "kissass",
  "kkk",
  "knife",
  "knockers",
  "kock",
  "kondum",
  "koon",
  "kotex",
  "krap",
  "krappy",
  "kraut",
  "kum",
  "kumbubble",
  "kumbullbe",
  "kummer",
  "kumming",
  "kumquat",
  "kums",
  "kunilingus",
  "kunnilingus",
  "kunt",
  "ky",
  "kyke",
  "lactate",
  "laid",
  "lapdance",
  "latin",
  "lesbain",
  "lesbayn",
  "lesbian",
  "lesbin",
  "lesbo",
  "lez",
  "lezbe",
  "lezbefriends",
  "lezbo",
  "lezz",
  "lezzo",
  "liberal",
  "libido",
  "licker",
  "lickme",
  "lies",
  "limey",
  "limpdick",
  "limy",
  "lingerie",
  "liquor",
  "livesex",
  "loadedgun",
  "lolita",
  "looser",
  "loser",
  "lotion",
  "lovebone",
  "lovegoo",
  "lovegun",
  "lovejuice",
  "lovemuscle",
  "lovepistol",
  "loverocket",
  "lowlife",
  "lsd",
  "lubejob",
  "lucifer",
  "luckycammeltoe",
  "lugan",
  "lynch",
  "macaca",
  "mad",
  "mafia",
  "magicwand",
  "mams",
  "manhater",
  "manpaste",
  "marijuana",
  "mastabate",
  "mastabater",
  "masterbate",
  "masterblaster",
  "mastrabator",
  "masturbate",
  "masturbating",
  "mattressprincess",
  "meatbeatter",
  "meatrack",
  "meth",
  "mexican",
  "mgger",
  "mggor",
  "mickeyfinn",
  "mideast",
  "milf",
  "minority",
  "mockey",
  "mockie",
  "mocky",
  "mofo",
  "moky",
  "moles",
  "molest",
  "molestation",
  "molester",
  "molestor",
  "moneyshot",
  "mooncricket",
  "mormon",
  "moron",
  "moslem",
  "mosshead",
  "mothafuck",
  "mothafucka",
  "mothafuckaz",
  "mothafucked",
  "mothafucker",
  "mothafuckin",
  "mothafucking",
  "mothafuckings",
  "motherfuck",
  "motherfucked",
  "motherfucker",
  "motherfuckin",
  "motherfucking",
  "motherfuckings",
  "motherlovebone",
  "muff",
  "muffdive",
  "muffdiver",
  "muffindiver",
  "mufflikcer",
  "mulatto",
  "muncher",
  "munt",
  "murder",
  "murderer",
  "muslim",
  "naked",
  "narcotic",
  "nasty",
  "nastybitch",
  "nastyho",
  "nastyslut",
  "nastywhore",
  "nazi",
  "necro",
  "negro",
  "negroes",
  "negroid",
  "negro's",
  "nig",
  "niger",
  "nigerian",
  "nigerians",
  "nigg",
  "nigga",
  "niggah",
  "niggaracci",
  "niggard",
  "niggarded",
  "niggarding",
  "niggardliness",
  "niggardliness's",
  "niggardly",
  "niggards",
  "niggard's",
  "niggaz",
  "nigger",
  "niggerhead",
  "niggerhole",
  "niggers",
  "nigger's",
  "niggle",
  "niggled",
  "niggles",
  "niggling",
  "nigglings",
  "niggor",
  "niggur",
  "niglet",
  "nignog",
  "nigr",
  "nigra",
  "nigre",
  "nip",
  "nipple",
  "nipplering",
  "nittit",
  "nlgger",
  "nlggor",
  "nofuckingway",
  "nook",
  "nookey",
  "nookie",
  "noonan",
  "nooner",
  "nude",
  "nudger",
  "nuke",
  "nutfucker",
  "nymph",
  "ontherag",
  "oral",
  "orga",
  "orgasim",
  "orgasm",
  "orgies",
  "orgy",
  "osama",
  "paki",
  "palesimian",
  "palestinian",
  "pansies",
  "pansy",
  "panti",
  "panties",
  "payo",
  "pearlnecklace",
  "peck",
  "pecker",
  "peckerwood",
  "pee",
  "peehole",
  "pee-pee",
  "peepshow",
  "peepshpw",
  "pendy",
  "penetration",
  "peni5",
  "penile",
  "penis",
  "penises",
  "penthouse",
  "period",
  "perv",
  "phonesex",
  "phuk",
  "phuked",
  "phuking",
  "phukked",
  "phukking",
  "phungky",
  "phuq",
  "pi55",
  "picaninny",
  "piccaninny",
  "pickaninny",
  "piker",
  "pikey",
  "piky",
  "pimp",
  "pimped",
  "pimper",
  "pimpjuic",
  "pimpjuice",
  "pimpsimp",
  "pindick",
  "piss",
  "pissed",
  "pisser",
  "pisses",
  "pisshead",
  "pissin",
  "pissing",
  "pissoff",
  "pistol",
  "pixie",
  "pixy",
  "playboy",
  "playgirl",
  "pocha",
  "pocho",
  "pocketpool",
  "pohm",
  "polack",
  "pom",
  "pommie",
  "pommy",
  "poo",
  "poon",
  "poontang",
  "poop",
  "pooper",
  "pooperscooper",
  "pooping",
  "poorwhitetrash",
  "popimp",
  "porchmonkey",
  "porn",
  "pornflick",
  "pornking",
  "porno",
  "pornography",
  "pornprincess",
  "pot",
  "poverty",
  "premature",
  "pric",
  "prick",
  "prickhead",
  "primetime",
  "propaganda",
  "pros",
  "prostitute",
  "protestant",
  "pu55i",
  "pu55y",
  "pube",
  "pubic",
  "pubiclice",
  "pud",
  "pudboy",
  "pudd",
  "puddboy",
  "puke",
  "puntang",
  "purinapricness",
  "puss",
  "pussie",
  "pussies",
  "pussy",
  "pussycat",
  "pussyeater",
  "pussyfucker",
  "pussylicker",
  "pussylips",
  "pussylover",
  "pussypounder",
  "pusy",
  "quashie",
  "queef",
  "queer",
  "quickie",
  "quim",
  "ra8s",
  "rabbi",
  "racial",
  "racist",
  "radical",
  "radicals",
  "raghead",
  "randy",
  "rape",
  "raped",
  "raper",
  "rapist",
  "rearend",
  "rearentry",
  "rectum",
  "redlight",
  "redneck",
  "reefer",
  "reestie",
  "refugee",
  "reject",
  "remains",
  "rentafuck",
  "republican",
  "rere",
  "retard",
  "retarded",
  "ribbed",
  "rigger",
  "rimjob",
  "rimming",
  "roach",
  "robber",
  "roundeye",
  "rump",
  "russki",
  "russkie",
  "sadis",
  "sadom",
  "samckdaddy",
  "sandm",
  "sandnigger",
  "satan",
  "scag",
  "scallywag",
  "scat",
  "schlong",
  "screw",
  "screwyou",
  "scrotum",
  "scum",
  "semen",
  "seppo",
  "servant",
  "sex",
  "sexed",
  "sexfarm",
  "sexhound",
  "sexhouse",
  "sexing",
  "sexkitten",
  "sexpot",
  "sexslave",
  "sextogo",
  "sextoy",
  "sextoys",
  "sexual",
  "sexually",
  "sexwhore",
  "sexy",
  "sexymoma",
  "sexy-slim",
  "shag",
  "shaggin",
  "shagging",
  "shat",
  "shav",
  "shawtypimp",
  "sheeney",
  "shhit",
  "shinola",
  "shit",
  "shitcan",
  "shitdick",
  "shite",
  "shiteater",
  "shited",
  "shitface",
  "shitfaced",
  "shitfit",
  "shitforbrains",
  "shitfuck",
  "shitfucker",
  "shitfull",
  "shithapens",
  "shithappens",
  "shithead",
  "shithouse",
  "shiting",
  "shitlist",
  "shitola",
  "shitoutofluck",
  "shits",
  "shitstain",
  "shitted",
  "shitter",
  "shitting",
  "shitty",
  "shoot",
  "shooting",
  "shortfuck",
  "showtime",
  "sick",
  "sissy",
  "sixsixsix",
  "sixtynine",
  "sixtyniner",
  "skank",
  "skankbitch",
  "skankfuck",
  "skankwhore",
  "skanky",
  "skankybitch",
  "skankywhore",
  "skinflute",
  "skum",
  "skumbag",
  "slant",
  "slanteye",
  "slapper",
  "slaughter",
  "slav",
  "slave",
  "slavedriver",
  "sleezebag",
  "sleezeball",
  "slideitin",
  "slime",
  "slimeball",
  "slimebucket",
  "slopehead",
  "slopey",
  "slopy",
  "slut",
  "sluts",
  "slutt",
  "slutting",
  "slutty",
  "slutwear",
  "slutwhore",
  "smack",
  "smackthemonkey",
  "smut",
  "snatch",
  "snatchpatch",
  "snigger",
  "sniggered",
  "sniggering",
  "sniggers",
  "snigger's",
  "sniper",
  "snot",
  "snowback",
  "snownigger",
  "sob",
  "sodom",
  "sodomise",
  "sodomite",
  "sodomize",
  "sodomy",
  "sonofabitch",
  "sonofbitch",
  "sooty",
  "sos",
  "soviet",
  "spaghettibender",
  "spaghettinigger",
  "spank",
  "spankthemonkey",
  "sperm",
  "spermacide",
  "spermbag",
  "spermhearder",
  "spermherder",
  "spic",
  "spick",
  "spig",
  "spigotty",
  "spik",
  "spit",
  "spitter",
  "splittail",
  "spooge",
  "spreadeagle",
  "spunk",
  "spunky",
  "squaw",
  "stagg",
  "stiffy",
  "strapon",
  "stringer",
  "stripclub",
  "stroke",
  "stroking",
  "stupid",
  "stupidfuck",
  "stupidfucker",
  "suck",
  "suckdick",
  "sucker",
  "suckme",
  "suckmyass",
  "suckmydick",
  "suckmytit",
  "suckoff",
  "suicide",
  "swallow",
  "swallower",
  "swalow",
  "swastika",
  "sweetness",
  "syphilis",
  "taboo",
  "taff",
  "tampon",
  "tang",
  "tantra",
  "tarbaby",
  "tard",
  "teat",
  "terror",
  "terrorist",
  "teste",
  "testicle",
  "testicles",
  "thicklips",
  "thirdeye",
  "thirdleg",
  "threesome",
  "threeway",
  "timbernigger",
  "tinkle",
  "tit",
  "titbitnipply",
  "titfuck",
  "titfucker",
  "titfuckin",
  "titjob",
  "titlicker",
  "titlover",
  "tits",
  "tittie",
  "titties",
  "titty",
  "tnt",
  "toilet",
  "tongethruster",
  "tongue",
  "tonguethrust",
  "tonguetramp",
  "tortur",
  "torture",
  "tosser",
  "towelhead",
  "trailertrash",
  "tramp",
  "trannie",
  "tranny",
  "transexual",
  "transsexual",
  "transvestite",
  "triplex",
  "trisexual",
  "trojan",
  "trots",
  "tuckahoe",
  "tunneloflove",
  "turd",
  "turnon",
  "twat",
  "twink",
  "twinkie",
  "twobitwhore",
  "uck",
  "uk",
  "unfuckable",
  "upskirt",
  "uptheass",
  "upthebutt",
  "urinary",
  "urinate",
  "urine",
  "usama",
  "uterus",
  "vagina",
  "vaginal",
  "vatican",
  "vibr",
  "vibrater",
  "vibrator",
  "vietcong",
  "violence",
  "virgin",
  "virginbreaker",
  "vomit",
  "vulva",
  "wab",
  "wank",
  "wanker",
  "wanking",
  "waysted",
  "weapon",
  "weenie",
  "weewee",
  "welcher",
  "welfare",
  "wetb",
  "wetback",
  "wetspot",
  "whacker",
  "whash",
  "whigger",
  "whiskey",
  "whiskeydick",
  "whiskydick",
  "whit",
  "whitenigger",
  "whites",
  "whitetrash",
  "whitey",
  "whiz",
  "whop",
  "whore",
  "whorefucker",
  "whorehouse",
  "wigger",
  "willie",
  "williewanker",
  "willy",
  "wn",
  "wog",
  "women's",
  "wop",
  "wtf",
  "wuss",
  "wuzzie",
  "xtc",
  "xxx",
  "yankee",
  "yellowman",
  "zigabo",
  "zipperhead",
  "ahole",
  "anus",
  "ash0le",
  "ash0les",
  "asholes",
  "ass",
  "Ass Monkey",
  "Assface",
  "assh0le",
  "assh0lez",
  "asshole",
  "assholes",
  "assholz",
  "asswipe",
  "azzhole",
  "bassterds",
  "bastard",
  "bastards",
  "bastardz",
  "basterds",
  "basterdz",
  "Biatch",
  "bitch",
  "bitches",
  "Blow Job",
  "boffing",
  "butthole",
  "buttwipe",
  "c0ck",
  "c0cks",
  "c0k",
  "Carpet Muncher",
  "cawk",
  "cawks",
  "Clit",
  "cnts",
  "cntz",
  "cock",
  "cockhead",
  "cock-head",
  "cocks",
  "CockSucker",
  "cock-sucker",
  "crap",
  "cum",
  "cunt",
  "cunts",
  "cuntz",
  "dick",
  "dild0",
  "dild0s",
  "dildo",
  "dildos",
  "dilld0",
  "dilld0s",
  "dominatricks",
  "dominatrics",
  "dominatrix",
  "dyke",
  "enema",
  "f u c k",
  "f u c k e r",
  "fag",
  "fag1t",
  "faget",
  "fagg1t",
  "faggit",
  "faggot",
  "fagit",
  "fags",
  "fagz",
  "faig",
  "faigs",
  "fart",
  "flipping the bird",
  "fuck",
  "fucker",
  "fuckin",
  "fucking",
  "fucks",
  "Fudge Packer",
  "fuk",
  "Fukah",
  "Fuken",
  "fuker",
  "Fukin",
  "Fukk",
  "Fukkah",
  "Fukken",
  "Fukker",
  "Fukkin",
  "g00k",
  "gay",
  "gayboy",
  "gaygirl",
  "gays",
  "gayz",
  "God-damned",
  "h00r",
  "h0ar",
  "h0re",
  "hells",
  "hoar",
  "hoor",
  "hoore",
  "jackoff",
  "jap",
  "japs",
  "jerk-off",
  "jisim",
  "jiss",
  "jizm",
  "jizz",
  "knob",
  "knobs",
  "knobz",
  "kunt",
  "kunts",
  "kuntz",
  "Lesbian",
  "Lezzian",
  "Lipshits",
  "Lipshitz",
  "masochist",
  "masokist",
  "massterbait",
  "masstrbait",
  "masstrbate",
  "masterbaiter",
  "masterbate",
  "masterbates",
  "Motha Fucker",
  "Motha Fuker",
  "Motha Fukkah",
  "Motha Fukker",
  "Mother Fucker",
  "Mother Fukah",
  "Mother Fuker",
  "Mother Fukkah",
  "Mother Fukker",
  "mother-fucker",
  "Mutha Fucker",
  "Mutha Fukah",
  "Mutha Fuker",
  "Mutha Fukkah",
  "Mutha Fukker",
  "n1gr",
  "nastt",
  "nigger;",
  "nigur;",
  "niiger;",
  "niigr;",
  "orafis",
  "orgasim;",
  "orgasm",
  "orgasum",
  "oriface",
  "orifice",
  "orifiss",
  "packi",
  "packie",
  "packy",
  "paki",
  "pakie",
  "paky",
  "pecker",
  "peeenus",
  "peeenusss",
  "peenus",
  "peinus",
  "pen1s",
  "penas",
  "penis",
  "penis-breath",
  "penus",
  "penuus",
  "Phuc",
  "Phuck",
  "Phuk",
  "Phuker",
  "Phukker",
  "polac",
  "polack",
  "polak",
  "Poonani",
  "pr1c",
  "pr1ck",
  "pr1k",
  "pusse",
  "pussee",
  "pussy",
  "puuke",
  "puuker",
  "queer",
  "queers",
  "queerz",
  "qweers",
  "qweerz",
  "qweir",
  "recktum",
  "rectum",
  "retard",
  "sadist",
  "scank",
  "schlong",
  "screwing",
  "semen",
  "sex",
  "sexy",
  "Sh!t",
  "sh1t",
  "sh1ter",
  "sh1ts",
  "sh1tter",
  "sh1tz",
  "shit",
  "shits",
  "shitter",
  "Shitty",
  "Shity",
  "shitz",
  "Shyt",
  "Shyte",
  "Shytty",
  "Shyty",
  "skanck",
  "skank",
  "skankee",
  "skankey",
  "skanks",
  "Skanky",
  "slut",
  "sluts",
  "Slutty",
  "slutz",
  "son-of-a-bitch",
  "tit",
  "turd",
  "va1jina",
  "vag1na",
  "vagiina",
  "vagina",
  "vaj1na",
  "vajina",
  "vullva",
  "vulva",
  "w0p",
  "wh00r",
  "wh0re",
  "whore",
  "xrated",
  "xxx",
  "b!+ch",
  "bitch",
  "blowjob",
  "clit",
  "arschloch",
  "fuck",
  "shit",
  "ass",
  "asshole",
  "b!tch",
  "b17ch",
  "b1tch",
  "bastard",
  "bi+ch",
  "boiolas",
  "buceta",
  "c0ck",
  "cawk",
  "chink",
  "cipa",
  "clits",
  "cock",
  "cum",
  "cunt",
  "dildo",
  "dirsa",
  "ejakulate",
  "fatass",
  "fcuk",
  "fuk",
  "fux0r",
  "hoer",
  "hore",
  "jism",
  "kawk",
  "l3itch",
  "l3i+ch",
  "lesbian",
  "masturbate",
  "masterbat*",
  "masterbat3",
  "motherfucker",
  "s.o.b.",
  "mofo",
  "nazi",
  "nigga",
  "nigger",
  "nutsack",
  "phuck",
  "pimpis",
  "pusse",
  "pussy",
  "scrotum",
  "sh!t",
  "shemale",
  "shi+",
  "sh!+",
  "slut",
  "smut",
  "teets",
  "tits",
  "boobs",
  "b00bs",
  "teez",
  "testical",
  "testicle",
  "titt",
  "w00se",
  "jackoff",
  "wank",
  "whoar",
  "whore",
  "*damn",
  "*dyke",
  "*fuck*",
  "*shit*",
  "@$$",
  "amcik",
  "andskota",
  "arse*",
  "assrammer",
  "ayir",
  "bi7ch",
  "bitch*",
  "bollock*",
  "breasts",
  "butt-pirate",
  "cabron",
  "cazzo",
  "chraa",
  "chuj",
  "Cock*",
  "cunt*",
  "d4mn",
  "daygo",
  "dego",
  "dick*",
  "dike*",
  "dupa",
  "dziwka",
  "ejackulate",
  "Ekrem*",
  "Ekto",
  "enculer",
  "faen",
  "fag*",
  "fanculo",
  "fanny",
  "feces",
  "feg",
  "Felcher",
  "ficken",
  "fitt*",
  "Flikker",
  "foreskin",
  "Fotze",
  "Fu(*",
  "fuk*",
  "futkretzn",
  "gay",
  "gook",
  "guiena",
  "h0r",
  "h4x0r",
  "hell",
  "helvete",
  "hoer*",
  "honkey",
  "Huevon",
  "hui",
  "injun",
  "jizz",
  "kanker*",
  "kike",
  "klootzak",
  "kraut",
  "knulle",
  "kuk",
  "kuksuger",
  "Kurac",
  "kurwa",
  "kusi*",
  "kyrpa*",
  "lesbo",
  "mamhoon",
  "masturbat*",
  "merd*",
  "mibun",
  "monkleigh",
  "mouliewop",
  "muie",
  "mulkku",
  "muschi",
  "nazis",
  "nepesaurio",
  "nigger*",
  "orospu",
  "paska*",
  "perse",
  "picka",
  "pierdol*",
  "pillu*",
  "pimmel",
  "piss*",
  "pizda",
  "poontsee",
  "poop",
  "porn",
  "p0rn",
  "pr0n",
  "preteen",
  "pula",
  "pule",
  "puta",
  "puto",
  "qahbeh",
  "queef*",
  "rautenberg",
  "schaffer",
  "scheiss*",
  "schlampe",
  "schmuck",
  "screw",
  "sh!t*",
  "sharmuta",
  "sharmute",
  "shipal",
  "shiz",
  "skribz",
  "skurwysyn",
  "sphencter",
  "spic",
  "spierdalaj",
  "splooge",
  "suka",
  "b00b*",
  "testicle*",
  "titt*",
  "twat",
  "vittu",
  "wank*",
  "wetback*",
  "wichser",
  "wop*",
  "yed",
  "zabourah",
  "arse",
  "arsehole",
  "as useful as tits on a bull",
  "balls",
  "bastard",
  "beaver",
  "beef curtains",
  "bell",
  "bellend",
  "bent",
  "berk",
  "bint",
  "bitch",
  "blighter",
  "blimey",
  "blimey o'reilly",
  "bloodclaat",
  "bloody",
  "bloody hell",
  "blooming",
  "bollocks",
  "bonk",
  "bugger",
  "bugger me",
  "bugger off",
  "built like a brick shit-house",
  "bukkake",
  "bullshit",
  "cack",
  "cad",
  "chav",
  "cheese eating surrender monkey",
  "choad",
  "chuffer",
  "clunge",
  "cobblers",
  "cock",
  "cock cheese",
  "cock jockey",
  "cock-up",
  "cocksucker",
  "cockwomble",
  "codger",
  "cor blimey",
  "corey",
  "cow",
  "crap",
  "crikey",
  "cunt",
  "daft",
  "daft cow",
  "damn",
  "dick",
  "dickhead",
  "did he bollocks!",
  "did i fuck as like!",
  "dildo",
  "dodgy",
  "duffer",
  "fanny",
  "feck",
  "flaps",
  "fuck",
  "fuck me sideways!",
  "fucking cunt",
  "fucktard",
  "gash",
  "ginger",
  "git",
  "gob shite",
  "goddam",
  "gorblimey",
  "gordon bennett",
  "gormless",
  "he’s a knob",
  "hell",
  ,
  "hobknocker",
  "I'd rather snort my own cum",
  "jesus christ",
  "jizz",
  "knob",
  "knobber",
  "knobend",
  "knobhead",
  "ligger",
  "like fucking a dying man's handshake",
  "mad as a hatter",
  "manky",
  "minge",
  "minger",
  "minging",
  "motherfucker",
  "munter",
  "muppet",
  "naff",
  "nitwit",
  "nonce",
  "numpty",
  "nutter",
  "off their rocker",
  "penguin",
  "pillock",
  "pish",
  "piss off",
  "piss-flaps",
  "pissed",
  "pissed off",
  "play the five-fingered flute",
  "plonker",
  "ponce",
  "poof",
  "pouf",
  "poxy",
  "prat",
  "prick",
  "prick",
  "prickteaser",
  "punani",
  "punny",
  "pussy",
  "randy",
  "rapey",
  "rat arsed",
  "rotter",
  "rubbish",
  "scrubber",
  "shag",
  "shit",
  "shite",
  "shitfaced",
  "skank",
  "slag",
  "slapper",
  "slut",
  "snatch",
  "sod",
  "sod-off",
  "son of a bitch",
  "spunk",
  "stick it up your arse!",
  "swine",
  "taking the piss",
  "tart",
  "tits",
  "toff",
  "tosser",
  "trollop",
  "tuss",
  "twat",
  "twonk",
  "u fukin wanker",
  "wally",
  "wanker",
  "wankstain",
  "wazzack",
  "whore",
  "abruti",
  "aller se faire foutre",
  "allez, va t’en",
  "amène ta mère pourque je te refasse",
  "avale mes couilles",
  "bachie coupe",
  "baise",
  "baise moi mor",
  "baise toi",
  "baiser",
  "bander",
  "batard",
  "bigornette",
  "bique",
  "bise",
  "bite",
  "bitte",
  "bloblos",
  "bon sang",
  "bordel",
  "bordel de merde",
  "bosser",
  "bourré",
  "bourrée",
  "brackmard",
  "branlage",
  "branler",
  "branlette",
  "branleur",
  "branleur de chiens morts",
  "branleuse",
  "brouter le cresson",
  "broutte-minou",
  "brûle en enfer",
  "brûle en l'enfer",
  "c'est des conneries",
  "c’est chiant",
  "c’est des conneries",
  "c’est le bordel ici",
  "c’est naze",
  "c’est quoi ce bordel",
  "c’est vraiment de ta faute",
  "ça casse les couilles",
  "ça craint",
  "ça fait shier",
  "ça m'emmerde",
  "ça me casse les couilles",
  "ça me fait chier",
  "ça me saoûle",
  "ça pue",
  "ça schlingue",
  "caca",
  "cailler",
  "calice",
  "calisse",
  "casse-couille",
  "casse-couilles",
  "casse-toi",
  "casse-toi, idiot",
  "casse-toi, idiote",
  "casser les couilles",
  "cave",
  "ce putain d’embouteillage",
  "ce putain d’ordinateur",
  "cela me soûle",
  "charogne",
  "chatte",
  "chiant",
  "chiante",
  "chiasse",
  "chien sale",
  "chienne",
  "chier",
  "chieur",
  "chieuse",
  "chiottes",
  "choleque de merde",
  "clito",
  "clitoris",
  "con",
  "conasse",
  "connard",
  "connasse",
  "conne",
  "connerie",
  "conneries",
  "couille",
  "couilles",
  "couillon",
  "couillonne",
  "cramouille",
  "crétin",
  "crève la gueule ouverte",
  "criss",
  "crisse",
  "crosseur",
  "cul",
  "debile",
  "déconne",
  "déconner",
  "dégage",
  "dégénéré",
  "dégueulasse",
  "dinde",
  "drague",
  "emmerdant",
  "emmerder",
  "emmerdeur",
  "emmerdeuse",
  "enculé",
  "enculé de ta mère",
  "enculé toi salaud",
  "enculée",
  "enculer",
  "enculer les mouches",
  "enculer une mouche",
  "enculeur",
  "enculeurs",
  "enfoiré",
  "enfoirée",
  "étron",
  "face de cul",
  "faire enculer",
  "faire foutre",
  "fais chier",
  "fait moi jouir",
  "ferme ta gueule",
  "fiche moi le paix",
  "fif",
  "fille de pute",
  "film de cul",
  "fils de pute",
  "fils de salope",
  "folle",
  "fous le camps",
  "fous le camps et morte",
  "foutre",
  "foutre la merde",
  "foutre le bordel",
  "framble",
  "fucker",
  "garage à bite",
  "garce",
  "gerbe",
  "gerber",
  "gouine",
  "graine",
  "grande folle",
  "grogniasse",
  "gros tas",
  "gross cul",
  "guenon",
  "gueule",
  "idiot",
  "idiote",
  "il est stupide",
  "il fait chaud ici, enculé",
  "il me fait chier",
  "imbécile",
  "j'en ai marre",
  "j'en ai plus rien à foutre",
  "j'en ai ral le bol",
  "j'en ai ral le cul",
  "j'en ai rien à cirer",
  "j'en ai rien à foutre",
  "j’ai baisé ta mère",
  "j’ai envie de chier",
  "j’en ai marre",
  "j’en ai plus rien à foutre",
  "j’en ai ral le bol",
  "j’en ai ral le cul",
  "j’en ai ras le bol",
  "j’en ai rien à cirer",
  "je m'en fiche",
  "je m'en fou",
  "je m'en fous",
  "je m'en sacre",
  "je m’emmerde",
  "Je m’en bats les couilles",
  "je m’en fiche",
  "je m’en fou",
  "je m’en fous",
  "je me fais chier",
  "je me fais chier ici",
  "je pis dans ta bouche",
  "je t'emmerde",
  "je t'encule",
  "je te déteste",
  "je te nique ta race sale fils de chien",
  "je veux faire l’amour avec toi",
  "jouir",
  "la chatte",
  "la meme chose a toi",
  "la putain de ta mère",
  "la vache",
  "laisse-moi tranquille",
  "le con",
  "lèche-cul",
  "ma couille",
  "mal baisée",
  "mance",
  "mange de la merde",
  "mange ma bite",
  "mange merde et morte",
  "mange tes grands morts",
  "maudit",
  "ménage à trois",
  "merdasse",
  "merde",
  "merde embulante",
  "merdeuse",
  "merdeux",
  "mes couilles en toi",
  "metteux",
  "meuf",
  "mince",
  "mon dieu",
  "morceau de merde",
  "nana",
  "ne rien foutre",
  "nègre",
  "negro",
  "nique la police",
  "nique ta mere",
  "nique ta mère",
  "nique ta mère espèce de gros bâtard de merde",
  "nique ta mère la chauve",
  "nique ta race",
  "niquer",
  "nom de dieu",
  "nom de dieu de merde",
  "noune",
  "oh la vache",
  "oh putain de merde",
  "oh, merde",
  "ordure",
  "osti",
  "osti de calisse de tabarnak",
  "ostie",
  "palucher",
  "partir en couille",
  "pauvre con",
  "pédale",
  "pédé",
  "pétasse",
  "péter",
  "péter la cenne",
  "péter les couilles",
  "petit tabernac",
  "petite pute",
  "pipi",
  "pisser",
  "pisser dans",
  "plotte",
  "pouffe",
  "pouffiasse",
  "poupée",
  "pousse-crotte",
  "punaise",
  "purée",
  "putain",
  "putain de bordel de merde",
  "putain de merde",
  "putain de temps",
  "putain t’es moche",
  "pute",
  "qu’est-ce que tu branles",
  "quel bordel",
  "quel salaud",
  "queue",
  "queutard",
  "ramoner",
  "retourne enculer",
  "rien à branler",
  "rien à foutre",
  "s’en branler",
  "s’en foutre",
  "sa mère",
  "sa mère la pute",
  "sa race",
  "sac à foutre",
  "sac à merde",
  "sac merde",
  "sac vin",
  "sacrament",
  "sacrebleu",
  "salaud",
  "salopard",
  "salope",
  "saloperie",
  "se foutre dans la merde",
  "se taire",
  "stupide",
  "suce",
  "suceuse",
  "t'as pas de couilles",
  "t'es rien qu'un petit connard",
  "t'es un salaud",
  "t’as intérêt ne pas m’énervé",
  "t’as pas de couilles",
  "t’es débile",
  "t’es rien qu’un petit connard",
  "t’es un salaud",
  "t’es vraiment emmerdant, toi",
  "ta gueule",
  "ta mère elle suce des ours",
  "ta mere est francaise",
  "ta mère est une pute",
  "ta mère la pute",
  "ta mere suce des bites en enfer",
  "tabarnak",
  "tais-toi",
  "tanche",
  "tantouze",
  "tapette",
  "tarte",
  "tas de marde",
  "tata",
  "tête de nœud",
  "tête moi le dard",
  "tête moi le noeud",
  "téteux",
  "teuch",
  "teuf",
  "traînée",
  "très stupide",
  "tringler",
  "trique",
  "troncher",
  "trou de cul",
  "trou du cul",
  "tu commence m’enmerder",
  "tu es con",
  "tu es très stupide",
  "tu es un putain",
  "tu et un ane",
  "tu fais chier",
  "tu m'emmerdes",
  "tu me fais chier",
  "tu me fatigues",
  "tu me gonfles",
  "turlute",
  "une salope",
  "va te branler",
  "va te crosser",
  "va te faire",
  "va te faire baiser",
  "va te faire enculer",
  "va te faire foutre",
  "va te faire mettre",
  "va te faire voir",
  "va te tripoter",
  "vache",
  "vas faire foutre a la vache",
  "veuve",
  "vous êtes stupide",
  "vous sentez comme le boeuf et le fromage",
  "vous vissez",
  "zigounette",
  "zizi",
  "zut",
  "zut alors",
  "2 girls 1 cup",
  "2g1c",
  "4r5e",
  "5h1t",
  "5hit",
  "a_s_s",
  "a$$",
  "a$$hole",
  "a2m",
  "a54",
  "a55",
  "a55hole",
  "aeolus",
  "ahole",
  "alabama hot pocket",
  "alaskan pipeline",
  "anal",
  "anal impaler",
  "anal leakage",
  "analannie",
  "analprobe",
  "analsex",
  "anilingus",
  "anus",
  "apeshit",
  "ar5e",
  "areola",
  "areole",
  "arian",
  "arrse",
  "arse",
  "arsehole",
  "aryan",
  "ass",
  "ass fuck",
  "ass hole",
  "ass-fucker",
  "ass-hat",
  "ass-jabber",
  "ass-pirate",
  "assault",
  "assbag",
  "assbagger",
  "assbandit",
  "assbang",
  "assbanged",
  "assbanger",
  "assbangs",
  "assbite",
  "assblaster",
  "assclown",
  "asscock",
  "asscracker",
  "asses",
  "assface",
  "assfaces",
  "assfuck",
  "assfucker",
  "assfukka",
  "assgoblin",
  "assh0le",
  "asshat",
  "asshead",
  "assho1e",
  "asshole",
  "assholes",
  "asshopper",
  "asshore",
  "assjacker",
  "assjockey",
  "asskiss",
  "asskisser",
  "assklown",
  "asslick",
  "asslicker",
  "asslover",
  "assman",
  "assmaster",
  "assmonkey",
  "assmucus",
  "assmunch",
  "assmuncher",
  "assnigger",
  "asspacker",
  "asspirate",
  "asspuppies",
  "assranger",
  "assshit",
  "assshole",
  "asssucker",
  "asswad",
  "asswhore",
  "asswipe",
  "asswipes",
  "auto erotic",
  "autoerotic",
  "axwound",
  "azazel",
  "azz",
  "b!tch",
  "b00bs",
  "b17ch",
  "b1tch",
  "babe",
  "babeland",
  "babes",
  "baby batter",
  "baby juice",
  "badfuck",
  "ball gag",
  "ball gravy",
  "ball kicking",
  "ball licking",
  "ball sack",
  "ball sucking",
  "ballbag",
  "balllicker",
  "balls",
  "ballsack",
  "bampot",
  "bang",
  "bang (one's) box",
  "bangbros",
  "banger",
  "banging",
  "bareback",
  "barely legal",
  "barenaked",
  "barf",
  "barface",
  "barfface",
  "bastard",
  "bastardo",
  "bastards",
  "bastinado",
  "batty boy",
  "bawdy",
  "bazongas",
  "bazooms",
  "bbw",
  "bdsm",
  "beaner",
  "beaners",
  "beardedclam",
  "beastial",
  "beastiality",
  "beatch",
  "beater",
  "beatyourmeat",
  "beaver",
  "beaver cleaver",
  "beaver lips",
  "beef curtain",
  "beef curtains",
  "beer",
  "beeyotch",
  "bellend",
  "bender",
  "beotch",
  "bestial",
  "bestiality",
  "bi-sexual",
  "bi+ch",
  "biatch",
  "bicurious",
  "big black",
  "big breasts",
  "big knockers",
  "big tits",
  "bigbastard",
  "bigbutt",
  "bigger",
  "bigtits",
  "bimbo",
  "bimbos",
  "bint",
  "birdlock",
  "bisexual",
  "bitch",
  "bitch tit",
  "bitchass",
  "bitched",
  "bitcher",
  "bitchers",
  "bitches",
  "bitchez",
  "bitchin",
  "bitching",
  "bitchtits",
  "bitchy",
  "black cock",
  "blonde action",
  "blonde on blonde action",
  "bloodclaat",
  "bloody",
  "bloody hell",
  "blow",
  "blow job",
  "blow me",
  "blow mud",
  "blow your load",
  "blowjob",
  "blowjobs",
  "blue waffle",
  "blumpkin",
  "boang",
  "bod",
  "bodily",
  "bogan",
  "bohunk",
  "boink",
  "boiolas",
  "bollick",
  "bollock",
  "bollocks",
  "bollok",
  "bollox",
  "bomd",
  "bondage",
  "bone",
  "boned",
  "boner",
  "boners",
  "bong",
  "boob",
  "boobies",
  "boobs",
  "booby",
  "booger",
  "bookie",
  "boong",
  "boonga",
  "booobs",
  "boooobs",
  "booooobs",
  "booooooobs",
  "bootee",
  "bootie",
  "booty",
  "booty call",
  "booze",
  "boozer",
  "boozy",
  "bosom",
  "bosomy",
  "bowel",
  "bowels",
  "bra",
  "brassiere",
  "breast",
  "breastjob",
  "breastlover",
  "breastman",
  "breasts",
  "breeder",
  "brotherfucker",
  "brown showers",
  "brunette action",
  "buceta",
  "bugger",
  "buggered",
  "buggery",
  "bukkake",
  "bull shit",
  "bullcrap",
  "bulldike",
  "bulldyke",
  "bullet vibe",
  "bullshit",
  "bullshits",
  "bullshitted",
  "bullturds",
  "bum",
  "bum boy",
  "bumblefuck",
  "bumclat",
  "bumfuck",
  "bummer",
  "bung",
  "bung hole",
  "bunga",
  "bunghole",
  "bunny fucker",
  "bust a load",
  "busty",
  "butchdike",
  "butchdyke",
  "butt",
  "butt fuck",
  "butt plug",
  "butt-bang",
  "butt-fuck",
  "butt-fucker",
  "butt-pirate",
  "buttbang",
  "buttcheeks",
  "buttface",
  "buttfuck",
  "buttfucka",
  "buttfucker",
  "butthead",
  "butthole",
  "buttman",
  "buttmuch",
  "buttmunch",
  "buttmuncher",
  "buttplug",
  "c-0-c-k",
  "c-o-c-k",
  "c-u-n-t",
  "c.0.c.k",
  "c.o.c.k.",
  "c.u.n.t",
  "c0ck",
  "c0cksucker",
  "caca",
  "cahone",
  "camel toe",
  "cameltoe",
  "camgirl",
  "camslut",
  "camwhore",
  "carpet muncher",
  "carpetmuncher",
  "cawk",
  "cervix",
  "chesticle",
  "chi-chi man",
  "chick with a dick",
  "child-fucker",
  "chin",
  "chinc",
  "chincs",
  "chink",
  "chinky",
  "choad",
  "choade",
  "choc ice",
  "chocolate rosebuds",
  "chode",
  "chodes",
  "chota bags",
  "cipa",
  "circlejerk",
  "cl1t",
  "cleveland steamer",
  "climax",
  "clit",
  "clit licker",
  "clitface",
  "clitfuck",
  "clitoris",
  "clitorus",
  "clits",
  "clitty",
  "clitty litter",
  "clogwog",
  "clover clamps",
  "clunge",
  "clusterfuck",
  "cnut",
  "cocain",
  "cocaine",
  "cock",
  "cock pocket",
  "cock snot",
  "cock sucker",
  "cockass",
  "cockbite",
  "cockblock",
  "cockburger",
  "cockeye",
  "cockface",
  "cockfucker",
  "cockhead",
  "cockholster",
  "cockjockey",
  "cockknocker",
  "cockknoker",
  "cocklicker",
  "cocklover",
  "cocklump",
  "cockmaster",
  "cockmongler",
  "cockmongruel",
  "cockmonkey",
  "cockmunch",
  "cockmuncher",
  "cocknose",
  "cocknugget",
  "cocks",
  "cockshit",
  "cocksmith",
  "cocksmoke",
  "cocksmoker",
  "cocksniffer",
  "cocksucer",
  "cocksuck",
  "cocksuck",
  "cocksucked",
  "cocksucker",
  "cocksuckers",
  "cocksucking",
  "cocksucks",
  "cocksuka",
  "cocksukka",
  "cockwaffle",
  "coffin dodger",
  "coital",
  "cok",
  "cokmuncher",
  "coksucka",
  "commie",
  "condom",
  "coochie",
  "coochy",
  "coon",
  "coonnass",
  "coons",
  "cooter",
  "cop some wood",
  "coprolagnia",
  "coprophilia",
  "corksucker",
  "cornhole",
  "corp whore",
  "cox",
  "crabs",
  "crack",
  "crack-whore",
  "cracker",
  "crackwhore",
  "crap",
  "crappy",
  "creampie",
  "cretin",
  "crikey",
  "cripple",
  "crotte",
  "cum",
  "cum chugger",
  "cum dumpster",
  "cum freak",
  "cum guzzler",
  "cumbubble",
  "cumdump",
  "cumdumpster",
  "cumguzzler",
  "cumjockey",
  "cummer",
  "cummin",
  "cumming",
  "cums",
  "cumshot",
  "cumshots",
  "cumslut",
  "cumstain",
  "cumtart",
  "cunilingus",
  "cunillingus",
  "cunn",
  "cunnie",
  "cunnilingus",
  "cunntt",
  "cunny",
  "cunt",
  "cunt hair",
  "cunt-struck",
  "cuntass",
  "cuntbag",
  "cuntface",
  "cuntfuck",
  "cuntfucker",
  "cunthole",
  "cunthunter",
  "cuntlick",
  "cuntlick",
  "cuntlicker",
  "cuntlicker",
  "cuntlicking",
  "cuntrag",
  "cunts",
  "cuntsicle",
  "cuntslut",
  "cuntsucker",
  "cut rope",
  "cyalis",
  "cyberfuc",
  "cyberfuck",
  "cyberfucked",
  "cyberfucker",
  "cyberfuckers",
  "cyberfucking",
  "cybersex",
  "d0ng",
  "d0uch3",
  "d0uche",
  "d1ck",
  "d1ld0",
  "d1ldo",
  "dago",
  "dagos",
  "dammit",
  "damn",
  "damned",
  "damnit",
  "darkie",
  "darn",
  "date rape",
  "daterape",
  "dawgie-style",
  "deep throat",
  "deepthroat",
  "deggo",
  "dendrophilia",
  "dick",
  "dick head",
  "dick hole",
  "dick shy",
  "dick-ish",
  "dick-sneeze",
  "dickbag",
  "dickbeaters",
  "dickbrain",
  "dickdipper",
  "dickface",
  "dickflipper",
  "dickfuck",
  "dickfucker",
  "dickhead",
  "dickheads",
  "dickhole",
  "dickish",
  "dickjuice",
  "dickmilk",
  "dickmonger",
  "dickripper",
  "dicks",
  "dicksipper",
  "dickslap",
  "dicksucker",
  "dicksucking",
  "dicktickler",
  "dickwad",
  "dickweasel",
  "dickweed",
  "dickwhipper",
  "dickwod",
  "dickzipper",
  "diddle",
  "dike",
  "dildo",
  "dildos",
  "diligaf",
  "dillweed",
  "dimwit",
  "dingle",
  "dingleberries",
  "dingleberry",
  "dink",
  "dinks",
  "dipship",
  "dipshit",
  "dirsa",
  "dirty",
  "dirty pillows",
  "dirty sanchez",
  "dlck",
  "dog style",
  "dog-fucker",
  "doggie style",
  "doggie-style",
  "doggiestyle",
  "doggin",
  "dogging",
  "doggy style",
  "doggy-style",
  "doggystyle",
  "dolcett",
  "domination",
  "dominatrix",
  "dommes",
  "dong",
  "donkey punch",
  "donkeypunch",
  "donkeyribber",
  "doochbag",
  "doofus",
  "dookie",
  "doosh",
  "dopey",
  "double dong",
  "double penetration",
  "doublelift",
  "douch3",
  "douche",
  "douche-fag",
  "douchebag",
  "douchebags",
  "douchewaffle",
  "douchey",
  "dp action",
  "drunk",
  "dry hump",
  "duche",
  "dumass",
  "dumb ass",
  "dumbass",
  "dumbasses",
  "dumbcunt",
  "dumbfuck",
  "dumbshit",
  "dummy",
  "dumshit",
  "dvda",
  "dyke",
  "dykes",
  "eat a dick",
  "eat hair pie",
  "eat my ass",
  "eatpussy",
  "ecchi",
  "ejaculate",
  "ejaculated",
  "ejaculates",
  "ejaculating",
  "ejaculatings",
  "ejaculation",
  "ejakulate",
  "enlargement",
  "erect",
  "erection",
  "erotic",
  "erotism",
  "escort",
  "essohbee",
  "eunuch",
  "extacy",
  "extasy",
  "f u c k",
  "f u c k e r",
  "f_u_c_k",
  "f-u-c-k",
  "f.u.c.k",
  "f4nny",
  "facefucker",
  "facial",
  "fack",
  "fag",
  "fagbag",
  "fagfucker",
  "fagg",
  "fagged",
  "fagging",
  "faggit",
  "faggitt",
  "faggot",
  "faggotcock",
  "faggots",
  "faggs",
  "fagot",
  "fagots",
  "fags",
  "fagtard",
  "faig",
  "faigt",
  "fanny",
  "fannybandit",
  "fannyflaps",
  "fannyfucker",
  "fanyy",
  "fart",
  "fartknocker",
  "fastfuck",
  "fat",
  "fatass",
  "fatfuck",
  "fatfucker",
  "fcuk",
  "fcuker",
  "fcuking",
  "fecal",
  "feck",
  "fecker",
  "felch",
  "felcher",
  "felching",
  "fellate",
  "fellatio",
  "feltch",
  "feltcher",
  "female squirting",
  "femdom",
  "fenian",
  "figging",
  "fingerbang",
  "fingerfuck",
  "fingerfuck",
  "fingerfucked",
  "fingerfucker",
  "fingerfucker",
  "fingerfuckers",
  "fingerfucking",
  "fingerfucks",
  "fingering",
  "fist fuck",
  "fisted",
  "fistfuck",
  "fistfucked",
  "fistfucker",
  "fistfucker",
  "fistfuckers",
  "fistfucking",
  "fistfuckings",
  "fistfucks",
  "fisting",
  "fisty",
  "flamer",
  "flange",
  "flaps",
  "fleshflute",
  "flog the log",
  "floozy",
  "foad",
  "foah",
  "fondle",
  "foobar",
  "fook",
  "fooker",
  "foot fetish",
  "footfuck",
  "footfucker",
  "footjob",
  "footlicker",
  "foreskin",
  "freakfuck",
  "freakyfucker",
  "freefuck",
  "freex",
  "frigg",
  "frigga",
  "frotting",
  "fubar",
  "fuc",
  "fuck",
  "fuck buttons",
  "fuck hole",
  "fuck off",
  "fuck puppet",
  "fuck trophy",
  "fuck yo mama",
  "fuck you",
  "fuck-ass",
  "fuck-bitch",
  "fuck-tard",
  "fucka",
  "fuckass",
  "fuckbag",
  "fuckboy",
  "fuckbrain",
  "fuckbutt",
  "fuckbutter",
  "fucked",
  "fuckedup",
  "fucker",
  "fuckers",
  "fuckersucker",
  "fuckface",
  "fuckfreak",
  "fuckhead",
  "fuckheads",
  "fuckher",
  "fuckhole",
  "fuckin",
  "fucking",
  "fuckingbitch",
  "fuckings",
  "fuckingshitmotherfucker",
  "fuckme",
  "fuckme",
  "fuckmeat",
  "fuckmehard",
  "fuckmonkey",
  "fucknugget",
  "fucknut",
  "fucknutt",
  "fuckoff",
  "fucks",
  "fuckstick",
  "fucktard",
  "fucktards",
  "fucktart",
  "fucktoy",
  "fucktwat",
  "fuckup",
  "fuckwad",
  "fuckwhit",
  "fuckwhore",
  "fuckwit",
  "fuckwitt",
  "fuckyou",
  "fudge packer",
  "fudge-packer",
  "fudgepacker",
  "fuk",
  "fuker",
  "fukker",
  "fukkers",
  "fukkin",
  "fuks",
  "fukwhit",
  "fukwit",
  "fuq",
  "futanari",
  "fux",
  "fux0r",
  "fvck",
  "fxck",
  "g-spot",
  "gae",
  "gai",
  "gang bang",
  "gang-bang",
  "gangbang",
  "gangbanged",
  "gangbangs",
  "ganja",
  "gash",
  "gassy ass",
  "gay sex",
  "gayass",
  "gaybob",
  "gaydo",
  "gayfuck",
  "gayfuckist",
  "gaylord",
  "gays",
  "gaysex",
  "gaytard",
  "gaywad",
  "gender bender",
  "genitals",
  "gey",
  "gfy",
  "ghay",
  "ghey",
  "giant cock",
  "gigolo",
  "ginger",
  "gippo",
  "girl on",
  "girl on top",
  "girls gone wild",
  "git",
  "glans",
  "goatcx",
  "goatse",
  "god damn",
  "god-dam",
  "god-damned",
  "godamn",
  "godamnit",
  "goddam",
  "goddammit",
  "goddamn",
  "goddamned",
  "goddamnit",
  "goddamnmuthafucker",
  "godsdamn",
  "gokkun",
  "golden shower",
  "goldenshower",
  "golliwog",
  "gonad",
  "gonads",
  "gonorrehea",
  "goo girl",
  "gooch",
  "goodpoop",
  "gook",
  "gooks",
  "goregasm",
  "gotohell",
  "gringo",
  "grope",
  "group sex",
  "gspot",
  "gtfo",
  "guido",
  "guro",
  "h0m0",
  "h0mo",
  "ham flap",
  "hand job",
  "handjob",
  "hard core",
  "hard on",
  "hardcore",
  "hardcoresex",
  "he-she",
  "he11",
  "headfuck",
  "hebe",
  "heeb",
  "hell",
  "hemp",
  "hentai",
  "heroin",
  "herp",
  "herpes",
  "herpy",
  "heshe",
  "hitler",
  "hiv",
  "ho",
  "hoar",
  "hoare",
  "hobag",
  "hoe",
  "hoer",
  "holy shit",
  "hom0",
  "homey",
  "homo",
  "homodumbshit",
  "homoerotic",
  "homoey",
  "honkey",
  "honky",
  "hooch",
  "hookah",
  "hooker",
  "hoor",
  "hootch",
  "hooter",
  "hooters",
  "hore",
  "horniest",
  "horny",
  "hot carl",
  "hot chick",
  "hotpussy",
  "hotsex",
  "how to kill",
  "how to murdep",
  "how to murder",
  "huge fat",
  "hump",
  "humped",
  "humping",
  "hun",
  "hussy",
  "hymen",
  "iap",
  "iberian slap",
  "inbred",
  "incest",
  "injun",
  "intercourse",
  "j3rk0ff",
  "jack off",
  "jack-off",
  "jackass",
  "jackasses",
  "jackhole",
  "jackoff",
  "jaggi",
  "jagoff",
  "jail bait",
  "jailbait",
  "jap",
  "japs",
  "jelly donut",
  "jerk",
  "jerk off",
  "jerk-off",
  "jerk0ff",
  "jerkass",
  "jerked",
  "jerkoff",
  "jigaboo",
  "jiggaboo",
  "jiggerboo",
  "jism",
  "jiz",
  "jizm",
  "jizz",
  "jizzed",
  "jock",
  "juggs",
  "jungle bunny",
  "junglebunny",
  "junkie",
  "junky",
  "kafir",
  "kawk",
  "kike",
  "kikes",
  "kill",
  "kinbaku",
  "kinkster",
  "kinky",
  "kkk",
  "klan",
  "knob",
  "knob end",
  "knobbing",
  "knobead",
  "knobed",
  "knobend",
  "knobhead",
  "knobjocky",
  "knobjokey",
  "kock",
  "kondum",
  "kondums",
  "kooch",
  "kooches",
  "kootch",
  "kraut",
  "kum",
  "kummer",
  "kumming",
  "kums",
  "kunilingus",
  "kunja",
  "kunt",
  "kwif",
  "kyke",
  "l3i+ch",
  "l3itch",
  "labia",
  "lameass",
  "lardass",
  "leather restraint",
  "leather straight jacket",
  "lech",
  "lemon party",
  "leper",
  "lesbian",
  "lesbians",
  "lesbo",
  "lesbos",
  "lez",
  "lezbian",
  "lezbians",
  "lezbo",
  "lezbos",
  "lezza",
  "lezzie",
  "lezzies",
  "lezzy",
  "lmao",
  "lmfao",
  "loin",
  "loins",
  "lolita",
  "looney",
  "lovemaking",
  "lube",
  "lust",
  "lusting",
  "lusty",
  "m-fucking",
  "m0f0",
  "m0fo",
  "m45terbate",
  "ma5terb8",
  "ma5terbate",
  "mafugly",
  "make me come",
  "male squirting",
  "mams",
  "masochist",
  "massa",
  "master-bate",
  "masterb8",
  "masterbat",
  "masterbat3",
  "masterbate",
  "masterbating",
  "masterbation",
  "masterbations",
  "masturbate",
  "masturbating",
  "masturbation",
  "maxi",
  "mcfagget",
  "menage a trois",
  "menses",
  "menstruate",
  "menstruation",
  "meth",
  "mick",
  "middle finger",
  "midget",
  "milf",
  "minge",
  "minger",
  "missionary position",
  "mo-fo",
  "mof0",
  "mofo",
  "molest",
  "mong",
  "moo moo foo foo",
  "moolie",
  "moron",
  "mothafuck",
  "mothafucka",
  "mothafuckas",
  "mothafuckaz",
  "mothafucked",
  "mothafucker",
  "mothafuckers",
  "mothafuckin",
  "mothafucking",
  "mothafuckings",
  "mothafucks",
  "mother fucker",
  "motherfuck",
  "motherfucka",
  "motherfucked",
  "motherfucker",
  "motherfuckers",
  "motherfuckin",
  "motherfucking",
  "motherfuckings",
  "motherfuckka",
  "motherfucks",
  "mound of venus",
  "mr hands",
  "mtherfucker",
  "mthrfucker",
  "mthrfucking",
  "muff",
  "muff diver",
  "muff puff",
  "muffdiver",
  "muffdiving",
  "munging",
  "munter",
  "murder",
  "mutha",
  "muthafecker",
  "muthafuckaz",
  "muthafuckker",
  "muther",
  "mutherfucker",
  "mutherfucking",
  "muthrfucking",
  "n1gga",
  "n1gger",
  "nad",
  "nads",
  "naked",
  "nambla",
  "napalm",
  "nappy",
  "nawashi",
  "nazi",
  "nazism",
  "need the dick",
  "negro",
  "neonazi",
  "nig nog",
  "nig-nog",
  "nigaboo",
  "nigg3r",
  "nigg4h",
  "nigga",
  "niggah",
  "niggas",
  "niggaz",
  "nigger",
  "niggers",
  "niggle",
  "niglet",
  "nimphomania",
  "nimrod",
  "ninny",
  "nipple",
  "nipples",
  "nob",
  "nob jokey",
  "nobhead",
  "nobjocky",
  "nobjokey",
  "nonce",
  "nooky",
  "nsfw images",
  "nude",
  "nudity",
  "numbnuts",
  "nut butter",
  "nut sack",
  "nutsack",
  "nutter",
  "nympho",
  "nymphomania",
  "octopussy",
  "old bag",
  "omg",
  "omorashi",
  "one cup two girls",
  "one guy one jar",
  "opiate",
  "opium",
  "oral",
  "orally",
  "organ",
  "orgasim",
  "orgasims",
  "orgasm",
  "orgasmic",
  "orgasms",
  "orgies",
  "orgy",
  "ovary",
  "ovum",
  "ovums",
  "p.u.s.s.y.",
  "p0rn",
  "paddy",
  "paedophile",
  "paki",
  "panooch",
  "pansy",
  "pantie",
  "panties",
  "panty",
  "pastie",
  "pasty",
  "pawn",
  "pcp",
  "pecker",
  "peckerhead",
  "pedo",
  "pedobear",
  "pedophile",
  "pedophilia",
  "pedophiliac",
  "pee",
  "peepee",
  "pegging",
  "penetrate",
  "penetration",
  "penial",
  "penile",
  "penis",
  "penisbanger",
  "penisfucker",
  "penispuffer",
  "perversion",
  "peyote",
  "phalli",
  "phallic",
  "phone sex",
  "phonesex",
  "phuck",
  "phuk",
  "phuked",
  "phuking",
  "phukked",
  "phukking",
  "phuks",
  "phuq",
  "piece of shit",
  "pigfucker",
  "pikey",
  "pillowbiter",
  "pimp",
  "pimpis",
  "pinko",
  "piss",
  "piss off",
  "piss pig",
  "piss-off",
  "pissed",
  "pissed off",
  "pisser",
  "pissers",
  "pisses",
  "pissflaps",
  "pissin",
  "pissing",
  "pissoff",
  "pisspig",
  "playboy",
  "pleasure chest",
  "pms",
  "polack",
  "pole smoker",
  "polesmoker",
  "pollock",
  "ponyplay",
  "poof",
  "poon",
  "poonani",
  "poonany",
  "poontang",
  "poop",
  "poop chute",
  "poopchute",
  "poopuncher",
  "porch monkey",
  "porchmonkey",
  "porn",
  "porno",
  "pornography",
  "pornos",
  "pot",
  "potty",
  "prick",
  "pricks",
  "prickteaser",
  "prig",
  "prince albert piercing",
  "prod",
  "pron",
  "prostitute",
  "prude",
  "psycho",
  "pthc",
  "pube",
  "pubes",
  "pubic",
  "pubis",
  "punani",
  "punanny",
  "punany",
  "punkass",
  "punky",
  "punta",
  "puss",
  "pusse",
  "pussi",
  "pussies",
  "pussy",
  "pussy fart",
  "pussy palace",
  "pussylicking",
  "pussypounder",
  "pussys",
  "pust",
  "puto",
  "queaf",
  "queef",
  "queer",
  "queerbait",
  "queerhole",
  "queero",
  "queers",
  "quicky",
  "quim",
  "r-tard",
  "racy",
  "raghead",
  "raging boner",
  "rape",
  "raped",
  "raper",
  "rapey",
  "raping",
  "rapist",
  "raunch",
  "rectal",
  "rectum",
  "rectus",
  "reefer",
  "reetard",
  "reich",
  "renob",
  "retard",
  "retarded",
  "reverse cowgirl",
  "revue",
  "rimjaw",
  "rimjob",
  "rimming",
  "ritard",
  "rosy palm",
  "rosy palm and her 5 sisters",
  "rtard",
  "rubbish",
  "rum",
  "rump",
  "rumprammer",
  "ruski",
  "rusty trombone",
  "s hit",
  "s_h_i_t",
  "s-h-1-t",
  "s-h-i-t",
  "s-o-b",
  "s.h.i.t.",
  "s.o.b.",
  "s&m",
  "s0b",
  "sadism",
  "sadist",
  "sambo",
  "sand nigger",
  "sandbar",
  "sandler",
  "sandnigger",
  "sanger",
  "santorum",
  "sausage queen",
  "scag",
  "scantily",
  "scat",
  "schizo",
  "schlong",
  "scissoring",
  "screw",
  "screwed",
  "screwing",
  "scroat",
  "scrog",
  "scrot",
  "scrote",
  "scrotum",
  "scrud",
  "scum",
  "seaman",
  "seamen",
  "seduce",
  "seks",
  "semen",
  "sex",
  "sexo",
  "sexual",
  "sexy",
  "sh!+",
  "sh!t",
  "sh1t",
  "shag",
  "shagger",
  "shaggin",
  "shagging",
  "shamedame",
  "shaved beaver",
  "shaved pussy",
  "shemale",
  "shi+",
  "shibari",
  "shirt lifter",
  "shit",
  "shit ass",
  "shit fucker",
  "shitass",
  "shitbag",
  "shitbagger",
  "shitblimp",
  "shitbrains",
  "shitbreath",
  "shitcanned",
  "shitcunt",
  "shitdick",
  "shite",
  "shiteater",
  "shited",
  "shitey",
  "shitface",
  "shitfaced",
  "shitfuck",
  "shitfull",
  "shithead",
  "shitheads",
  "shithole",
  "shithouse",
  "shiting",
  "shitings",
  "shits",
  "shitspitter",
  "shitstain",
  "shitt",
  "shitted",
  "shitter",
  "shitters",
  "shittier",
  "shittiest",
  "shitting",
  "shittings",
  "shitty",
  "shiz",
  "shiznit",
  "shota",
  "shrimping",
  "sissy",
  "skag",
  "skank",
  "skeet",
  "skullfuck",
  "slag",
  "slanteye",
  "slave",
  "sleaze",
  "sleazy",
  "slope",
  "slut",
  "slut bucket",
  "slutbag",
  "slutdumper",
  "slutkiss",
  "sluts",
  "smartass",
  "smartasses",
  "smeg",
  "smegma",
  "smut",
  "smutty",
  "snatch",
  "sniper",
  "snowballing",
  "snuff",
  "sod off",
  "sodom",
  "sodomize",
  "sodomy",
  "son of a bitch",
  "son of a motherless goat",
  "son of a whore",
  "son-of-a-bitch",
  "souse",
  "soused",
  "spac",
  "spade",
  "sperm",
  "spic",
  "spick",
  "spik",
  "spiks",
  "splooge",
  "splooge moose",
  "spooge",
  "spook",
  "spread legs",
  "spunk",
  "steamy",
  "stfu",
  "stiffy",
  "stoned",
  "strap on",
  "strapon",
  "strappado",
  "strip",
  "strip club",
  "stroke",
  "stupid",
  "style doggy",
  "suck",
  "suckass",
  "sucked",
  "sucking",
  "sucks",
  "suicide girls",
  "sultry women",
  "sumofabiatch",
  "swastika",
  "swinger",
  "t1t",
  "t1tt1e5",
  "t1tties",
  "taff",
  "taig",
  "tainted love",
  "taking the piss",
  "tampon",
  "tard",
  "tart",
  "taste my",
  "tawdry",
  "tea bagging",
  "teabagging",
  "teat",
  "teets",
  "teez",
  "terd",
  "teste",
  "testee",
  "testes",
  "testical",
  "testicle",
  "testis",
  "threesome",
  "throating",
  "thrust",
  "thug",
  "thundercunt",
  "tied up",
  "tight white",
  "tinkle",
  "tit",
  "tit wank",
  "titfuck",
  "titi",
  "tities",
  "tits",
  "titt",
  "tittie5",
  "tittiefucker",
  "titties",
  "titty",
  "tittyfuck",
  "tittyfucker",
  "tittywank",
  "titwank",
  "toke",
  "tongue in a",
  "toots",
  "topless",
  "tosser",
  "towelhead",
  "tramp",
  "tranny",
  "transsexual",
  "trashy",
  "tribadism",
  "trumped",
  "tub girl",
  "tubgirl",
  "turd",
  "tush",
  "tushy",
  "tw4t",
  "twat",
  "twathead",
  "twatlips",
  "twats",
  "twatty",
  "twatwaffle",
  "twink",
  "twinkie",
  "two fingers",
  "two fingers with tongue",
  "two girls one cup",
  "twunt",
  "twunter",
  "ugly",
  "unclefucker",
  "undies",
  "undressing",
  "unwed",
  "upskirt",
  "urethra play",
  "urinal",
  "urine",
  "urophilia",
  "uterus",
  "uzi",
  "v14gra",
  "v1gra",
  "va-j-j",
  "vag",
  "vagina",
  "vajayjay",
  "valium",
  "venus mound",
  "veqtable",
  "viagra",
  "vibrator",
  "violet wand",
  "virgin",
  "vixen",
  "vjayjay",
  "vodka",
  "vomit",
  "vorarephilia",
  "voyeur",
  "vulgar",
  "vulva",
  "w00se",
  "wad",
  "wang",
  "wank",
  "wanker",
  "wankjob",
  "wanky",
  "wazoo",
  "wedgie",
  "weed",
  "weenie",
  "weewee",
  "weiner",
  "weirdo",
  "wench",
  "wet dream",
  "wetback",
  "wh0re",
  "wh0reface",
  "white power",
  "whitey",
  "whiz",
  "whoar",
  "whoralicious",
  "whore",
  "whorealicious",
  "whorebag",
  "whored",
  "whoreface",
  "whorehopper",
  "whorehouse",
  "whores",
  "whoring",
  "wigger",
  "willies",
  "willy",
  "window licker",
  "wiseass",
  "wiseasses",
  "wog",
  "womb",
  "woody",
  "wop",
  "wrapping men",
  "wrinkled starfish",
  "wtf",
  "x-rated",
  "xrated",
  "xx",
  "xxx",
  "yaoi",
  "yeasty",
  "yellow showers",
  "yid",
  "yiffy",
  "yobbo",
  "zoophile",
  "zoophilia",
  "zubb",
];

export default badwords;
