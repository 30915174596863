import React from "react";
import { Card } from "react-bootstrap";
import SoftBadge from "components/common/SoftBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import ProductShareChart from "./ProductShareChart";

const MaintenanceRequestsCard = ({ reqRate = {} }) => {
  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Recent Open Violation Tracking Notices</h6>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2 className="mb-1 text-700 fw-normal lh-1 fs-2">{reqRate?.rate}</h2>
          <SoftBadge pill bg="success" className="me-2 fs--2">
            <FontAwesomeIcon icon="caret-up" className="me-1" />
            {reqRate?.performance}%
          </SoftBadge>
        </div>
        <div>
          <div className="my-n5">
            <ProductShareChart reqRate={reqRate} />
          </div>
          <p className="mb-0 mt-4 text-center fs--2 text-500">
            Open Rate: <span className="text-800"> {reqRate?.performance}% </span>
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MaintenanceRequestsCard;
