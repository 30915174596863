import React from 'react';
import './ImageUnit.css';

function ImageUnit({ src, firstname, lastname, bgColor }) {
  if (!src) {
    let l1 = firstname[0]?.toUpperCase();
    let l2 = lastname[0]?.toUpperCase();

    let colors = ['purple','black','red','blue','orange','crimson','blueviolet','brown','slateblue','chocolate','coral','cornflowerblue','sienna','rebeccapurple'];

    let rn = Math.floor(Math.random() * colors.length);

    return (
      <div className="bg-name text-white" style={{ backgroundColor: bgColor || colors[rn] }}>
        <span className="bg-data">{`${l1}${l2}`}</span>
      </div>
    );
  }

  return (
    <img
      src={src}
      className="img-fluid rounded-circle"
      alt="sample"
      width="35"
    />
  );
}

export default ImageUnit;
