import React from "react";
import BSSliderCustom from "components/common/BSSliderCustom";

import {
  Button,
  Card,
  Col,
  // OverlayTrigger,
  Row,
  // Tooltip
} from "react-bootstrap";
import Flex from "components/common/Flex";
// import { Link } from 'react-router-dom';

// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

export default function Amenities({ amenity, handleBookSelectedAmenity }) {
  try {
    // console.log(amenity);
    let {
      amenityimages,
      description,
      amenity_price,
      amenityname,
      payment_type,
    } = amenity;
    if (amenityname)
      amenityname = amenityname[0].toUpperCase() + amenityname.slice(1);

    if (description)
      description = description[0].toUpperCase() + description.slice(1);

    // if(description.length > 50)
    // console.log(amenity);
    console.log(description.length);

    return (
      <Card
        className="overflow-hidden"
        style={{ minHeight: amenityimages.length === 0 ? 448 : "" }}
      >
        <Card.Body
          as={Flex}
          direction="column"
          justifyContent="between"
          className="p-0"
        >
          <div>
            {amenityimages.length > 0 ? (
              <BSSliderCustom items={amenityimages} fromShowAmenities />
            ) : (
              <div
                style={{ minHeight: 295 }}
                className="d-flex align-items-center justify-content-center"
              >
                <h5 className="text-info">no images provided!</h5>
              </div>
            )}

            <div className="p-3" style={{ height: "100px" }}>
              <h5 className="fs-0 mb-2 text-primary">
                {amenityname || "Name"}
              </h5>
              <h5
                className="text-dark"
                style={{ fontSize: description.length > 60 ? "14px" : "16px" }}
              >
                {description ||
                  "Description for current amenity Description for current amenity"}
              </h5>
            </div>
          </div>
          <Row className="g-0 mb-3 align-items-end">
            <Col className="ps-3">
              <h4 className="fs-1 text-warning d-flex align-items-center">
                {/* <span>${price}</span> */}
                <span>
                  {payment_type === "Flat Rate" &&
                    `$${amenity_price} / Flat Rate`}
                  {payment_type === "Daily" && `$${amenity_price} / Day`}
                  {payment_type === "Per Hour" && `$${amenity_price} / Hour`}
                  {payment_type === "No Fee" && `$${amenity_price}0`}
                  {payment_type === "Night Fee" && `$${amenity_price} / Night `}
                  {/* {payment_type === 'Hourly Rate'
                    ? `$${amenity_price} / Hour`
                    : payment_type !== 'Hourly Rate' && amenity_price
                    ? `$${amenity_price}`
                    : 'Free'} */}
                </span>
                {/* {oldPrice && (
                  <del className="ms-2 fs--1 text-700">${oldPrice}</del>
                )} */}
                {/* <span className='badge bg-success fs--1 text-white ms-3'>
                  {amenity.payment_type}
                </span> */}
              </h4>
              {/* <p className="mb-0 fs--1 text-800">
                {amenity.type} 
              </p> */}
            </Col>
            <Col xs="auto" className="pe-3">
              {/* <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip style={{ position: 'fixed' }}>Book Now</Tooltip>
                }
              > */}
              <Button
                variant={"falcon-default"}
                size="sm"
                onClick={() => handleBookSelectedAmenity(amenity._id)}
              >
                {/* <AddShoppingCartIcon /> */}Book Now
              </Button>
              {/* </OverlayTrigger> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
