// import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { useNavigate } from 'react-router-dom';

// const Success = ({ reset }) => {
const Success = () => {
  // const { setStep, setUser } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  // const emptyData = () => {
  //   setStep(1);
  //   setUser({});
  //   reset();
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     redirectUser();
  //   }, 3000);
  // }, []);


  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Your account is all set!</h4>
          <p className="fs-0">Redirecting you to Login page in 5 sec...</p>
          {/* <Link
            to="/login"
            className="btn btn-primary px-5 my-3"
            onClick={emptyData}
          >
            Login
          </Link> */}
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
