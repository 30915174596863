import React from "react";
import {
  Col,
  Row,
  Form as BSForm,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpIcon from "@mui/icons-material/HelpOutline";

// const BillingUserForm = ({ register, errors, setValue }) => {
import { AuthWizardContext } from "../../../../context/Context";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { convertUppercaseToLowercase, getHostName } from "helpers/utils";

const CompleteProfile = () => {
  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);
  console.log(step);

  let hostname = getHostName();

  const handleSubmit = (data) => {
    // console.log(data);
    // return;

    axios
      .post(`${hostname}/api/auth/isCompanyTaken`, {
        company_name: data.p_name,
        site_name: data.site_name,
      })
      .then((res) => {
        console.log(res.data);
        toast.success(<span>Site name available</span>, {
          theme: "colored",
        });
        const c_data = {};
        c_data.firstName = user.first_name;
        c_data.lastName = user.last_name;
        c_data.email = user.email;
        c_data.password = user.password;
        c_data.companyName = data.p_name;
        c_data.siteName = data.site_name;
        c_data.buisnessType = data.p_type;
        console.log(c_data);
        setUser(Object.assign({}, user, data));
        setStep(step + 1);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          <span>
            Site name already taken!
            <br /> please select a new one
          </span>,
          {
            theme: "colored",
          }
        );
      });
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({}, user, values));

    setStep(step - 2);
  };

  return (
    <>
      <Formik
        initialValues={{
          p_name: user.p_name || "",
          site_name: user.site_name || "",
          p_type: user.p_type || "",
        }}
        validationSchema={Yup.object({
          p_name: Yup.string().required("property Name is required!"),
          site_name: Yup.string()
            .required("Site Name is required!")
            .matches(
              /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
              "Invalid site name. Please enter a valid site name like this 'acmesite' or 'acme-site'."
            ),
          p_type: Yup.string().required("please select property type!"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values,setFieldValue }) => (
          <Form>
            <BSForm.Group className="mb-3">
              <BSForm.Label>Company Name</BSForm.Label>
              <Field name="p_name" type="text" className="form-control" />
              <ErrorMessage
                name="p_name"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>

            <BSForm.Group className="mb-3">
              <BSForm.Label>
                Site Name{" "}
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip
                      style={{
                        position: "fixed",
                        minWidth: "350px",
                        backgroundColor: "#000",
                        borderRadius: "3px",
                      }}
                      id="overlay-trigger-example"
                    >
                      <div
                        style={{
                          width: "340px",
                          textAlign: "left",
                        }}
                      >
                        Must contain only letters (a-z), numbers (0-9).
                        <br />
                        No spaces or special characters allowed.
                        <br /> Should be unique and descriptive of your company.
                        <br /> like "my-company-site" or "companysite"',
                      </div>
                    </Tooltip>
                  }
                >
                  <HelpIcon />
                </OverlayTrigger>
              </BSForm.Label>
              {/* <div className="d-flex flex-column position-relative">
                <Field
                  name="site_name"
                  type="text"
                  className="form-control"
                  style={{ paddingRight: "40%", overflow: "hidden" }}
                />
                <span
                  className="translate-middle"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "78%",
                  }}
                >
                  .condozak.app
                </span>
              </div> */}

              <Field
                name="site_name"
                type="text"
                className="form-control"
                value={values.site_name}
                onChange={(e) =>
                  setFieldValue(
                    "site_name",
                    convertUppercaseToLowercase(e.target.value)
                  )
                }
              />

              <ErrorMessage
                name="site_name"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-4">
              <BSForm.Label>Company Type</BSForm.Label>
              <Field
                component="select"
                id="p_type"
                name="p_type"
                //  multiple={true}
                className="form-select"
              >
                <option value="">Select</option>
                <option value="company">Property Management Company</option>
                <option value="association">Association</option>
              </Field>
              <ErrorMessage
                name="p_type"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>

            <div>
              <Button
                variant="secondary"
                type="button"
                onClick={() => handleStepMinus(values)}
              >
                Previous
              </Button>
              <Button
                variant="primary"
                className="float-end"
                type="submit"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CompleteProfile;
