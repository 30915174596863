import React from "react";
import { Modal } from "react-bootstrap";
import Wizard from "./wizardRemastered/Wizard";
import FalconCloseButton from "../../../../components/common/FalconCloseButton";

export default function AddAmenityModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      // className='bg-info'
      style={{ backdropFilter: "blur(3px)" }}
      keyboard={false}
      size="lg"
      // fullscreen
      centered
    >
      <Modal.Header>
        <Modal.Title>Create Amenity</Modal.Title>
        <FalconCloseButton onClick={props.handleClose} />
      </Modal.Header>
      <Modal.Body className="scrollbar">
        <Wizard
          handleClose={props.handleClose}
          validation={true}
          progressBar={true}
          getAmenitiesData={props.getAmenitiesData}
          userData={props.userData}
          hostname={props.hostname}
          jwtToken={props.jwtToken}
        />
      </Modal.Body>
    </Modal>
  );
}
