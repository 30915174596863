import FalconCardHeader from '../../../../../../components/common/FalconCardHeader';
import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

export default function NoRestrictions({ user }) {
  return (
    <Card className="mb-3">
      <FalconCardHeader title={user.booking_type} />
      <Card.Body className="bg-light p-3">
        <Row>
          <Col>
            <p>Start Time</p>
          </Col>
          <Col>
            <p>
              12:00:00 AM
              {/* {user.start_time_for_no_restrictions}{' '}
              {user.start_time_zone_for_no_restrictions} */}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>End Time</p>
          </Col>
          <Col>
            <p>
              11:59:59 PM
              {/* {user.end_time_for_no_restrictions}/ */}
            </p>
          </Col>
        </Row>
        <br />
        <Row className="mb-3">
          <Col>Minimum Booking Lead Time</Col>
          <Col>{user.min_book_lead_time}</Col>
        </Row>
        <Row className="mb-3">
          <Col>Maximum Booking Lead Time</Col>
          <Col>{user.max_book_lead_time}</Col>
        </Row>
        {/* <Row>
          <Col>
            <p>Unavailable Day</p>
          </Col>
          <Col>
            <p>{user.amenity_unavailable_for_no_restrictions}</p>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
}
