import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Form as BSForm, Row, Col, Card, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axios from "axios";
import VisitorWizard from "./wizard/Wizard";
import FalconCardHeader from "components/common/FalconCardHeader";
import {
  formatLogInfo,
  generatePdfByJsPdf,
  getTodayItemsFromList,
} from "helpers/utils";
import IconButton from "components/common/IconButton";
import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { PDFDocument } from "pdf-lib";
import { StandardFonts, rgb, degrees } from "pdf-lib";
import { startCase } from "lodash";

import { CalendarToday } from "@mui/icons-material";
import DatePicker from "react-datepicker";

import "./VisitorParking.css";

export default function VisitorParkingModal({
  show,
  handleClose,
  payload,
  setPayload,
  getAllData,
  VisitorParkingAdditionalSettings = {},
  propertyLogo = {},
  //userData,
  hostname,
  jwtToken,
}) {
  const { property_address } = VisitorParkingAdditionalSettings;
  const { p_id } = payload;

  const printRef = React.useRef();

  // for report date
  const [loader, setLoader] = React.useState(new Date());
  const [reportDate, setReportDate] = React.useState(new Date());
  const [reportDateData, setReportDateData] = React.useState(
    []
    // payload.todayPassesArr?.length ? [...payload.todayPassesArr] : []
  );

  const handleChangeReportDate = (date) => {
    setReportDate(date);
    let arr = getTodayItemsFromList(payload.AllVisitorParkingList, true, date);
    console.log(arr);
    setReportDateData(arr);
  };

  React.useEffect(() => {
    if (payload.loadType === "Generate Report") {
      setLoader(true);
      setReportDateData(payload.todayPassesArr);
      setLoader(false);
    }
  }, [payload]);

  const forInputrefHSS = React.useRef(null);

  const handeInputRefHSS = () => {
    forInputrefHSS.current?.setFocus();
  };

  const arrayBufferToBase64 = (Arraybuffer, Filetype, fileName) => {
    let binary = "";
    const bytes = new Uint8Array(Arraybuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const file = window.btoa(binary);
    const mimType =
      Filetype === "pdf"
        ? "application/pdf"
        : Filetype === "xlsx"
        ? "application/xlsx"
        : Filetype === "pptx"
        ? "application/pptx"
        : Filetype === "csv"
        ? "application/csv"
        : Filetype === "docx"
        ? "application/docx"
        : Filetype === "jpg"
        ? "application/jpg"
        : Filetype === "png"
        ? "application/png"
        : "";
    const url = `data:${mimType};base64,` + file;
    // return url;

    // url for the file
    // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    // download the file
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadPdf = async () => {
    const element = printRef.current;

    const canvasVar = await html2canvas(element);
    const dataVar = canvasVar.toDataURL("image/png");

    console.log(dataVar);

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

    page.setFont(helveticaFont);

    const jpgImage = await pdfDoc.embedPng(dataVar);
    let jpgDims = "";
    let widthX = 11;
    let heightY = 450;
    if (jpgImage.height > 500) {
      jpgDims = jpgImage.scale(0.7);
      widthX = 20;
      heightY = 500;
    } else {
      jpgDims = jpgImage.scale(0.9);
      widthX = 30;
      heightY = 480;
    }

    console.log(jpgImage);
    // console.log(jpgDims);

    // return;

    page.drawImage(jpgImage, {
      x: widthX,
      y: height - heightY,
      width: jpgDims.width,
      height: jpgDims.height,
    });
    // page.moveTo(5, 200);
    // page.drawText("The Life of an Egg", { size: 36 });

    // page.moveDown(36);
    // page.drawText("An Epic Tale of Woe", { size: 30 });

    //     new street house etc n sample
    // Azilda, Ontario, 547890.
    // Canada

    // let addressVar = `${p_id.property_manager.firstname} ${p_id.property_manager.lastname} \n${p_id.property_name} \n${property_address.city}, ${property_address.state}, ${property_address.zipcode}, \n${property_address.country},`;

    page.drawText(startCase(p_id.property_name), {
      x: width / 3 - 20,
      y: height - 70,
      font: timesRomanFont,
      size: 24,
      color: rgb(0.25, 0.25, 0.25),
      lineHeight: 18,
      fontWeight: "bold",
    });
    let propertyManagerVar = `${p_id.property_manager.firstname} ${
      p_id.property_manager.lastname
    } \n${formatLogInfo(payload.end_date, true)}`;

    page.drawText(propertyManagerVar, {
      x: 25,
      y: height - 60,
      font: timesRomanFont,
      size: 12,
      color: rgb(0.25, 0.25, 0.25),
      lineHeight: 18,
    });

    let addressVar = `${property_address.city}, ${property_address.state}, ${property_address.zipcode}. \n${property_address.country}`;

    page.drawText(addressVar, {
      x: width - 141,
      y: height - 60,
      font: timesRomanFont,
      size: 12,
      color: rgb(0.25, 0.25, 0.25),
      lineHeight: 18,
    });
    // page.drawText(addressVar, {
    //   x: 25,
    //   y: 450,
    //   font: timesRomanFont,
    //   size: 12,
    //   color: rgb(0.25, 0.25, 0.25),
    //   lineHeight: 24,

    // });

    const pdfBytes = await pdfDoc.save();

    let file = arrayBufferToBase64(
      pdfBytes,
      "pdf",
      `${p_id.property_name} ${formatLogInfo(new Date(), true)}`
    );
    // console.log(file);
    // window.open(file);

    return;

    //await wait(1000);

    // const canvas = await html2canvas(element);
    // const data = canvas.toDataURL("image/png");

    // const pdf = new jsPDF();
    // const imgProperties = pdf.getImageProperties(data);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    // pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);

    // window.open(pdf.output("bloburi", `-${new Date().toISOString()}.pdf`));
  };

  const handleDownloadPdfReport = async () => {
    const element = printRef.current;

    const canvasVar = await html2canvas(element);
    const dataVar = canvasVar.toDataURL("image/png");

    console.log(dataVar);
    console.log(element);

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

    page.setFont(helveticaFont);

    const jpgImage = await pdfDoc.embedPng(dataVar);
    let jpgDims = "";
    let widthX = 11;
    let heightY = 450;
    if (jpgImage.height > 500) {
      jpgDims = jpgImage.scale(0.6);
      widthX = 20;
      heightY = 180;
    } else {
      jpgDims = jpgImage.scale(0.6);
      widthX = 15;
      heightY = 180;
    }

    console.log(jpgImage);
    // console.log(jpgDims);

    // return;

    page.drawImage(jpgImage, {
      x: widthX,
      y: height - heightY,
      width: jpgDims.width,
      height: jpgDims.height,
    });
    // page.moveTo(5, 200);
    // page.drawText("The Life of an Egg", { size: 36 });

    // page.moveDown(36);
    // page.drawText("An Epic Tale of Woe", { size: 30 });

    //     new street house etc n sample
    // Azilda, Ontario, 547890.
    // Canada

    // let addressVar = `${p_id.property_manager.firstname} ${p_id.property_manager.lastname} \n${p_id.property_name} \n${property_address.city}, ${property_address.state}, ${property_address.zipcode}, \n${property_address.country},`;
    console.log(payload);
    // return;
    page.drawText(startCase(payload.property_name), {
      x: width / 3 - 20,
      y: height - 70,
      font: timesRomanFont,
      size: 24,
      color: rgb(0.25, 0.25, 0.25),
      lineHeight: 18,
      fontWeight: "bold",
    });
    let propertyManagerVar = `${payload.userData.firstname} ${
      payload.userData.lastname
    } \n${formatLogInfo(new Date(), true)}`;

    page.drawText(propertyManagerVar, {
      x: 25,
      y: height - 60,
      font: timesRomanFont,
      size: 12,
      color: rgb(0.25, 0.25, 0.25),
      lineHeight: 18,
    });

    let addressVar = `${property_address.city}, ${property_address.state}, ${property_address.zipcode}. \n${property_address.country}`;

    page.drawText(addressVar, {
      x: width - 141,
      y: height - 60,
      font: timesRomanFont,
      size: 12,
      color: rgb(0.25, 0.25, 0.25),
      lineHeight: 18,
    });
    // page.drawText(addressVar, {
    //   x: 25,
    //   y: 450,
    //   font: timesRomanFont,
    //   size: 12,
    //   color: rgb(0.25, 0.25, 0.25),
    //   lineHeight: 24,

    // });

    const pdfBytes = await pdfDoc.save();

    let file = arrayBufferToBase64(
      pdfBytes,
      "pdf",
      `${payload.property_name} ${formatLogInfo(new Date(), true)}`
    );
    // console.log(file);
    // window.open(file);

    return;

    //await wait(1000);

    // const canvas = await html2canvas(element);
    // const data = canvas.toDataURL("image/png");

    // const pdf = new jsPDF();
    // const imgProperties = pdf.getImageProperties(data);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    // pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);

    // window.open(pdf.output("bloburi", `-${new Date().toISOString()}.pdf`));
  };

  const addOrUpdateVistorParkingSettingByPM = (values) => {
    console.log(values);

    let vparkingdata = {
      p_id: payload.property_id,
      permit_type: values.permit_type,
      max_overnight_permits_per_unit: values.max_permits_of_unit_monthly,
      start_time: values.parking_enforcement_start_time?.toString(),
      end_time: values.parking_enforcement_end_time?.toString(),
      // start_time: `${values.parking_enforcement_start_time} ${values.parking_enforcement_start_timezone}`,
      // end_time: `${values.parking_enforcement_end_time} ${values.parking_enforcement_end_timezone}`,
      total_visitor_parking_spots: values.total_parking_spots,
      max_consecutive_booking_limit: values.max_consecutive_reserve_limit,
    };

    // console.log(vparkingdata);
    // return;

    axios
      .post(
        `${hostname}/api/property/VisitorParkingSettings`,
        {
          ...vparkingdata,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        toast.success("setting updated.", { theme: "colored" });
        handleClose();
        getAllData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNewPermitByOwner = (user, setStep, setUser) => {
    console.log(user);

    let vpermitobj = {
      user_id: payload.userData.data,
      p_id: payload.userData.propid,
      unit_id: payload.userData.UnitsOwner[0]._id,
      vehicle_make: user.vehicle_make,
      vehicle_model: user.vehicle_model,
      vehicle_color: user.vehicle_color,
      license_plate: user.vehicle_license_plate,
      license_plate_country: user.vehicle_license_plate_country,
      license_plate_state: user.vehicle_license_plate_state,
      start_date: user.start_date,
      end_date: user.end_date,
      parking_spot: user.parking_spot,
      send_confirmation_mail: user.send_confirmation_mail,
    };
    console.log(vpermitobj);

    axios
      .post(
        `${hostname}/api/property/BookParking`,
        {
          ...vpermitobj,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("permit created.", { theme: "colored" });
        handleClose();
        getAllData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong!", { theme: "colored" });
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={payload.loadType === "Generate Report" ? "xl" : "lg"}
        // centered
        // size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex">
            {payload.loadType}
            {payload.loadType === "Parking Permit Details" && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="print"
                iconClassName="me-1"
                className="ms-5 me-1 mb-2 mb-sm-0"
                // onClick={handleDownloadPdf}
                onClick={() =>
                  generatePdfByJsPdf(printRef.current, "preview", 50)
                }
              >
                Print
              </IconButton>
            )}

            {payload.loadType === "Generate Report" && (
              <div className="d-flex">
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="print"
                  iconClassName="me-1"
                  className="ms-5 me-3 mb-2 mb-sm-0"
                  // onClick={handleDownloadPdfReport}
                  onClick={() =>
                    generatePdfByJsPdf(printRef.current, "preview", 50)
                  }
                >
                  Print
                </IconButton>
                <div className="position-relative visitor-parking-report-div">
                  <DatePicker
                    ref={forInputrefHSS}
                    // showIcon
                    placeholderText="Select Start Date"
                    selected={reportDate}
                    type="date"
                    onChange={handleChangeReportDate}
                    // value={reportDate}
                    className="form-control"
                    // minDate={new Date()}
                    // maxDate={values.event_end_date}
                  />
                  <span
                    className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                    onClick={handeInputRefHSS}
                  >
                    <CalendarToday fontSize="small" />
                  </span>
                </div>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={printRef}>
          {payload.loadType === "Generate Report" && (
            <div className="position-relative mb-5 visitor-parking-report-div-mobile">
              <DatePicker
                ref={forInputrefHSS}
                // showIcon
                placeholderText="Select Start Date"
                selected={reportDate}
                type="date"
                onChange={handleChangeReportDate}
                // value={reportDate}
                className="form-control"
                // minDate={new Date()}
                // maxDate={values.event_end_date}
              />
              <span
                className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                onClick={handeInputRefHSS}
              >
                <CalendarToday fontSize="small" />
              </span>
            </div>
          )}
          {payload.loadType === "Parking Settings" && (
            <div>
              <Formik
                initialValues={{
                  permit_type:
                    payload.parkingSettingData.permit_type || "Overnight Pass",
                  max_permits_of_unit_monthly:
                    payload.parkingSettingData.max_overnight_permits_per_unit ||
                    "",
                  parking_enforcement_start_time: dayjs(
                    payload.parkingSettingData.start_time
                  ),
                  // new Date(payload.parkingSettingData.start_time) || new Date(),
                  // parking_enforcement_start_timezone:
                  //   payload.parkingSettingData.start_time?.split(" ")[1] || "",
                  parking_enforcement_end_time: dayjs(
                    payload.parkingSettingData.end_time
                  ),
                  // new Date(payload.parkingSettingData.end_time) || new Date(),
                  // parking_enforcement_end_timezone:
                  //   payload.parkingSettingData.end_time?.split(" ")[1] || "",
                  total_parking_spots:
                    payload.parkingSettingData.total_visitor_parking_spots ||
                    "",
                  max_consecutive_reserve_limit:
                    payload.parkingSettingData.max_consecutive_booking_limit ||
                    "",
                }}
                validate={(values) => {
                  const errors = {};

                  return errors;
                }}
                validationSchema={Yup.object({
                  permit_type: Yup.string().required(
                    "this option is required!"
                  ),
                  max_permits_of_unit_monthly: Yup.number()
                    .required("this option is required!")
                    .min(1, "invalid value")
                    .max(31, "invalid value"),
                  parking_enforcement_start_time: Yup.string().required(
                    "this option is required!"
                  ),
                  // parking_enforcement_start_timezone: Yup.string().required(
                  //   "this option is required!"
                  // ),
                  parking_enforcement_end_time: Yup.string().required(
                    "this option is required!"
                  ),
                  // parking_enforcement_end_timezone: Yup.string().required(
                  //   "this option is required!"
                  // ),
                  total_parking_spots: Yup.string().required(
                    "this option is required!"
                  ),
                  // max_consecutive_reserve_limit: Yup.string().required(
                  //   "this option is required!"
                  // ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  addOrUpdateVistorParkingSettingByPM(values);
                }}
              >
                {({
                  isSubmitting,
                  values,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }) => (
                  <Form>
                    <Row>
                      <Col md={6}>
                        <BSForm.Group className="mb-3">
                          <BSForm.Label>Permit Type</BSForm.Label>
                          <Field
                            component="select"
                            id="permit_type"
                            name="permit_type"
                            disabled
                            className="form-select"
                          >
                            <option value="Overnight Pass">
                              Overnight Pass
                            </option>
                          </Field>
                          <ErrorMessage
                            name="permit_type"
                            component="div"
                            className="text-danger fs--1"
                          />
                        </BSForm.Group>
                      </Col>
                      <Col md={6}>
                        <BSForm.Group className="mb-3">
                          <BSForm.Label>
                            Maximum Overnight Permits Per Unit (Monthly)
                          </BSForm.Label>
                          <Field
                            type="number"
                            name="max_permits_of_unit_monthly"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="max_permits_of_unit_monthly"
                            component="div"
                            className="text-danger fs--1"
                          />
                        </BSForm.Group>
                      </Col>
                    </Row>
                    <BSForm.Group className="my-3">
                      <BSForm.Label>
                        Overnight Parking Enforcement Time
                      </BSForm.Label>
                      <Row className="mb-3 p-2">
                        <Col md={6}>
                          <label>Start Time</label>
                          <br />
                          {/* <Field
                            type="text"
                            name="parking_enforcement_start_time"
                            className="form-control"
                            placeholder="HH : MM"
                          /> */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              // className="bg-danger"
                              reduceAnimations
                              sx={{ width: "100%" }}
                              label="Controlled picker"
                              value={values.parking_enforcement_start_time}
                              name="parking_enforcement_start_time"
                              onChange={(newValue) =>
                                setFieldValue(
                                  "parking_enforcement_start_time",
                                  newValue.$d
                                )
                              }
                            />
                          </LocalizationProvider>
                          <ErrorMessage
                            name="parking_enforcement_start_time"
                            component={"div"}
                            className="text-danger fs--1"
                          />
                          {/* <div className="d-flex justify-content-end mt-3">
                            <label className="mx-3 ">
                              <span className="h6">AM</span>{" "}
                              <Field
                                type="radio"
                                className="form-control-input"
                                name="parking_enforcement_start_timezone"
                                value="AM"
                              />
                            </label>
                            <label className="mx-3">
                              {" "}
                              <span className="h6">PM</span>{" "}
                              <Field
                                type="radio"
                                className="form-control-input"
                                name="parking_enforcement_start_timezone"
                                value="PM"
                              />
                            </label>
                          </div>
                          <div className="text-end">
                            <ErrorMessage
                              name="parking_enforcement_start_timezone"
                              component={"div"}
                              className="text-danger fs--1"
                            />
                          </div> */}
                        </Col>
                        <Col md={6}>
                          <label>End Time</label>
                          <br />
                          {/* <Field
                            type="text"
                            name="parking_enforcement_end_time"
                            className="form-control"
                            placeholder="HH : MM"
                          /> */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Controlled picker"
                              value={values.parking_enforcement_end_time}
                              name="parking_enforcement_end_time"
                              sx={{ width: "100%" }}
                              onChange={(newValue) =>
                                setFieldValue(
                                  "parking_enforcement_end_time",
                                  newValue.$d
                                )
                              }
                            />
                          </LocalizationProvider>
                          <ErrorMessage
                            name="parking_enforcement_end_time"
                            component={"div"}
                            className="text-danger fs--1"
                          />
                          {/* <div className="d-flex justify-content-end mt-3">
                            <label className="mx-3 ">
                              <span className="h6">AM</span>{" "}
                              <Field
                                type="radio"
                                className="form-control-input"
                                name="parking_enforcement_end_timezone"
                                value="AM"
                              />
                            </label>
                            <label className="mx-3">
                              {" "}
                              <span className="h6">PM</span>{" "}
                              <Field
                                type="radio"
                                className="form-control-input"
                                name="parking_enforcement_end_timezone"
                                value="PM"
                              />
                            </label>
                          </div>
                          <div className="text-end">
                            <ErrorMessage
                              name="parking_enforcement_end_timezone"
                              component={"div"}
                              className="text-danger fs--1"
                            />
                          </div> */}
                        </Col>
                      </Row>
                    </BSForm.Group>

                    <Row>
                      <Col md={12}>
                        <BSForm.Group className="mb-3">
                          <BSForm.Label>
                            Total Visitor Parking Spots
                          </BSForm.Label>
                          <Field
                            type="number"
                            name="total_parking_spots"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="total_parking_spots"
                            component="div"
                            className="text-danger fs--1"
                          />
                        </BSForm.Group>
                      </Col>
                      {/* <Col md={6}>
                        <BSForm.Group className="mb-3">
                          <BSForm.Label>
                            Maximum Consecutive Days Reserve Limit
                          </BSForm.Label>
                          <Field
                            type="number"
                            name="max_consecutive_reserve_limit"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="max_consecutive_reserve_limit"
                            component="div"
                            className="text-danger fs--1"
                          />
                        </BSForm.Group>
                      </Col> */}
                    </Row>
                    <br />
                    <div className="text-end">
                      <Button type="submit" disabled={isSubmitting}>
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {payload.loadType === "New Parking Permit" && (
            <VisitorWizard
              progressBar
              handleNewPermitByOwner={handleNewPermitByOwner}
              payload={payload}
              VisitorParkingAdditionalSettings={
                VisitorParkingAdditionalSettings
              }
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )}
          {payload.loadType === "Parking Permit Details" && (
            <Card className="shadow-none">
              <Card.Body className=" px-0">
                <Row className="align-items-center text-center mb-3">
                  <Col sm={6} className="text-sm-start">
                    {propertyLogo?.property_logo &&
                      propertyLogo.logoType !== "link" && (
                        <img
                          src={propertyLogo.property_logo}
                          alt="property-logo"
                          width={130}
                        />
                      )}
                  </Col>
                  <Col className="text-sm-end mt-3 mt-sm-0">
                    {/* <h2 className="mb-3">Invoice</h2> */}
                    <h5>
                      {payload.userData.role === "Site-Administrators" ||
                      payload.userData.role === "Condozak-Basic-Users"
                        ? startCase(payload?.property_name) ||
                          "property name here"
                        : ""}
                      {payload.userData.role === "unit_owner"
                        ? startCase(payload.userData?.property_name) ||
                          "property name here"
                        : ""}
                    </h5>
                    <p className="fs--1 mb-0">
                      {property_address?.address}
                      <br />
                      {`${property_address?.city}, ${property_address?.state}, ${property_address?.country}.`}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <hr />
                  </Col>
                </Row>
                <Table bordered className="">
                  <tbody>
                    <tr>
                      <td style={{ width: "40%" }}>Vehicle Permit</td>
                      <td className="fw-bold">{payload._id?.slice(0, 8)}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Make</td>
                      <td className="fw-bold">{payload.vehicle_make}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Model</td>
                      <td className="fw-bold">{payload.vehicle_model}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Color</td>
                      <td className="fw-bold">{payload.vehicle_color}</td>
                    </tr>
                    <tr>
                      <td>License Plate #</td>
                      <td className="fw-bold">{payload.license_plate}</td>
                    </tr>
                    <tr>
                      <td>License Plate Country</td>
                      <td className="fw-bold">
                        {payload.license_plate_country}
                      </td>
                    </tr>
                    <tr>
                      <td>License Plate State</td>
                      <td className="fw-bold">{payload.license_plate_state}</td>
                    </tr>
                    <tr>
                      <td>Start Date</td>
                      <td className="fw-bold">
                        {formatLogInfo(payload.start_date, true)}
                      </td>
                    </tr>
                    <tr>
                      <td>End Date</td>
                      <td className="fw-bold">
                        {formatLogInfo(payload.end_date, true)}
                      </td>
                    </tr>
                    <tr>
                      <td> Confirmation Email</td>
                      <td className="fw-bold">
                        {payload.send_confirmation_mail}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <p className="text-end fw-bold pb-0">
                    {p_id.property_manager.firstname}{" "}
                    {p_id.property_manager.lastname}
                  </p>
                  <p className="text-end pt-0">
                    Date Created : {formatLogInfo(payload.createdAt, true)}
                  </p>
                </div>
              </Card.Body>
            </Card>
          )}
          {payload.loadType === "Generate Report" && (
            // {/* <div ref={reportRef} style={{minHeight:'90vh'}}> */}
            <div>
              <div>
                <div className="">
                  <Row className="align-items-center text-center mb-3">
                    <Col sm={6} className="text-sm-start">
                      {propertyLogo?.property_logo &&
                        propertyLogo.logoType !== "link" && (
                          <img
                            src={propertyLogo.property_logo}
                            alt="property-logo"
                            width={130}
                          />
                        )}
                    </Col>
                    <Col className="text-sm-end mt-3 mt-sm-0">
                      {/* <h2 className="mb-3">Invoice</h2> */}
                      <h5>
                        {payload.userData.role === "Site-Administrators" ||
                        payload.userData.role === "Condozak-Basic-Users"
                          ? startCase(payload?.property_name) ||
                            "property name here"
                          : ""}
                        {payload.userData.role === "unit_owner"
                          ? startCase(payload.userData?.property_name) ||
                            "property name here"
                          : ""}
                      </h5>
                      <p className="fs--1 mb-0">
                        {property_address?.address}
                        <br />
                        {`${property_address?.city}, ${property_address?.state}, ${property_address?.country}.`}
                      </p>
                    </Col>
                    <Col xs={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Table bordered responsive>
                    <thead>
                      <tr className="text-center">
                        <th>Permit #</th>
                        <th>Vehicle Make</th>
                        <th>Vehicle Model</th>
                        <th>Vehicle Color</th>
                        <th>License Plate #</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportDateData?.map((v) => (
                        <tr key={v._id}>
                          <td>{v?._id?.slice(0, 8)}</td>
                          <td>{v.vehicle_make}</td>
                          <td>{v.vehicle_model}</td>
                          <td>{v.vehicle_color}</td>
                          <td>{v.license_plate}</td>
                          <td>{formatLogInfo(v.start_date, true)}</td>
                          <td>{formatLogInfo(v.end_date, true)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div>
                    <p className="text-end fw-bold pb-0">
                      {payload.userData.firstname} {payload.userData.lastname}
                    </p>
                    <p className="text-end pt-0">
                      Date Created : {formatLogInfo(new Date(), true)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
