import React from "react";

import { Form as BSForm, Button, Row, Col, Image } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { toast } from "react-toastify";

import { AuthWizardContext } from "context/Context";
import { CloudUpload } from "@mui/icons-material";

const VisitorParkingVehicleDetails = ({
  from = "",
  event = {},
  updateEvent,
  VisitorParkingAdditionalSettings,
}) => {
  const { parking_setting_time } = VisitorParkingAdditionalSettings;

  let start_time_obj = new Date(parking_setting_time.start_time);
  let start_hours = start_time_obj.getHours();
  let start_mins = start_time_obj.getMinutes();
  if (start_mins < 10) start_mins = "0" + start_mins;
  let start_hours_tmp = start_hours % 12 || 12;
  let start_ampm = "";
  if (start_hours_tmp < 12) {
    start_hours_tmp = "0" + start_hours_tmp;
    start_ampm = "AM";
  } else {
    start_ampm = "PM";
  }
  let start_time =
    start_hours_tmp + ":" + start_mins.toString() + " " + start_ampm;

  let end_time_obj = new Date(parking_setting_time.end_time);
  let end_hours = end_time_obj.getHours();
  let end_mins = end_time_obj.getMinutes();
  if (end_mins < 10) end_mins = "0" + end_mins;
  let end_hours_tmp = end_hours % 12 || 12;
  let end_ampm = "";
  if (end_hours_tmp < 12) {
    end_hours_tmp = "0" + end_hours_tmp;
    end_ampm = "AM";
  } else {
    end_ampm = "PM";
  }
  let end_time = end_hours_tmp + ":" + end_mins.toString() + " " + end_ampm;

  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const handleSubmit = (data) => {
    console.log(data);
    setUser(Object.assign({}, user, data));
    setStep(step + 1);
  };

  return (
    <div>
      <Formik
        initialValues={{
          vehicle_make: user.vehicle_make || "",
          vehicle_model: user.vehicle_model || "",
          vehicle_color: user.vehicle_color || "",
          vehicle_license_plate: user.vehicle_license_plate || "",
          vehicle_license_plate_country:
            user.vehicle_license_plate_country || "",
          vehicle_license_plate_state: user.vehicle_license_plate_state || "",
        }}
        validationSchema={Yup.object({
          vehicle_make: Yup.string().required("this option is required!"),
          vehicle_model: Yup.string().required("this option is required!"),
          vehicle_color: Yup.string().required("this option is required!"),
          vehicle_license_plate: Yup.string().required(
            "this option is required!"
          ),
          vehicle_license_plate_country: Yup.string().required(
            "this option is required!"
          ),
          vehicle_license_plate_state: Yup.string().required(
            "this option is required!"
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <p>
              Note: Reserve your visitor parking permit if your visitor will be
              staying overnight. Vehicles will be monitored between{" "}
              {start_time || "1:00 AM"} and {end_time || "5:00 AM"}
            </p>
            <Row>
              <Col md={6}>
                <BSForm.Group className="mb-3">
                  <BSForm.Label>Vehicle Make</BSForm.Label>
                  <Field
                    component="select"
                    id="vehicle_make"
                    name="vehicle_make"
                    className="form-select"
                  >
                    <option value="">Select</option>

                    {"Abarth,Acura,Alfa Romeo,Aston Martin,Audi,Bentley,BMW,Buick,Cadillac,Chevrolet,Chrysler,Citroen,Dacia,Dodge,Ferrari,Fiat,Ford,GMC,Honda,Hummer,Hyundai,Infiniti,Isuzu,Jaguar,Jeep,Kia,Lamborghini,Lancia,Land Rover,Lexus,Lincoln,Lotus,Maserati,Mazda,Mercedes-Benz,Mercury,Mini,Mitsubishi,Nissan,Opel,Peugeot,Pontiac,Porsche,Ram,Renault,Saab,Saturn,Scion,Seat,Skoda,Smart,SsangYong,Subaru,Suzuki,Tesla,Toyota,Volkswagen,Volvo,Wiesmann,Other"
                      ?.split(",")
                      ?.map((v) => (
                        <option value={v} key={v}>
                          {v}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="vehicle_make"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
              <Col md={6}>
                <BSForm.Group className="mb-3">
                  <BSForm.Label>Vehicle Model</BSForm.Label>
                  <Field
                    name="vehicle_model"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="vehicle_model"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                {" "}
                <BSForm.Group className="mb-3">
                  <BSForm.Label>Vehicle Color</BSForm.Label>
                  <Field
                    name="vehicle_color"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="vehicle_color"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
              <Col md={6}>
                <BSForm.Group className="mb-3">
                  <BSForm.Label>License Plate #</BSForm.Label>
                  <Field
                    name="vehicle_license_plate"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="vehicle_license_plate"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
            </Row>

            <BSForm.Group className="mb-3">
              <BSForm.Label>License Plate Country</BSForm.Label>
              <Field
                component="select"
                id="vehicle_license_plate_country"
                name="vehicle_license_plate_country"
                className="form-select"
              >
                <option value="">Select</option>

                {["Canada", "United States"]?.map((v) => (
                  <option value={v} key={v}>
                    {v}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="vehicle_license_plate_country"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-3">
              <BSForm.Label>License Plate State</BSForm.Label>
              <Field
                component="select"
                id="vehicle_license_plate_state"
                name="vehicle_license_plate_state"
                className="form-select"
              >
                <option value="">Select</option>

                {values.vehicle_license_plate_country === "Canada" &&
                  [
                    "Alberta",
                    "British Columbia",
                    "Manitoba",
                    "New Brunswick",
                    "Newfoundland and Labrador",
                    "Northwest Territories",
                    "Nova Scotia",
                    "Nunavut",
                    "Ontario",
                    "Prince Edward Island",
                    "Quebec",
                    "Saskatchewan",
                    "Yukon Territory",
                  ]?.map((v) => (
                    <option value={v} key={v}>
                      {v}
                    </option>
                  ))}
                {values.vehicle_license_plate_country === "United States" &&
                  [
                    "Alabama",
                    "Alaska",
                    "Arizona",
                    "Arkansas",
                    "California",
                    "Colorado",
                    "Connecticut",
                    "Delaware",
                    "Florida",
                    "Georgia",
                    "Hawaii",
                    "Idaho",
                    "Illinois",
                    "Indiana",
                    "Iowa",
                    "Kansas",
                    "Kentucky",
                    "Louisiana",
                    "Maine",
                    "Maryland",
                    "Massachusetts",
                    "Michigan",
                    "Minnesota",
                    "Mississippi",
                    "Missouri",
                    "Montana",
                    "Nebraska",
                    "Nevada",
                    "New Hampshire",
                    "New Jersey",
                    "New Mexico",
                    "New York",
                    "North Carolina",
                    "North Dakota",
                    "Ohio",
                    "Oklahoma",
                    "Oregon",
                    "Pennsylvania",
                    "Rhode Island",
                    "South Carolina",
                    "South Dakota",
                    "Tennessee",
                    "Texas",
                    "Utah",
                    "Vermont",
                    "Virginia",
                    "Washington",
                    "West Virginia",
                    "Wisconsin",
                    "Wyoming",
                  ]?.map((v) => (
                    <option value={v} key={v}>
                      {v}
                    </option>
                  ))}
              </Field>
              <ErrorMessage
                name="vehicle_license_plate_state"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <br />
            <div className="text-end">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VisitorParkingVehicleDetails;
