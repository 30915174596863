import React from "react";
import numberToWords from "number-to-words";
import {
  CloseButton,
  Col,
  Modal,
  Row,
  // Dropdown,
  Button,
  Image,
  Nav,
  Form as BSForm,
} from "react-bootstrap";

import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
import ModalMediaContent from "./ModalMediaContent";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { formatLogInfo, getFileType } from "helpers/utils";
import { Avatar } from "@mui/material";
import axios from "axios";
// import Cookies from "universal-cookie";
import {
  // Add,
  Check,
  Clear,
  Done,
  DoneOutline,
  // Info,
  InfoOutlined,
  MessageOutlined,
  // Sort,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { startCase } from "lodash";

// import Loader from "components/common/Loader";

export default function VoilationTrackingModel({
  show,
  handleClose,
  payload,
  // setPayload,
  getAllData,
  fromSingleUnit = false,
  unit_number_of_single_unit,
  unit_number_of_single_unit_id,
  userData,
  hostname,
  jwtToken,
}) {
  const [loader, setLoader] = React.useState(false);

  const [violationsTypeList, setViolationsTypeList] = React.useState([]);
  const [unitsList, setUnitsList] = React.useState([]);

  const [editFieldComment, setEditFieldComment] = React.useState({
    text: "",
    shown: false,
    show_id: "",
    defaultText: "",
  });

  React.useEffect(() => {
    getUnitsListAndVoilationTypesData();
  }, [payload]);

  const getUnitsListAndVoilationTypesData = () => {
    axios
      .post(
        `${hostname}/api/property/GetViolationPreReqs`,
        {
          p_id: payload.property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        let Types = res.data.Data?.Settings;
        setViolationsTypeList(Types);

        // console.log(res.data);
        let Units = res.data.Data?.Units;
        if (!fromSingleUnit) {
          let arr = Units?.map((v) => {
            return { _id: v._id, unit_number: v.unit_number };
          });
          // console.log(arr);
          setUnitsList(arr);
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoader(false);
      });
  };

  // React.useEffect(() => {
  //   setLoader(true);
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 400);
  // }, [payload.comments]);

  const [showImgOverlay, setShowImgOverlay] = React.useState({
    shown: false,
    src: "",
  });

  // console.log(showImgOverlay);

  const createViolationDB = (values = {}) => {
    // setLoader(true);
    console.log(values);

    let reqData = {
      created_by_id: payload.userData.data,
      updated_by_id: payload.userData.data,
      p_id: payload.property_id,
      unit_id: !fromSingleUnit
        ? values.unit_number
        : unit_number_of_single_unit_id,
      violation_type: values.violation_type,
      subject: values.subject,
      description: values.description,
      violation_files: values.req_images,
    };

    console.log(reqData);
    // return;

    axios
      .post(
        `${hostname}/api/property/CreateViolation`,
        { ...reqData },
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData();
        // setLoader(false);
        handleClose();
        toast.success("Violation Added.", { theme: "colored" });
        // setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        // setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const updateServiceRequest = (
    values = {},
    mediaDeleteRequest = false,
    extra = {}
  ) => {
    // setLoader(true);
    console.log(values);

    // request_type,
    //   assigned_to_id, ////if manager want to change this and assign to some other employee Send his id in this
    //   other_request_type,
    //   subject,
    //   description,
    //   priority_level,
    //   unit_permission,
    //   updated_by,
    //   status,

    let reqData = {
      request_type: values.request_type,
      other_request_type: "",
      subject: values.subject,
      description: values.description,
      priority_level: values.priority_level,
      unit_permission: values.unit_permission,
      status: extra?.title,
      assigned_to_id: values.assigne?._id,

      // req_images: values.req_images?.filter((v) => v.type !== "link"),
    };
    // reqData.generated_by_id = userData.data;
    reqData.unit_id = payload?.unit_id;
    reqData.p_id = payload?.property_id;
    reqData.updated_by = userData.data;
    reqData.maintenance_id = payload?._id;
    reqData.deleted_link = values.deleted_link;

    if (!mediaDeleteRequest) {
      reqData.req_images = [];

      values.req_images?.forEach((v) => {
        if (v.type !== "link") {
          reqData.req_images?.push(v.file);
        }
      });
    }

    // else {
    //   reqData.deleted_link = [];

    //   values.req_images?.forEach((v) => {
    //     if (v.type === "link") {
    //       reqData.deleted_link?.push(v.file);
    //     }
    //   });
    // }

    console.log(reqData);
    // return;

    axios
      .post(
        `${hostname}/api/unit/UpdateMaintenance`,
        { ...reqData },
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        // if (values.comment !== "") {
        //   addComment(values.comment, "", reporter.data);
        //   return;
        getAllData(false);
        // setLoader(false);
        // handleClose();
        toast.info("Request Updated.", { theme: "colored" });
        // setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const updateViolationStage = (stageLevel = "") => {
    let obj = {};
    if (typeof stageLevel === "number") {
      let word = numberToWords.toWordsOrdinal(stageLevel);
      console.log("checking");
      stageLevel = word.charAt(0).toUpperCase() + word.slice(1) + " Notice";
    }

    if (stageLevel === "First Notice")
      obj.stage = { type: "primary", title: "First Notice" };
    if (stageLevel === "Second Notice")
      obj.stage = { type: "warning", title: "Second Notice" };
    if (stageLevel === "Third Notice")
      obj.stage = { type: "danger", title: "Third Notice" };
    if (
      stageLevel !== "First Notice" &&
      stageLevel !== "Second Notice" &&
      stageLevel !== "Third Notice" &&
      stageLevel !== "Resolved"
    )
      obj.stage = { type: "danger", title: stageLevel };
    if (stageLevel === "Resolved")
      obj.stage = { type: "success", title: "Resolved" };
    return obj;
  };

  const addComment = (values = {}, setFieldValue) => {
    let commentData = {
      text: values.comment,
      user_id: payload?.userData.data, ///loggedin user
      violation_id: payload?._id,
      comment_type: values.user_comment_type,
    };

    console.log(commentData);
    // return;

    axios
      .post(
        `${hostname}/api/property/AddViolationComment`,
        {
          ...commentData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // setFieldValue('');
        let updatedComments = res.data.data.comments?.map((v) => {
          let inObj = {};
          inObj.text = v.text;
          inObj.comment_id = v._id;
          inObj.user_id = v.user_id._id;
          inObj.firstname = v.user_id.firstname;
          inObj.lastname = v.user_id.lastname;
          inObj.bgColor = v.user_id.bgColor;
          inObj.time = formatLogInfo(v.updatedAt);
          inObj.isEdited = v.isEdited;
          inObj.comment_type = v.comment_type || "comment";
          return inObj;
        });

        setFieldValue("comment", "");

        if (commentData.comment_type !== "comment") {
          let stage = updateViolationStage(res.data.data.violation_stage);

          setFieldValue("stage", stage?.stage);
        }

        if (values.sortOrder === "asc")
          updatedComments.sort(dynamicSort("time"));
        if (values.sortOrder === "dsc")
          updatedComments.sort(dynamicSort("-time"));
        console.log(updatedComments);
        setFieldValue("comments", updatedComments);

        // getAllData(false);
        // setLoader(false);
        // handleClose();

        toast.success("Comment Added.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const updateComment = (comment = "", setFieldValue, values) => {
    let commentData = {
      text: editFieldComment.text,
      user_id: payload?.userData.data, ///loggedin user
      comment_id: comment.comment_id,
      ///loggedin user
    };

    console.log(commentData);

    axios
      .post(
        `${hostname}/api/property/UpdateViolationComment`,
        {
          ...commentData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // getAllData(false);
        // setFieldValue("comment", "");
        let updatedComments = res.data.data.comments?.map((v) => {
          let inObj = {};
          inObj.text = v.text;
          inObj.comment_id = v._id;
          inObj.user_id = v.user_id._id;
          inObj.firstname = v.user_id.firstname;
          inObj.lastname = v.user_id.lastname;
          inObj.bgColor = v.user_id.bgColor;
          inObj.time = formatLogInfo(v.updatedAt);
          inObj.isEdited = v.isEdited;
          inObj.comment_type = v.comment_type || "comment";

          return inObj;
        });

        if (values.sortOrder === "asc")
          updatedComments?.sort(dynamicSort("time"));
        if (values.sortOrder === "dsc")
          updatedComments?.sort(dynamicSort("-time"));
        console.log(updatedComments);
        setFieldValue("comments", updatedComments);
        setEditFieldComment({
          text: "",
          shown: false,
          show_id: "",
          defaultText: "",
        });
        // setLoader(false);
        // handleClose();
        toast.success("Comment Updated.", { theme: "colored" });
        // setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const deleteComment = (comment, setFieldValue, values) => {
    console.log(comment);
    axios
      .post(
        `${hostname}/api/property/DeleteViolationComment`,
        {
          // maintenance_id: payload?._id,
          comment_id: comment?.comment_id,
          user_id: payload?.userData.data, ///loggedin user
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let updatedComments = res.data.data.comments?.map((v) => {
          let inObj = {};
          inObj.text = v.text;
          inObj.comment_id = v._id;
          inObj.user_id = v.user_id._id;
          inObj.firstname = v.user_id.firstname;
          inObj.lastname = v.user_id.lastname;
          inObj.bgColor = v.user_id.bgColor;
          inObj.time = formatLogInfo(v.updatedAt);
          inObj.isEdited = v.isEdited;
          inObj.comment_type = v.comment_type || "comment";
          return inObj;
        });

        if (values.sortOrder === "asc")
          updatedComments.sort(dynamicSort("time"));
        if (values.sortOrder === "dsc")
          updatedComments.sort(dynamicSort("-time"));

        setFieldValue("comments", updatedComments);

        // getAllData(false);
        // setLoader(false);
        // handleClose();
        toast.warning("Comment Deleted.", { theme: "colored" });
        // setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handleFiles = (e, setFieldValue, values) => {
    console.log(e.target.files);
    let data = [...e.target.files];

    data = data?.map((file) => {
      let entry = {};
      entry.tmp_id = uuidv4();
      entry.mimeType = file.type;
      entry.type = file.type?.split("/")[1];
      entry.file = file;
      return entry;
    });
    console.log(data);
    setFieldValue("req_images", values.req_images?.concat(data));
    e.target.value = null;

    if (payload?.loadType === "View Request Owner") {
      let obj = { ...values };
      obj.req_images = values.req_images?.concat(data);
      console.log(obj);
      updateServiceRequest(obj);
    }

    // setFieldValue("req_images", data);
    // setFiles(files?.concat(data));
  };

  const handleRemoveFile = (e, setFieldValue, values, id) => {
    let data = [];
    let todelete = {};
    data = values.req_images?.filter((v) => v.tmp_id !== id);
    todelete = values.req_images?.filter((v) => v.tmp_id === id)[0];
    // setFiles(data);
    todelete = todelete.file;
    console.log(data);
    console.log(todelete);
    setFieldValue("req_images", data);
    let obj = { ...values };
    obj.deleted_link = todelete;
    obj.req_images = data;

    if (payload?.loadType === "View Request Owner")
      updateServiceRequest(obj, true);
  };

  const validate = (values) => {
    let errors = [];
    values.req_images?.forEach((v) => {
      console.log(v);
      let obj = {};
      if (v.type !== "image" && v.type !== "audio" && v.type !== "video") {
        obj.msg = "invalid file type!";
        obj.id = v.tmp_id;
        errors.push(obj);
      }
    });
    console.log(errors);
    return errors;
  };

  const handleFilesActions = (file, actionType = "view") => {
    console.log(file);

    if (actionType === "view") {
      console.log("working fine");
      if (
        file.mimetype === "image/png" ||
        file.mimetype === "image/jpg" ||
        file.mimetype === "image/jpeg"
      )
        setShowImgOverlay({ shown: true, src: file.data });
      else {
        window.open(file.data);
      }
    }

    if (actionType === "download") downloadFile(file);
    // window.open(file,"_blank");
  };

  function downloadFile(filePath) {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  }

  // if (loader)
  //   return (
  //     <Modal
  //       style={{ backdropFilter: "blur(3px)" }}
  //       show={show}
  //       // size={payload?.loadType === "Create Request" ? "md" : "lg"}
  //       size={"lg"}
  //       onHide={handleClose}
  //       contentClassName="border-0"
  //       dialogClassName=""
  //     >
  //       <Modal.Body style={{ minHeight: "100vh" }}>
  //         <Loader />
  //       </Modal.Body>
  //     </Modal>
  //   );

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const revertSortOrder = (comments = [], setFieldValue, sortOrder = "") => {
    let arr = [...comments];
    let res = [];

    if (sortOrder === "asc") {
      setFieldValue("sortOrder", sortOrder);
      arr.sort(dynamicSort("time"));
    }
    if (sortOrder === "dsc") {
      setFieldValue("sortOrder", sortOrder);
      arr.sort(dynamicSort("-time"));
    }

    console.log(arr);
    setFieldValue("comments", arr);
  };

  const handleChangeTypeAndAddDescription = (e, setFieldValue) => {
    setFieldValue("violation_type", e.target.value);
    let arr = violationsTypeList?.filter((v) => v._id === e.target.value);
    // console.log(arr);
    setFieldValue("description", arr[0]?.violation_description);
  };

  const getCommentIcon = (values = {}) => {
    const { user_comment_type } = values;
    if (user_comment_type === "comment") return "message";
    if (user_comment_type === "notice") return "info";
    if (user_comment_type === "resolve") return "check";
  };

  if (showImgOverlay.shown)
    return (
      <Modal
        style={{ backdropFilter: "blur(3px)" }}
        show={show}
        size={"xl"}
        onHide={handleClose}
        contentClassName="border-0"
        dialogClassName=""
      >
        <div>
          <div className="d-flex justify-content-end p-3">
            <CloseButton
              onClick={() => {
                setShowImgOverlay({ shown: false, src: "" });
              }}
            />
          </div>
          <br />
          <div className="d-flex justify-content-center p-3">
            <Image
              src={
                showImgOverlay.src instanceof File
                  ? URL.createObjectURL(showImgOverlay.src)
                  : showImgOverlay.src
              }
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </Modal>
    );

  return (
    <Modal
      //style={{ backdropFilter: "blur(3px)" }}
      show={show}
      size={"lg"}
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName=""
    >
      {payload?.loadType !== "Add New Violation" && (
        <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <CloseButton
            onClick={handleClose}
            className="btn btn-sm btn-circle d-flex flex-center transition-base"
          />
        </div>
      )}

      <Modal.Body className={"p-0"}>
        {payload?.loadType === "Add New Violation" && (
          <>
            <Formik
              initialValues={{
                violation_type: "",
                subject: "",
                description: "",
                unit_number: "",
                req_images: [],
                stage: "First Notice",
                createdAt: payload?.createdAt || new Date(),
                updatedAt: new Date(),
              }}
              validationSchema={Yup.object({
                violation_type: Yup.string("Enter valid value").required(
                  "Please select request type!"
                ),
                subject: Yup.string("Enter valid value!").required(
                  "Please enter subject!"
                ),
                description: Yup.string("Enter valid value"),
                unit_number: !fromSingleUnit
                  ? Yup.string().required("unit number is required!")
                  : "",
              })}
              onSubmit={(values, { setSubmitting }) => {
                createViolationDB(values);
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                values,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <div className="bg-light">
                    <div className="d-flex justify-content-between align-items-center px-3 pt-3 pb-0">
                      <h5>Add New Violation</h5>
                      <CloseButton
                        onClick={handleClose}
                        className="btn btn-sm btn-circle d-flex flex-center transition-base"
                      />
                    </div>
                    <br />
                  </div>
                  <div className="p-4">
                    <Row>
                      <Col lg={8}>
                        <ModalMediaContent
                          title="Violation Type"
                          icon="align-left"
                          isHr={false}
                        >
                          <BSForm.Group className="mb-3 mt-3">
                            <BSForm.Select
                              name="violation_type"
                              onChange={(e) =>
                                handleChangeTypeAndAddDescription(
                                  e,
                                  setFieldValue
                                )
                              }
                              onBlur={handleBlur}
                              value={values.violation_type}
                            >
                              <option value="">Select Type</option>

                              {violationsTypeList.map((v, i) => (
                                <option value={v._id} key={v._id}>
                                  {v.violation_type}
                                </option>
                              ))}
                            </BSForm.Select>
                            <ErrorMessage
                              name="violation_type"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>

                          <BSForm.Group className="mb-3">
                            <BSForm.Label>
                              Subject{" "}
                              {/* <span className="text-danger">*</span> */}
                            </BSForm.Label>
                            <Field
                              type="text"
                              name="subject"
                              placeholder="Add subject here"
                              className="me-3 form-control"
                            />
                            <ErrorMessage
                              name="subject"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>
                          <BSForm.Group className="mb-3">
                            <BSForm.Label>
                              Description
                              {/* <span className="text-danger">*</span> */}
                            </BSForm.Label>
                            <BSForm.Control
                              name="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              as={"textarea"}
                              rows={4}
                              value={values.description}
                              placeholder="Description here...."
                              style={{ width: "300px !important" }}
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>
                          {!fromSingleUnit && (
                            <BSForm.Group className="mb-3">
                              <BSForm.Label>
                                Unit #
                                {/* <span className="text-danger">*</span> */}
                              </BSForm.Label>
                              <Field
                                component="select"
                                id="unit_number"
                                name="unit_number"
                                className="form-select"
                              >
                                <option value="">Select</option>
                                {unitsList.map((v, i) => (
                                  <option value={v._id} key={v._id}>
                                    {v.unit_number}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="unit_number"
                                component="div"
                                className="text-danger m-2 fs--1"
                              />
                            </BSForm.Group>
                          )}
                          <div style={{ marginBottom: "50px" }}></div>
                        </ModalMediaContent>

                        <ModalMediaContent
                          title="Attachments"
                          icon="paperclip"
                          headingClass="d-flex justify-content-between"
                          headingContent={
                            <label className="btn btn-white shadow-sm cursor-pointer ">
                              <input
                                type="file"
                                name="req_images"
                                hidden
                                onChange={(e) =>
                                  handleFiles(e, setFieldValue, values)
                                }
                                multiple
                              />
                              <FontAwesomeIcon icon="plus" />
                            </label>
                          }
                        >
                          {values?.req_images?.length < 1 && (
                            <div>
                              <br />
                              <p className="text-info fs--1">
                                No Attachments Added.
                              </p>
                              {/* <br /> */}
                            </div>
                          )}
                          {values?.req_images?.map((file, index) => (
                            <div className="d-flex mb-3" key={index * 2 + "kk"}>
                              {/* <Image
                                src={URL.createObjectURL(file.file)}
                                // src={file}
                                className="shadow-sm"
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  backgroundPosition: "center",
                                  marginBottom: "10px",
                                  marginRight: "20px",
                                  borderRadius: "5px",
                                }} 
                              />
                              */}
                              <div style={{ width: "150px", height: "100px" }}>
                                {getFileType(file)}
                              </div>
                              <span style={{ fontSize: "12px" }}>
                                <br />

                                <div className="mb-1">
                                  <span className="text-primary">
                                    Attachment # {index + 1}
                                    {/* {file.name} */}
                                  </span>
                                </div>

                                <div className="mb-1">
                                  {/* <span
                                    className="cursor-pointer"
                                    onClick={(e) =>
                                      handleFilesActions(file.file, "view")
                                    }
                                  >
                                    View
                                  </span>
                                  <span> | </span> */}
                                  <span
                                    // style={{letterSpacing:'2px'}}
                                    className="cursor-pointer text-danger"
                                    onClick={(e) =>
                                      handleRemoveFile(
                                        e,
                                        setFieldValue,
                                        values,
                                        file.tmp_id
                                      )
                                    }
                                  >
                                    Remove
                                  </span>
                                </div>
                                <div>
                                  uploaded at {formatLogInfo(new Date(), true)}
                                </div>
                              </span>
                            </div>
                          ))}
                        </ModalMediaContent>
                      </Col>
                      <Col lg={4}>
                        <>
                          <div className="">
                            <h6 className="mt-5 mt-lg-0">Violation Stage</h6>

                            <div
                              className={`mt-3 text-seconadry badge badge-soft-${"primary"}`}
                              style={{ fontSize: "14px" }}
                            >
                              {values.stage || "value "}
                            </div>
                          </div>

                          <div style={{ marginTop: "30px" }}></div>

                          <div style={{ marginTop: "40px" }}></div>
                          <h6 className="mt-lg-0">Created At</h6>
                          <Nav className="flex-lg-column fs--1">
                            <Nav.Item className="me-2 me-lg-0">
                              <Nav.Link
                                as={Link}
                                to="#!"
                                className="nav-link-card-details cursor-df "
                              >
                                <div>{formatLogInfo(values?.createdAt)}</div>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <div style={{ marginTop: "30px" }}></div>
                          {fromSingleUnit && (
                            <>
                              <h6 className="mt-lg-0">Unit number</h6>
                              <Nav className="flex-lg-column fs--1">
                                <Nav.Item className="me-2 me-lg-0">
                                  <Nav.Link
                                    as={Link}
                                    to="#!"
                                    className="nav-link-card-details cursor-df "
                                  >
                                    <div>{unit_number_of_single_unit}</div>
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                              <div style={{ marginTop: "30px" }}></div>
                            </>
                          )}
                        </>
                      </Col>
                    </Row>
                    <br />
                    <div className="text-end">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {payload?.loadType === "View Violation" && (
          <Formik
            initialValues={{
              violation_type: payload.violation_type,
              subject: payload?.subject || "",
              description: payload?.description || "",
              req_images: payload.attachments || [],
              stage: payload.stage,
              comment: "",
              comments: payload?.comments || [],
              createdAt: payload?.createdAt || new Date(),
              updatedAt: payload?.updatedAt || new Date(),
              sortOrder: "asc",
              user_comment_type: "comment", // resolve | notice,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            {({
              isSubmitting,
              values,
              setFieldValue,
              handleChange,
              handleBlur,
            }) => (
              <Form>
                <div className="bg-light rounded-top-lg px-4 py-3">
                  <h4 className={`mb-1`}>
                    {values?.subject || "Add a new illustration to the owner"}
                  </h4>
                </div>
                <div className="p-4">
                  <Row>
                    <Col lg={8}>
                      <ModalMediaContent title="Description" icon="align-left">
                        <p className="text-word-break fs--1">
                          {values?.description ||
                            "The illustration must match to the contrast of ."}
                        </p>
                      </ModalMediaContent>

                      <ModalMediaContent
                        title="Attachments"
                        icon="paperclip"
                        headingClass="d-flex justify-content-between"
                      >
                        {values?.req_images?.length < 1 && (
                          <div>
                            <br />
                            <p className="text-info fs--1">
                              No Attachments Added.
                            </p>
                            {/* <br /> */}
                          </div>
                        )}
                        {values?.req_images?.map((file, index) => (
                          <div className="d-flex mb-1" key={index * 2 + "kk"}>
                            {/* <Image
                              
                              src={file.data}
                              className="shadow-sm"
                              style={{
                                width: "150px",
                                backgroundPosition: "center",
                                marginBottom: "10px",
                                marginRight: "20px",
                                borderRadius: "5px",
                              }}
                            /> */}
                            <div style={{ width: "150px", height: "100px" }}>
                              {getFileType(file)}
                            </div>
                            <span style={{ fontSize: "12px" }}>
                              <br />

                              <div className="mb-1">
                                <span className="text-primary">
                                  Attachment # {index + 1}
                                  {/* {file.name} */}
                                </span>
                              </div>

                              <div className="mb-1">
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleFilesActions(file, "view");
                                  }}
                                >
                                  Enlarge
                                </span>
                                {/* <span> | </span> */}
                                {/* <span
                                  className="cursor-pointer"
                                  onClick={(e) =>
                                    handleRemoveFile(
                                      e,
                                      setFieldValue,
                                      values,
                                      file.tmp_id
                                    )
                                  }
                                >
                                  Remove
                                </span> */}
                              </div>
                              <div>uploaded at {values.createdAt}</div>
                            </span>
                          </div>
                        ))}
                      </ModalMediaContent>

                      <ModalMediaContent
                        title="Comments"
                        customContentIcons
                        icon={getCommentIcon(values)}
                        headingClass="mb-3 d-flex justify-content-between"
                        headingContent={
                          <div className="d-flex">
                            {values.sortOrder === "asc" && (
                              <>
                                <span>Oldest first </span>{" "}
                                <Button
                                  variant="falcon-primary"
                                  className="ms-2"
                                  size="sm"
                                  onClick={() =>
                                    revertSortOrder(
                                      values.comments,
                                      setFieldValue,
                                      "dsc"
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-down-wide-short" />
                                </Button>
                              </>
                            )}
                            {values.sortOrder === "dsc" && (
                              <>
                                <span>Newest first </span>{" "}
                                <Button
                                  variant="falcon-primary"
                                  className="ms-2"
                                  size="sm"
                                  onClick={() =>
                                    revertSortOrder(
                                      values.comments,
                                      setFieldValue,
                                      "asc"
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-up-wide-short" />
                                </Button>
                              </>
                            )}
                          </div>
                        }
                      >
                        <>
                          {values.stage.title !== "Resolved" && (
                            <Flex>
                              <Avatar
                                sx={{
                                  height: "28px",
                                  width: "28px",
                                  fontSize: "12px",
                                  marginRight: "14px",
                                  bgcolor:
                                    payload?.userData?.bgColor || "blueviolet",
                                }}
                              >
                                {startCase(payload?.userData?.firstname[0]) ||
                                  "N"}
                                {startCase(payload?.userData?.lastname[0]) ||
                                  "H"}
                              </Avatar>
                              <div className="flex-1 fs--1">
                                <div className="position-relative border rounded mb-3">
                                  <>
                                    <BSForm.Control
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="comment"
                                      as="textarea"
                                      value={values.comment}
                                      className="border-0 rounded-bottom-0 resize-none"
                                      rows={3}
                                    />
                                    <Flex
                                      justifyContent="end"
                                      alignItems="center"
                                      className="bg-light rounded-bottom p-2 mt-1"
                                    >
                                      <BSForm.Select
                                        size="sm"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.user_comment_type}
                                        name="user_comment_type"
                                      >
                                        <option value={"comment"}>
                                          Save as Comment
                                        </option>
                                        <option value={"notice"}>
                                          Send Notice
                                        </option>
                                        <option value={"resolve"}>
                                          Save and Resolve
                                        </option>
                                      </BSForm.Select>
                                      <Button
                                        className="ms-3"
                                        size="sm"
                                        color="primary"
                                        type="submit"
                                        disabled={!values.comment}
                                        onClick={() =>
                                          addComment(values, setFieldValue)
                                        }
                                      >
                                        Save
                                      </Button>
                                    </Flex>
                                  </>
                                </div>
                              </div>
                            </Flex>
                          )}

                          {values?.comments?.map((comment) => (
                            <Flex key={comment.comment_id} className="mb-3">
                              <div className="me-2">
                                {comment.comment_type === "comment" && (
                                  <MessageOutlined
                                    fontSize="medium"
                                    className="text-primary"
                                  />
                                )}
                                {comment.comment_type === "notice" && (
                                  <InfoOutlined
                                    fontSize="medium"
                                    className="text-primary"
                                  />
                                )}
                                {comment.comment_type === "resolve" && (
                                  <Done
                                    fontSize="medium"
                                    className="text-primary"
                                  />
                                )}
                              </div>

                              <Link to="#!">
                                <Avatar
                                  sx={{
                                    height: "28px",
                                    width: "28px",
                                    fontSize: "12px",
                                    marginRight: "5px",
                                    bgcolor: comment?.bgColor || "blueviolet",
                                  }}
                                >
                                  {startCase(comment?.firstname[0]) || "N"}
                                  {startCase(
                                    comment?.lastname !== null
                                      ? comment?.lastname[0]
                                      : ""
                                  ) || "H"}
                                </Avatar>
                              </Link>
                              {comment.comment_id ===
                                editFieldComment.show_id && (
                                <div className="">
                                  <BSForm.Control
                                    as={"textarea"}
                                    rows={4}
                                    cols={50}
                                    // name="e"
                                    value={editFieldComment.text}
                                    onChange={(e) =>
                                      setEditFieldComment({
                                        ...editFieldComment,
                                        text: e.target.value,
                                      })
                                    }
                                    className="me-3 mb-2 form-control"
                                  />
                                  <div className="d-flex mb-3">
                                    <Button
                                      variant="falcon-default"
                                      size="sm"
                                      onClick={() => {
                                        updateComment(
                                          comment,
                                          setFieldValue,
                                          values
                                        );
                                      }}
                                    >
                                      <Check fontSize="small" />
                                    </Button>
                                    <Button
                                      variant="falcon-default"
                                      size="sm"
                                      className="ms-2"
                                      onClick={() =>
                                        setEditFieldComment({
                                          ...editFieldComment,
                                          shown: false,
                                          show_id: "",
                                        })
                                      }
                                    >
                                      <Clear fontSize="small" />
                                    </Button>
                                  </div>
                                </div>
                              )}
                              {comment.comment_id !==
                                editFieldComment.show_id && (
                                <div className="flex-1 ms-2 fs--1">
                                  <p className="mb-1 bg-200 rounded-3 p-2">
                                    <Link to="#!" className="fw-semi-bold">
                                      {comment?.firstname}
                                    </Link>{" "}
                                    {comment?.text}
                                  </p>
                                  {payload?.userData?.data ===
                                    comment.user_id &&
                                    comment.comment_type === "comment" && (
                                      <>
                                        <Link
                                          to="#!"
                                          onClick={() =>
                                            // updateComment(comment, setFieldValue)
                                            setEditFieldComment({
                                              ...editFieldComment,
                                              shown: true,
                                              show_id: comment.comment_id,
                                              text: comment.text,
                                              defaultText: comment.text,
                                            })
                                          }
                                        >
                                          {" "}
                                          Edit{" "}
                                        </Link>{" "}
                                        &bull;
                                        <Link
                                          to="#!"
                                          onClick={() =>
                                            deleteComment(
                                              comment,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                        >
                                          {" "}
                                          Delete{" "}
                                        </Link>{" "}
                                        &bull; {/* {comment.time} */}
                                      </>
                                    )}
                                  <span
                                    className="fs--1"
                                    // to="#!"
                                    // onClick={() =>
                                    //   deleteComment(comment, setFieldValue)
                                    // }
                                  >
                                    {" "}
                                    {comment?.time}{" "}
                                    {comment?.isEdited ? "(edited)" : ""}
                                  </span>{" "}
                                </div>
                              )}
                            </Flex>
                          ))}
                        </>
                      </ModalMediaContent>
                    </Col>
                    <Col lg={4}>
                      <>
                        <div className="">
                          <h6 className="mt-5 mb-3 mt-lg-0">Violation Stage</h6>

                          <div
                            className={` px-3 py-2 text-seconadry badge badge-soft-${
                              values?.stage?.type || "warning"
                            }`}
                            style={{ fontSize: "14px" }}
                          >
                            {values?.stage?.title || "value "}
                          </div>
                        </div>

                        <br />
                        {fromSingleUnit && (
                          <>
                            <h6 className="mt-lg-0">Unit number</h6>
                            <Nav className="flex-lg-column fs--1">
                              <Nav.Item className="me-2 me-lg-0">
                                <Nav.Link
                                  as={Link}
                                  to="#!"
                                  className="nav-link-card-details cursor-df "
                                >
                                  <div>{unit_number_of_single_unit}</div>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <div style={{ marginTop: "30px" }}></div>
                          </>
                        )}

                        <h6 className="mt-5 mt-lg-0">Created At</h6>
                        <Nav className="flex-lg-column fs--1">
                          <Nav.Item className="me-2 me-lg-0">
                            <Nav.Link
                              as={Link}
                              to="#!"
                              className="nav-link-card-details cursor-df "
                            >
                              <div>{values?.createdAt}</div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <br />

                        <h6 className="mt-5 mt-lg-0">Updated At</h6>
                        <Nav className="flex-lg-column fs--1">
                          <Nav.Item className="me-2 me-lg-0">
                            <Nav.Link
                              as={Link}
                              to="#!"
                              className="nav-link-card-details cursor-df "
                            >
                              <div>{formatLogInfo(values?.updatedAt)}</div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
