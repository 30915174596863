import React from "react";

import { Card, Col, Row, Badge } from "react-bootstrap";

// import ProfileUserTable from "./ProfileUserTable";
import StudentInfo from "./StudentInfo";
// import UserGroups from "./UserGroups";
import UserGroups from "components/pages/UnitOnwer&Tennent/UsersInfo/userDetails/UserGroups";
import ProfileUserTable from "components/pages/UnitOnwer&Tennent/UsersInfo/userDetails/ProfileUserTable";

import FalconCloseButton from "components/common/FalconCloseButton";

import UsersManagementModel from "components/pages/sharedUI/UsersManagementModel";

const UserDetails = ({
  handleCloseUserDetailTab,
  currentUser,
  addUserToGroup,
  showChangeGroupDetailModal,
  handleCloseChangeGroupDetailModal,
  userData,
  userForGroup,
  getUsersData,
  payload,
  setPayload,
  eConsentData,
  hostname,
  jwtToken,
}) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="">
      <Row className="pe-lg-0 mb-3">
        <Col md={12}>
          <Card>
            <Card.Body className="d-flex justify-content-between">
              <h5>
                {`${currentUser.firstname}  ${currentUser.lastname}`}
                {currentUser.role.type === "bg-custom" && (
                  <span className="ms-2 custom-badge">
                    {currentUser.role.title}
                  </span>
                )}
                {currentUser.role.type !== "bg-custom" && (
                  <Badge
                    className={`ms-2 bg-${currentUser.role.type} fw-light`}
                  >
                    {currentUser.role.title}
                  </Badge>
                )}
              </h5>
              <FalconCloseButton onClick={handleCloseUserDetailTab} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="">
        <Col md={8} className="pe-lg-0 mb-3">
          <StudentInfo currentUser={currentUser} />
        </Col>
        <Col md={4}>
          <ProfileUserTable currentUser={currentUser} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <UserGroups
            currentUser={currentUser}
            addUserToGroup={addUserToGroup}
            eConsentData={eConsentData}
            
          />
        </Col>
      </Row>

      <UsersManagementModel
        show={showChangeGroupDetailModal}
        handleClose={handleCloseChangeGroupDetailModal}
        userData={userData}
        getUsersData={getUsersData}
        payload={payload}
        setPayload={setPayload}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    </div>
  );
};

export default UserDetails;
