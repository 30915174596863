import React from "react";
import { Button, Image } from "react-bootstrap";

export default function PreviewFileComp({ file, handleClose }) {
  console.log(file);

  if (
    file?.mimetype === "image/png" ||
    file?.mimetype === "image/jpg" ||
    file?.mimetype === "image/jpeg"
  )
    return (
      <div>
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "#000",
            zIndex: 10000,
            backdropFilter: "blur(3px)",
          }}
        >
          <div className="text-end me-3 p-3">
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={file.data} fluid rounded />
          </div>
        </div>
      </div>
    );
  if (
    file?.mimetype === "application/pdf" ||
    file?.mimetype === "application/x-zip-compressed" ||
    file?.mimetype === "text/plain" ||
    file?.mimetype === "application/octect-stream" ||
    file?.mimetype ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    file?.mimetype === "folder"
  )
    return (
      <div>
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "#000",
            zIndex: 10000,
            backdropFilter: "blur(3px)",
            overflowY: "scroll",
          }}
        >
          <div className="text-end me-3 p-3">
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <object
              data={file.data}
              style={{ height: "100vh", width: "100vh",backgroundColor:'silver' }}
            ></object>
          </div>
        </div>
      </div>
    );
}
