import React, { useContext, useState } from "react";

import { Card, Nav, ProgressBar } from "react-bootstrap";

import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useForm } from "react-hook-form";

import MailDetailsPage from "./MailDetailsPage";

import Success from "./Success";

import EmailCompose from "./EmailCompose";

import AppContext, { AuthWizardContext } from "context/Context";

import WizardModal from "./WizardModal";
import Flex from "components/common/Flex";
import LetterCompose from "./LetterCompose";
import SendMail from "./SendMail";

const WizardLayout = ({
  variant,
  validation,
  progressBar,
  handleClose,
  getAllData,
  setModelSize,
  userData,
  mailOutState,
  setMailOutState,
  mailOutErrors,
  setMailOutErrors,
  handleFieldValueChange,
  sendDataToDB,
  payload,
  setPayload,
  hostname,
  jwtToken,
}) => {
  // loader
  const [loader, setLoader] = useState(false);
  const [showLetterPage, setShowLetterPage] = React.useState(
    payload.draft_letter || false
  );

  const { isRTL } = useContext(AppContext);
  console.log(isRTL);
  const { user, step, setStep } = useContext(AuthWizardContext);
  console.log(user);
  console.log(step);

  const {
    // register,
    // handleSubmit,
    // formState,
    // watch,
    // setValue,
    reset,
    clearErrors,
  } = useForm();

  const [modal, setModal] = useState(false);

  let navItems = [
    {
      icon: "book",
      label: "Mail Details",
    },
    {
      icon: "clock",
      label: "Email Compose",
    },

    {
      icon: "envelope",
      label: "Send Mail",
    },
  ];

  if (showLetterPage)
    navItems = [
      {
        icon: "book",
        label: "Mail Details",
      },
      {
        icon: "clock",
        label: "Email Compose",
      },
      {
        icon: "book",
        label: "Draft Letter",
      },
      {
        icon: "envelope",
        label: "Send Mail",
      },
      // {
      //   icon: "thumbs-up",
      //   label: "Done",
      // },
    ];

  const toggle = () => setModal(!modal);

  const handleNavs = (targetStep) => {
    if (mailOutState.step !== 4) {
      if (targetStep < mailOutState.step) {
        if (mailOutState.step == 3 && targetStep == 2) {
          setMailOutState({ ...mailOutState, step: targetStep - 1 });
        } else {
          setMailOutState({ ...mailOutState, step: targetStep });
        }
      } else {
        // handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        // as={Form}
        // noValidate
        // onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard"
      >
        <Card.Header
          className={classNames("bg-light", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === "pills"
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={mailOutState.step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={mailOutState.step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && (
          <ProgressBar now={mailOutState.step * 20} style={{ height: 2 }} />
        )}
        <Card.Body className="fw-normal py-4">
          {mailOutState.step === 1 && (
            <MailDetailsPage
              setModelSize={setModelSize}
              mailOutState={mailOutState}
              setMailOutState={setMailOutState}
              mailOutErrors={mailOutErrors}
              setMailOutErrors={setMailOutErrors}
              handleFieldValueChange={handleFieldValueChange}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )}
          {mailOutState.step === 2 && (
            <EmailCompose
              setShowLetterPage={setShowLetterPage}
              setModelSize={setModelSize}
              userData={userData}
              mailOutState={mailOutState}
              setMailOutState={setMailOutState}
              mailOutErrors={mailOutErrors}
              setMailOutErrors={setMailOutErrors}
              handleFieldValueChange={handleFieldValueChange}
              payload={payload}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )}
          {mailOutState.step === 3 && (
            <LetterCompose
              setModelSize={setModelSize}
              userData={userData}
              mailOutState={mailOutState}
              setMailOutState={setMailOutState}
              mailOutErrors={mailOutErrors}
              setMailOutErrors={setMailOutErrors}
              handleFieldValueChange={handleFieldValueChange}
              payload={payload}
              setPayload={setPayload}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )}
          {mailOutState.step === 4 && (
            <SendMail
              handleClose={handleClose}
              getAllData={getAllData}
              setModelSize={setModelSize}
              userData={userData}
              mailOutState={mailOutState}
              setMailOutState={setMailOutState}
              mailOutErrors={mailOutErrors}
              setMailOutErrors={setMailOutErrors}
              handleFieldValueChange={handleFieldValueChange}
              sendDataToDB={sendDataToDB}
              payload={payload}
            />
          )}
          {mailOutState.step === 5 && (
            <Success
              reset={reset}
              handleClose={handleClose}
              getAllData={getAllData}
              mailOutState={mailOutState}
              setMailOutState={setMailOutState}
              mailOutErrors={mailOutErrors}
              setMailOutErrors={setMailOutErrors}
              handleFieldValueChange={handleFieldValueChange}
            />
          )}
        </Card.Body>

        {/* <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              if (step == 3) {
                setStep(step - 2);
              } else {
                setStep(step - 1);
              }
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            {loader ? (
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            ) : (
              'Next'
            )}
          </IconButton>
        </Card.Footer> */}
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: index < 5 ? step > index : step > 4,
          active: step === index,
        })}
        // onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: step > index,
          active: step === index,
        })}
        // onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

export default WizardLayout;
