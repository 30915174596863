import React, { Fragment } from "react";

import classNames from "classnames";

import { Nav, Navbar, Row, Col } from "react-bootstrap";

import { navbarBreakPoint, topNavbarBreakpoint } from "../../../config";
import Flex from "../../../components/common/Flex";
import AppContext from "../../../context/Context";

import NavbarVerticalMenu from "./NavbarVerticalMenu";
import NavbarTopDropDownMenus from "../../../components/navbar/top/NavbarTopDropDownMenus";
import bgNavbar from "../../../assets/img/generic/bg-navbar.png";
import "./NavbarVertical.css";

// import Cookies from "universal-cookie";
import { capitalize } from "../../../helpers/utils";

import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import { startCase, words } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PieChartIcon from "@mui/icons-material/PieChart";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import BookIcon from "@mui/icons-material/Book";
// import CampaignIcon from "@mui/icons-material/Campaign";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import EmailIcon from "@mui/icons-material/Email";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CommuteIcon from "@mui/icons-material/Commute";
import PetsIcon from "@mui/icons-material/Pets";
import HistoryIcon from "@mui/icons-material/History";
import LogoBottom from "../../../components/common/LogoBottom";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import TableRowsIcon from "@mui/icons-material/TableRows";
import PaidIcon from "@mui/icons-material/Paid";
import PriceChangeIcon from "@mui/icons-material/PriceChange";

import { useTranslation } from "react-i18next";
import { RoomService } from "@mui/icons-material";
// import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const NavbarVertical = ({ userData = {}, redirect_link = "/login" }) => {
  const { t } = useTranslation();

  // const userData = useAuthUser();

  const formatCompanyName = (p_name) => {
    let n = words(p_name);
    // console.log(n.length);
    if (n.length > 2) {
      p_name = `${n[0]} ${n[1]}...`;
      // return p_name
    }
    return startCase(p_name);
  };

  const navigate = useNavigate();
  const rlocation = useLocation();

  const { property_id, property_name, unit_id, unit_number } = useParams();
  // const cookies = new Cookies();
  // const userData = cookies.get("userData");

  // console.log(userData);
  if (userData === undefined) navigate(redirect_link);

  const getPath = (oldLink) => {
    let link = oldLink;
    if (userData !== undefined) {
      if (userData.role === "unit_owner") {
        link = `${oldLink}/${userData.UnitsOwner[0]._id}/${userData.UnitsOwner[0].unit_number}`;
      }
    }
    return link;
  };

  let dashboardRoutes = {
    label: "Dashboard",
    labelDisable: true,
    children: [
      {
        name: t("dashboardRoutes.dashboard"),
        active: true,
        // icon: 'chart-pie',
        icon: <PieChartIcon fontSize="small" />,
        to: "/dashboard",
      },
    ],
  };

  let dashboardOwnerRoutes = {
    label: "Dashboard",
    labelDisable: true,
    children: [
      {
        name: t("dashboardRoutes.dashboard"),
        active: true,
        // icon: 'chart-pie',
        icon: <PieChartIcon fontSize="small" />,
        to: getPath("/dashboard"),
      },
    ],
  };

  let managementRoutes = {
    label: t("managementRoutes.label"),
    children: [
      {
        name: t("managementRoutes.company_profile"),
        icon: <ApartmentIcon fontSize="small" />,
        to: `admin/management/company/${userData.compid}/profile`,
        active: true,
      },
      {
        name: t("managementRoutes.users"),
        // icon: 'user',
        icon: <GroupIcon fontSize="small" />,
        to: "admin/management/users",
        active: true,
      },
      {
        name: t("managementRoutes.groups"),
        icon: <GroupsIcon fontSize="small" />,
        to: "admin/management/groups",
        active: true,
      },
      {
        name: t("managementRoutes.billing"),
        icon: <CreditScoreIcon fontSize="small" />,
        to: "admin/management/billing",
        active: true,
      },
    ],
  };

  let propertyRoutesMain = {
    label: t("propertyRoutesMain.label"),
    children: [
      {
        name: t("propertyRoutesMain.properties"),
        icon: <ApartmentIcon fontSize="small" />,
        to: "/property/management/buildings",
        active: true,
      },
      {
        name: t("propertyRoutesMain.resident_directory"),
        icon: <AdminPanelSettingsIcon fontSize="small" />,
        // to: `/${property_id}/${property_name}/owner-directory`,
        to: `/owner-directory`,
        active: true,
      },
    ],
  };

  let propertyRoutes = {
    label: t("propertyRoutes.label"),
    children: [
      {
        name: t("propertyRoutes.unit_list"),
        icon: <LocalConvenienceStoreIcon fontSize="small" />,
        to: `/property/management/buildings/${property_id}/${property_name}/units`,
        active: true,
      },
      {
        name: t("propertyRoutes.profile"),
        icon: <ApartmentIcon fontSize="small" />,
        to: `/property/management/buildings/${property_id}/${property_name}/profile`,
        active: true,
      },
      {
        name: t("propertyRoutes.financials"),
        icon: <LocalAtmIcon fontSize="small" />,
        to: `/property/management/buildings/${property_id}/${property_name}/financials`,
        active: true,
      },

      {
        name: t("propertyRoutes.budget"),
        icon: <PriceChangeIcon fontSize="small" />,
        to: `/property/management/buildings/${property_id}/${property_name}/budget`,
        active: true,
      },
      {
        name: "Invoices",
        icon: <CurrencyExchangeIcon fontSize="small" />,
        to: `/property/management/buildings/${property_id}/${property_name}/invoices`,
        active: true,
      },
      {
        name: "Payment Processor",
        icon: <CreditScoreIcon fontSize="small" />,
        to: `/property/management/buildings/${property_id}/${property_name}/payment-processor`,
        active: true,
      },
      {
        name: "Payments",
        icon: <ReceiptLongIcon fontSize="small" />,
        to: `/property/management/buildings/${property_id}/${property_name}/payments`,
        active: true,
      },
    ],
  };

  let featuresRoutes = {
    label: "features",
    children: [
      {
        name: "Amenities",
        icon: <TableRowsIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/amenities`,
        active: true,
      },
      {
        name: "Amenity Bookings",
        icon: <BookIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/amenity-bookings`,
        active: true,
      },
      // {
      //   name: "Announcements",
      //   icon: <CampaignIcon fontSize="small" />,
      //   to: `/property/features/${property_id}/${property_name}/annuncements`,
      //   active: true,
      // },
      {
        name: "Bulletin Board",
        icon: <NewspaperIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/bulletins`,
        active: true,
      },
      /*{
        name: 'Complaints',
        icon: <ErrorIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/complaints`,
        active: true
      },*/
      {
        name: "Maintenance Requests",
        icon: <EngineeringIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/service-requests`,
        active: true,
      },
      {
        name: "Events Calender",
        icon: <CalendarMonthIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/events-calender`,
        active: true,
        // children: [
        //   {
        //     name: 'Events List',
        //     icon: <CalendarMonthIcon fontSize="small" />,
        //     to: `/property/features/${property_id}/${property_name}/events-calender`,
        //     active: true
        //   },
        //   {
        //     name: 'Create Event',
        //     icon: <CalendarMonthIcon fontSize="small" />,
        //     to: `/property/features/${property_id}/${property_name}/events-add`,
        //     active: true
        //   }
        // ]
      },
      {
        name: "Document Drive",
        icon: <LocalLibraryIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/document-drive`,
        active: true,
      },

      {
        name: "Mail-Outs",
        icon: <EmailIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/mail-outs`,
        active: true,
      },
      // {
      //   name: 'Online Payments',
      //   icon: <PaymentsIcon fontSize="small" />,
      //   to: `/property/features/${property_id}/${property_name}/online-payments`,
      //   active: true
      // },
      // {
      //   name: 'Owner Directory',
      //   icon: <AdminPanelSettingsIcon fontSize="small" />,
      //   to: `/property/features/${property_id}/${property_name}/owner-directory`,
      //   active: true
      // },
      {
        name: "Violations Tracking",
        icon: <TrackChangesIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/violation-tracking`,
        active: true,
      },
      {
        name: "Visitor Management",
        icon: <ManageAccountsIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/visitor-management`,
        active: true,
      },
      {
        name: "Visitor Parking",
        icon: <LocalParkingIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/visitor-parking-management`,
        active: true,
      },
      // {
      //   name: "Integrations",
      //   icon: <AccountTreeIcon fontSize="small" />,
      //   to: `/property/features/${property_id}/${property_name}/accounts-integrations`,
      //   active: true,
      // },
      {
        active: true,
      },
      /*{
        name: 'Maintaince Tracking',
        icon: <EngineeringIcon fontSize="small" />,
        to: `/property/features/${property_id}/${property_name}/maintaince-tracking`,
        active: true
      }*/
      // {
      //   name: 'Portfolio Management',
      //   icon: <ContactMailIcon fontSize="small" />,
      //   to: `/property/features/${property_id}/${property_name}/portfolio-management`,
      //   active: true
      // }
    ],
  };

  let unitRoutes = {
    label: t("unitRoutes.label"),
    children: [
      // {
      //   name: t("unitRoutes.unit_details"),
      //   icon: <MapsHomeWorkIcon fontSize="small" />,
      //   to: `/${property_id}/${property_name}/unit/${unit_id}/details`,
      //   active: true,
      // },
      {
        name: "Unit Details",
        icon: <MapsHomeWorkIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/attached-units`,
        active: true,
      },
      {
        name: t("unitRoutes.residents"),
        icon: <SupervisedUserCircleIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`,
        active: true,
      },
      {
        name: "Vehicles",
        icon: <CommuteIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/vehicle-info`,
        active: true,
      },
      {
        name: "Pets",
        icon: <PetsIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/pet-info`,
        active: true,
      },
      {
        name: "Violations Tracking",
        icon: <TrackChangesIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/violations-tracking`,
        active: true,
      },
      {
        name: "Invoices",
        icon: <CurrencyExchangeIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/unit-invoices`,
        active: true,
      },
      {
        name: "Payments",
        icon: <PriceChangeIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/unit-payments`,
        active: true,
      },
      {
        name: "Amenities",
        icon: <BookIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/unit-booked-amenities`,
        active: true,
      },
      {
        name: "Maintenance Requests",
        icon: <EngineeringIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/unit-maintenance-requests`,
        active: true,
      },
      {
        name: "Visitors",
        icon: <TrackChangesIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/unit-visitor-management`,
        active: true,
      },
      {
        name: "Visitor Parking",
        icon: <LocalParkingIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/unit-visitor-parking`,
        active: true,
      },
      {
        name: t("unitRoutes.unit_history"),
        icon: <HistoryIcon fontSize="small" />,
        to: `/${property_id}/${property_name}/${unit_number}/${unit_id}/history`,
        active: true,
      },
    ],
  };

  let unitOwnerTennentRoutes = {
    label: t("unitRoutes.label"),
    children: [
      {
        name: t("unitRoutes.unit_details"),
        icon: <MapsHomeWorkIcon fontSize="small" />,
        to: getPath("/owner-portal/unit-details"),
        active: true,
      },
      {
        name: t("unitRoutes.residents"),
        icon: <SupervisedUserCircleIcon fontSize="small" />,
        to: getPath("/owner-portal/residents-detail"),
        active: true,
      },

      {
        name: "Vehicles",
        icon: <CommuteIcon fontSize="small" />,
        // to: `/${property_id}/${property_name}/unit/${unit_id}/vehicle-info`,
        to: getPath("/owner-portal/vehicle-details"),
        // to: "/owner-portal/vehicle-details",
        active: true,
      },
      {
        name: "Pets",
        icon: <PetsIcon fontSize="small" />,
        // to: `/${property_id}/${property_name}/unit/${unit_id}/pet-info`,
        to: getPath("/owner-portal/pet-details"),
        active: true,
      },
      {
        name: t("unitOwnerTennentRoutes.my_bookings"),
        icon: <BookIcon fontSize="small" />,
        // to: `/${property_id}/${property_name}/unit/${unit_id}/pet-info`,
        to: "/owner-portal/my-bookings",
        active: true,
      },
      {
        name: t("unitOwnerTennentRoutes.my_invoices"),
        icon: <PaidIcon fontSize="small" />,
        // to: `/${property_id}/${property_name}/unit/${unit_id}/pet-info`,
        to: getPath("/owner-portal/my-invoices"),
        active: true,
      },
      {
        name: t("unitOwnerTennentRoutes.payment_history"),
        icon: <HistoryIcon fontSize="small" />,
        to: "/owner-portal/payments-history",
        // to: `/${property_id}/${property_name}/unit/${unit_id}/history`,
        active: true,
      },
    ],
  };

  let ownerPortalFeaturesRoutes = {
    label: "features",
    children: [
      {
        name: "Inbox",
        icon: <EmailIcon fontSize="small" />,
        to: `/owner-portal/inbox`,
        active: true,
      },
      {
        name: "Amenities",
        icon: <RoomService fontSize="small" />,
        to: getPath(`/owner-portal/amenities`),
        active: true,
      },
      // {
      //   name: "Announcements",
      //   icon: <CampaignIcon fontSize="small" />,
      //   to: `/owner-portal/annuncements`,
      //   active: true,
      // },
      {
        name: "Bulletin Board",
        icon: <NewspaperIcon fontSize="small" />,
        to: `/owner-portal/bulletin-board`,
        active: true,
      },
      {
        name: "Maintenance Requests",
        icon: <EngineeringIcon fontSize="small" />,
        to: getPath(`/owner-portal/service-requests`),
        active: true,
      },
      // {
      //   name: 'Complaints',
      //   icon: <ErrorIcon fontSize="small" />,
      //   to: `/owner-portal/complaints`,
      //   active: true
      // },
      {
        name: "Events Calendar",
        icon: <CalendarMonthIcon fontSize="small" />,
        to: `/owner-portal/events-calender`,
        active: true,
      },
      // {
      //   name: "Document Drive",
      //   icon: <LocalLibraryIcon fontSize="small" />,
      //   to: `/owner-portal/file-library`,
      //   active: true,
      // },
      // {
      //   name: 'Mail-Outs',
      //   icon: <EmailIcon fontSize="small" />,
      //   to: `/owner-portal/mail-outs`,
      //   active: true
      // },
      // {
      //   name: 'Online Payments',
      //   icon: <PaymentsIcon fontSize="small" />,
      //   to: `/owner-portal/online-payments`,
      //   active: true
      // },
      // {
      //   name: "Resident Directory",
      //   icon: <AdminPanelSettingsIcon fontSize="small" />,
      //   to: `/owner-portal/owner-directory`,
      //   active: true,
      // },
      // {
      //   name: "Violations Tracking",
      //   icon: <TrackChangesIcon fontSize="small" />,
      //   to: `/owner-portal/violation-tracking`,
      //   active: true,
      // },
      {
        name: "Visitors",
        icon: <ManageAccountsIcon fontSize="small" />,
        to: `/owner-portal/visitors`,
        active: true,
      },
      {
        name: "Visitor Parking",
        icon: <LocalParkingIcon fontSize="small" />,
        to: `/owner-portal/visitor-parking-management`,
        active: true,
      },
      // {
      //   name: 'Account Integration',
      //   icon: <AccountTreeIcon fontSize="small" />,
      //   to: `/owner-portal/account-integration`,
      //   active: true
      // },
      // {
      //   name: 'Integrations',
      //   icon: <AccountTreeIcon fontSize="small" />,
      //   to: `/owner-portal/accounts-integrations`,
      //   active: true
      // },
      {
        // name: 'Integrations',
        // icon: <AccountTreeIcon fontSize="small" />,
        // to: `/owner-portal/accounts-integrations`,
        active: true,
      },
      // {
      //   name: 'Maintaince Tracking',
      //   icon: <EngineeringIcon fontSize="small" />,
      //   to: `/owner-portal/maintaince-tracking`,
      //   active: true
      // }
      // {
      //   name: 'Portfolio Management',
      //   icon: <ContactMailIcon fontSize="small" />,
      //   to: `/owner-portal/portfolio-management`,
      //   active: true
      // }
    ],
  };

  let customRoutes = [];

  // console.log(userData.role);

  // company routes mgmt

  if (userData.role === "No role Assigned") {
    // console.log("invited company user");
    customRoutes = [dashboardRoutes];
  }
  if (userData.role === "Condozak-Basic-Users") {
    customRoutes = [dashboardRoutes, propertyRoutesMain];
  }
  if (userData.role === "Site-Administrators") {
    customRoutes = [dashboardRoutes, managementRoutes, propertyRoutesMain];
  }

  // for sidebar update property
  if (
    rlocation.pathname.includes("/property/management/buildings/") ||
    window.location.pathname.includes("/property/management/buildings/") ||
    rlocation.pathname.includes("/property/features") ||
    window.location.pathname.includes("/property/features")
  ) {
    if (userData.role === "Site-Administrators")
      customRoutes = [propertyRoutes, featuresRoutes];
    if (userData.role === "Condozak-Basic-Users")
      customRoutes = [propertyRoutes, featuresRoutes];
  }
  // sidebar update unit section
  if (
    rlocation.pathname.includes(`/${unit_number}/${unit_id}`) ||
    window.location.pathname.includes(`/${unit_number}/${unit_id}`)
  ) {
    if (userData.role === "Site-Administrators") {
      customRoutes = [unitRoutes];
    }
    if (userData.role === "Condozak-Basic-Users") {
      customRoutes = [unitRoutes];
    }
  }

  // owners mgmt

  if (userData.role === "none") {
    customRoutes = [dashboardOwnerRoutes];
  }
  if (userData.role === "unit_owner") {
    customRoutes = [
      dashboardOwnerRoutes,
      unitOwnerTennentRoutes,
      ownerPortalFeaturesRoutes,
    ];
  }
  if (userData.role === "sup_owner") {
    customRoutes = [
      dashboardOwnerRoutes,
      unitOwnerTennentRoutes,
      ownerPortalFeaturesRoutes,
    ];
  }
  if (userData.role === "primary_tenant") {
    customRoutes = [
      dashboardOwnerRoutes,
      unitOwnerTennentRoutes,
      ownerPortalFeaturesRoutes,
    ];
  }
  if (userData.role === "sup_tanant") {
    customRoutes = [
      dashboardOwnerRoutes,
      unitOwnerTennentRoutes,
      ownerPortalFeaturesRoutes,
    ];
  }

  const {
    config: {
      // isFluid,
      // isRTL,
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
    setConfig,
  } = React.useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  const handleClick = () => {
    document
      .getElementsByTagName("html")[0]
      .classList.toggle("navbar-vertical-collapsed");
    setConfig("isNavbarVerticalCollapsed", !isNavbarVerticalCollapsed);
  };

  React.useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  const [onEnter, setOnEnter] = React.useState(false);

  let time = null;
  const handleMouseEnter = () => {
    setTimeout(() => {
      setOnEnter(true);
    }, 300);
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    setOnEnter(false);
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }) => {
     //console.log(label);
    return (
      <Nav.Item as="li">
        <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
          <Col
            xs="auto"
            className="navbar-vertical-label navbar-vertical-label"
          >
            {label}
          </Col>
          <Col className="ps-0">
            <hr className="mb-0 navbar-vertical-divider"></hr>
          </Col>
        </Row>
      </Nav.Item>
    );
  };

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical togglebtn-parent", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant="light"
    >
      <Flex alignItems="center" className="">
        {/* <ToggleButton /> */}
        <LogoBottom at="navbar-vertical" width={"175px"} />
        {/* <Image src={cz_5} fluid width={'200px'}/> */}
      </Flex>

      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="position-relative overflow-visible"
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
          // maxHeight : '100vh'
        }}
      >
        <span
          onClick={handleClick}
          id="toggleNavigationTooltip"
          className={`togglebtn d-flex align-items-center justify-content-center navbar-vertical-toggle ${
            isNavbarVerticalCollapsed && "opacity-1"
          }`}
        >
          <FontAwesomeIcon
            icon={isNavbarVerticalCollapsed ? "chevron-right" : "chevron-left"}
          />
        </span>

        <div className="navbar-vertical-content scrollbar">
          {(userData.role === "Site-Administrators" ||
            userData.role === "Condozak-Basic-Users") &&
            (rlocation.pathname.includes("/property/management/buildings/") ||
              window.location.pathname.includes(
                "/property/management/buildings/"
              ) ||
              rlocation.pathname.includes("/property/features") ||
              window.location.pathname.includes("/property/features")) && (
              <div>
                <h5 className="text-primary py-3 pb-3 show-transition">
                  {isNavbarVerticalCollapsed ? (
                    onEnter ? (
                      formatCompanyName(property_name)
                    ) : (
                      <span className="propertyNameCustom">
                        {property_name[0]}
                      </span>
                    )
                  ) : (
                    formatCompanyName(startCase(property_name))
                  )}
                </h5>
                <span>
                  <FontAwesomeIcon
                    icon="arrow-left"
                    onClick={() => navigate("/property/management/buildings")}
                    className="cursor-pointer bk-to-prts"
                  />
                </span>
                {isNavbarVerticalCollapsed ? (
                  onEnter ? (
                    <Link
                      className="btn btn-link btn-sm text-dark"
                      // size="sm"
                      to="/property/management/buildings"
                    >
                      Back to Properties
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  <Link
                    className="btn btn-link btn-sm text-dark"
                    // size="sm"
                    to="/property/management/buildings"
                  >
                    Back to Properties
                  </Link>
                )}
              </div>
            )}
          {(userData.role === "Site-Administrators" ||
            userData.role === "Condozak-Basic-Users") &&
            (rlocation.pathname.includes(`/${unit_number}/${unit_id}`) ||
              window.location.pathname.includes(
                `/${unit_number}/${unit_id}`
              )) && (
              <div>
                <h5 className="text-primary py-3 pb-3 show-transition">
                  {isNavbarVerticalCollapsed ? (
                    onEnter ? (
                      formatCompanyName(property_name)
                    ) : (
                      <span className="propertyNameCustom">
                        {property_name[0]}
                      </span>
                    )
                  ) : (
                    formatCompanyName(property_name)
                  )}
                </h5>
                <span>
                  <FontAwesomeIcon
                    icon="arrow-left"
                    onClick={() =>
                      navigate(
                        `/property/management/buildings/${property_id}/${property_name}/units`
                      )
                    }
                    className="cursor-pointer bk-to-prts"
                  />
                </span>
                {isNavbarVerticalCollapsed ? (
                  onEnter ? (
                    <Link
                      className="btn btn-link btn-sm text-dark"
                      // size="sm"
                      to={`/property/management/buildings/${property_id}/${property_name}/units`}
                    >
                      Back to Units list
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  <Link
                    className="btn btn-link btn-sm text-dark"
                    // size="sm"
                    to={`/property/management/buildings/${property_id}/${property_name}/units`}
                  >
                    Back to Unit List
                  </Link>
                )}
              </div>
            )}

          <Nav className="flex-column" as="ul">
            {customRoutes.map((route) => (
              <Fragment key={route.label}>
                {!route.labelDisable && (
                  <NavbarLabel label={capitalize(route.label)} />
                )}
                <NavbarVerticalMenu
                  routes={route.children}
                  userData={userData}
                />
              </Fragment>
            ))}
          </Nav>

          <>
            {navbarPosition === "combo" && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
