import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './AccountsIntegrations.css';
import OnlinePayment from './OnlinePayment';
import PackageMgmt from './PackageMgmt';
import AccountIntegration from './AccountIntegration';
import PaymentsIcon from '@mui/icons-material/Payments';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

let integrationRoutes = [
    {
        label : 'Online Payments',
        key : 'online_payments',
        icon : <PaymentsIcon fontSize='large'/>
    },
    {
        label : 'Package Management',
        key : 'package_management',
        icon : <AllInboxIcon fontSize='large' />
    },
    {
        label : 'Account Integration',
        key : 'account_integration',
        icon : <AccountTreeIcon fontSize='large' />
    }
]

export default function AccountsIntegrations() {

    
  const [selectedTab, setSelectedTab] = React.useState(null);
  const handleSelectTab = tab => setSelectedTab(tab);

  if (selectedTab === 'Online Payments') {
    return <OnlinePayment handleSelectTab={handleSelectTab} />;
  }
  if (selectedTab === 'Package Management') {
    return <PackageMgmt handleSelectTab={handleSelectTab} />;
  }
  if (selectedTab === 'Account Integration') {
    return <AccountIntegration handleSelectTab={handleSelectTab} />;
  }
  return (
    <div className="p-5 bg-white" style={{ height: '93vh' }}>
      <Row>
        {integrationRoutes?.map(
          (v, i) => (
            <Col
              md={6}
              key={v.key + i}
              className="px-3 pb-4"
              onClick={() => handleSelectTab(v.label)}
            >
              <Card style={{ height: '200px' }} className="bg-light">
                {/* <FalconCardHeader title={v} /> */}
                <Card.Body className="heading-box d-flex flex-column align-items-center justify-content-center">
                    <span>{v.icon}</span>
                  <h3 className="text-secondary mt-3">{v.label}</h3>
                  {/* <p className="text-secondary">{selectedTab}</p> */}
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </Row>
    </div>
  );
}
