import React from 'react';
import { Modal, Form, Table } from 'react-bootstrap';
// import { Offcanvas, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import Wizard from './wizardRemastered/Wizard';
import FalconCloseButton from '../../../../components/common/FalconCloseButton';
// import axios from 'axios';
// import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SoftBadge from '../../../../components/common/SoftBadge';

export default function AddressDetailsModal(props) {
  // const [validated, setValidated] = useState(false);
  // const [formData, setFormData] = useState({
  //   property_id: '',
  //   property_name: '',
  //   property_url: '',
  //   phone: '',
  //   address: '',
  //   c_id: props.userData.compid
  // });
  // const [field, setField] = useState('');
  // const handleSubmit = event => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     event.preventDefault();
  //     console.log(formData);
  //     setValidated(true);
  //     // return;
  //     axios
  //       .post('http://localhost:5000/api/property/propertyregister', {
  //         ...formData
  //       })
  //       .then(res => {
  //         console.log(res.data);
  //         toast.success(
  //           <span className="text-white">Registration Successful!</span>
  //         );
  //         props.getAllPropertiesData(props.userData.compid);
  //         props.handleClose();
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         props.getAllPropertiesData(props.userData.compid);
  //         toast.error(<span className="text-white">Already registrated!</span>);
  //       });
  //   }
  // };

  // const handleChange = e => {
  //   let data = { ...formData };
  //   data[e.target.name] = e.target.value;
  //   setFormData(data);
  // };

  

  let addressData = [
    {
      unit_number: '101',
      user: 'Joe Biden',
      group: 'Owners (On-Site)',
      email: 'joebiden@demo.info',
      status: { title: 'Sent', type: 'success', icon: 'check' }
    },
    {
      unit_number: '103',
      user: 'Donald Thompson',
      group: 'Owners (On-Site)',
      email: 'donald@demo.info',
      status: { title: 'Pending', type: 'info', icon: 'redo' }
    },
    {
      unit_number: '152',
      user: 'Amenda Clock',
      group: 'Owners (On-Site)',
      email: 'amendaclark@demo.info',
      status: { title: 'Not Sent', type: 'danger', icon: 'ban' }
    },
    {
      unit_number: '104',
      user: 'Joe Biden',
      group: 'Owners (On-Site)',
      email: 'joebiden@demo.info',
      status: { title: 'Sent', type: 'success', icon: 'check' }
    },
    {
      unit_number: '101',
      user: 'Donald Thompson',
      group: 'Owners (On-Site)',
      email: 'donald@demo.info',
      status: { title: 'Pending', type: 'info', icon: 'redo' }
    },
    {
      unit_number: '152',
      user: 'Amenda Clock',
      group: 'Owners (On-Site)',
      email: 'amendaclark@demo.info',
      status: { title: 'Not Sent', type: 'danger', icon: 'ban' }
    },
    {
      unit_number: '108',
      user: 'Joe Biden',
      group: 'Owners (On-Site)',
      email: 'joebiden@demo.info',
      status: { title: 'Sent', type: 'success', icon: 'check' }
    },
    {
      unit_number: '101',
      user: 'Donald Thompson',
      group: 'Owners (On-Site)',
      email: 'donald@demo.info',
      status: { title: 'Pending', type: 'info', icon: 'redo' }
    },
    {
      unit_number: '152',
      user: 'Amenda Clock',
      group: 'Owners (On-Site)',
      email: 'amendaclark@demo.info',
      status: { title: 'Not Sent', type: 'danger', icon: 'ban' }
    },
    {
      unit_number: '101',
      user: 'Joe Biden',
      group: 'Owners (On-Site)',
      email: 'joebiden@demo.info',
      status: { title: 'Sent', type: 'success', icon: 'check' }
    },
    {
      unit_number: '101',
      user: 'Donald Thompson',
      group: 'Owners (On-Site)',
      email: 'donald@demo.info',
      status: { title: 'Pending', type: 'info', icon: 'redo' }
    },
    {
      unit_number: '152',
      user: 'Amenda Clock',
      group: 'Owners (On-Site)',
      email: 'amendaclark@demo.info',
      status: { title: 'Not Sent', type: 'danger', icon: 'ban' }
    }
  ];

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header>
        <div className='d-flex justify-content-between align-items-start' style={{'width':'100%'}}>
          <Form.Group className="mb-3">
            <div className="input-group">
              <span className="input-group-text bg-white" id="basic-addon1">
                <FontAwesomeIcon icon={faSearch} color="grey" />
              </span>
              <input
                type="text"
                name="searchv"
                //onKeyUp={handleSearchBar}
                className="form-control"
                placeholder="Search by id, name ..."
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </Form.Group>
        <FalconCloseButton onClick={props.handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Table className="text-center" bordered hover striped>
          <thead>
            <tr>
              <th>Unit Number</th>
              <th>User</th>
              <th>Group</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {addressData?.map((v, i) => (
              <tr key={i + '09'}>
                <td>{v.unit_number}</td>
                <td>{v.user}</td>
                <td>{v.group}</td>
                <td>{v.email}</td>
                <td>
                  <SoftBadge pill bg={v.status.type}>
                    {v.status.title}
                    <FontAwesomeIcon icon={v.status.icon} className="ms-2" />
                  </SoftBadge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

// export default CreatePropertyModal;
