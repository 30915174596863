import React from "react";
import {
  Button,
  Modal,
  Card,
  Table,
  Form as BSForm,
  Row,
  Col,
} from "react-bootstrap";
// import Wizard from './wizardRemastered/Wizard';
import FalconCloseButton from "components/common/FalconCloseButton";
import FalconCardHeader from "components/common/FalconCardHeader";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";

export default function ReceivePaymentsModel(props) {
  try {
    const { amenity, userData, hostname, jwtToken } = props;

    const acceptPayment = (values) => {
      console.log(values);
      let payment = {
        invoice_id: amenity._id, //// _id NOT invoice number
        accepted_by: userData?.data,
        currency: "CAD",
        payment_method: values.payment_option, ///////valid types: "Cash, "Check"
        amount: "",
        date: "",
        check_number: "",
      };
      if (values.payment_option === "Cash") {
        payment.amount = values.payment_amount_cash;
        payment.date = values.payment_date_cash;
      }

      if (values.payment_option === "Check") {
        payment.amount = values.payment_amount_check;
        payment.date = values.payment_date_check;
        payment.check_number = values.check_number_check;
      }

      console.log(payment);
      // return;
      if (values.payment_option === "Cash" || values.payment_option === "Check")
        axios
          .post(
            `${hostname}/api/pay/AcceptPayment`,
            {
              ...payment,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            props.getAllData();
            props.handleClose();
            toast.success(res.data.Message || "success.", { theme: "colored" });
          })
          .catch((err) => {
            if ("response" in err)
              toast.error(err.response.data.error, { theme: "colored" });
            else toast.error("something went wrong!", { theme: "colored" });
            console.log(err);
          });
    };

    return (
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        // className='bg-info'
        style={{ backdropFilter: "blur(3px)" }}
        keyboard={false}
        size="lg"
        // fullscreen
        // centered
      >
        <Modal.Header>
          <Modal.Title>Receive Payment </Modal.Title>
          <FalconCloseButton onClick={props.handleClose} />
        </Modal.Header>
        {amenity.loadType === "Receive Payment Manager" && (
          <Modal.Body className="">
            <Card className="mb-3">
              <FalconCardHeader
                // className="bg-light"
                title="Amenity Payment Summary"
              />
              <Card.Body className="bg-light">
                <Table responsive>
                  <tbody>
                    <tr>
                      <td>Booking ID</td>
                      <td className="fw-bold">
                        {amenity._id?.slice(0, 8) || "4547453"}
                      </td>
                    </tr>
                    <tr>
                      <td>Amenity Fee</td>
                      <td className="fw-bold">
                        {`$` + amenity.amount + ".00" || "$150.00"}
                      </td>
                    </tr>
                    <tr>
                      <td>Balance Due</td>
                      <td className="fw-bold">
                        {`$` + amenity.amount + ".00" || "$150.00"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Card>
              <FalconCardHeader title="Payment Method" />
              <Card.Body className="bg-light">
                <Formik
                  initialValues={{
                    payment_option: "Credit Card",

                    // card
                    card_number_card: "",
                    exp_date_card: new Date().toISOString().slice(0, 10),
                    cvv_card: "",

                    // cash
                    payment_amount_cash: "",
                    payment_date_cash: new Date().toISOString().slice(0, 10),

                    // check
                    payment_amount_check: "",
                    payment_date_check: new Date().toISOString().slice(0, 10),
                    check_number_check: "",
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (values.payment_option === "Cash") {
                      if (!values.payment_amount_cash)
                        errors.payment_amount_cash = "amount is required!";
                      if (!values.payment_date_cash)
                        errors.payment_date_cash = "date is required!";
                    }
                    if (values.payment_option === "Check") {
                      if (!values.payment_amount_check)
                        errors.payment_amount_check = "amount is required!";
                      if (!values.check_number_check)
                        errors.check_number_check = "check number is required!";
                      if (!values.payment_date_check)
                        errors.payment_date_check = "date is required!";
                    }
                    if (values.payment_option === "Credit Card") {
                      if (!values.card_number_card)
                        errors.card_number_card = "card number is required!";
                      if (!values.exp_date_card)
                        errors.exp_date_card = "date is required!";
                      if (!values.cvv_card)
                        errors.cvv_card = "cvv is required!";
                    }

                    console.log(errors);
                    return errors;
                  }}
                  // validationSchema={Yup.object({
                  //   payment_option: Yup.string(),

                  //   // cash
                  //   payment_amount_cash: Yup.string().when("payment_option", {
                  //     is: "Cash",
                  //     then: (schema) => schema.required("amount is required!"),
                  //   }),
                  //   payment_date_cash: Yup.string().when("payment_option", {
                  //     is: "Cash",
                  //     then: (schema) => schema.required("date is required!"),
                  //   }),

                  //   // check
                  //   payment_amount_check: Yup.string().when("payment_option", {
                  //     is: "Check",
                  //     then: (schema) => schema.required("amount is required!"),
                  //   }),
                  //   payment_date_check: Yup.string().when("payment_option", {
                  //     is: "Check",
                  //     then: (schema) => schema.required("amount is required!"),
                  //   }),
                  //   // card
                  //   card_number_card: Yup.string().when("payment_option", {
                  //     is: "Credit Card",
                  //     then: (schema) =>
                  //       schema.required("card number is required!"),
                  //   }),
                  //   exp_date_card: Yup.string().when("payment_option", {
                  //     is: "Credit Card",
                  //     then: (schema) => schema.required("date is required!"),
                  //   }),
                  //   cvv_card: Yup.string().when("payment_option", {
                  //     is: "Credit Card",
                  //     then: (schema) => schema.required("cvv is required!"),
                  //   }),
                  // })}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    acceptPayment(values);
                  }}
                >
                  {({ isSubmitting, values }) => (
                    <Form>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault2"
                              >
                                Credit Card
                              </label>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="payment_option"
                                value="Credit Card"
                                id="flexRadioDefault2"
                              />
                            </div>
                            <div className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                              >
                                Cash
                              </label>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="payment_option"
                                value="Cash"
                                id="flexRadioDefault1"
                              />
                            </div>

                            <div className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault3"
                              >
                                Check
                              </label>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="payment_option"
                                value="Check"
                                id="flexRadioDefault3"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={8}>
                          {values.payment_option === "Cash" && (
                            <div>
                              <BSForm.Group className="mb-3">
                                <BSForm.Label>Payment Amount</BSForm.Label>
                                <Field
                                  name="payment_amount_cash"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="payment_amount_cash"
                                  className="fs--1 text-danger"
                                  component={"div"}
                                />
                              </BSForm.Group>
                              <BSForm.Group className="mb-3">
                                <BSForm.Label>Payment Date</BSForm.Label>
                                <Field
                                  name="payment_date_cash"
                                  className="form-control"
                                  type="date"
                                  //   defaultValue={new Date()
                                  //     .toISOString()
                                  //     .slice(0, 10)}
                                />
                                <ErrorMessage
                                  name="payment_date_cash"
                                  className="fs--1 text-danger"
                                  component={"div"}
                                />
                              </BSForm.Group>
                            </div>
                          )}
                          {values.payment_option === "Check" && (
                            <div>
                              <BSForm.Group className="mb-3">
                                <BSForm.Label>Check Number</BSForm.Label>
                                <Field
                                  name="check_number_check"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="check_number_check"
                                  className="fs--1 text-danger"
                                  component={"div"}
                                />
                              </BSForm.Group>
                              <Row>
                                <BSForm.Group className="mb-3" as={Col} md={6}>
                                  <BSForm.Label>Payment Amount</BSForm.Label>
                                  <Field
                                    name="payment_amount_check"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="payment_amount_check"
                                    className="fs--1 text-danger"
                                    component={"div"}
                                  />
                                </BSForm.Group>
                                <BSForm.Group className="mb-3" as={Col} md={6}>
                                  <BSForm.Label>Payment Date</BSForm.Label>
                                  <Field
                                    name="payment_date_check"
                                    className="form-control"
                                    type="date"
                                    // defaultValue={new Date()
                                    //   .toISOString()
                                    //   .slice(0, 10)}
                                  />
                                  <ErrorMessage
                                    name="payment_date_check"
                                    className="fs--1 text-danger"
                                    component={"div"}
                                  />
                                </BSForm.Group>
                              </Row>
                            </div>
                          )}
                          {values.payment_option === "Credit Card" && (
                            <div>
                              <BSForm.Group className="mb-3">
                                <BSForm.Label>Card Number</BSForm.Label>
                                <Field
                                  name="card_number_card"
                                  className="form-control"
                                  placeholder="**** **** **** ****"
                                />
                                <ErrorMessage
                                  name="card_number_card"
                                  className="fs--1 text-danger"
                                  component={"div"}
                                />
                              </BSForm.Group>
                              <Row>
                                <BSForm.Group className="mb-3" as={Col} md={6}>
                                  <BSForm.Label>Exp Date</BSForm.Label>
                                  <Field
                                    name="exp_date_card"
                                    className="form-control"
                                    type="date"
                                  />
                                  <ErrorMessage
                                    name="exp_date_card"
                                    className="fs--1 text-danger"
                                    component={"div"}
                                  />
                                </BSForm.Group>
                                <BSForm.Group className="mb-3" as={Col} md={6}>
                                  <BSForm.Label>CVV</BSForm.Label>
                                  <Field
                                    name="cvv_card"
                                    className="form-control"
                                    placeholder="1234"
                                  />
                                  <ErrorMessage
                                    name="cvv_card"
                                    className="fs--1 text-danger"
                                    component={"div"}
                                  />
                                </BSForm.Group>
                              </Row>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <div className="text-end">
                        <Button
                          variant="info"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Process Payment
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Modal.Body>
        )}
        {amenity.loadType === "Pay Payment Owner" && (
          <Modal.Body className="">
            <Card className="mb-3">
              <FalconCardHeader
                // className="bg-light"
                title="Amenity Payment Summary"
              />
              <Card.Body className="bg-light">
                <Table responsive>
                  <tbody>
                    <tr>
                      <td>Booking ID</td>
                      <td className="fw-bold">
                        {amenity._id?.slice(0, 8) || "4547453"}
                      </td>
                    </tr>
                    <tr>
                      <td>Amenity Fee</td>
                      <td className="fw-bold">
                        {`$` + amenity.amenityPrice + ".00" || "$150.00"}
                      </td>
                    </tr>
                    <tr>
                      <td>Balance Due</td>
                      <td className="fw-bold">
                        {`$` + amenity.amenityPrice + ".00" || "$150.00"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Card>
              <FalconCardHeader title="Payment Method" />
              <Card.Body className="bg-light">
                <Formik
                  initialValues={{ payment_option: "Credit Card" }}
                  validate={(values) => {
                    const errors = {};
                    console.log(values);
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({ isSubmitting, values }) => (
                    <Form>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault2"
                              >
                                Credit Card
                              </label>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="payment_option"
                                value="Credit Card"
                                id="flexRadioDefault2"
                              />
                            </div>
                            <div className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                              >
                                Cash
                              </label>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="payment_option"
                                value="Cash"
                                id="flexRadioDefault1"
                              />
                            </div>

                            <div className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault3"
                              >
                                Check
                              </label>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="payment_option"
                                value="Check"
                                id="flexRadioDefault3"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={8}>
                          {values.payment_option === "Credit Card" && (
                            <div>
                              <BSForm.Group className="mb-3">
                                <BSForm.Label>Card Number</BSForm.Label>
                                <Field
                                  name="card_number_card"
                                  className="form-control"
                                  placeholder="**** **** **** ****"
                                />
                                <ErrorMessage
                                  name="card_number_card"
                                  className="fs--1 text-danger"
                                  component={"div"}
                                />
                              </BSForm.Group>
                              <Row>
                                <BSForm.Group className="mb-3" as={Col} md={6}>
                                  <BSForm.Label>Exp Date</BSForm.Label>
                                  <Field
                                    name="exp_date_card"
                                    className="form-control"
                                    type="date"
                                  />
                                  <ErrorMessage
                                    name="exp_date_card"
                                    className="fs--1 text-danger"
                                    component={"div"}
                                  />
                                </BSForm.Group>
                                <BSForm.Group className="mb-3" as={Col} md={6}>
                                  <BSForm.Label>CVV</BSForm.Label>
                                  <Field
                                    name="cvv_card"
                                    className="form-control"
                                    placeholder="1234"
                                  />
                                  <ErrorMessage
                                    name="cvv_card"
                                    className="fs--1 text-danger"
                                    component={"div"}
                                  />
                                </BSForm.Group>
                              </Row>
                            </div>
                          )}
                          {values.payment_option === "Check" && (
                            // <div>
                            //   <BSForm.Group className="mb-3">
                            //     <BSForm.Label>Check Number</BSForm.Label>
                            //     <Field
                            //       name="check_number_check"
                            //       className="form-control"
                            //     />
                            //     <ErrorMessage
                            //       name="check_number_check"
                            //       className="fs--1 text-danger"
                            //       component={"div"}
                            //     />
                            //   </BSForm.Group>
                            //   <Row>
                            //     <BSForm.Group className="mb-3" as={Col} md={6}>
                            //       <BSForm.Label>Payment Amount</BSForm.Label>
                            //       <Field
                            //         name="payment_amount_check"
                            //         className="form-control"
                            //       />
                            //       <ErrorMessage
                            //         name="payment_amount_check"
                            //         className="fs--1 text-danger"
                            //         component={"div"}
                            //       />
                            //     </BSForm.Group>
                            //     <BSForm.Group className="mb-3" as={Col} md={6}>
                            //       <BSForm.Label>Payment Date</BSForm.Label>
                            //       <Field
                            //         name="payment_amount_check"
                            //         className="form-control"
                            //         type="date"
                            //       />
                            //       <ErrorMessage
                            //         name="payment_amount_check"
                            //         className="fs--1 text-danger"
                            //         component={"div"}
                            //       />
                            //     </BSForm.Group>
                            //   </Row>
                            // </div>
                            <div>
                              <h5>Instrutions for Check payment</h5>
                              <p
                                className="text-secondary"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {amenity.check_description}
                              </p>
                            </div>
                          )}
                          {values.payment_option === "Cash" && (
                            // <div>
                            //   <BSForm.Group className="mb-3">
                            //     <BSForm.Label>Payment Amount</BSForm.Label>
                            //     <Field
                            //       name="payment_amount_cash"
                            //       className="form-control"
                            //     />
                            //     <ErrorMessage
                            //       name="payment_amount_cash"
                            //       className="fs--1 text-danger"
                            //       component={"div"}
                            //     />
                            //   </BSForm.Group>
                            //   <BSForm.Group className="mb-3">
                            //     <BSForm.Label>Payment Date</BSForm.Label>
                            //     <Field
                            //       name="payment_amount_cash"
                            //       className="form-control"
                            //       type="date"
                            //     />
                            //     <ErrorMessage
                            //       name="payment_amount_cash"
                            //       className="fs--1 text-danger"
                            //       component={"div"}
                            //     />
                            //   </BSForm.Group>
                            // </div>
                            <div>
                              <h5>Instrutions for Cash payment</h5>
                              <p
                                className="text-secondary"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {amenity.cash_description}
                              </p>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <div className="text-end">
                        {values.payment_option === "Credit Card" && (
                          <Button
                            variant="info"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Process Payment
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Modal.Body>
        )}
      </Modal>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
