import FalconCardHeader from "components/common/FalconCardHeader";
import React from "react";
import { Button, Card } from "react-bootstrap";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
export default function CompanyLetterHead({
  addLetterHead,
  companyLetterHead,
  deleteLetterHead,
  viewLetterHead,
}) {
  return (
    <div>
      <Card className="mb-3">
        <FalconCardHeader title="Company Letterhead Upload Instructions" />
        <Card.Body className="bg-light" style={{ minHeight: "422px" }}>
          <div className="px-3">
            <p>
              To customize your communications to residents, please upload your
              company letterhead.
            </p>
            <ul>
              <li className="mb-3">
                The letterhead template must be in PDF file format.
              </li>
              <li className="mb-3">The file size should not exceed 2MB.</li>

              <li className="mb-3">
                Only single-page letterhead templates are allowed for upload.
              </li>
            </ul>
            {/* <span className="fs-4">•</span> Creating letterheads with proper
            margins and leaving space for letter content.
            <br />
            <span className="fs-4">•</span> Set standard margins for the
            letterhead (e.g., one inch on all sides).
            <br />
            <span className="fs-4">•</span> Include additional information at
            the footer if necessary, such as disclaimers or additional contact
            details.
            <br />
            <span className="fs-4">•</span> Upload the letterhead template in
            the PDF file format for easy reuse. */}
            <br />
            <br />
            {companyLetterHead !== "" && (
              <div className="text-center my-3">
                <Button variant="link" onClick={viewLetterHead}>
                  View/Change
                </Button>
                <Button
                  variant="link"
                  className="text-danger"
                  onClick={deleteLetterHead}
                >
                  Delete
                </Button>
              </div>
            )}
            {companyLetterHead === "" && (
              <div className="text-center my-3">
                <Button onClick={addLetterHead}>Upload Letter Head</Button>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
