import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import NavbarTop from "../components/navbar/top/NavbarTop";
import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
import Footer from "../components/footer/Footer";
import ProductProvider from "../components/app/e-commerce/ProductProvider";
// import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const MainLayout = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  let redirect_link = cookies.get("_expire_l") || "/login";
  let jwtToken = cookies.get("_expire_t");

  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { pathname } = useLocation();

  React.useEffect(() => {
    const checkTokenExpiration = () => {
      console.log("validating token");

      let redirect_link = cookies.get("_expire_l") || "/login";
      const decoded_jwt = jwtToken ? jwtDecode(jwtToken) : "";

      // console.log(decoded_jwt);

      // Adjust this based on your authName
      const expirationTime = decoded_jwt.exp;

      // Current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // console.log(currentTime);

      if (!jwtToken) {
        navigate(redirect_link);
        return;
      }
      if (jwtToken && currentTime >= expirationTime) {
        toast.warning("Session Disconnected!", { autoClose: false });
        cookies.remove("_expire_t", { path: "/" });
        cookies.remove("_expire_l", { path: "/" });

        setTimeout(() => {
          window.location.href = redirect_link;
        }, 400);
      }
    };

    setTimeout(() => {
      checkTokenExpiration();
    }, 10000);

    // Check every 60 seconds
    const interval = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(interval);
  }, [jwtToken, navigate]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (
    !userData ||
    userData === "undefined" ||
    userData === undefined ||
    userData === ""
  )
    return <Navigate to={redirect_link} />;
  else
    return (
      <div className="container-fluid">
        <NavbarVertical userData={userData} redirect_link={redirect_link} />

        <ProductProvider>
          <div className="content">
            <NavbarTop userData={userData} />
            {/*------ Main Routes ------*/}
            <Outlet />
            <Footer />
          </div>
        </ProductProvider>
      </div>
    );
};

export default MainLayout;
