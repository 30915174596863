import React from "react";
import VisitorManagementOW from "components/pages/UnitOnwer&Tennent/Features/VisitorManagement/VisitorManagement";

export default function UnitVisitorManagement() {
  return (
    <>
      <VisitorManagementOW from='singleUnit'/>
    </>
  );
}
