import { useState, useEffect } from "react";

import Flex from "components/common/Flex";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
// import UserAdvanceTable from './UserAdvanceTable';
import GroupTable from "./GroupTable";

import axios from "axios";
import Cookies from "universal-cookie";

import { flatten } from "lodash";
import GroupDetailTab from "./GroupDetailTab";
import Loader from "components/common/Loader";
import jwtDecode from "jwt-decode";
import { getHostName } from "helpers/utils";

const Starter = () => {
  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";
  const hostname = getHostName();

  const [groupsData, setGroupsData] = useState([]);
  // const [groupsDataTemp, setGroupsDataTemp] = useState([...groupsData]);
  const [usersDataAll, setUsersDataAll] = useState([]);
  const [loader, setLoader] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});
  const [userGroupDetail, setUserGroupDetail] = useState({});
  // group detail tab
  const [showGroupDetailTab, setShowGroupDetailTab] = useState(false);
  const handleCloseGroupDetailTab = () => setShowGroupDetailTab(false);
  const handleShowGroupDetailTab = () => setShowGroupDetailTab(true);

  useEffect(() => {
    setLoader(true);
    getGroupsData();
    getUsersData(userData.compid);
  }, []);

  //
  const getUsersData = async (compid) => {
    if (compid) {
      axios
        .post(
          `${hostname}/api/auth/RoleTable`,
          {
            c_id: compid,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          let allData = [flatten(res.data.data), flatten(res.data.invited)];
          allData = flatten(allData);
          // console.log(allData);
          setUsersDataAll(allData);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };
  //
  const getGroupsData = () => {
    if (userData.compid) {
      axios
        .post(
          `${hostname}/api/auth/Grouptable`,
          {
            c_id: userData.compid,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
         // console.log(res.data.data);

          // let tmpg = res.data.data?.map(v => )
          setGroupsData(res.data.data);
          // setGroupsDataTemp(res.data.data);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err.response.data);
          setLoader(false);
        });
    }
  };

  const ShowGroupDetail = (group) => {
    let usersOfGroup = [...usersDataAll].filter((v) =>
      v.groups.includes(group)
    );
    // console.log(usersOfGroup);
    setGroupDetail(group);
    setUserGroupDetail(usersOfGroup);
    handleShowGroupDetailTab();
  };

  if (loader) {
    return <Loader />;
  } else if (showGroupDetailTab)
    return (
      <GroupDetailTab
        handleCloseGroupDetailTab={handleCloseGroupDetailTab}
        group={groupDetail}
        userGroupDetail={userGroupDetail}
      />
    );
  else {
    return (
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Groups</h4>
          </Flex>

          <Row>
            <Col md="6">
              <p>
                Use groups to give multiple users in your organization the same
                permissions, restrictions, and product access.
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <GroupTable
            groups={groupsData}
            users={usersDataAll}
            ShowGroupDetail={ShowGroupDetail}
          />
          <br />
          {/* <CreateGroup show={show} handleClose={handleClose} /> */}
          {/* <CreateGroupModel show={show} handleClose={handleClose} /> */}
        </Card.Body>
      </Card>
    );
  }
};

export default Starter;
