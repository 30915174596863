import React from 'react';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function CustomBreadCrumbs({ links = [] }) {
  return (
    <Card>
      <Card.Body className="">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" active={true}>
            Home
          </Breadcrumb.Item>
          {links?.map((v, i) => (
            <Breadcrumb.Item
              key={v.path + i + '7839'}
              href={v.path}
              active={v.path}
            >
              {v.label}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </Card.Body>
    </Card>
  );
}
