import React from "react";
import CustomTitle from "./CustomTitle";
import { Card } from "react-bootstrap";
import GroupDetailsComponent from "./GroupDetailsComponent";

export default function GroupDetailTab({
  handleCloseGroupDetailTab,
  group,
  userGroupDetail,
}) {
  return (
    <div>
      <Card className="mb-3">
        <CustomTitle
          heading={
            group === "Condozak-Basic-Users"
              ? "Condozak-Users"
              : group || "name here"
          }
          handleCloseDetail={handleCloseGroupDetailTab}
        />
      </Card>
      <GroupDetailsComponent group={group} users={userGroupDetail} />
    </div>
  );
}
