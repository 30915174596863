
import React, { useContext } from "react";

import { Button, Col, Row } from "react-bootstrap";

import { AuthWizardContext } from "context/Context";

import { Formik, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

const eventTypesArr =
  "Class, Training or Workshop-Concert or Performance-Conference-Convention-Dinner or Gala-Festival or Fair";

const AdditionalInfoPage = ({ from = "", event = {}, updateEvent }) => {
  const { step, setStep, user, setUser } = useContext(AuthWizardContext);
  console.log(user);

  const handleSubmit = (values) => {
    console.log(values);
    setUser(Object.assign({}, user, values));

    setStep(step + 1);
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({}, user, values));

    setStep(step - 1);
  };

  return (
    <div className={`${from === "Edit Event Page" && "card mt-3"}`}>
      {from === "Edit Event Page" && (
        <div className="card-header">Edit Basic Info</div>
      )}

      <div className={`${from === "Edit Event Page" && "card-body bg-light"}`}>
        <Formik
          initialValues={{
            event_organizer:
              from === "Edit Event Page"
                ? event.event_organizer
                : user.event_organizer || "",
            event_sponsor:
              from === "Edit Event Page"
                ? event.event_sponsor
                : user.event_sponsor || "",
            event_type:
              from === "Edit Event Page"
                ? event.event_type
                : user.event_type || "",
            // event_privacy: user.event_privacy || "Private Page",
            venue: from === "Edit Event Page" ? event.venue : user.venue || "",
          }}
          validationSchema={Yup.object({
            event_organizer: Yup.string().required("organizer is required!"),
            event_sponsor: Yup.string().required("sponsor is required!"),
            event_type: Yup.string().required("event type is required!"),
            // event_privacy: Yup.string().required("event privacy is required!"),
            venue: Yup.string().required("venue is required!"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="event_organizer">Event Organizer</label>
                  <Field
                    name="event_organizer"
                    className="form-control"
                    placeholder="enter event organizer name ..."
                  />
                  <ErrorMessage
                    name="event_organizer"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="event_sponsor">Event Sponsor</label>
                  <Field
                    name="event_sponsor"
                    className="form-control"
                    placeholder="enter event sponsor name ..."
                  />
                  <ErrorMessage
                    name="event_sponsor"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="typeProperty">Event Type</label>
                  <Field
                    component="select"
                    id="typeProperty"
                    name="event_type"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {eventTypesArr?.split("-").map((v, i) => (
                      <option key={v} value={v}>
                        {v}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="event_type"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="venue">Venue</label>
                  <Field
                    name="venue"
                    className="form-control"
                    placeholder="enter venue here"
                  />
                  <ErrorMessage
                    name="venue"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                  <br />
                </Col>
              </Row>

              {from !== "Edit Event Page" && (
                <div className="">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => handleStepMinus(values)}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    className="float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              )}
              {from === "Edit Event Page" && (
                <div className="">
                  <Button
                    variant="primary"
                    className="float-end"
                    onClick={() => updateEvent(values)}
                  >
                    Update
                  </Button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdditionalInfoPage;
