import { toast } from "react-toastify";
import React, { useState } from "react";

import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { getHostName } from "helpers/utils";
import Cookies from "universal-cookie";
const Paymenttest = ({
  clientSecret,
  handleCloseModal,
  invoiceId,
  getAllInvoicesData,
}) => {
  console.log(clientSecret);
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cookies = new Cookies();
  let jwtToken = cookies.get("_expire_t");
  let hostname = getHostName();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      setError(result.error.message);
      toast.error(result.error.message);
      toast.error(`Payment error`, {
        theme: "colored",
      });
    } else {
      // Payment was successful, you can handle success here
      getAllInvoicesData();
      console.log("Payment Successfull");
      setPaymentSuccess(true);
      toast.success(`Payment Successfull`, {
        theme: "colored",
      });

      if (invoiceId) {
        recordPayment(invoiceId, result.paymentIntent.id);
      }
    }
  };

  const recordPayment = async (invoiceId, paymentIntentId) => {
    try {
      const response = await axios.post(
        `${hostname}/api/pay/RecordInvoice`,
        {
          invoiceId,
          paymentIntentId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      console.log("Payment recorded on the backend:", response.data);
      getAllInvoicesData();
    } catch (error) {
      console.error("Error recording payment:", error);
    }
  };
  if (paymentSuccess) {
    handleCloseModal();
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="card-element" className="mb-3">
        <Form.Label>Card Details</Form.Label>
        <CardElement className="form-control" />
      </Form.Group>
      <div className="text-end">
        <Button variant="primary" type="submit" disabled={!stripe}>
          Pay
        </Button>
      </div>
    </Form>
  );
};

export default Paymenttest;
