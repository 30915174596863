import { useState, useEffect } from "react";

import Flex from "components/common/Flex";
import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

import MailOutsTable from "./MailOutsTable";

import { capitalize } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import { Link } from "react-router-dom";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";
import Loader from "components/common/Loader";

const AllUnits = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getAllUnitsFromUData();
  }, []);

  const getAllUnitsFromUData = () => {
    setPropertiesData(userData.UnitsOwner);
    setLoader(false);
  };

  const getAllPropertiesData = (c_id) => {
    // console.log("working");
    console.log(c_id);
    // return;
    if (c_id) {
      axios
        .post(
          `${hostname}/api/property/Allproperties`,
          {
            c_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          console.log(res.data);
          let properties = res.data.data.map((p) => {
            // console.log(p.property_info);
            console.log(p.address);
            return Object.assign(
              { address: p.address || "-" },
              p.property_info
            );
          });
          properties = properties.map((p) => {
            p.property_name = capitalize(p.property_name);
            return Object.assign(
              {
                status: {
                  title: "Active",
                  type: "success",
                  icon: "check",
                },
              },
              p
            );
          });
          setPropertiesData(properties);
          setPropertiesDataTemp(properties);

        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  // create property modal

  const [showDetail, setShowDetail] = useState(false);

  const handleShowDetail = () => setShowDetail(true);
  const handleCloseDetail = () => setShowDetail(false);

  const handleSearchBar = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    // console.log(searchValue);
    let propertyTemp = [...propertiesDataTemp];
    let ans = [];
    ans = propertyTemp.filter((p) => {
      // console.log(p.property_id);
      if (
        p.property_id.toLowerCase().indexOf(searchValue) !== -1 ||
        p.property_name.toLowerCase().indexOf(searchValue) !== -1 ||
        p.address.toLowerCase().indexOf(searchValue) !== -1
      )
        return (
          p.property_id.toLowerCase().indexOf(searchValue) !== -1 ||
          p.property_name.toLowerCase().indexOf(searchValue) !== -1 ||
          p.address.toLowerCase().indexOf(searchValue) !== -1
        );
      else return "";
    });

    // console.log(groupsTemp);
    // console.log(ans);

    setPropertiesData(ans);
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Link className="text-secondary" to={"/dashboard"}>
                  Home
                </Link>
                {" / "}
                <Link to={"#!"}>All Units</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>All Units</h4>
            </Flex>
            <br />

            <Row className="flex-end-center">
              <Col xs="auto" sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white"
                      id="basic-addon1"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                    <input
                      type="text"
                      name="searchv"
                      onKeyUp={handleSearchBar}
                      className="form-control"
                      placeholder="Search by unit ..."
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <MailOutsTable
              properties={propertiesData}
              getAllPropertiesData={getAllPropertiesData}
              handleShowDetail={handleShowDetail}
            />
            <br />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default AllUnits;
