import React from "react";
import ImageUnit from "./ImageUnit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../../../../../components/common/SoftBadge";
import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
// import CardDropdown from '../../../../../../../components/common/CardDropdown';
import { formatLogInfo } from "../../../../../../../helpers/utils";
import { Avatar } from "@mui/material";
import { startCase } from "lodash";
// import Avatar from 'components/common/Avatar';
// import './UserTable.css';
// import { isArray } from 'lodash';

const TableRow = ({
  data,
  propLength,
  handleShowOwnersDetailTab,
  // inviteUser,
  // showUserDetails
  // adminCount
}) => {
  const getUserShortName = (currentUser) => {
    if (typeof currentUser === "object") {
      if (
        currentUser.firstname !== undefined &&
        currentUser.lastname !== undefined
      ) {
        return (
          startCase(currentUser?.firstname[0]) +
          startCase(currentUser?.lastname[0])
        );
      }
      if (
        currentUser.firstname !== undefined &&
        currentUser.lastname === undefined
      ) {
        return startCase(currentUser?.firstname[0]);
      }
    }
  };

  try {
    return (
      <tr
        className="align-middle"
        style={{ height: propLength === 1 ? "100px" : "" }}
      >
        <td className="text-nowrap">
          <div className="d-flex align-items-center">
            {/* <div className="me-2">
              <ImageUnit
                src={data?.avatar}
                firstname={data?.firstname || data?.firstName}
                lastname={data?.lastname || ""}
                bgColor={data?.bgColor}
              />
            </div> */}
            <Avatar
              sx={{
                bgcolor: data?.bgColor,
                fontSize: "16px",
                // width: "100px",
                // height: "100px",
              }}
              className="img-thumbnail"
            >
              {getUserShortName(data)}
            </Avatar>
            <div>
              <div
                className="ms-1"
                onClick={() =>
                  data.status.title === "Active" &&
                  handleShowOwnersDetailTab(data)
                }
              >
                <span
                  className={
                    data.status.title === "Active"
                      ? "text-primary cursor-pointer"
                      : ""
                  }
                >
                  {data?.firstname || data?.firstName} {data?.lastname || ""}
                </span>
                {data?.role.type === "bg-custom" && (
                  <span className="ms-2 custom-badge">{data?.role.title}</span>
                )}
                {data?.role.type !== "bg-custom" && (
                  <Badge className={`ms-2 bg-${data?.role.type} fw-light`}>
                    {data?.role.title}
                  </Badge>
                )}
              </div>
              <div className="ms-1">{data.email?.toLowerCase()}</div>
            </div>
          </div>
        </td>
        <td className="text-nowrap text-center">
          {data.LogInfo === undefined ? "-" : formatLogInfo(data.LogInfo)}
        </td>
        <td className="text-center">
          <SoftBadge pill bg={data.status.type}>
            {data.status.title}
            <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
          </SoftBadge>
        </td>
      </tr>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
  
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={4}>No owner is currently associated with this unit.</td>
    </tr>
  );
};

const UnitOwnersTable = ({
  users,
  inviteUser,
  resendInvite,
  deleteInvitedUser,
  grantUserAccess,
  revokeUserAccess,
  addUserToGroup,
  showUserDetails,
  adminCount,
  handleShowOwnersDetailTab,
}) => {
  // console.log(users);
  return (
    <Table responsive striped hover bordered style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th scope="col">Resident</th>
          <th scope="col" className="text-center">
            Last Active
          </th>
          <th scope="col" className="text-center">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {users?.length > 0 ? (
          users?.map((user, i) => (
            <TableRow
              data={user}
              key={user?.email + i}
              revokeUserAccess={revokeUserAccess}
              grantUserAccess={grantUserAccess}
              inviteUser={inviteUser}
              deleteInvitedUser={deleteInvitedUser}
              resendInvite={resendInvite}
              addUserToGroup={addUserToGroup}
              showUserDetails={showUserDetails}
              adminCount={adminCount}
              propLength={users.length}
              handleShowOwnersDetailTab={handleShowOwnersDetailTab}
            />
          ))
        ) : (
          <EmptyRow />
        )}
      </tbody>
    </Table>
  );
};

export default UnitOwnersTable;
