import { AuthWizardContext } from '../../../../context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';

// const Success = ({ reset, handleClose }) => {
const Success = ({ handleClose, getAllPropertiesData }) => {
  const { setStep, user, setUser } = useContext(AuthWizardContext);

  // console.log(user);
  const emptyData = () => {
    getAllPropertiesData(user.c_id);
    handleClose();
    setStep(1);
    setUser({});
    // reset();
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1 fw-light">
            <span className="fw-bold">{user.property_name}</span> created
            Successfully
          </h4>
          <p className="fs-0"></p>
          <Button color="primary" className="px-5 my-3" onClick={emptyData}>
            Finish
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
