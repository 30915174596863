import React from "react";
import { Card, Table } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import { AuthWizardContext } from "context/Context";
import { formatLogInfo } from "helpers/utils";
export default function BookingReview({ selectedAmenity }) {
  const { user } = React.useContext(AuthWizardContext);

  try {
    return (
      <div>
        <Card>
          <FalconCardHeader title="Booking Details" />
          <Card.Body className="bg-light">
            <Table bordered>
              <tbody>
                <tr>
                  <td>Amenity Name</td>
                  <th>{selectedAmenity.amenityname || "Party Room"} </th>
                </tr>
                {selectedAmenity.type === "Hotel Style" && (
                  <>
                    <tr>
                      <td>Check-In Date</td>
                      <th>
                        {formatLogInfo(user.start_date, true) || "Start Date"}
                      </th>
                    </tr>
                    <tr>
                      <td>Check-Out Date</td>
                      <th>
                        {formatLogInfo(user.end_date, true) || "End Date"}
                      </th>
                    </tr>
                  </>
                )}
                {selectedAmenity.type === "Daily" && (
                  <>
                    {/* <tr>
                      <td>Start Date</td>
                      <th>
                        {formatLogInfo(user.start_date, true) || "Start Date"}
                      </th>
                    </tr> */}
                    {user.isSingleDate && (
                      <tr>
                        {/* <td>Booking Days</td>
                          <th>

                            {user.days_for_daily || "days"}
                          </th> */}
                        <td>Booking Date</td>
                        <th>
                          {formatLogInfo(user.start_date, true) || "Start Date"}
                        </th>
                      </tr>
                    )}
                    {!user.isSingleDate && (
                      <>
                        <tr>
                          <td>Start Date</td>
                          <th>
                            {formatLogInfo(user.start_date, true) ||
                              "Start Date"}
                          </th>
                        </tr>
                        <tr>
                          <td>End Date</td>
                          <th>
                            {formatLogInfo(user.end_date, true) || "End Date"}
                          </th>
                        </tr>
                      </>
                    )}
                  </>
                )}
                {selectedAmenity.type === "Time Slots" && (
                  <tr>
                    <td>Date</td>
                    <th>
                      {formatLogInfo(user.start_date, true) || "End Date"}
                    </th>
                  </tr>
                )}
                {selectedAmenity.type === "Hotel Style" || selectedAmenity.type === 'Daily' ? (
                  <>
                    <tr>
                      <td>{selectedAmenity.type === 'Daily' ? 'Start':"Check-In"} Time</td>
                      <th>{selectedAmenity.checkin_time}</th>
                    </tr>
                    <tr>
                      <td>{selectedAmenity.type === 'Daily' ? 'End':"Check-Out"} Time</td>
                      <th>{selectedAmenity.checkout_time}</th>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td>Time</td>
                    <th>{user.time_slot || "End Date"}</th>
                  </tr>
                )}
                <tr>
                  <td>No. of guests</td>
                  <th>{user.max_guest_limit || "max guest limit"}</th>
                </tr>
                <tr>
                  <td>Email Remainder</td>
                  <th>{user.email_remainder || "Send Mail"}</th>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        {/* <br />
      <Card>
        <FalconCardHeader title="Payment Details" />
        <Card.Body className="bg-light">
          <Table bordered>
            <tbody>
              <tr>
                <td>Amenity Name</td>
                <th>{user.amenity_name || 'Party Room'} </th>
              </tr>
              <tr>
                <td>From</td>
                <th>{user.amenity_date || "Start Date"} </th>
              </tr>
              <tr>
                <td>Untill</td>
                <th>{user.amenity_time || "End Date"}</th>
              </tr>
              <tr>
                <td>No. of guests</td>
                <th>{user.amenity_max_guest_limit || "max guest limit"}</th>
              </tr>
              <tr>
                <td>Email Remainder</td>
                <th>{user.email_remainder || "Send Mail"}</th>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card> */}
        {/* <div className="mt-4">
          <Button variant="secondary" onClick={() => setStep(step - 1)}>
            Previous
          </Button>
          <Button
            variant="primary"
            className="float-end"
            onClick={() => setStep(step + 1)}
          >
            Next
          </Button>
        </div> */}
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
