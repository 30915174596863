import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Badge } from "react-bootstrap";
import corner1 from "../../../assets/img/illustrations/corner-4.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Background from "../../common/Background";
import Lottie from "lottie-react";
import welcome from "assets/img/animated-icons/welcome.json";
const WelcomeUser = ({ className, userData }) => {
  const getUserRole = () => {
    let role = "";

    if (userData.role === "Site-Administrators") {
      role = "Property Manager";
    }
    if (userData.role === "Condozak-Basic-Users") {
      role = "Basic User";
    }
    if (userData.role === "unit_owner") {
      role = "Unit Owner";
    }
    return <Badge bg="success">{role}</Badge>;
  };

  return (
    <Card className={`overflow-hidden ${className}`}>
      <Background image={corner1} className="p-card bg-card" />
      <Card.Body className="position-relative">
        <h5>Welcome </h5>

        <div
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4 className="text-warning ">
            {userData.firstname} {userData.lastname}{" "}
            {/* <span className="fs--1">{getUserRole()}</span> */}
          </h4>

          <div style={{ width: "45%" }}>
            <Lottie animationData={welcome} loop={true} />
          </div>
        </div>
        {/* <hr /> */}
        {/* <p className="pt-3">
        
      </p> */}
        {(userData.role === "Site-Administrators" ||
          userData.role === "Condozak-Basic-Users") && (
          <Button
            as={Link}
            variant="link"
            className="fs--1 text-success ps-0"
            to="/property/management/buildings"
          >
            Visit Properties
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Button>
        )}
        {userData.role === "unit_owner" && (
          <Button
            as={Link}
            variant="link"
            className="fs--1 text-success ps-0"
            to="/owner-portal/inbox"
          >
            Visit Inbox
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Button>
        )}
        <Button
          as={Link}
          variant="link"
          className="fs--1 text-warning ps-0"
          to="/user/settings"
        >
          Edit Profile
          <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
        </Button>
      </Card.Body>
    </Card>
  );
};

export default WelcomeUser;
