import React from "react";
import { Modal } from "react-bootstrap";
import Wizard from "./wizardRemastered/Wizard";

import FalconCloseButton from "../../../components/common/FalconCloseButton";

export default function CreatePropertyModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      style={{ backdropFilter: "blur(3px)" }}
    >
      <Modal.Header>
        <Modal.Title>Add Property</Modal.Title>
        <FalconCloseButton onClick={props.handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Wizard
          handleClose={props.handleClose}
          validation={true}
          progressBar={true}
          getAllPropertiesData={props.getAllPropertiesData}
          userData={props.userData}
          hostname={props.hostname}
          jwtToken={props.jwtToken}
        />
      </Modal.Body>
    </Modal>
  );
}

// export default CreatePropertyModal;
