import React from "react";
import { Row, Col, Card, CloseButton } from "react-bootstrap";

import EventDetailsPage from "./wizard/EventDetailsPage";
// import AddressPage from "./wizard/AddressPage";
import AdditionalInfoPage from "./wizard/AdditionalInfoPage";
import EventPrice from "./wizard/EventPrice";
import EventDuration from "./wizard/EventDuration";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditEvent({
  handleCloseEditEventPage,
  event = {},
  userData = {},
  getAllData,
  property_id,
  hostname,
  jwtToken,
}) {
  const updateEvent = (eventData = {}) => {
    // console.log(eventData);

    eventData.updated_by = userData.data;
    eventData.event_id = event._id;
    delete eventData.p_id;
    delete eventData._id;

    // console.log(eventData);

    let ed = Object.fromEntries(
      Object.entries(eventData).filter(
        ([_, v]) => v != null || v != undefined || v != ""
      )
    );

    console.log(ed);
    console.log(event);

    if (ed.event_cover_image instanceof File) {
      addCoverImageAndShowSuccess(ed.event_cover_image);
      return;
    } else {
      delete ed.event_cover_image;
      delete ed.image_url;
      console.log(ed);

      axios
        .post(`${hostname}/api/property/UpdateEvent`, { ...ed },{
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          }
        })
        .then((res) => {
          console.log(res.data);
          toast.success("event updated", { theme: "colored" });
          getAllData();
        })
        .catch((err) => {
          console.log(err);
          if ("response" in err)
            toast.error(err.response.data.error, { theme: "colored" });
          else toast.error("something went wrong!", { theme: "colored" });
        });
    }
  };

  const addCoverImageAndShowSuccess = (file) => {
    let e_data = {};
    e_data.event_id = event._id;
    e_data.req_images = file;

    console.log(e_data);
    axios
      .post(
        `${hostname}/api/property/AddEventImage`,
        { ...e_data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Event image updated successfully", { theme: "colored" });
        getAllData();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const [ticketsData, setTicketsData] = React.useState([]);

  const [actionData, setActionData] = React.useState({
    type: "",
    isShown: false,
    currentList: [],
    currentEntry: {},
  });

  React.useEffect(() => {
    getTicketsData();
  }, []);

  const getTicketsData = () => {
    axios
      .post(`${hostname}/api/property/ReadTicketOption`, {
        ticket_id: event.event_ticket._id,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        setTicketsData(res.data.Data.ticket_options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addTicket = (ticket, clearState) => {
    console.log(ticket);
    delete ticket._id;

    // return;
    axios
      .post(`${hostname}/api/property/AddTicketOption`, {
        ticket_id: event.event_ticket._id,
        ...ticket,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.success("ticket Added.", { theme: "colored" });
        getTicketsData();
        clearState({ name: "", price: 0.0, checked: false });
        setActionData({ type: "", isShown: false });
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const updateTicket = (ticket, clearState) => {
    ticket.tkt_id = ticket._id;
    delete ticket._id;

    console.log(ticket);
    axios
      .post(`${hostname}/api/property/UpdateTicketOption`, {
        ticket_option_id: ticket.tkt_id,
        ticket_id: event.event_ticket._id,
        ...ticket,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.info("ticket updated.", { theme: "colored" });
        getTicketsData();
        clearState({ name: "", price: 0.0, checked: false });
        setActionData({ type: "", isShown: false });
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const removeTicket = (ticket) => {
    console.log(ticket);
    axios
      .post(`${hostname}/api/property/DeleteTicketOption`, {
        ticket_id: event.event_ticket._id,
        ticket_option_id: ticket._id,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.warning("ticket removed.", { theme: "colored" });
        getTicketsData();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };
  const disableTicket = (ticket) => {
    console.log(ticket);
    axios
      .post(`${hostname}/api/property/DisableTicketOption`, {
        ticket_id: event.event_ticket._id,
        ticket_option_id: ticket._id,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.warning("ticket disabled.", { theme: "colored" });
        getTicketsData();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };
  const enableTicket = (ticket) => {
    console.log(ticket);
    axios
      .post(`${hostname}/api/property/EnableTicketOption`, {
        ticket_id: event.event_ticket._id,
        ticket_option_id: ticket._id,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        toast.success("ticket enabled.", { theme: "colored" });
        getTicketsData();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center">
              <h5>{event?.event_name}</h5>
              <CloseButton onClick={handleCloseEditEventPage} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg={7}>
          <EventDetailsPage
            from="Edit Event Page"
            event={event}
            updateEvent={updateEvent}
          />
          <EventPrice
            from="Edit Event Page"
            event={event}
            updateEvent={updateEvent}
            ticketsData={ticketsData}
            addTicket={addTicket}
            removeTicket={removeTicket}
            updateTicket={updateTicket}
            actionData={actionData}
            setActionData={setActionData}
            getAllTicketsData={getTicketsData}
            enableTicket={enableTicket}
            disableTicket={disableTicket}
          />
        </Col>
        <Col lg={5}>
          <div className="sticky-sidebar">
            {/* <AddressPage from="Edit Event Page" event={event} updateEvent={updateEvent} /> */}
            <EventDuration
              from="Edit Event Page"
              event={event}
              updateEvent={updateEvent}
            />
            <AdditionalInfoPage
              from="Edit Event Page"
              event={event}
              updateEvent={updateEvent}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
