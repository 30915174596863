import React from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import { ErrorMessage, Formik, Field } from "formik";
import FalconCardHeader from "../../../../../../components/common/FalconCardHeader";

export default function Rules({ amenityCurrent, updateAmenityRules }) {
  try {
    const customValidation = (values) => {
      // console.log(values);
      const errors = {};

      if (!values.amenity_rules) {
        errors.amenity_rules = "rules are required!";
      }

      // if (!values.volume_of_booking) {
      //   errors.volume_of_booking = "booking volume option is required!";
      // }
      // if (values.volume_of_booking === "Limit by Unit") {
      //   if (!values.limit_by_unit_value) {
      //     errors.limit_by_unit_value = "number of times per value!";
      //   }
      //   if (!values.limit_by_unit_date) {
      //     errors.limit_by_unit_date = "limit for booking is required!";
      //   }
      // }

      if (!values.max_guest_limit) {
        errors.max_guest_limit = "maximum guest limit is required!";
      }
      if (!values.allow_same_booking) {
        errors.allow_same_booking = "select this option also!";
      }
      if (values.allow_same_booking === "Yes") {
        if (!values.multiple_bookings_on_time_slots) {
          errors.multiple_bookings_on_time_slots = "select this option also!";
        }
      }

      return errors;
    };

    return (
      <div>
        <Formik
          initialValues={{
            approval: amenityCurrent.approval || "",
            amenity_rules: amenityCurrent.terms || "",
            // volume_of_booking: amenityCurrent.booking_volume_limit || "",
            allow_same_booking: amenityCurrent.allow_same_booking,
            // limit_by_unit_value:
              // amenityCurrent.booking_volume_limit_value || "",
            // limit_by_unit_date:
              // amenityCurrent.booking_volume_limit_duration || "",
            booking_day: "",
            booking_start_date: "",
            booking_end_date: "",
            duration_in_hours: amenityCurrent.duration || "",
            max_guest_limit: amenityCurrent.max_guest_limit || "",
            multiple_bookings_on_time_slots:
              amenityCurrent.overlaps_allowed || "",
          }}
          validate={(values) => customValidation(values)}
          onSubmit={(values, { setSubmitting }) => {
            updateAmenityRules(values);
            setSubmitting(false);
            // console.log(values);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            // setValues
            /* and other goodies */
          }) => (
            <div className="">
              <Card style={{ minHeight: "400px" }} className="mb-3">
                <FalconCardHeader title="Amenity Rules" />
                <Card.Body className="bg-light">
                  <div className="">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label>Terms & Conditions *</label>
                        <textarea
                          rows={5}
                          type="text"
                          name="amenity_rules"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.amenity_rules}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="amenity_rules"
                          component={"div"}
                          className="text-danger fs--1"
                        />
                      </div>

                      {/* <div className="mb-3">
                        <label>Volume of Booking *</label>{" "}
                        <label className="mx-3 ">
                          No Limit{" "}
                          <Field
                            type="radio"
                            name="volume_of_booking"
                            value="No Limit"
                          />
                        </label>
                        <label className="mx-3">
                          {" "}
                          Limit by unit{" "}
                          <Field
                            type="radio"
                            name="volume_of_booking"
                            value="Limit by Unit"
                          />
                        </label>
                        <ErrorMessage
                          name="volume_of_booking"
                          component={"div"}
                          className="text-danger fs--1"
                        />
                      </div>
                      {values.volume_of_booking === "Limit by Unit" && (
                        <Row className="mb-3">
                          <Col md={4}>
                            <Field
                              type="text"
                              name="limit_by_unit_value"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="limit_by_unit_value"
                              component={"div"}
                              className="text-danger fs--1"
                            />
                          </Col>
                          <Col className="text-center">times per</Col>
                          <Col md={4} className="ps-0">
                            <Field
                              component="select"
                              id="limit_by_unit_date"
                              name="limit_by_unit_date"
                              multiple={false}
                              className="form-select"
                            >
                              <option value="">select</option>
                              <option value="1 Day">Day</option>
                              <option value="1 Week">Week</option>
                              <option value="1 Month">Month</option>
                              <option value="1 Year">Year</option>
                            </Field>
                            <ErrorMessage
                              name="limit_by_unit_date"
                              component={"div"}
                              className="text-danger fs--1"
                            />
                          </Col>
                        </Row>
                      )}
                      <br /> */}

                      <Row className="mb-3">
                        <Col md={8}>
                          <label>Maximum guest limit per Booking *</label>
                        </Col>
                        <Col md={4}>
                          <input
                            type="text"
                            name="max_guest_limit"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.max_guest_limit}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="max_guest_limit"
                            component={"div"}
                            className="text-danger fs--1"
                          />
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <label>
                          Allow multiple bookings for this amenity ?
                        </label>{" "}
                        {/* <br /> */}
                        <label className="mx-3 ">
                          Yes{" "}
                          <Field
                            type="radio"
                            name="allow_same_booking"
                            value="Yes"
                          />
                        </label>
                        <label className="mx-3">
                          {" "}
                          No{" "}
                          <Field
                            type="radio"
                            name="allow_same_booking"
                            value="No"
                          />
                        </label>
                        <ErrorMessage
                          name="allow_same_booking"
                          component={"div"}
                          className="text-danger fs--1"
                        />
                      </div>
                      {values.allow_same_booking === "Yes" && (
                        // {values.multiple_bookings_on_time_slots === 'Yes' && (
                        <div className="mb-4 row">
                          <Col md={8}>
                            <label>
                              Number of Simultaneous Bookings for each time slot
                              *
                            </label>
                          </Col>
                          <Col md={4}>
                            <input
                              type="text"
                              name="multiple_bookings_on_time_slots"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.multiple_bookings_on_time_slots}
                              className="form-control"
                            />
                            <ErrorMessage
                              name="multiple_bookings_on_time_slots"
                              component={"div"}
                              className="text-danger fs--1"
                            />
                          </Col>
                        </div>
                      )}
                      <br />
                      <div>
                        <Button
                          variant="info"
                          className=" float-end"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </div>
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
        </Formik>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
