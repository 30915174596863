import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
export default function HotelStyle() {
  // try {
    return (
      <>
        <Row className="mb-3">
          <Col>
            <label>Start Time</label>
            <Field
              name="check_in_time_for_hotel_style"
              type="text"
              className="form-control"
              placeholder="HH:mm"
            />
            <ErrorMessage
              name="check_in_time_for_hotel_style"
              className="fs--1 text-danger"
              component={'div'}
            />
            <div className="d-flex justify-content-end mt-3">
              <label className="mx-3 ">
                <span className="h6">AM</span>{' '}
                <Field
                  type="radio"
                  name="check_in_time_zone_for_hotel_style"
                  value="AM"
                />
              </label>
              <label className="mx-3">
                {' '}
                <span className="h6">PM</span>{' '}
                <Field
                  type="radio"
                  name="check_in_time_zone_for_hotel_style"
                  value="PM"
                />
              </label>
            </div>
            <div className="text-end">
              <ErrorMessage
                name="check_in_time_zone_for_hotel_style"
                component={'div'}
                className="text-danger fs--1"
              />
            </div>
          </Col>
          <Col>
            <label>End Time</label>
            <Field
              name="check_out_time_for_hotel_style"
              type="text"
              className="form-control"
              placeholder="HH:mm"
            />
            <ErrorMessage
              name="check_in_time_for_hotel_style"
              className="fs--1 text-danger"
              component={'div'}
            />
            <div className="d-flex justify-content-end mt-3">
              <label className="mx-3 ">
                <span className="h6">AM</span>{' '}
                <Field
                  type="radio"
                  name="check_out_time_zone_for_hotel_style"
                  value="AM"
                />
              </label>
              <label className="mx-3">
                {' '}
                <span className="h6">PM</span>{' '}
                <Field
                  type="radio"
                  name="check_out_time_zone_for_hotel_style"
                  value="PM"
                />
              </label>
            </div>
            <div className="text-end">
              <ErrorMessage
                name="check_out_time_zone_for_hotel_style"
                component={'div'}
                className="text-danger fs--1"
              />
            </div>
          </Col>
        </Row>
        {/* <Row className="mb-3">
          <Col md={5}>
            <label>Maximum length of stay</label>
          </Col>
          <Col md={4}>
            <Field
              name="max_length_of_stay_hotel_style"
              type="text"
              className="form-control"
              placeholder="Enter here"
            />
            <ErrorMessage
              name="max_length_of_stay_hotel_style"
              component={'div'}
              className="text-danger fs--1"
            />
          </Col>
          <Col md={3}>
            <p className="text-end">Night (s)</p>
          </Col>
        </Row> */}
        {/* <Row>
          <Col>
            <div className="mb-3">
              <label>Amenity unavailable for certain days</label>
              <Field
                name="amenity_unavailable_for_hotel_style"
                type="date"
                className="form-control"
              />
              <ErrorMessage
                name="amenity_unavailable_for_hotel_style"
                component={'div'}
                className="text-danger fs--1"
              />
            </div>
          </Col>
        </Row> */}
      </>
    );
  // } catch (e) {
  //   console.log(e);
  //   return (<p className="text-danger">{e.message}</p>);
  // }
}
