import React from "react";

import { Form as BSForm, Button, Row, Col, Image } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CalendarToday } from "@mui/icons-material";
import DatePicker from "react-datepicker";

import axios from "axios";
import { toast } from "react-toastify";

import { AuthWizardContext } from "context/Context";
import { CloudUpload } from "@mui/icons-material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "./muitimepicker.css";

const VisitorBookingDetails = () => {
  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const forInputrefHSS = React.useRef(null);

  const handeInputRefHSS = () => {
    forInputrefHSS.current?.setFocus();
  };

  const forInputrefHSE = React.useRef(null);

  const handeInputRefHSE = () => {
    forInputrefHSE.current?.setFocus();
  };

  const handleSubmit = (data) => {
    console.log(data);
    setUser(Object.assign({}, user, data));
    setStep(step + 1);
  };

  const handleDateUpdate = (e, setFieldValue, isEndDate, values) => {
    let date = e;
    let date_formatted = new Date(date);

    let days = 0;

    if (!isEndDate) {
      setFieldValue("start_date", date);
      if (date > values.end_date) {
        setFieldValue("end_date", date);
        // setFieldValue("end_date", addDays(date, 1));
        // setFieldValue("event_registration_deadline", date);
      }
      days = getDaysFromDatesRange(date_formatted, values.end_date);
    }
    if (isEndDate) {
      setFieldValue("end_date", date);
      days = getDaysFromDatesRange(values.start_date, date_formatted);
    }

    console.log(days);
  };

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  const getDaysFromDatesRange = (start, end) => {
    let days = (end - start) / (1000 * 3600 * 24);
    days = Math.ceil(days);
    days++; // includes last day
    console.log(days);

    return days;
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({}, user, values));

    setStep(step - 1);
  };

  return (
    <div>
      <Formik
        initialValues={{
          start_date: user.start_date || "",
          end_date: user.end_date || "",
          start_time: dayjs(user.start_time?.$d) || "",
          end_time: dayjs(user.end_time?.$d) || "",
        }}
        validationSchema={Yup.object({
          start_date: Yup.string().required("this option is required!"),
          end_date: Yup.string().required("this option is required!"),
          start_time: Yup.string().required("this option is required!"),
          end_time: Yup.string().required("this option is required!"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <BSForm.Group>
                  <BSForm.Label>Arrival Date</BSForm.Label>
                  <div className=" position-relative">
                    <DatePicker
                      ref={forInputrefHSS}
                      // showIcon
                      placeholderText="Select Start Date"
                      selected={values.start_date}
                      name="start_date"
                      type="date"
                      onChange={(e) =>
                        handleDateUpdate(e, setFieldValue, false, values)
                      }
                      onBlur={handleBlur}
                      value={values.start_date}
                      className="form-control"
                      minDate={new Date()}
                      // maxDate={values.event_end_date}
                    />
                    <span
                      className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                      onClick={handeInputRefHSS}
                    >
                      <CalendarToday fontSize="small" />
                    </span>
                  </div>
                  <ErrorMessage
                    name="start_date"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
              <Col md={6}>
                <BSForm.Group>
                  <BSForm.Label>Arrival Time</BSForm.Label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      // className="bg-danger"
                      reduceAnimations
                      sx={{ width: "100%" }}
                      className="visitor-mgmt-start-timer"
                      label=""
                      value={values.start_time}
                      name="start_time"
                      onChange={(newValue) =>
                        setFieldValue("start_time", newValue.$d)
                      }
                    />
                  </LocalizationProvider>

                  <ErrorMessage
                    name="start_time"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <BSForm.Group>
                  <BSForm.Label>Departure Date</BSForm.Label>
                  <div className=" position-relative">
                    <DatePicker
                      ref={forInputrefHSE}
                      // showIcon
                      placeholderText="Select End Date"
                      selected={values.end_date}
                      name="end_date"
                      type="date"
                      onChange={(e) =>
                        handleDateUpdate(e, setFieldValue, true, values)
                      }
                      onBlur={handleBlur}
                      value={values.end_date}
                      className="form-control"
                      minDate={values.start_date}
                      // maxDate={values.event_end_date}
                    />
                    <span
                      className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                      onClick={handeInputRefHSE}
                    >
                      <CalendarToday fontSize="small" />
                    </span>
                  </div>
                  <ErrorMessage
                    name="end_date"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
              <Col md={6}>
                <BSForm.Group>
                  <BSForm.Label>Departure Time</BSForm.Label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      className="visitor-mgmt-end-timer"
                      reduceAnimations
                      sx={{ width: "100%" }}
                      label=""
                      value={values.end_time}
                      name="end_time"
                      onChange={(newValue) =>
                        setFieldValue("end_time", newValue.$d)
                      }
                    />
                  </LocalizationProvider>

                  <ErrorMessage
                    name="end_time"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
            </Row>

            <br />
            <div className="">
              <Button
                variant="secondary"
                type="button"
                onClick={() => handleStepMinus(values)}
              >
                Previous
              </Button>
              <Button
                variant="primary"
                className="float-end"
                type="submit"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VisitorBookingDetails;
