import BSSliderCustom from '../../../../components/common/BSSliderCustom';
import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function AmenityImagesModal(props) {
  try {
    const { show, handleClose,images } = props;
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          scrollable
          size='lg'
          style={{'backdropFilter':'blur(3px)'}}
        >
          <Modal.Header closeButton>
            <Modal.Title>Amenity Images</Modal.Title>
          </Modal.Header>
          <Modal.Body className='rounded-4'>
            <BSSliderCustom items={images} fromShowAmenities fromReviewAmenity/>
          </Modal.Body>
          
        </Modal>
      </>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
