import React from "react";
import { Row, Col, Form as BSForm, Card } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Profile = ({ unitDetails, updateUnit }) => {
  try {
    console.log(unitDetails);

    return (
      <div>
        <Card style={{ height: "90vh" }}>
          <Card.Header>
            <h5>Unit Details</h5>
          </Card.Header>
          <Card.Body className="bg-light">
            <Formik
              initialValues={{
                unit_number: unitDetails.unit_number || "",
                unit_type: unitDetails.unit_type || "",
                cef: unitDetails.cef || "",
                notes: unitDetails.description || "",
                // unit_area: '',
                // parking_number: '',
                // locker: '',
                invited_user:
                  (unitDetails.type === "Invited"
                    ? unitDetails.Inviteduser
                    : unitDetails.type === "Registered"
                    ? unitDetails.Owneremail
                    : "") || "",
              }}
              validationSchema={Yup.object().shape({
                unit_number: Yup.string().required(
                  "Please enter unit number to continue!"
                ),
                unit_type: Yup.string().required("Please select unit type!"),
                cef: Yup.string().required("condo element fee is required!"),
                notes: Yup.string().required(
                  "Please enter unit number to continue!"
                ),
                invited_user: Yup.string().required(
                  "Please enter unit number to continue!"
                ),
                // unit_area: Yup.string().required(
                //   'Please enter Area value!'
                // ),
                // parking_number: Yup.string().required(
                //   'Enter parking number to continue!'
                // ),
                // locker: Yup.string().required('Locker number is required!')
              })}
              onSubmit={(values, { setSubmitting }) => {
                // if (props.headerData.cef + +values.cef > 100) {
                //   setIsFull(true);
                //   return;
                // } else {
                //   setIsFull(false);
                // }
                // props.addUnitInfo(values);
                console.log(values);
                // alert(values);
                updateUnit(values);
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                handleBlur,
                handleChange,
                values,
                // handleReset
              }) => (
                <Form>
                  <Row className="mb-3">
                    <Col md={6}>
                      <BSForm.Label>
                        Unit Number <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="text"
                        name="unit_number"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="unit_number"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>

                    <Col md={6}>
                      <BSForm.Label>
                        & CEF <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field type="text" name="cef" className="form-control" />
                      <ErrorMessage
                        name="cef"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <BSForm.Label>
                        Unit Type <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        component="select"
                        id="unit_type"
                        name="unit_type"
                        // multiple={true}
                        className="form-select"
                      >
                        <option value="">Select unit type</option>
                        <option value="condo">Condo</option>
                        <option value="Locker">Locker</option>
                        <option value="Parking Spot">Parking Spot</option>
                      </Field>
                      <ErrorMessage
                        name="unit_type"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                    {/* <Col md={6}>
                    <BSForm.Label>
                    Area (in sq.ft) 
                    </BSForm.Label>
                    <Field
                    type="text"
                    name="unit_area"
                    className="form-control"
                    />
                    <ErrorMessage
                    name="unit_area"
                      component="div"
                      className="text-danger m-2 fs--1"
                      />
                    </Col> */}
                    {unitDetails.type === "Pending" ? (
                      <Col md={6} className="d-flex align-items-end">
                        <p className="text-info">
                          Please Add Owner to continue!
                        </p>
                      </Col>
                    ) : (
                      <Col md={6}>
                        <BSForm.Label>
                          Unit Owner Email
                          {/* <span className="text-danger">*</span> */}
                        </BSForm.Label>
                        <Field
                          type="text"
                          name="invited_user"
                          className="form-control"
                          disabled
                        />
                        <ErrorMessage
                          name="invited_user"
                          component="div"
                          className="text-danger m-2 fs--1"
                        />
                      </Col>
                    )}
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Notes <span className="fs--1">*</span>
                      </BSForm.Label>

                      <BSForm.Control
                        as={"textarea"}
                        rows={5}
                        type="text"
                        name="notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notes}
                      />
                      <ErrorMessage
                        name="notes"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  {/* <Row className="mb-3">
                  <Col md={6}>
                  <BSForm.Label>
                  Parking Number 
                  </BSForm.Label>
                    <Field
                    type="text"
                    name="parking_number"
                      className="form-control"
                      />
                      <ErrorMessage
                      name="parking_number"
                      component="div"
                      className="text-danger m-2 fs--1"
                      />
                  </Col>
                  <Col md={6}>
                  <BSForm.Label>
                  Locker 
                    </BSForm.Label>
                    <Field type="text" name="locker" className="form-control" />
                    <ErrorMessage
                    name="locker"
                      component="div"
                      className="text-danger m-2 fs--1"
                      />
                      </Col>
                    </Row> */}
                  <br />
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-success float-end"
                    // onClick={() =>
                    //   setTimeout(() => {
                    //     handleReset();
                    //   }, 500)
                    // }
                  >
                    Update--1
                  </button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default Profile;
