import React from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Badge, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { startCase } from "lodash";

import LaunchIcon from "@mui/icons-material/Launch";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";

import { toast } from "react-toastify";
import { getHostName } from "helpers/utils";

const ProfileDropdown = (props) => {
  const { userData } = props;

  let hostname = getHostName();

  const { t } = useTranslation();

  //const signOut = useSignOut();

  const [formData, setFormData] = React.useState({
    avatar: props.avatar || "",
  });

  const [unitList, setUnitList] = React.useState([]);

  //const { unit_number, unit_id } = useParams();

  const { avatar } = formData;

  const cookies = new Cookies();

  const user_id = userData.data;
  const navigate = useNavigate();

  React.useEffect(() => {
    getProfileImg();
    getAllUnitsFromUData();
  }, []);

  const getProfileImg = () => {
    axios
      .post(`${hostname}/api/auth/imageR`, { user_id })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success == true) {
          setFormData({
            avatar: res.data.link,
          });
        } else {
          // setFormData({
          //   avatar: seperate,
          // });
        }
      })

      .catch((err) => {
        // console.log(err);
        // setFormData({
        //   avatar: seperate,
        // });
      });
  };

  const getAllUnitsFromUData = () => {
    let data = userData;
    if (data.UnitsOwner !== "none") setUnitList(data.UnitsOwner);
  };

  const handleLogout = async () => {
    let rlink = cookies.get("_expire_l") || "/login";

    await clearTokenCookie();
    cookies.remove("_expire_t");
    cookies.remove("_expire_l");

    window.location.href = rlink;
  };

  const clearTokenCookie = () => {
    axios.post(`${hostname}/api/auth/clearTokenCookie`);
  };

  const clearCoolkiesnData = () => {
    if (userData === null || userData === undefined) {
      navigate("/login");
      return;
    }
    if (
      userData?.role === "Site-Administrators" || // manager
      userData?.role === "Condozak-Basic-Users" || // company basic user
      userData?.role === "No Roles Assigned" // company invited user
    ) {
      // navigate("/login");
      window.location.href = "/login";
    }
    if (
      userData?.role === "unit_owner" || // owner
      userData?.role === "none" // invited owner
    ) {
      window.location.href = "/property/login";
      // navigate("/property/login");
    }

    toast.success("Logout Successful", { theme: "colored" });

    // cookies.remove("authToken", { path: "/" });
    // cookies.remove("email", { path: "/" });
    // cookies.remove("xyz", { path: "/" });

    cookies.remove("token", { path: "/" });
    cookies.remove("userData", { path: "/" });
    cookies.remove("email_not_auth", { path: "/" });
    cookies.remove("link_auth", { path: "/" });

    /// cookies.remove('token');
    // clearTokenCookie();

    // cookies.remove("buildingDetails");
  };

  if (userData)
    return (
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          {/* <Avatar src={avatar} /> */}
          <Avatar
            sx={{
              bgcolor: userData?.bgColor,
              fontSize: "14px",
              fontWeight: "400",
              width: "30px",
              height: "30px",
            }}
            // className="img-thumbnail"
            src={avatar}
          >
            {startCase(userData?.firstname[0]) +
              startCase(userData?.lastname[0])}
          </Avatar>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item
              as="span"
              className="fw-bold text-warning"
              style={{ cursor: "default" }}
              // href="#!"
            >
              {/* <FontAwesomeIcon icon="crown" className="me-1" /> */}
              <span>
                {userData.firstname || "Name"} {userData.lastname || "Here"}
              </span>
            </Dropdown.Item>
            <Dropdown.Item
              as="span"
              // href="#!"
              style={{ cursor: "default" }}
            >
              {userData.email || "Name@gmail.com"}
            </Dropdown.Item>
            <Dropdown.Item style={{ cursor: "default" }} as="span">
              {startCase(
                userData.role === "unit_owner"
                  ? userData.property_name
                  : userData.company_name
              ) || "buisness name here"}
            </Dropdown.Item>

            {userData && userData.role === "unit_owner" && (
              <>
                <Dropdown.Divider />
                {unitList?.map((v, i) => (
                  <Dropdown.Item
                    className="text-primary fw-bold mb-2"
                    key={v.unit_number}
                    href={`/owner-portal/all-units/${v._id}/${v.unit_number}`}
                  >
                    Unit # {v.unit_number || "number"}{" "}
                    {i === 0 && (
                      <Badge className="bg-primary float-end">default</Badge>
                    )}
                  </Dropdown.Item>
                ))}
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      `/owner-portal/all-units/${userData.UnitsOwner[0]._id}/${userData.UnitsOwner[0].unit_number}`
                    )
                  }
                >
                  View all
                  <LaunchIcon className="float-end" fontSize="small" />
                </Dropdown.Item>
              </>
            )}

            <Dropdown.Divider />
            <Dropdown.Item as={Link} to="/user/settings">
              {t("topbar_right.settings")}
            </Dropdown.Item>

            {/* <Dropdown.Item onClick={() => (window.location.href = "/logout")}>
              {t("topbar_right.logout")}
            </Dropdown.Item> */}
            <Dropdown.Item onClick={() => handleLogout()}>
              {t("topbar_right.logout")}
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );

  return <p>loading</p>;
};

export default ProfileDropdown;
