import React from "react";

import { Link } from "react-router-dom";

import { Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "components/common/SoftBadge";

import FalconCardHeader from "components/common/FalconCardHeader";
import { formatLogInfo } from "helpers/utils";

const StudentInfo = ({ currentUser }) => {
  return (
    <Card className="font-sans-serif" style={{ minHeight: "300px" }}>
      <FalconCardHeader title="View Details" />
      <Card.Body className="d-flex gap-3 flex-column flex-sm-row align-items-center bg-light">
        <Table
          borderless
          className=" fw-medium mb-0 mt-0 text-left ms-5"
          cellSpacing={5}
        >
          <tbody>
            <tr>
              <td className="p-1" style={{ width: "35%", textAlign: "left" }}>
                Name
              </td>
              <td className="p-1 text-600">{`${currentUser.firstname}  ${currentUser.lastname}`}</td>
            </tr>
            <tr>
              <td className="p-1" style={{ width: "35%" }}>
                Last Login:
              </td>
              <td className="p-1 text-600">
                {currentUser.LogInfo ? currentUser.LogInfo : "-" || "-"}
              </td>
            </tr>
            <tr>
              <td className="p-1" style={{ width: "35%" }}>
                Joined:
              </td>
              <td className="p-1 text-600">
                {currentUser.createdAt
                  ? formatLogInfo(currentUser.createdAt)
                  : "-" || "-"}
              </td>
            </tr>
            <tr>
              <td className="p-1" style={{ width: "35%" }}>
                Email:
              </td>
              <td className="p-1">
                <Link to="#!" className="text-600">
                  {currentUser.email || "email"}
                </Link>
              </td>
            </tr>
            <tr>
              <td className="p-1" style={{ width: "35%" }}>
                Status
              </td>
              <td className="p-1">
                {currentUser.verified ? (
                  currentUser.Status === "1" ? (
                    <SoftBadge bg={"success"} pill className="">
                      {"active"}
                      <FontAwesomeIcon icon="check" className="ms-2" />
                    </SoftBadge>
                  ) : (
                    <SoftBadge bg={"danger"} pill className="">
                      {"revoked"}
                      <FontAwesomeIcon icon="check" className="ms-2" />
                    </SoftBadge>
                  )
                ) : (
                  <SoftBadge bg={"info"} pill className="">
                    {"invited"}
                    <FontAwesomeIcon icon="check" className="ms-2" />
                  </SoftBadge>
                )}
              </td>
            </tr>
            <tr>
              <td className="p-1" style={{ width: "35%" }}>
                Mobile No:
              </td>
              <td className="p-1">
                <Link to="tel:+01234567890" className="text-600">
                  +012-345-67890
                </Link>
                {/* <SoftBadge
                  bg="primary"
                  pill
                  className="d-none d-md-inline-block ms-2"
                >
                  2-step verification
                  <FontAwesomeIcon icon="link" className="ms-1" />
                </SoftBadge> */}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default StudentInfo;
