import React from "react";
import WizardLayout from "./WizardLayout";
import AuthWizardProvider from "./AuthWizardProvider";

const Wizard = ({
  variant,
  validation,
  progressBar,
  handleClose,
  getAmenitiesData,

  userData,
  hostname,
  jwtToken,
}) => {
  return (
    <AuthWizardProvider>
      <WizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
        handleClose={handleClose}
        getAmenitiesData={getAmenitiesData}
        userData={userData}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    </AuthWizardProvider>
  );
};

export default Wizard;
