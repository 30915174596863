import Calendar from "components/common/Calendar";
// import FalconCardHeader from "components/common/FalconCardHeader";
import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function EventDetailPrice({
  event,
  registerEvent,
  viewEventTickets,
  BuyMoreEventTickets,
  userData = {},
}) {
  console.log(event.event_ticket?.event_ticket_type);
  console.log(event);

  const getLiveEventValue = () => {
    let currentDate = new Date();

    let givenDate = new Date(event.event_end_date);

    // console.log(givenDate);
    // console.log(currentDate);
    currentDate.setDate(currentDate.getDate() - 1);

    if (currentDate > givenDate) {
      console.log("this is past event", givenDate);
      return true;
    } else if (currentDate <= givenDate) {
      console.log("this is future event plus today", givenDate);
      return false;
    } else {
      console.log("equal", givenDate);
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body style={{ minHeight: "200px" }} className="">
        <div className="d-flex">
          <Calendar {...event?.calendar} />
          <div className="fs--1 ms-5">
            <h5 className="fs-0 text-capitalize">{event.event_name}</h5>
            <p className="mb-0 text-capitalize">
              by
              <Link className=" ms-1 " to="#!">
                {event.event_organizer}
              </Link>
            </p>
            {event.event_ticket?.event_ticket_type === "Free Ticket" && (
              <span className="fs-0 text-warning fw-semi-bold">Free</span>
            )}
            {event.event_ticket?.event_ticket_type === "Paid Ticket" && (
              <span className="fs-0 text-warning fw-semi-bold">
                ${event.event_paid_price}
              </span>
            )}
            {event.event_ticket?.event_ticket_type === "Donation Ticket" && (
              <span className="fs-0 text-warning fw-semi-bold">
                ${event.event_paid_price}
              </span>
            )}
          </div>
        </div>

        <div className="text-center">
          {!event.isRegistered &&
            userData.role === "unit_owner" &&
            !getLiveEventValue() && (
              <Button
                variant="falcon-primary"
                size="sm"
                className="  px-4 px-sm-5 mt-5"
                onClick={() => registerEvent(event)}
              >
                Register Now
              </Button>
            )}
          {event.isRegistered &&
            userData.role === "unit_owner" &&
            !getLiveEventValue() && (
              <>
                {/* <Button
                  variant="falcon-primary"
                  size="sm"
                  className="px-4 px-sm-5  my-3"
                  onClick={() => viewEventTickets(event)}
                >
                  View Tickets
                </Button> */}
                <Button
                  variant="falcon-info"
                  size="sm"
                  className="px-4 px-sm-5 mt-3"
                  onClick={() => BuyMoreEventTickets(event)}
                >
                  Buy More
                </Button>
                <br />
              </>
            )}
          {event.isRegistered && userData.role === "unit_owner" && (
            <>
              <Button
                variant="falcon-primary"
                size="sm"
                className="px-4 px-sm-5  my-3"
                onClick={() => viewEventTickets(event)}
              >
                View Tickets
              </Button>
              {/* <Button
                  variant="falcon-info"
                  size="sm"
                  className="px-4 px-sm-5"
                  onClick={() => BuyMoreEventTickets(event)}
                >
                  Buy More
                </Button> */}
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
