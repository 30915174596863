import React from "react";

import axios from "axios";
// import Cookies from "universal-cookie";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { dataURLtoFile } from "../dataURLtoFile";

import ProfileSettings from "./ProfileSettings";
import ProfileImage from "./ProfileImage";
import AddressUser from "./AddressUser";
import ChangePassword from "./ChangePassword";
import EmailNotificationsSettings from "./EmailNotificationSettings";
import PreferencesForm from "./PreferencesForm";
import DangerZone from "./DangerZone";
import Loader from "components/common/Loader";

// import useAuthUser from "react-auth-kit/hooks/useAuthUser";
// import useSignIn from "react-auth-kit/hooks/useSignIn";

import { getHostName } from "helpers/utils";

import Cookies from "universal-cookie";
// import jwt_decode from "jwt-decode";
import jwtDecode from "jwt-decode";

const Settings = () => {
  // const userData = useAuthUser();
  // const signIn = useSignIn();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  let hostname = getHostName();
  //console.log(hostname);

  const [showButton, setShowButton] = React.useState(false);
  const [CancelButton, setCancelButton] = React.useState(false);

  const [userProfile, setUserProfile] = React.useState({});
  const [userProfileImage, setUserProfileImage] = React.useState({});
  const [userAddress, setUserAddress] = React.useState({});

  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    showUserProfile();
    showUserImage();
    showUserAddress();
  }, []);

  const showUserProfile = () => {
    axios
      .post(
        `${hostname}/api/auth/show`,
        {
          email: userData.email,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);

        setTimeout(() => {
          setLoader(false);
        }, 300);

        setUserProfile({ ...res.data });
        // toast.success("Updated");
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    // else console.log("login 1st");
  };

  const updateUserProfile = async (userProfile) => {
    try {
      const res = await axios.post(
        `${hostname}/api/auth/update`,
        {
          ...userProfile,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      //console.log(res.data);
      setUserProfile({ ...res.data });
      let userDatalone = { ...userData };
      userDatalone.email = userProfile.email2;
      userDatalone.firstname = userProfile.firstname;
      userDatalone.lastname = userProfile.lastname;

      const res2 = await axios.post(
        `${hostname}/api/property/tokenRefresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      toast.success("profile updated", { theme: "colored" });
      cookies.set("_expire_t", res2.data.Data);

      setTimeout(() => {
        window.location.reload();
      }, 400);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!", { theme: "colored" });
      // setLoader(false);
    }
  };

  const showUserImage = () => {
    if (userData.email)
      axios
        .post(
          `${hostname}/api/auth/imageR`,
          {
            user_id: userData.data,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          // if (res.data.success == true) {
          //   setTimeout(() => {
          //     setLoader(false);
          //   }, 300);
          setUserProfileImage({ ...res.data });
          // } else {
          setLoader(false);
          // }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (event) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUserProfileImage({ img: event.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      setShowButton(true);
      setCancelButton(true);
    };

    input.click();
  };

  const handleSave = async () => {
    setShowButton(false);
    setCancelButton(false);
    setLoader(true);
    try {
      const data = dataURLtoFile(userProfileImage.img, "profile-picture");
      const formData = new FormData();
      formData.append("croppedImage", data);
      formData.append("user_id", userData.data);
      await axios.post(`${hostname}/api/property/propertynmi`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setTimeout(() => {
        setLoader(false);
        window.location.reload();
      }, 300);
      toast.success("Success", {
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error" + error, {
        theme: "colored",
      });
      console.error(error);
    }
  };

  const handleDelete = () => {
    axios
      .post(
        `${hostname}/api/property/DeleteProfilePicture`,
        {
          user_id: userData.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = async () => {
    setShowButton(false);
    setCancelButton(false);
    setLoader(true);
    showUserImage();
  };

  const updateUserPassword = (psObj) => {
    // console.log(psObj);
    setLoader(true);
    if (userData.email)
      axios
        .post(
          `${hostname}/api/auth/changepassword`,
          {
            email: userData.email,
            password: psObj.password,
            newpassword: psObj.new_password,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then(() => {
          //console.log(res.data);
          setTimeout(() => {
            setLoader(false);
          }, 300);
          toast.success("Update successful", { theme: "colored" });
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          toast.error(err.response.data.error, { theme: "colored" });
        });
  };

  const showUserAddress = () => {
    setLoader(true);

    if (userData.data)
      axios
        .post(
          `${hostname}/api/auth/showaddress`,
          {
            user_id: userData.data,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          setUserAddress({ ...res.data.user });
          setTimeout(() => {
            setLoader(false);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
  };

  const updateUserAddress = (userAddress) => {
    console.log(userAddress);
    // return;
    setLoader(true);
    if (userData.data)
      axios
        .post(
          `${hostname}/api/auth/Address`,
          {
            ...userAddress,
            user_id: userData.data,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          setUserAddress({ ...userAddress });
          setTimeout(() => {
            setLoader(false);
          }, 300);
          toast.success(res.data.data, { theme: "colored" });
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);

          toast.error(err.response.data.error, { theme: "colored" });
        });
  };

  if (loader) return <Loader />;

  return (
    <>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings
            userProfile={userProfile}
            updateUserProfile={updateUserProfile}
            userData={userData}
            jwtToken={jwtToken}
            hostname={hostname}
          />
          <AddressUser
            userAddress={userAddress}
            updateUserAddress={updateUserAddress}
            loader={loader}
          />
          <ChangePassword updateUserPassword={updateUserPassword} />
          <EmailNotificationsSettings />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <ProfileImage
              userProfileImage={userProfileImage}
              userData={userData}
              // avatarProp={formData.avatar}
              showButton={showButton}
              CancelButton={CancelButton}
              // uploadedFileName={uploadedFileName}
              handleClick={handleClick}
              handleCancel={handleCancel}
              handleSave={handleSave}
              handleDelete={handleDelete}
            />
            <PreferencesForm
              userData={userData}
              jwtToken={jwtToken}
              hostname={hostname}
            />
            <DangerZone />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
