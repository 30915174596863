import React from 'react';
import { Card, Accordion, Row, Col } from 'react-bootstrap';
import FalconCardHeader from '../../../../../../components/common/FalconCardHeader';
const ReportAccordian = ({ day, i, slots = [] }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey={i}>
        <Accordion.Header>
          Time Slots for <span className="text-info ms-1">{day}</span>
        </Accordion.Header>
        <Accordion.Body className="text-center">
          <ul className="list-unstyled">
            {slots?.map((v, i) => (
              <li key={i + v} className="mb-2">
                {v}
              </li>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default function TimeSlots({ user, timeSlotsAll }) {
  try {
    return (
      <Card className="mb-3">
        <FalconCardHeader title="Selected Times" />
        <Card.Body className="bg-light">
          {timeSlotsAll?.map(({ day, start, end }, i) => (
            <div key={i + 11} className="mb-3">
              <ReportAccordian
                i={i}
                day={day}
                start={start}
                end={end}
                slots={user.timeslotsReviewUser[day]}
              />
            </div>
          ))}
          <br />
          <Row className="mb-3">
            <Col>Minimum Booking Lead Time</Col>
            <Col>{user.min_book_lead_time}</Col>
          </Row>
          <Row className="mb-3">
            <Col>Maximum Booking Lead Time</Col>
            <Col>{user.max_book_lead_time}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
