import React from "react";

import classNames from "classnames";
import { Alert, Card, Row, Col, Form } from "react-bootstrap";
//import InboxRow from "./InboxRow";
import SoftBadge from "components/common/SoftBadge";
// import Avatar from "components/common/Avatar";
import EmailAttachment from "./EmailAttachment";
import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
// import { Avatar } from "@mui/material";

const InboxRow = ({
  email,
  displaySingleEmailData,
  bulkMails,
  addBulkAction,
  previewAttachment,
}) => {
  const {
    id,
    // img,
    read,
    // star,
    time,
    title,
    // user,
    sender_name,
    description,
    badge,
    attachments,
  } = email;

  return (
    <Row
      className={classNames(
        "border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 align-items-center",
        {
          "bg-light": read,
        }
      )}
    >
      <Col xs="auto" className="d-none d-sm-block align-self-start">
        <Flex alignItems="center">
          {/* <Form.Check
            type="checkbox"
            id="inboxBulkSelect"
            className="form-check mb-0 fs-0"
            checked={bulkMails?.includes(id)}
            onChange={() =>
              setSelectedItems(selectedItems?.filter((v) => v !== id))
            }
          /> */}
          <Form.Check
            id={id}
            onChange={() => addBulkAction(id)}
            checked={bulkMails?.includes(id)}
          />
        </Flex>
      </Col>
      <Col
        xs
        md={9}
        xxl={10}
        onClick={() => displaySingleEmailData({ mail_id: email.mail_id })}
      >
        <Row>
          <Col md={4} xl={3} xxl={2} className="ps-md-0 mb-1 mb-md-0">
            <Flex className="position-relative">
              {/* <Avatar src={img} size="s" rounded="soft" /> */}
              {/* <Avatar
                sx={{
                  height: "20px",
                  width: "20px",
                  fontSize: "11px",
                  bgcolor: "green",
                }}
              >
                U
              </Avatar> */}
              <div className="">
                {/* flex-1 ^^^ */}
                <Link
                  to="#!"
                  className={classNames(
                    "stretched-link inbox-link text-primary",
                    {
                      "fw-bold": !read,
                    }
                  )}
                >
                  {sender_name || "no-reply"}
                </Link>
                {!!badge && (
                  <SoftBadge bg="success" className="ms-2">
                    {badge}
                  </SoftBadge>
                )}
              </div>
            </Flex>
          </Col>
          <Col>
            <span className="d-block inbox-link cursor-pointer">
              <span className={classNames({ "fw-bold": !read })}>{title}</span>
              <span className="mx-1">&ndash;</span>
              {/* <span className="" dangerouslySetInnerHTML={{ __html: description }}></span> */}
              <span>{description}</span>
            </span>
            {attachments?.map((attachment, i) => (
              <EmailAttachment
                attachment={attachment}
                key={`${attachment.name} ${i}`}
                previewAttachment={previewAttachment}
                displaySingleEmailData={displaySingleEmailData}
                email={email}
              />
            ))}
          </Col>
        </Row>
      </Col>
      <Col
        onClick={() => displaySingleEmailData({ mail_id: email.mail_id })}
        xs="auto"
        as={Flex}
        direction="column"
        justifyContent="between"
        className="ms-auto align-self-start cursor-pointer"
      >
        <span className={classNames({ "fw-bold": !read })}>{time}</span>
      </Col>
    </Row>
  );
};

export default function InboxTable({
  list,
  displaySingleEmailData,
  bulkMails,
  addBulkAction,
  previewAttachment,
}) {
  // const unreadEmails = list.filter((email) => !email.read);
  // const otherEmails = list.filter((email) => email.read);
  console.log(list);
  console.log(list.length);

  return (
    <div>
      <Card.Body className="fs--1 border-top border-200 p-0">
        <>
          {list?.map((email) => (
            <InboxRow
              email={email}
              key={email.id}
              displaySingleEmailData={displaySingleEmailData}
              bulkMails={bulkMails}
              addBulkAction={addBulkAction}
              previewAttachment={previewAttachment}
            />
          ))}
        </>

        {list?.length < 1 && (
          <Alert variant="info" className="mb-0 rounded-0">
            <h5 className="alert-heading">Inbox empty!</h5>
            <hr />
            <p className="mb-0">Emails will be shown here automatically.</p>
          </Alert>
        )}
      </Card.Body>
    </div>
  );
}
