// import CustomFilterbar from "components/common/CustomFilterbar";
import CustomSearchbar from "components/common/CustomSearchbar";
import Flex from "components/common/Flex";
import React from "react";
import { Row, Col, Card, Breadcrumb, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
// import Cookies from "universal-cookie";
import VisitorsModal from "./VisitorsModal";
import VisitorsTable from "./VisitorsTable";
import axios from "axios";
// import dateLib from "date-and-time";
import Loader from "components/common/Loader";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

export default function VisitorManagement({ from = "" }) {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_name, property_id, unit_id, unit_number } = useParams();

  const [loader, setLoader] = React.useState(false);

  const [visitorsList, setVisitorsList] = React.useState([]);
  const [visitorsListTemp, setVisitorsListTemp] = React.useState([]);

  const [showModal, setShowModal] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const getAllVisitorsData = () => {
    let link = "";
    let input = {};

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      link = `${hostname}/api/property/GetAllVisitors`;
      input = { p_id: property_id };
    }
    if (userData.role === "unit_owner") {
      link = `${hostname}/api/property/GetUnitVisitors`;
      input = { unit_id: userData.UnitsOwner[0]._id };
    }

    console.log(input);

    // return;
    //
    // call api

    axios
      .post(
        link,
        { ...input },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let arr = res.data.Data?.map((v) => {
          let un = "";
          if (
            userData.role === "Site-Administrators" ||
            userData.role === "Condozak-Basic-Users"
          ) {
            un = v.unit_id.unit_number;
          }
          if (userData.role === "unit_owner") {
            un = userData.UnitsOwner[0].unit_number;
          }
          v.unit_number = un;

          // v.start_date = v.arrival_date;
          // v.start_time = dateLib.format(new Date(v.arrival_time), "hh:mm A [GMT]Z",true);
          // v.end_date = v.dep_date;
          // v.end_time = v.dep_date;
          return v;
        });
        console.log(arr);

        arr.sort((a, b) =>
          a.arrival_date < b.arrival_date
            ? 1
            : b.arrival_date < a.arrival_date
            ? -1
            : 0
        );

        if (from === "singleUnit") {
          arr = arr?.filter((v) => v.unit_id.unit_number === unit_number);
        }

        setVisitorsList(arr);
        setVisitorsListTemp(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    // setLoader(true);
    getAllVisitorsData();
  }, []);

  const addNewUser = () => {
    setPayload({});
    let obj = {};
    obj.loadType = "Add New Visitor";
    obj.property_id = property_id;
    obj.userData = userData;
    // obj.unit_id = unit_id;,
    setPayload({ ...obj });
    handleShow();
  };

  const viewUserDetails = (obj = {}) => {
    setPayload({});
    obj.loadType = "View User Details";
    obj.property_id = property_id;
    obj.userData = userData;
    // obj.unit_id = unit_id;
    setPayload({ ...obj });
    handleShow();
  };

  if (loader) return <Loader />;
  return (
    <div>
      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") &&
        from === "singleUnit" && (
          <Row className="g-3 mb-3">
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                  <Link className="me-2 text-secondary" to="/dashboard">
                    Home
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to="/property/management/buildings"
                  >
                    Properties
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                  >
                    {property_name}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/units`}
                  >
                    Units
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-primary"
                    // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/history`}
                    to="#!"
                  >
                    {unit_number}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-primary"
                    // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                    to={"#!"}
                  >
                    Visitor Management
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") &&
        from !== "singleUnit" && (
          <Row className="g-3 mb-3">
            <Col>
              <Card>
                <Card.Body className="">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link className="text-secondary" to={"/"}>
                        Home
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        className="text-secondary"
                        to={"/property/management/buildings"}
                      >
                        Properties
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link className="text-secondary" to={"#!"}>
                        {property_name}
                      </Link>
                    </Breadcrumb.Item>{" "}
                    <Breadcrumb.Item active>
                      <Link to={"#!"}>Visitor Management</Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      {userData.role === "unit_owner" && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link className="text-secondary" to={"/"}>
                      Home
                    </Link>
                  </Breadcrumb.Item>{" "}
                  <Breadcrumb.Item active>
                    <Link to={"#!"}>Visitors</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Visitors</h4>

            {/* {userData.role === "Site-Administrators" && (
              <Button variant="primary" size="sm" onClick={addSettingByPM}>
                Settings
              </Button>
            )} */}
            {userData.role === "unit_owner" && (
              <Button variant="primary" size="sm" onClick={addNewUser}>
                + Add New Visitor
              </Button>
            )}
          </Flex>
          <br />

          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              {/* <CustomFilterbar
                filterFlags={["Parking Reserved", "Parking Expired"]}
                // dataListTemp={mailOutsDataTemp}
                // stateUpdator={setMailOutsData}
                from="MailOuts Table"
              /> */}

              <CustomSearchbar
                searchFlags={[
                  "_id",
                  "name",
                  "email",
                  "unit_number",
                  "category",
                ]}
                // dataListTemp={mailOutsDataTemp}
                // stateUpdator={setMailOutsData}
                placeholders={[
                  "id",
                  "name",
                  "email",
                  "category",
                  "unit_number",
                ]}
              />
            </Col>
          </Row>

          <VisitorsTable
            list={visitorsList}
            viewUserDetails={viewUserDetails}
          />
          <br />

          <VisitorsModal
            show={showModal}
            handleClose={handleClose}
            payload={payload}
            setPayload={setPayload}
            getAllData={getAllVisitorsData}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
