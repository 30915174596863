import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Form as BSForm,
  CloseButton,
  ListGroup,
  ListGroupItem,
  Table,
} from "react-bootstrap";
import { ArrowBack, Clear, CloudUpload, Undo } from "@mui/icons-material";
import {
  byteConverter,
  formatLogInfo,
  getFileExtData,
  getFileType,
  getPureDate,
} from "helpers/utils";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import { toArray } from "lodash";
import ReactDatePicker from "react-datepicker";
export default function DocumentDriveModel({
  show,
  handleClose,
  payload,
  getAllData,
  userData,
  property_id,
  base_folder_id, //root id
  current_folder_id, // current cursor id
  getFolderContents,
  RootDriveFilesList = [],
  hostname,
  jwtToken,
}) {
  const fileRef = React.useRef(null);

  const [modelLoader, setModelLoader] = React.useState(false);

  const [DriveTreeModel, setDriveTreeModel] = React.useState([
    { folder_id: base_folder_id, folder_name: "Document Drive" },
  ]);

  const [DriveFilesListModel, setDriveFilesListModel] = React.useState([]);
  const [DriveFilesListModelTemp, setDriveFilesListModelTemp] = React.useState(
    []
  );

  const [current_folder_id_model, set_current_folder_id_model] =
    React.useState("");

  const handleDriveTreeLink = async (
    folder_id_drive = "",
    folder_name_drive = "",
    for_tree_bar = false
  ) => {
    if (for_tree_bar) {
      setModelLoader(true);
      let arr = [...DriveTreeModel];

      if (folder_id_drive === base_folder_id) {
        let folder_data = await getFolderContents(folder_id_drive);

        setDriveFilesListModel(folder_data);
        setDriveFilesListModelTemp(folder_data);
        set_current_folder_id_model(folder_id_drive);
        setModelLoader(false);
        return;
      }

      let folder_index = arr.indexOf({
        folder_id: folder_id_drive,
        folder_name: folder_name_drive,
      });
      let slicedArr = arr.slice(0, folder_index);
      console.log(slicedArr);

      const { folder_id } = slicedArr[slicedArr.length - 1];

      let folder_data = await getFolderContents(folder_id);

      setDriveFilesListModel(folder_data);
      setDriveFilesListModelTemp(folder_data);
      setDriveTreeModel(slicedArr);
      set_current_folder_id_model(folder_id);
      setModelLoader(false);
      return;
    }

    setModelLoader(true);

    let folder_data = await getFolderContents(folder_id_drive);

    setDriveTreeModel(
      DriveTreeModel.concat({
        folder_id: folder_id_drive,
        folder_name: folder_name_drive,
      })
    );

    setDriveFilesListModel(folder_data);
    setDriveFilesListModelTemp(folder_data);
    set_current_folder_id_model(folder_id_drive);

    setModelLoader(false);
  };

  const goBack = async (folder_id_drive) => {
    if (folder_id_drive === base_folder_id) {
      return;
    }

    setModelLoader(true);
    let arr = [...DriveTreeModel];
    let folder_id_global = "";

    let folder_data = [];

    if (arr.length < 2) {
      folder_data = await getFolderContents(base_folder_id);
    } else {
      arr.pop();
      const { folder_id } = arr[arr.length - 1];

      folder_id_global = folder_id;
      folder_data = await getFolderContents(folder_id);
    }

    setDriveFilesListModel(folder_data);
    setDriveFilesListModelTemp(folder_data);
    setDriveTreeModel(arr);
    set_current_folder_id_model(folder_id_global);
    setModelLoader(false);
  };

  const checkDuplicates = function (e) {
    var arr = [];
    var fileInputs = document.querySelectorAll(".testFiles");
    fileInputs.forEach(function (input) {
      if (arr.length == 0) {
        arr.push(input.files[0].name);
      } else {
        if (arr.indexOf(input.files[0].name) > -1) {
          e.preventDefault();
          alert("there's a duplicate");
        } else {
          arr.push(input.files[0].name);
        }
      }
    });
  };

  const handleFiles = (e, setFieldValue, values) => {
    const validTypes = [
      "txt",
      "doc",
      "docx",
      "pdf",
      "csv",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "jpeg",
      "jpg",
      "png",
      "zip",
      "rar",
      "rtf",
    ];

    console.log(e.target.files);
    let data = new Set([...e.target.files]);
    data = toArray(data);
    console.log(data);
    data = data?.map((file) => {
      let entry = {};
      entry.tmp_id = uuidv4();
      entry.mimeType = file.type;
      entry.type = file.type?.split("/")[1];
      entry.file = file;
      const type = file.name.substr(file.name.lastIndexOf(".") + 1);
      const size = file.size / 1024 / 1024;

      if (!validTypes.includes(type)) {
        entry.type_error = `invalid file ${type} !`;
      } else {
        entry.type_error = "";
      }
      if (validTypes.includes(type) && size > 2) {
        entry.size_error = `max file limit is 2 MB !`;
      } else {
        entry.size_error = "";
      }

      RootDriveFilesList?.forEach((v) => {
        if (v.file_name === file.name) {
          let nameIndex = file.name.lastIndexOf(".");
          let name = file.name.substr(0, nameIndex);
          let ext = file.name.substr(nameIndex);
          let count = file.name.indexOf(")." + ext);
          count--;
          if (count < 0) count = 1;
          else {
            count = parseInt(file.name[count]);
            count++;
          }
          // can't rename entry.file.name as it is constant
          // adding a new key of name
          // but backend will receive files with const attributes.
          let new_name = `${name}(${count})${ext}`;
          entry.file = new File([file], new_name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          entry.dublicate_error = `file already exists! suffix updated`;
        } else entry.dublicate_error = "";
      });

      return entry;
    });
    console.log(data);

    setFieldValue("drive_files", values.drive_files?.concat(data));

    e.target.value = null;
  };

  const handleRemoveFile = (e, setFieldValue, values, id) => {
    let data = [];
    data = values.drive_files?.filter((v) => v.tmp_id !== id);

    setFieldValue("drive_files", data);
  };

  const getFileValidation = (values) => {
    let i = 0;

    values?.drive_files?.forEach((v) => {
      if (
        v.type_error !== "" ||
        v.size_error !== ""
        // v.dublicate_error !== ""
      )
        i++;
    });
    console.log(i);
    return i;
  };

  const uploadFilesHandler = (values = {}) => {
    console.log(values);

    let arr = [];

    arr = values.drive_files.map((v, i) => {
      let obj = { ...v };
      if (values.expiryDate[i]) obj.expiry_date = values.expiryDate[i];
      else obj.expiry_date = "-";

      return obj;
    });

    // console.log(arr);

    let filesObj = {};
    filesObj.drive_files = arr;
    filesObj.uploaded_by_id = userData.data;
    filesObj.p_id = property_id;
    filesObj.parent_id = current_folder_id;

    console.log(filesObj);
    // return;

    axios
      .post(
        `${hostname}/api/property/UploadFileDrive`,
        {
          ...filesObj,
        },
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Upload Successful", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const create_new_folder = (values = {}) => {
    let filesObj = {};
    filesObj.folder_name = values.folder_name;
    filesObj.uploaded_by_id = userData.data;
    filesObj.p_id = property_id;
    filesObj.parent_id = current_folder_id;

    console.log(filesObj);
    // return;

    axios
      .post(
        `${hostname}/api/property/CreateFolderDrive`,
        {
          ...filesObj,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Folder Created", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handleMoveAction = (bulkMode = false) => {
    let moving_file_id = [];
    moving_file_id.push(payload._id);
    let target_folder_id = current_folder_id_model;
    if (!target_folder_id) target_folder_id = base_folder_id;

    if (bulkMode) {
      moving_file_id = payload.bulkFiles?.map((v) => v._id);
      console.log(moving_file_id);
      //return;
    }

    if (!payload._id && !bulkMode) {
      toast.error("invalid entry id", { theme: "colored" });
      return;
    }

    console.log(moving_file_id);
    console.log(target_folder_id);

    axios
      .post(`${hostname}/api/property/MoveFiles`, {
        doc_id: moving_file_id,
        new_parent_id: target_folder_id,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Operation Successful", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const soft_delete = (bulkMode = false) => {
    let doc_id = [];
    doc_id.push(payload._id);

    if (bulkMode) doc_id = payload.bulkFiles?.map((v) => v._id);

    if (!payload._id && !bulkMode) {
      toast.error("invalid entry id", { theme: "colored" });
      return;
    }

    // console.log(doc_id);
    // return;

    axios
      .post(`${hostname}/api/property/DeleteFiles`, {
        doc_id,
        p_id: property_id,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);

        getAllData();
        handleClose();
        toast.warning("Operation Success", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handle_bulk_soft_delete = () => {
    for (let i = 0; i < payload.bulkFiles.length; i++) {
      const element = payload.bulkFiles[i];
      soft_delete(element._id, true);
    }
  };

  const handle_bulk_move = () => {
    for (let i = 0; i < payload.bulkFiles.length; i++) {
      const element = payload.bulkFiles[i];
      handleMoveAction(element._id, true);
    }
  };

  const handle_bulk_tree_navigate = (obj) => {
    let isNavigatable = false;

    payload.bulkFiles.forEach((k) => {
      if (k._id !== obj._id) isNavigatable = true;
      else isNavigatable = false;
    });

    if (obj.file_type === "folder" && isNavigatable) {
      handleDriveTreeLink(obj._id, obj.file_name);
    }
  };

  const handleRename = (values) => {
    let renameData = {};

    renameData.doc_id = payload._id;
    renameData.name = values.label_name;
    if (payload.file_type === "file") {
      renameData.name = values.label_name + "." + payload.extention;
      renameData.expiry_date = values.expiry_date || "-";
    }

    // console.log(renameData);
    // return;

    axios
      .post(`${hostname}/api/property/RenameDocument`, {
        ...renameData,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Rename Success", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handleCloseModel = () => {
    setDriveFilesListModel([]);
    setDriveFilesListModelTemp([]);
    setDriveTreeModel([
      { folder_id: base_folder_id, folder_name: "Document Drive" },
    ]);
    set_current_folder_id_model("");
    handleClose();
  };

  console.log(fileRef);

  const handleDateChange = (date, setFieldValue, file_id, values) => {
    let arr = values.drive_files.map((v) => {
      let obj = { ...v };
      if (obj.tmp_id === file_id) obj.expiry_date = date;
      return obj;
    });
    console.log(arr);
    // return;
    setFieldValue("drive_files", arr);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModel}
        backdrop="static"
        keyboard={false}
        size={
          payload.loadType === "Upload Files" ||
          payload.loadType === "Move" ||
          payload.loadType === "Bulk Move"
            ? "lg"
            : "md"
        }
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "Upload Files" && (
            <div className="px-3">
              <Formik
                initialValues={{
                  drive_files: [],
                  expiryDate: [], // Initial expiry date
                }}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  uploadFilesHandler(values);
                  setSubmitting(false);
                }}
              >
                {({
                  isSubmitting,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form>
                    <BSForm.Label htmlFor="drive_files">
                      Upload Files here
                    </BSForm.Label>
                    <label
                      htmlFor="drive_files"
                      className="d-flex justify-content-center align-items-center fs-2 mt-3 cursor-pointer"
                      style={{
                        display: "block",
                        height: "170px",
                        borderRadius: "20px",
                        // backgroundColor:'silver',
                        border: "2px dashed #dee2ef",
                      }}
                    >
                      <input
                        // ref={fileRef}
                        onChange={(e) => handleFiles(e, setFieldValue, values)}
                        // onChange={handleChange}
                        multiple
                        onBlur={handleBlur}
                        // value={values.drive_files}
                        id="drive_files"
                        name="drive_files"
                        type="file"
                        // accept="image/*"
                        hidden
                        className="form-control"
                      />
                      <div>
                        <CloudUpload fontSize="large" /> Click to upload
                      </div>
                    </label>
                    {/* <ErrorMessage
                      name="drive_files"
                      component={"div"}
                      className="fs--1 text-danger"
                    /> */}
                    {/* {meta.touched && meta.error ? (
                      <div style={{ color: "red" }}>{meta.error}</div>
                    ) : null} */}
                    <br />
                    {values?.drive_files?.length < 1 && (
                      <div>
                        <br />
                        <p className="text-info fs--1">No Attachments Added.</p>
                      </div>
                    )}
                    {values?.drive_files?.length > 0 && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Expiry</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {typeof values.drive_files === "object" &&
                            values?.drive_files?.map((file, index) => (
                              <tr key={file.tmp_id}>
                                <td style={{ minWidth: "400px" }}>
                                  <div className="d-flex align-items-center">
                                    <span style={{ width: "25px" }}>
                                      {getFileExtData(
                                        file.file.name.substr(
                                          file.file.name.lastIndexOf(".") + 1
                                        )
                                      )}
                                    </span>
                                    <span className="ms-3"></span>
                                    {file.file.name}
                                  </div>
                                  <div className="text-danger fs--1">
                                    {file.type_error}
                                  </div>
                                  <div className="text-danger fs--1">
                                    {file.size_error}
                                  </div>
                                  <div className="text-warning fs--1">
                                    {file.dublicate_error}
                                  </div>
                                </td>

                                <td style={{ minWidth: "200px" }}>
                                  <Field name={`expiryDate[${index}]`}>
                                    {({ field }) => (
                                      <ReactDatePicker
                                        onChange={(date) => {
                                          const newExpiryDates = [
                                            ...values.expiryDate,
                                          ]; // Copy the expiryDate array
                                          newExpiryDates[index] = date; // Update the expiryDate for the specific index
                                          setFieldValue(
                                            `expiryDate`,
                                            newExpiryDates
                                          ); // Set the new array value using setFieldValue
                                        }}
                                        value={field.value}
                                        className="form-control form-control-sm"
                                        placeholderText="expiry date"
                                        selected={field.value}
                                      />
                                    )}
                                  </Field>

                                  {/* <ReactDatePicker
                                    size="sm"
                                    name={`expiry-${i}`}
                                    onChange={(date) =>
                                      handleDateChange(
                                        date,
                                        setFieldValue,
                                        file.tmp_id,
                                        values
                                      )
                                    }
                                    onBlur={handleBlur}
                                    className="form-control form-control-sm"
                                    placeholderText="expiry date"
                                    selected={new Date()}
                                    showIcon
                                  /> */}
                                  {/* <BSForm.Control
                                    type="date"
                                    size="sm"
                                    name={`expiry-${i}`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={new Date().toDateString()}
                                  /> */}
                                </td>

                                <td>
                                  <Button
                                    variant="falcon-danger"
                                    size="sm"
                                    onClick={(e) =>
                                      handleRemoveFile(
                                        e,
                                        setFieldValue,
                                        values,
                                        file.tmp_id
                                      )
                                    }
                                  >
                                    <Clear />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}

                    <br />
                    {getFileValidation(values) > 0 && (
                      <div className="text-end text-danger ">
                        please remove unsupported files ...
                      </div>
                    )}
                    {getFileValidation(values) < 1 && (
                      <div className="text-end">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="primary"
                        >
                          Done
                        </Button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {payload.loadType === "Create Folder" && (
            <div>
              <Formik
                initialValues={{
                  folder_name: "",
                }}
                onSubmit={(values) => {
                  create_new_folder(values);
                }}
                validationSchema={Yup.object({
                  folder_name: Yup.string().required("name is required!"),
                })}
              >
                <Form>
                  <BSForm.Label htmlFor="folder_name">Folder Name</BSForm.Label>
                  <Field
                    id="folder_name"
                    name="folder_name"
                    className="form-control"
                    placeholder="Folder Name here..."
                  />
                  <ErrorMessage
                    name="folder_name"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                  <br />
                  <div className="text-end">
                    <Button type="submit" variant="primary">
                      Create
                    </Button>
                  </div>
                </Form>
              </Formik>
            </div>
          )}
          {payload.loadType === "Edit" && (
            <div>
              <Formik
                initialValues={{
                  label_name:
                    payload.file_type === "file"
                      ? payload.file_name.substr(
                          0,
                          payload.name.lastIndexOf(".")
                        )
                      : payload.file_name,
                  expiry_date:
                    payload.expiry_date && payload.expiry_date !== "-"
                      ? new Date(payload.expiry_date)
                      : "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  handleRename(values);
                  setSubmitting(false);
                }}
                validationSchema={Yup.object({
                  label_name: Yup.string().required("name is required!"),
                })}
              >
                {({
                  isSubmitting,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form>
                    <BSForm.Label htmlFor="label_name">
                      Enter New Name to Update
                    </BSForm.Label>
                    <Field
                      id="label_name"
                      name="label_name"
                      className="form-control"
                      placeholder=" Name here..."
                    />
                    <ErrorMessage
                      name="label_name"
                      component={"div"}
                      className="fs--1 text-danger"
                    />
                    {payload.file_type === "file" && (
                      <>
                        {" "}
                        <div className="mb-3"></div>
                        <BSForm.Label htmlFor="label_name">
                          Update Expiry Date
                        </BSForm.Label>
                        <ReactDatePicker
                          name="expiry_date"
                          selected={values.expiry_date}
                          onChange={(date) =>
                            setFieldValue("expiry_date", date)
                          }
                          className="form-control"
                          placeholderText="expiry date"
                        />
                        <ErrorMessage
                          name="expiry_date"
                          component={"div"}
                          className="fs--1 text-danger"
                        />
                      </>
                    )}
                    <br />
                    <div className="text-end">
                      <Button type="submit" variant="primary">
                        Done
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {payload.loadType === "Delete" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to delete{" "}
                <code>"{payload.file_name}"</code> ?
              </p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={() => soft_delete()}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {payload.loadType === "Bulk Delete" && (
            <div>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to delete ?
              </p>
              <code>
                {payload.bulkFiles.map((v) => (
                  <div>
                    <span key={v._id}>"{v.file_name}"</span>
                    <br />
                  </div>
                ))}
              </code>

              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={() => soft_delete(true)}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </div>
          )}
          {payload.loadType === "Move" && (
            <div className="px-3">
              <p className="mb-3">
                confirm move <code>{payload.file_name} </code> into{" "}
                <b>{DriveTreeModel[DriveTreeModel.length - 1].folder_name} </b>?
              </p>

              <br />
              <p>or choose another directory below ...</p>
              <div className="bg-light px-3 mb-3">
                {DriveTreeModel?.map((v, i) => (
                  <React.Fragment key={v.folder_id}>
                    {i > 0 && "/"}
                    {"  "}
                    <span
                    // variant="falcon-link"
                    // size="sm"
                    // onClick={() =>
                    //   handleDriveTreeLink(v.folder_id, v.folder_name, true)
                    // }
                    >
                      {v.folder_name}
                    </span>{" "}
                  </React.Fragment>
                ))}
              </div>
              <ListGroup>
                {DriveTreeModel.length > 1 && (
                  <ListGroupItem
                    action
                    onClick={() => goBack(current_folder_id_model)}
                  >
                    <ArrowBack />
                    <span className="ms-3">Go Back</span>
                  </ListGroupItem>
                )}
              </ListGroup>
              <ListGroup>
                {DriveTreeModel.length === 1 &&
                  payload?.baseFolderData?.map((v) => (
                    <ListGroupItem
                      key={v._id}
                      action
                      onClick={() =>
                        v.file_type === "folder" &&
                        v._id !== payload._id &&
                        handleDriveTreeLink(v._id, v.file_name)
                      }
                    >
                      <div className="d-flex align-items-center">
                        {v.file_type === "file" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {/* {getFileType({ mimetype: v.mimetype }, true)} */}
                            {getFileExtData(v.extention)}
                          </span>
                        )}
                        {v.file_type === "folder" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {getFileType({ mimetype: v.mimetype }, true)}
                          </span>
                        )}
                        <span>{v.file_name}</span>
                      </div>
                    </ListGroupItem>
                  ))}
                {DriveTreeModel.length > 1 &&
                  DriveFilesListModel?.map((v) => (
                    <ListGroupItem
                      key={v._id}
                      action
                      onClick={() =>
                        v.file_type === "folder" &&
                        v._id !== payload._id &&
                        handleDriveTreeLink(v._id, v.file_name)
                      }
                    >
                      <div className="d-flex align-items-center">
                        {v.file_type === "file" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {/* {getFileType({ mimetype: v.mimetype }, true)} */}
                            {getFileExtData(v.extention)}
                          </span>
                        )}
                        {v.file_type === "folder" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {getFileType({ mimetype: v.mimetype }, true)}
                          </span>
                        )}
                        <span>{v.file_name}</span>
                      </div>
                    </ListGroupItem>
                  ))}
              </ListGroup>
              <br />
              <div className="text-end">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={() => handleMoveAction()}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
          {payload.loadType === "Bulk Move" && (
            <div className="px-3">
              <p className="mb-3">
                confirm move below content into{" "}
                <b>{DriveTreeModel[DriveTreeModel.length - 1].folder_name} </b>?
              </p>
              <p>
                {payload?.bulkFiles?.map((v) => (
                  <span key={v._id}>
                    <code>{v.file_name} </code>
                    <br />
                  </span>
                ))}
              </p>

              <br />
              <p>or choose another directory below ...</p>
              <div className="bg-light px-3 mb-3">
                {DriveTreeModel?.map((v, i) => (
                  <React.Fragment key={v.folder_id}>
                    {i > 0 && "/"}
                    {"  "}
                    <span
                    // variant="falcon-link"
                    // size="sm"
                    // onClick={() =>
                    //   handleDriveTreeLink(v.folder_id, v.folder_name, true)
                    // }
                    >
                      {v.folder_name}
                    </span>{" "}
                  </React.Fragment>
                ))}
              </div>
              <ListGroup>
                {DriveTreeModel.length > 1 && (
                  <ListGroupItem
                    action
                    onClick={() => goBack(current_folder_id_model)}
                  >
                    <ArrowBack />
                    <span className="ms-3">Go Back</span>
                  </ListGroupItem>
                )}
              </ListGroup>
              <ListGroup>
                {DriveTreeModel.length === 1 &&
                  payload?.baseFolderData?.map((v) => (
                    <ListGroupItem
                      key={v._id}
                      action
                      onClick={() => handle_bulk_tree_navigate(v)}
                    >
                      <div className="d-flex align-items-center">
                        {v.file_type === "file" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {/* {getFileType({ mimetype: v.mimetype }, true)} */}
                            {getFileExtData(v.extention)}
                          </span>
                        )}
                        {v.file_type === "folder" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {getFileType({ mimetype: v.mimetype }, true)}
                          </span>
                        )}
                        <span>{v.file_name}</span>
                      </div>
                    </ListGroupItem>
                  ))}
                {DriveTreeModel.length > 1 &&
                  DriveFilesListModel?.map((v) => (
                    <ListGroupItem
                      key={v._id}
                      action
                      onClick={() => handle_bulk_tree_navigate(v)}
                    >
                      <div className="d-flex align-items-center">
                        {v.file_type === "file" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {/* {getFileType({ mimetype: v.mimetype }, true)} */}
                            {getFileExtData(v.extention)}
                          </span>
                        )}
                        {v.file_type === "folder" && (
                          <span className="me-3" style={{ width: "30px" }}>
                            {getFileType({ mimetype: v.mimetype }, true)}
                          </span>
                        )}
                        <span>{v.file_name}</span>
                      </div>
                    </ListGroupItem>
                  ))}
              </ListGroup>
              <br />
              <div className="text-end">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={() => handleMoveAction(true)}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
