// import React, { useState, useContext } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
// import FalconDropzone from 'components/common/FalconDropzone';
// import avatarImg from 'assets/img/team/avatar.png';
// import { isIterableArray } from 'helpers/utils';
// import Avatar from 'components/common/Avatar';
// import cloudUpload from 'assets/img/icons/cloud-upload.svg';
// import { AuthWizardContext } from 'context/Context';
// import Flex from 'components/common/Flex';
import { Col, Row } from 'react-bootstrap';

const PersonalForm = ({ register, errors, setValue, regData }) => {
  // const { user } = useContext(AuthWizardContext);
  // const [avatar, setAvatar] = useState([
  //   ...(user.avater ? user.avater : []),
  //   { src: avatarImg }
  // ]);

  return (
    <>
      <Row className="mb-3">
        <Col md="auto">
          <h3>Check your Inbox</h3>
        </Col>
        <hr />
        <Col md="auto">
          <h5 className="fw-light">
            A verification email has been dispatched to{' '}
            {<span className="text-secondary fw-bold">{regData.email}</span> ||
              'name@gmail.com'}
          </h5>
          <br />
          <p>Enter code below to proceed!</p>
        </Col>
      </Row>

      <WizardInput
        type="text"
        label="Verification code"
        name="verifyCode"
        errors={errors}
        setValue={setValue}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          placeholder: 'Verification code here...',
          ...register('verifyCode', {
            required: 'Verification code is required!',
            minLength: {
              value: 4,
              message: 'A valid code can be at least 4 chars long!'
            }
          })
        }}
      />
    </>
  );
};

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default PersonalForm;
