import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Fuse from "fuse.js";

export default function CustomSearchbar({
  searchFlags = [],
  dataListTemp = [],
  stateUpdator = () => {},
  placeholders = [],
}) {
  // console.log(searchFlags);
  // console.log(dataListTemp);
  const handleSearchBarAction = (e) => {
    try {
      const fuse = new Fuse([...dataListTemp], {
        keys: searchFlags,
        isCaseSensitive: false,
      });

      let res = fuse.search(e.target.value);

      res = res.map((v) => {
        return v.item;
      });

      // console.log(res);

      if (res.length) stateUpdator([...res]);
      // else alert("no data");

      // return;
      // let searchValue = e.target.value?.toLowerCase();
      // let ans = [];
      // searchFlags?.forEach((flag) => {
      //   console.log(flag);
      //   ans = dataListTemp?.filter((v) => {
      //     console.log(v);
      //     console.log(v.unit_number);
      //     if (v[flag]?.toLowerCase()?.indexOf(searchValue) !== -1)
      //       return v[flag]?.toLowerCase()?.indexOf(searchValue) !== -1;
      //     else return "";
      //   });
      // });
      // console.log(ans);
      // stateUpdator(ans);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {/* <Form className="position-relative">
              <Form.Control
                type="search"
                placeholder="Search..."
                size="sm"
                aria-label="Search"
                className="rounded search-input ps-4"
                // onChange={handleSearchBarAction}
              />
              <FontAwesomeIcon
                icon="search"
                className="fs--1 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
              />
            </Form> */}
      <Form.Group className="mb-3">
        <div className="input-group mb-3">
          <span className="input-group-text bg-white" id="basic-addon2">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <input
            type="text"
            name="searchv"
            onKeyUp={handleSearchBarAction}
            className="form-control"
            placeholder={`Search by ${placeholders?.map((v) => v)} ...`}
            aria-label="search-units-mgr"
            aria-describedby="search-units-mgr"
          />
        </div>
      </Form.Group>
    </>
  );
}
