// import FaqAlt from 'components/pages/faq/faq-alt/FaqAlt';
import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import BillingCard from "./BillingCard";
import BillingDetails from "./BillingDetails";
import BillingHeader from "./BillingHeader";
import Paymenttest from "./Paymenttest";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";
const stripePromise = loadStripe(
  "pk_test_51NPrO8C1KCwmpA0kgy5EoTWIKe0kCLEi6esEybr2gDrARuAdwnB7o48PqADCUcFglGZ0XWW4qgvbl6ynTez1ikc8001LzJsdHi"
);
const Billing = () => {

  const cookies = new Cookies();
  let jwtToken = cookies.get('_expire_t');
  let hostname = getHostName();

  ///My code
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    // Fetch the clientSecret from your API and set it in state
    fetchClientSecretFromAPI().then((secret) => {
      setClientSecret(secret);
    });
  }, []);

  const fetchClientSecretFromAPI = async () => {
    try {
      axios
        .post(
          `${hostname}/api/pay/StripeSetup`,
          {},
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.clientSecret);
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
      //const data = await response.json();
      //return data.clientSecret; // Assuming your API returns the clientSecret
    } catch (error) {
      console.error("Error fetching clientSecret:", error);
      return null;
    }
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <Paymenttest clientSecret={clientSecret} />
      </Elements>
      <BillingHeader className="mb-3" />

      <Row className="g-3">
        <Col lg={8} className="pe-lg-2 mb-3">
          <Card className="h-100">
            <Card.Header>
              <h5 className="mb-0">Billing Details</h5>
            </Card.Header>
            <Card.Body className="bg-light">
              <BillingDetails />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="pe-lg-2 mb-3">
          <Card className="h-100">
            <Card.Header>
              <h5 className="mb-0">Billing</h5>
            </Card.Header>
            <Card.Body className="bg-light">
              <BillingCard />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <FaqAlt /> */}
    </>
  );
};

Billing.propTypes = {};

export default Billing;
