// import React, { useEffect, useState } from 'react';
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import * as Yup from "yup";
import { Formik } from "formik";

const ProfileInfo = ({ property, updateProperty, userData }) => {
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Property Profile" />
      <Card.Body className="bg-light">
        <div>
          <Formik
            initialValues={{
              p_id: property.property_id || "id here",
              // p_id: '1212',
              p_name: property.property_name,
              p_url: property.property_url,
              phone: property.phone,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.p_id) {
                errors.p_id = "property id is required!";
              }
              if (!values.p_name) {
                errors.p_name = "property name is required!";
              }
              if (!values.p_url) {
                errors.p_url = "property url is required!";
              }
              if (!values.phone) {
                errors.phone = "property phone is required!";
              }
              return errors;
            }}
            validationSchema={Yup.object({
              p_url: Yup.string().matches(
                /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
                "Invalid site name. Please enter a valid site name like this 'acmesite' or 'acme-site'."
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              updateProperty(values, property);
              setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                // console.log(values);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Row className="mb-2 g-3">
                  <Form.Group as={Col} lg={6} controlId="p_id">
                    <Form.Label>
                      Property ID <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="property id here"
                      value={values.p_id}
                      name="p_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <Form.Text className="text-danger ms-2">
                      {errors.p_id && touched.p_id && errors.p_id}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} lg={6} controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      value={values.phone}
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <Form.Text className="text-danger ms-2">
                      {errors.phone && touched.phone && errors.phone}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row className="g-3">
                  <Form.Group controlId="p_name">
                    <Form.Label>
                      Property Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="property name"
                      value={values.p_name}
                      name="p_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <Form.Text className="text-danger ms-2">
                      {errors.p_name && touched.p_name && errors.p_name}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="p_url">
                    <Form.Label>
                      Property URL <span className="text-danger">*</span>
                    </Form.Label>
                    {/* <div className="d-flex flex-column position-relative"> */}
                    <Form.Control
                      type="text"
                      placeholder="url here"
                      // value={values.p_url + ".condozak.app"}
                      value={values.p_url}
                      name="p_url"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // style={{ paddingRight: '40%', overflow: 'hidden' }}
                      disabled
                    />
                    {/* <span
                        className="translate-middle"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '78%'
                        }}
                      >
                        .condozak.app
                      </span> */}
                    {/* </div> */}
                    <Form.Text className="text-danger ms-2">
                      {errors.p_url && touched.p_url && errors.p_url}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <br />
                {(userData.role === "Site-Administrators" ||
                  userData.role === "Condozak-Basic-Users") && (
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfileInfo;
