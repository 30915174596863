
import React from 'react';


import ServiceRequestsComp from 'components/pages/UnitOnwer&Tennent/Features/ServiceRequests/ServiceRequests'


export default function UnitMaintenanceRequests() {
  return (
    <><ServiceRequestsComp from='singleUnit'/></>
  )
}
