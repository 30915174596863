import FalconCardHeader from "components/common/FalconCardHeader";
import { formatLogInfo } from "helpers/utils";
import { startCase } from "lodash";
import React from "react";
import { Card, Row, Col, Table } from "react-bootstrap";

export default function EConsentHistory({ eHistory = [], userData }) {
  return (
    <>
      <Row>
        <Col>
          <Card>
            <FalconCardHeader title="E-Consent History" className="bg-light" />
            <Card.Body className="">
              <Table
                striped
                bordered
                hover
                responsive
                style={{ fontSize: "14px" }}
                className="text-center"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date & Time</th>
                    <th>Created By</th>
                    <th>Action</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {eHistory?.map((v, i) => (
                    <tr key={v?._id}>
                      <td>{i + 1}</td>
                      <td>{formatLogInfo(v.createdAt)}</td>
                      <td>{`${startCase(v.accepted_by?.firstname)} ${startCase(v.accepted_by?.lastname)}`}</td>
                      <td>{v.action}</td>
                      <td>{v.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
