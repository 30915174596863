import React from "react";
import { Card, Form } from "react-bootstrap";

import Table from "react-bootstrap/Table";

import FalconCardHeader from "components/common/FalconCardHeader";
import { toast } from "react-toastify";
import axios from "axios";
import { formatLogInfo } from "helpers/utils";
import EConsentModal from "components/pages/sharedUI/EConsentModal";
import EConsentHistory from "./EConsentHistory";
// import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";

import Loader from "components/common/Loader";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

const UserGroups = ({ currentUser, eConsentData }) => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [loader, setLoader] = React.useState(false);

  let user_id = userData.data;
  const manager_id = userData.data;

  const { unit_id } = useParams();

  let accepted_by = currentUser?._id;

  if (userData.role === "Site-Administrators") {
    user_id = currentUser._id;
    accepted_by = userData.data;
  }

  const [eHistory, setEHistory] = React.useState([]);
  const [eHistoryDate, setEHistoryDate] = React.useState("-");

  const [show, setShow] = React.useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  console.log("new update ##*************************************");

  const [consentEnabled, setConsentEnabled] = React.useState(
    eConsentData?.eConsentStatus === "Inctive" ? false : true
  );

  React.useEffect(() => {
    setLoader(true);
    setConsentEnabled(eConsentData?.eConsentStatus === "Active" ? true : false);
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, [eConsentData]);

  const handleConsentUpdate = (e) => {
    console.log(e.target.value);
    // const { value } = e.target;
    if (consentEnabled) {
      setConsentEnabled(!consentEnabled);
      revokeEconsentAgreement();
    }
    if (!consentEnabled) {
      setConsentEnabled(!consentEnabled);
      acceptEconsentAgreement();
    }
  };

  const acceptEconsentAgreement = () => {
    axios
      .post(
        `${hostname}/api/property/AcceptEConsent`,
        {
          e_id: eConsentData?._id,
          accepted_by: userData?.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(res.data.message || "Succesfully Accepted", {
          theme: "colored",
        });
        getConsentHistory();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
  };
  const revokeEconsentAgreement = () => {
    console.log("im here 67");
    axios
      .post(
        `${hostname}/api/property/RevokeEConsent`,
        {
          e_id: eConsentData?._id,
          accepted_by: userData?.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.info(res.data.message || "Succesfully Revoked", {
          theme: "colored",
        });
        getConsentHistory();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
  };

  const getConsentHistory = () => {
    axios
      .post(
        `${hostname}/api/property/EConsentHistory`,
        {
          user_id,
          unit_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setEHistory(res.data?.data);
        setEHistoryDate(res.data.date_accepted || "-");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getConsentHistory();
  }, []);

  if (loader) return <Loader />;

  return (
    <>
      <Card className="font-sans-serif mb-3">
        <FalconCardHeader title={`Electronic Consent`} className="bg-light" />
        <Card.Body className="">
          {/* <h5 className="text-secondary mt-2 mb-3">Manage e-consent access.</h5> */}
          {/* <br /> */}
          <div className="">
            <Table responsive borderless className="">
              {/* <caption>List of users</caption> */}
              <thead>
                <tr>
                  <th>E-Consent Form</th>
                  <th>Status</th>
                  <th>Date Accepted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="text-primary cursor-pointer"
                    onClick={handleShow}
                  >
                    E-Consent Agreement
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      checked={consentEnabled}
                      onChange={handleConsentUpdate}
                      className="cursor-pointer"
                    />
                  </td>
                  <td>
                    {/* {formatLogInfo(eConsentData.createdAt)} */}
                    {eHistoryDate === "-" ? "-" : formatLogInfo(eHistoryDate)}
                    {/* <span className="ms-3">{consentEnabled ? "On" : "Off"}</span> */}
                  </td>
                </tr>
              </tbody>
              <tfoot className="fs--1">
                <tr>
                  <td colSpan={2}>
                    {/* {consentEnabled && (
                    <span className="text-success">
                      * you may receive regular updates regarding your unit.
                    </span>
                  )} */}
                    {!consentEnabled &&
                      userData.role === "Site-Administrators" && (
                        <span className="text-success fs--1">
                          By enabling this e-consent, I, as the property
                          manager, confirm that I have obtained and am
                          authorized to provide consent on behalf of the
                          resident. The resident has reviewed and agreed to the
                          terms and conditions.
                        </span>
                      )}
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </Card.Body>
        <EConsentModal
          show={show}
          handleClose={handleClose}
          eConsentData={eConsentData}
          noAdmin
        />
      </Card>

      <EConsentHistory eHistory={eHistory} userData={userData} />
    </>
  );
};

export default UserGroups;
