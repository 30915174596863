import React from "react";

import { useState, useEffect } from "react";

import Flex from "../../../../../../../components/common/Flex";

import { Card, Col, Row } from "react-bootstrap";

import UnitOwnersTable from "./UnitOwnersTable";

import axios from "axios";
import { toast } from "react-toastify";
import { capitalize, flatten, isArray } from "lodash";

import OwnerDetailComponent from "./userDetails/UserDetailsComponent";

import { Link, useParams } from "react-router-dom";
import Loader from "components/common/Loader";
import CustomFilterbar from "components/common/CustomFilterbar";
import CustomSearchbar from "components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

const UnitOwners = () => {
  const { property_id, property_name, unit_id, unit_number } = useParams();

  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [usersList, setUsersList] = useState([]);
  const [usersListTmp, setUsersListTemp] = useState([...usersList]);
  const [headerData, setHeaderData] = useState({
    adminCount: 0,
    userCount: 0,
    activeCount: 0,
  });

  // show user details tab
  const [showOwnersDetailTab, setShowOwnersDetailTab] = React.useState(false);

  const handleShowOwnersDetailTabFun = () => setShowOwnersDetailTab(true);
  const handleCloseOwnersDetailTabFun = () => setShowOwnersDetailTab(false);

  const handleShowOwnersDetailTab = (user) => {
    setPayload(user);
    getEConsentValueFromDB(user?._id);
    handleShowOwnersDetailTabFun();
  };

  const [eConsentData, setEconsentData] = React.useState({});

  const [loader, setLoader] = useState(false);
  const [payload, setPayload] = React.useState({});

  useEffect(() => {
    setLoader(true);
    getUsersDataNew();
  }, []);

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const getEConsentValueFromDB = (user_id) => {
    axios
      .post(
        `${hostname}/api/property/ShowEConsent`,
        {
          user_id,
          unit_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setEconsentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let compid = "jjj";

  const getUsersDataNew = () => {
    if (unit_id) {
      axios
        .post(
          `${hostname}/api/owner/GetAllResidents`,
          {
            unit_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);

          // streamline data for last 3
          let namesOfUsers = [
            "SupplemantryOwners",
            "Tenantsinfo",
            "SupplemantryTenants",
          ];
          let listOfUsers = [];

          namesOfUsers.forEach((userGroup, i) => {
            res.data[userGroup]?.forEach((userObj) => {
              let user = { ...userObj };
              delete user._id;
              let user_id = { ...userObj.user_id };
              if (i === 0) {
                user.type = "sup_owner";
                user.role = { title: "Supplementary Owner", type: "info" };
              }
              if (i === 1) {
                user.type = "primary_tenant";
                user.role = { title: "Primary tenant", type: "success" };
              }
              if (i === 2) {
                user.type = "sup_tenant";
                user.role = {
                  title: "Supplementary Tenant",
                  type: "bg-custom",
                };
              }
              delete user.user_id;
              listOfUsers.push({ ...user_id, ...user });
            });
          });

          // console.log(listOfUsers);
          let invitedData = res.data.Invited?.map((v) => {
            let name = v.email?.split("@")[0];

            let role = { title: "Invited Owner", type: "danger" };

            if (v.group === "Supplementary")
              role = { title: "Supplementary Owner", type: "info" };
            if (v.group === "Tenant")
              role = { title: "Primary Tenant", type: "success" };
            if (v.group === "Supplementary Tenant")
              role = { title: "Supplementary Tenant", type: "bg-custom" };

            return {
              type: "invited_user",
              ...v,
              firstname: capitalize(name),
              lastname: ".",
              role,
            };
          });

          // merge data
          let usersAll = [
            {
              ...res.data.PrimaryOwner,
              type: "primary_owner",
              role: { title: "Primary Owner", type: "Primary" },
            },
            ...invitedData,
            ...listOfUsers,
          ];

          usersAll = usersAll?.map((user) => {
            if (user.resident_status === "Invited")
              return {
                status: { type: "info", title: "Invited", icon: "redo" },
                ...user,
              };
            if (user.resident_status === "Active")
              return {
                status: { type: "success", title: "Active", icon: "check" },
                ...user,
              };
            if (user.resident_status === "Revoked")
              return {
                status: { type: "warning", title: "Revoked", icon: "ban" },
                ...user,
              };
            if (user.resident_status === "Ignored")
              return {
                status: { type: "secondary", title: "Ignored", icon: "ban" },
                ...user,
              };

            return {
              status: { type: "secondary", title: "Ignored", icon: "ban" },
              ...user,
            };
          });

          // console.log(usersAll);

          usersAll = usersAll?.map((v) => {
            let obj = { ...v };
            if (obj._id === userData.data) obj.isLoggedInUser = true;
            else obj.isLoggedInUser = false;
            return { ...obj };
          });

          // usersAll = usersAll?.filter((v) => v.status.title === "Active");
          console.log(usersAll);
          
          setUsersList([...usersAll]);
          setUsersListTemp([...usersAll]);

          let totalUsers = usersAll.length;
          let active = usersAll.filter(
            (v) => v.status?.title === "Active"
          ).length;

          let sup = usersAll.filter((v) => v.status?.title === "Active").length;

          // console.log(totalUsers);
          setHeaderData({
            userCount: totalUsers,
            activeCount: active,
            adminCount: 1,
            supCount: sup,
          });

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const getUsersData = (unit_id) => {
    if (unit_id) {
      axios
        .post(
          `${hostname}/api/owner/OwnerDetails`,
          {
            unit_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          let usersTmpArry = [
            flatten(res.data.data),
            flatten(res.data.invited),
          ];
          usersTmpArry = flatten(usersTmpArry);

          usersTmpArry = usersTmpArry.map((user) => {
            let tmp = {};

            if (user.verified === undefined) {
              if (user.Status === "1") {
                tmp = Object.assign(
                  {
                    status: {
                      title: "Invited",
                      type: "info",
                      icon: "check",
                    },
                    c_id: compid,
                  },
                  user
                );
              }
              if (user.Status === "0") {
                // console.log(user);
                tmp = Object.assign(
                  {
                    status: {
                      title: "Ignored",
                      type: "secondary",
                      icon: "check",
                    },
                    c_id: compid,
                  },
                  user
                );
              }
            }
            return tmp;
          });

          let admins = 0;
          let users = 0;
          let actives = 0;
          usersTmpArry.forEach((v) => {
            if (isArray(v.groups)) {
              admins += v.groups.filter(
                (group) => group === "Site-Administrators"
              ).length;
            }
          });
          users = usersTmpArry.length;
          actives = usersTmpArry.filter(
            (v) => v.status.title === "Active"
          ).length;

          let hdata = {};
          hdata.adminCount = admins;
          hdata.usersCount = users;
          hdata.activeCount = actives;
          // console.log(hdata);
          setHeaderData({ ...hdata });

          usersTmpArry.sort(dynamicSort("email"));

          // console.log(usersT);
          // console.log(usersTmpArry);
          setUsersList(usersTmpArry);
          setUsersListTemp(usersTmpArry);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const inviteUser = (data_user = {}, group = "") => {
    // console.log(data_user);
    if (group) {
      let colors = [
        "purple",
        "black",
        "red",
        "blue",
        "orange",
        "crimson",
        "blueviolet",
        "brown",
        "slateblue",
        "chocolate",
        "coral",
        "cornflowerblue",
        "sienna",
        "rebeccapurple",
      ];

      let rn = Math.floor(Math.random() * colors.length);

      let data = {
        firstname: data_user.firstname,
        c_id: data_user.c_id,
        email: data_user.email,
        group: group,
        bgColor: colors[rn],
      };
      // console.log(data);
      // return;

      axios
        .post(
          `${hostname}/api/auth/inviteUser`,
          { data },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then(() => {
          // console.log(res.data);
          setTimeout(() => {
            toast.success(
              <span className="text-white">{`invite sent to ${data_user.email}.`}</span>
            );
          }, 500);
          getUsersData(userData.compid);
        })
        .catch((err) => {
          console.log(err.response.data.error);
          toast.error(
            <span className="text-white">
              {err.response.data.error || "Error occured!"}
            </span>
          );
        });
    }
  };

  const resendInvite = (data_user = {}) => {
    // console.log(data_user);
    let data = {
      firstname: data_user.firstname,
      c_id: data_user.c_id,
      email: data_user.email,
      group: data_user?.groups[0],
      bgColor: data_user.bgColor,
      invitee_email: userData.email,
    };
    // console.log(data);
    // return;
    axios
      .post(
        `${hostname}/api/auth/resendinvite`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        setTimeout(() => {
          toast.success(
            <span className="text-white">{`invite sent to ${data_user.email}.`}</span>
          );
        }, 500);
        getUsersData(userData.compid);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        toast.error(
          <span className="text-white">
            {err.response.data.error || "Error occured!"}
          </span>
        );
      });
  };

  if (loader) {
    return <Loader />;
  }
  if (showOwnersDetailTab)
    return (
      <OwnerDetailComponent
        handleCloseUserDetailTab={handleCloseOwnersDetailTabFun}
        currentUser={payload}
        payload={payload}
        setPayload={setPayload}
        getUsersData={getUsersData}
        userData={userData}
        eConsentData={eConsentData}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    );

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
              <Link className="me-2 text-secondary" to="/dashboard">
                Home
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to="/property/management/buildings"
              >
                Properties
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/property/management/buildings/${property_id}/${property_name}/profile`}
              >
                {property_name}
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/property/management/buildings/${property_id}/${property_name}/units`}
              >
                Units
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
              >
                {unit_number}
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-primary"
                // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                to={"#!"}
              >
                Residents
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Residents</h4>
            {/* <Button variant="primary" size="sm" onClick={handleShow}>
              test modal
            </Button> */}
            {/* <Button
                variant="primary"
                size="sm"
              >
                Invite Users
              </Button> */}
          </Flex>

          <Row>
            <Col md="4">
              <Flex justifyContent={"between"} alignItems={"start"}>
                <h6>Total Residents</h6>
                <h6>Active Residents</h6>
                {/* <h6>Adminitrators</h6> */}
              </Flex>
              <Flex
                justifyContent={"between"}
                alignItems={"start"}
                className="px-5"
              >
                <h5 className="text-primary">{headerData.userCount || "0"}</h5>
                <h5 className="text-primary">
                  {headerData.activeCount || "0"}
                </h5>
                {/* <h5 className="text-primary">
                    {headerData.adminCount || "0"}
                  </h5> */}
              </Flex>
            </Col>
          </Row>
          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomFilterbar
                filterFlags={[
                  "Status Active",
                  "Status Disabled",
                  "Status Invited",
                  "Status Ignored",
                  "Status Revoked",
                  "Role Admin",
                ]}
                dataListTemp={usersListTmp}
                stateUpdator={setUsersList}
                from="Users Table"
              />

              <CustomSearchbar
                searchFlags={["firstname", "lastname", "email"]}
                dataListTemp={usersListTmp}
                stateUpdator={setUsersList}
                placeholders={["name", " email"]}
              />
            </Col>
          </Row>
          <UnitOwnersTable
            // itemsPerPage={10}
            users={usersList}
            inviteUser={inviteUser}
            resendInvite={resendInvite}
            adminCount={headerData.adminCount}
            handleShowOwnersDetailTab={handleShowOwnersDetailTab}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default UnitOwners;
