import FalconCardHeader from "components/common/FalconCardHeader";
import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
// import { toast } from 'react-toastify';
// import Cookies from 'universal-cookie';
// import axios from 'axios';

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ChangePassword = ({ updateUserPassword }) => {
  // const [error, setError] = useState('');
  // const [success, setSuccess] = useState('');
  // const [formData, setFormData] = useState({
  //   password: '',
  //   newpassword: '',
  //   confirmpassword: ''
  // });

  // const cookies = new Cookies();
  // const email = cookies.get('email');
  // const userData = cookies.get('userData');

  // const { password,newpassword,confirmpassword} = formData;
  // const { password, newpassword } = formData;

  // const handleChange = e => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // };

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${userData.token}`
  //     }
  //   };

  //   if (formData.newpassword !== formData.confirmpassword) {
  //     formData.newpassword = '';
  //     formData.confirmpassword = '';
  //     toast.info(`Passwords do not match `, {
  //       theme: 'colored'
  //     });
  //     setTimeout(() => {
  //       setError('');

  //     }, 5000);
  //     return setError("Passwords don't match");
  //   }
  //   try {
  //     const { data } = await axios.post(
  //       `http://localhost:5000/api/auth/changepassword`,
  //       {
  //         email,
  //         password,
  //         newpassword
  //       },
  //       config
  //     );
  //     console.log(data);
  //     // formData.password = '';
  //     // formData.newpassword = '';
  //     // formData.confirmpassword = '';
  //     setSuccess(data.data);

  //     toast.success('Password change successfull', {
  //       theme: 'colored'
  //     });
  //   } catch (error) {
  //     toast.warning('Incorrect password', {
  //       theme: 'colored'
  //     });
  //     // formData.password = '';
  //     setError(error.response.data.error);
  //     setTimeout(() => {
  //       setError('');
  //     }, 5000);
  //   }
  // };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change Password" />
      <Card.Body className="bg-light">
        <Formik
          initialValues={{
            password: "",
            new_password: "",
            confirm_password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (values.confirm_password !== values.new_password)
              errors.confirm_password = "both password must be matched!";
            return errors;
          }}
          validationSchema={Yup.object({
            password: Yup.string().required("old password is required!"),
            new_password: Yup.string().required("new password is required!"),
            confirm_password: Yup.string().required(
              "please confirm your password!"
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            updateUserPassword(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Old Password</Form.Label>
                <Field type="text" name="password" className="form-control" />
                <ErrorMessage
                  name="password"
                  className="text-danger fs--1"
                  component="div"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="newpassword">
                <Form.Label>New Password</Form.Label>
                <Field
                  type="text"
                  name="new_password"
                  className="form-control"
                />
                <ErrorMessage
                  name="new_password"
                  className="text-danger fs--1"
                  component="div"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirmpassword">
                <Form.Label>Confirm Password</Form.Label>
                <Field
                  type="text"
                  name="confirm_password"
                  className="form-control"
                />
                <ErrorMessage
                  name="confirm_password"
                  className="text-danger fs--1"
                  component="div"
                />
              </Form.Group>
              <br />

              <Button
                variant="primary"
                className="float-end"
                type="submit"
                disabled={isSubmitting}
              >
                Update Password
              </Button>
            </FormikForm>
          )}
        </Formik>
        {/* <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="text"
                value={formData.password}
                name="password"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newpassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="text"
                value={formData.newpassword}
                name="newpassword"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmpassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="text"
                value={formData.confirmpassword}
                name="confirmpassword"
                onChange={handleChange}
              />
            </Form.Group>
            <br />
            <Button className="float-end" type="submit">
              Update Password
            </Button>
          </Form> */}
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
