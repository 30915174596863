import React from "react";
import PropTypes from "prop-types";
import WizardLayout from "./WizardLayout";
import AuthWizardProvider from "./AuthWizardProvider";

const Wizard = ({
  variant,
  validation,
  progressBar,
  handleClose,
  getAllData,
  userData,
  setModelSize,
  mailOutState,
  setMailOutState,
  mailOutErrors,
  setMailOutErrors,
  handleFieldValueChange,
  sendDataToDB,
  payload,
  setPayload,
  hostname,
  jwtToken,
}) => {
  return (
    <AuthWizardProvider>
      <WizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
        handleClose={handleClose}
        getAllData={getAllData}
        setModelSize={setModelSize}
        userData={userData}
        mailOutState={mailOutState}
        setMailOutState={setMailOutState}
        mailOutErrors={mailOutErrors}
        setMailOutErrors={setMailOutErrors}
        handleFieldValueChange={handleFieldValueChange}
        sendDataToDB={sendDataToDB}
        payload={payload}
        setPayload={setPayload}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    </AuthWizardProvider>
  );
};

Wizard.propTypes = {
  variant: PropTypes.oneOf(["pills"]),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
};

export default Wizard;
