import FalconCardHeader from "components/common/FalconCardHeader";
import { formatLogInfo } from "helpers/utils";
import React from "react";
import { Card, Table, Button } from "react-bootstrap";
import { AuthWizardContext } from "context/Context";

export default function VisitorParkingReviewDetails({
  handleNewPermitByOwner,
}) {
  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const sendOwnerToBackend = () => {
    handleNewPermitByOwner(user, setStep, setUser);
  };

  return (
    <div>
      <Card>
        <FalconCardHeader title="Booking Details" />
        <Card.Body className="bg-light px-0">
          <Table striped bordered>
            <tbody>
              <tr>
                <td>Vehicle Make</td>
                <td className="fw-bold">{user.vehicle_make}</td>
              </tr>
              <tr>
                <td>Vehicle Model</td>
                <td className="fw-bold">{user.vehicle_model}</td>
              </tr>
              <tr>
                <td>Vehicle Color</td>
                <td className="fw-bold">{user.vehicle_color}</td>
              </tr>
              <tr>
                <td>License Plate #</td>
                <td className="fw-bold">{user.vehicle_license_plate}</td>
              </tr>
              <tr>
                <td>License Plate Country</td>
                <td className="fw-bold">
                  {user.vehicle_license_plate_country}
                </td>
              </tr>
              <tr>
                <td>License Plate State</td>
                <td className="fw-bold">{user.vehicle_license_plate_state}</td>
              </tr>
              <tr>
                <td>Start Date</td>
                <td className="fw-bold">
                  {formatLogInfo(user.start_date, true)}
                </td>
              </tr>
              <tr>
                <td>End Date</td>
                <td className="fw-bold">
                  {formatLogInfo(user.end_date, true)}
                </td>
              </tr>
              <tr>
                <td> Confirmation Email</td>
                <td className="fw-bold">{user.send_confirmation_mail}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <br />
      <div className="">
        <Button
          variant="secondary"
          type="button"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          variant="primary"
          className="float-end"
          type="button"
          onClick={sendOwnerToBackend}
        >
          Create Permit
        </Button>
      </div>
    </div>
  );
}
