import React from "react";
import { Button, Modal } from "react-bootstrap";

import FalconCloseButton from "../../../../components/common/FalconCloseButton";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "components/common/Loader";
export default function CancelAmenityModal(props) {
  const { hostname, jwtToken } = props;

  try {
    const [loader, setLoader] = React.useState(false);

    const cancelBookingNow = () => {
      setLoader(true);
      // console.log("from cancel booking");

      axios
        .post(
          `${hostname}/api/amenity/CancelBooking`,
          {
            booking_id: props.booking_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          props.getAmenitiesData();
          setLoader(false);
          props.handleClose();
          toast.success("Booking Cancelled", { theme: "colored" });
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          toast.error(
            <p>
              Booking can't be cancelled! <br /> Cancel lead time limit
              reached!"
            </p>,
            { theme: "colored" }
          );
        });
    };

    return (
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        // className='bg-info'
        style={{ backdropFilter: "blur(3px)" }}
        keyboard={false}
        // size="sm"
        // fullscreen
        centered
      >
        <Modal.Header>
          <Modal.Title className="fs-1">Cancel Booking </Modal.Title>
          <FalconCloseButton onClick={props.handleClose} />
        </Modal.Header>
        <Modal.Body className="">
          <p className="">
            Are you sure you would like to cancel this booking?
          </p>
          <br />
          {/* <br /> */}
          {/* <Form.Group className="mb-3">
            <Form.Label>Reason for Cancle</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={reason}
              onChange={handleChange}
            />
          </Form.Group> */}
          {loader ? (
            <Loader noHeight />
          ) : (
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={cancelBookingNow}
                style={{ width: 80 }}
                // disabled={!reason}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={props.handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
