import React from "react";
import classNames from "classnames";
// import { Link } from "react-router-dom";
import logo from "../../assets/img/illustrations/logo-condozak4.png";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
// import { UserContext } from "context/UserContext";

const LogoBottom = ({ at, width, className, to, ...rest }) => {
  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  // const { userType } = React.useContext(UserContext);
  // console.log(userType);

  // const getPath = (oldLink = "/dashboard") => {
  //   let link = oldLink;
  //   if (userData !== undefined && userData !== "" && userData !== "undefined") {
  //     if (userData.role === "Condozak-Basic-Users") {
  //       // link already set
  //     }
  //     if (userData.role === "Site-Administrators") {
  //       // link already set
  //     }
  //     if (userData.role === "unit_owner") {
  //       link = `${oldLink}/${userData.UnitsOwner[0]._id}/${userData.UnitsOwner[0].unit_number}`;
  //     }
  //   }
  //   if (userType === "user") {
  //     link = "#!";
  //   }
  //   if (userType === "prop-mgmt") {
  //     link = "#!";
  //   }
  //   if (userType === "unit-user") {
  //     link = "#!";
  //   }
  //   return link;
  // };

  return (
    <span
      // style={{ width: '100%' }}
      // to={'#!'}
     // to={getPath()}
      // to={to ? to : '/dashboard'}
      className={classNames(
        "text-decoration-none text-primary",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center justify-content-around py-3":
              at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center fw-bolder fs-5": at === "auth",
          },
          className
        )}
      >
        <img className="me-2" src={logo} alt="Logo2" width={width} />

        {/* {!isNavbarVerticalCollapsed && (
          )} */}
        {/* <span className={classNames('font-monospace', textClass)}>
          Condozak
        </span> */}
      </div>
    </span>
  );
};

// LogoBottom.defaultProps = { at: 'auth', width: 58 };

export default LogoBottom;
