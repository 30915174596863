import SoftBadge from "components/common/SoftBadge";
import { Dropdown, Table, Row, Col, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./MailDetailsModal.css";
import CardDropdown from "components/common/CardDropdown";
// import axios from "axios";
import React from "react";
import Loader from "components/common/Loader";
import { formatLogInfo } from "helpers/utils";
import CustomFilterbar from "components/common/CustomFilterbar";
import CustomSearchbar from "components/common/CustomSearchbar";
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
import JSZip from "jszip";
import saveAs from "file-saver";
// import { toast } from "react-toastify";

import date from "date-and-time";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { PDFName, PDFPage } from "pdf-lib";

import { convert } from "html-to-text";
// import { words } from "lodash";
import {
  embedPdfPages,
  getHtmlWithImageActualData,
  handleCreatePurePdf,
} from "helpers/utils-pdf";

import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function MailDetailsModal({
  show,
  handleClose,
  payload,
  mailData,
  setMailData,
  mailDataTmp,
  setMailDataTmp,
  updatedLetterHead,

  // propertyLetterhead,
}) {
  const [loader, setLoader] = React.useState(false);
  const [mailDataCpy, setMailDataCpy] = React.useState([]);
  const [mailDataCpyTmp, setMailDataCpyTmp] = React.useState([]);

  const { property_name } = useParams();

  React.useEffect(() => {
    setLoader(true);
    setMailDataCpy([...mailData]);
    setMailDataCpyTmp([...mailData]);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, [payload, mailData]);

  const [previewContent, setPreviewContent] = React.useState({
    isShown: false,
    type: "",
    index: "",
  });

  const [bulkMails, setBulkMails] = React.useState([]);
  console.log(bulkMails);

  const addBulkAction = (v) => {
    console.log(v);
    if (bulkMails.includes(v)) {
      let state = bulkMails?.filter((j) => j !== v);
      setBulkMails(state);
    } else {
      setBulkMails(bulkMails.concat(v));
    }
  };

  function generateBase64Pdf(docDefinition, getb64) {
    return new Promise((resolve, reject) => {
      const pdfDoc = pdfMake.createPdf(docDefinition);
      if (getb64)
        pdfDoc.getBase64((result) => {
          resolve(result); // Resolve the promise with the Base64 result
        });
      else
        pdfDoc.getBlob((result) => {
          resolve(result); // Resolve the promise with the Base64 result
        });
    });
  }

  const applyBulkAction = async (actionType = "") => {
    setLoader(true);

    console.log(bulkMails);

    if (bulkMails.length > 0) {
      console.log("call api here..");
      let arr = mailData?.filter((v) => bulkMails.includes(v._id));

      console.log(arr);
      // return;

      if (actionType === "mail") {
        let mails = [];
        arr?.forEach((v, i) => {
          console.log(v);
          let obj = {};
          obj.mail_pdf = v.mailout_id.mail_content;
          obj.user_name = "sample name-" + i;

          mails.push(obj);
        });

        console.log(mails);

        let bulkData = [];

        for (let index = 0; index < mails.length; index++) {
          const element = mails[index];
          console.log(element);
          let file = "";
          let content = "";
          if (!element.mail_pdf) {
            continue;
          }

          console.log(file);
          console.log("working");

          content = await getHtmlWithImageActualData(element.mail_pdf);

          console.log(content);

          let htmlContent = htmlToPdfmake(content, {
            defaultStyles: { p: { margin: [0, 0, 0, 0] } },
            removeExtraBlanks: true,
          });

          let docDefinition = {
            // meta info
            info: {
              title: "mail",
              author: "",
              subject: "",
              keywords: "",
            },

            pageSize: "A4",

            // [left, top, right, bottom] or [horizontal, vertical] or just a number
            pageMargins: [40, 60, 40, 60],

            content: htmlContent,
          };

          bulkData.push({ docDefinition, user_name: element.user_name });
        }

        console.log(bulkData);

        let promises = [];

        // Generate Base64 PDF for each document definition
        for (let i = 0; i < bulkData.length; i++) {
          promises.push(generateBase64Pdf(bulkData[i].docDefinition));
        }

        // Wait for all promises to resolve
        Promise.all(promises)
          .then((base64PdfArray) => {
            console.log(base64PdfArray); // Array with Base64 PDFs
            // Now you can send `base64PdfArray` back to the caller function or do other processing
            const zip = new JSZip();

            base64PdfArray.forEach((file, i) => {
              // if (file?.startsWith("data")) file = file?.split("base64,")[1];

              // const blob = b64toBlob(file, "application/pdf");

              // bulkData.push(blob);

              console.log("####################################");
              console.log("####################################");
              console.log("####################################");
              console.log(file);

              // zip.file(bulkData[i].user_name + i + ".pdf", file);
              zip.file(`${bulkData[i].user_name}-${i + 1}.pdf`, file);
            });

            console.log(zip);

            // once you finish adding all the pdf to the zip, return the zip file
            zip.generateAsync({ type: "blob" }).then((blob) => {
              console.log(zip.files);
              saveAs(
                blob,
                date.format(new Date(), "YYYYMMDD") +
                  "_" +
                  payload.property_name +
                  ".zip"
              );
              setLoader(false);
            });
          })
          .catch((error) => {
            console.error("Error generating PDFs:", error);
          });
      }
      if (actionType === "letter") {
        let letters = [];
        arr?.forEach((v, i) => {
          console.log(v);
          let obj = {};
          obj.letter_pdf = v.mailout_id.letter_content;
          obj.user_name = "sample letter name-" + i;

          letters.push(obj);
        });

        console.log(letters);

        let bulkData = [];

        for (let index = 0; index < letters.length; index++) {
          const element = letters[index];
          console.log(element);
          let file = "";
          let content = "";
          if (!element.letter_pdf) {
            continue;
          }

          console.log(file);
          console.log("working");

          content = await getHtmlWithImageActualData(element.letter_pdf);

          console.log(content);

          let htmlContent = htmlToPdfmake(content, {
            defaultStyles: { p: { margin: [0, 0, 0, 0] } },
            removeExtraBlanks: true,
          });

          let docDefinition = {
            // meta info
            info: {
              title: "letter",
              author: "",
              subject: "",
              keywords: "",
            },

            pageSize: "A4",

            // [left, top, right, bottom] or [horizontal, vertical] or just a number
            pageMargins: [40, 60, 40, 60],

            content: htmlContent,
          };
          if (updatedLetterHead !== "") {
            console.log("letterhead to attach");
            docDefinition.pageMargins = [40, 160, 40, 160];

            // pdfMake.createPdf(docDefinition).getBase64((result) => {
            //   // console.log(result);
            //   embedPdfPages(result, updatedLetterHead);
            // });
          }

          bulkData.push({ docDefinition, user_name: element.user_name });
        }

        console.log(bulkData);

        let promises = [];

        // Generate Base64 PDF for each document definition
        for (let i = 0; i < bulkData.length; i++) {
          if (updatedLetterHead !== "")
            promises.push(generateBase64Pdf(bulkData[i].docDefinition, true));
          else promises.push(generateBase64Pdf(bulkData[i].docDefinition));
        }

        // Wait for all promises to resolve
        Promise.all(promises)
          .then((base64PdfArray) => {
            console.log(base64PdfArray); // Array with Base64 PDFs
            // Now you can send `base64PdfArray` back to the caller function or do other processing
            const zip = new JSZip();

            base64PdfArray.forEach((file, i) => {
              // if (file?.startsWith("data")) file = file?.split("base64,")[1];

              // const blob = b64toBlob(file, "application/pdf");

              // bulkData.push(blob);

              if (updatedLetterHead !== "") {
                console.log("linking here");
                // return;
                let embeddedWithLetter = embedPdfPages(
                  file,
                  updatedLetterHead,
                  "return-as-blob"
                );

                zip.file(
                  `${bulkData[i].user_name}-${i + 1}.pdf`,
                  embeddedWithLetter
                );
              } else {
                zip.file(`${bulkData[i].user_name}-${i + 1}.pdf`, file);
              }
            });

            // console.log(zip);

            // once you finish adding all the pdf to the zip, return the zip file
            zip.generateAsync({ type: "blob" }).then((blob) => {
              console.log(zip.files);
              saveAs(
                blob,
                date.format(new Date(), "YYYYMMDD") +
                  "_" +
                  payload.property_name +
                  ".zip"
              );
              setLoader(false);
            });
          })
          .catch((error) => {
            console.error("Error generating PDFs:", error);
          });
      }
      if (actionType === "delete") {
        console.log("delete");
        let arr = [];
        let arrToDelete = [];
        arr = mailData?.filter((v) => !bulkMails.includes(v.id));
        arrToDelete = mailData?.filter((v) => bulkMails.includes(v.id));
        console.log(arr);
        setLoader(false);

        // arrToDelete?.forEach((v) => {
        //   callGivenOption("delete", true, v);
        // });
      }
      setBulkMails([]);
    } else {
      console.log("no files to convert");
    }
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  

  const previewAndPrint = async (type = "", htmlContent) => {
    console.log(type);

    let content = await getHtmlWithImageActualData(htmlContent);

    if (type === "mail") {
      handleCreatePurePdf(content, {
        title: "mail content",
        author: "condozak",
      });
    }
    if (type === "letter") {
      handleCreatePurePdf(
        content,
        {
          title: "letter content",
          author: "condozak",
        },
        updatedLetterHead !== "",
        updatedLetterHead
      );
    }
  };

  // if (loader) return <Loader />;

  if (mailData.length < 0) return <Loader />;

  return (
    <>
      <Modal
        show={show.shown}
        onHide={() => {
          setBulkMails([]);
          handleClose({ shown: false, type: "" });
        }}
        backdrop="static"
        keyboard={false}
        size={show.type === "Mail Details Table" ? "xl" : "lg"}
        // size="lg"
        // fullscreen={show.type === "Mail Details Table"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {show.type === "Mail Details Table"
              ? "Recipient Details"
              : show.type}
          </Modal.Title>
        </Modal.Header>
        {loader ? (
          <Modal.Body style={{ minHeight: "90vh" }}>
            <Loader />
          </Modal.Body>
        ) : (
          <Modal.Body>
            {show.type === "Letter Preview" && (
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{ __html: payload.letter_content }}
                ></div>
              </Modal.Body>
            )}
            {show.type === "Mail Details Table" && (
              <div style={{ minHeight: "90vh" }}>
                <div>
                  <Row className="flex-end-center mb-1">
                    <Col className=" d-flex justify-content-sm-end">
                      {bulkMails.length > 0 && (
                        <div className="me-3 hide-pdf-div">
                          <Button
                            variant="falcon-success"
                            onClick={() => applyBulkAction("mail")}
                            // disabled={bulkMails.length === 1}
                          >
                            Download Mail Copies
                          </Button>
                          {payload.letter_drafted && (
                            <Button
                              variant="falcon-success"
                              onClick={() => applyBulkAction("letter")}
                              // disabled={bulkMails.length === 1}
                              className="ms-3"
                            >
                              Download Letter Copies
                            </Button>
                          )}
                        </div>
                      )}
                      <CustomFilterbar
                        filterFlags={["Status Sent", "Status No-Consent"]}
                        dataListTemp={mailDataTmp}
                        stateUpdator={setMailData}
                        from="Mail Details Inner Table"
                      />
                      <CustomSearchbar
                        searchFlags={[
                          "user_id.firstname",
                          "user_id.email",
                          "unit_id.unit_number",
                          "group",
                          "status",
                        ]}
                        dataListTemp={mailDataTmp}
                        stateUpdator={setMailData}
                        placeholders={["resident", "unit #", "email", "group"]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {bulkMails.length > 0 && (
                        <div className="me-3 show-pdf-div">
                          <Button
                            variant="falcon-success"
                            onClick={() => applyBulkAction(false)}
                            disabled={bulkMails.length === 1}
                          >
                            Download Mail Copies
                          </Button>
                          {payload.letter_drafted && (
                            <Button
                              variant="falcon-success"
                              onClick={() => applyBulkAction(true)}
                              disabled={bulkMails.length === 1}
                              className="my-3"
                            >
                              Download Letter Copies
                            </Button>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>

                <Table responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Unit #</th>
                      <th>Recipient</th>
                      <th>Groups</th>
                      <th>Time</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mailData?.map((v, i) => (
                      <tr
                        key={v?._id}
                        style={{
                          height: mailData?.length === 1 ? "130px" : "",
                        }}
                      >
                        <td>
                          <Form.Check
                            id={v?._id}
                            onChange={() => addBulkAction(v?._id)}
                            checked={bulkMails?.includes(v?._id)}
                          />
                        </td>
                        <td>{v?.unit_id?.unit_number}</td>
                        <td>{v?.user_id?.firstname}</td>
                        <td>{v?.group}</td>
                        <td style={{ minWidth: "130px" }}>
                          {formatLogInfo(v?.createdAt)}
                        </td>
                        <td>{v?.user_id?.email}</td>
                        <td>
                          {v?.status === "Sent" && (
                            <SoftBadge bg="success">Sent</SoftBadge>
                          )}
                          {v?.status === "E-consent Inactive" && (
                            <SoftBadge bg="secondary">
                              E-consent Inactive
                            </SoftBadge>
                          )}
                        </td>
                        <td>
                          <CardDropdown>
                            <div className="py-2">
                              <Dropdown.Item
                                onClick={() =>
                                  previewAndPrint(
                                    "mail",
                                    v?.mailout_id.mail_content
                                  )
                                }
                              >
                                Print Mail
                              </Dropdown.Item>
                              {v?.mailout_id?.draft_letter && (
                                <Dropdown.Item
                                  onClick={() =>
                                    previewAndPrint(
                                      "letter",
                                      v?.mailout_id.letter_content
                                    )
                                  }
                                >
                                  Print Letter
                                </Dropdown.Item>
                              )}
                            </div>
                          </CardDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
