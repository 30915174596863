import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";

import { Formik } from "formik";

const FinancialsInfo = () => {
  return (
    <Card className="mb-3" style={{ minHeight: "90vh" }}>
      <FalconCardHeader title="Financials Information" />
      <Card.Body className="bg-light">
        <Formik
          initialValues={{
            taxAuthority: "",
            taxPoint: "",
            DPTT: "",
            DSTT: "",
            baseCurrency: "",
            currentBudget: "",
            taxOpted: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.taxAuthority) {
              errors.taxAuthority = "this field is required!";
            }
            if (!values.taxPoint) {
              errors.taxPoint = "this field is required!";
            }
            if (!values.DPTT) {
              errors.DPTT = "this field is required!";
            }
            if (!values.DSTT) {
              errors.DSTT = "this field is required!";
            }
            if (!values.baseCurrency) {
              errors.baseCurrency = "this field is required!";
            }
            if (!values.currentBudget) {
              errors.currentBudget = "this field is required!";
            }
            if (!values.taxOpted) {
              errors.taxOpted = "this field is required!";
            }
            // for (const val in values) {
            //   if (values.hasOwnProperty.call(values, val)) {
            //     if (values[val] === undefined)
            //       errors[val] = 'this field is required!';
            //   }
            // }

            return errors;
          }}
          onSubmit={({ setSubmitting }) => {
            setTimeout(() => {
              // alert(JSON.stringify(values, null, 2));
              // console.log(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="g-3">
                <Form.Group as={Col} lg={6} controlId="firstname">
                  <Form.Label>Tax Authority</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Tax Authority"
                    value={values.taxAuthority}
                    name="taxAuthority"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Text className="text-danger ms-2">
                    {errors.taxAuthority &&
                      touched.taxAuthority &&
                      errors.taxAuthority}
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} lg={6} controlId="lastname">
                  <Form.Label>Tax Point</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="tax point"
                    value={values.taxPoint}
                    name="taxPoint"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Text className="text-danger ms-2">
                    {errors.taxPoint && touched.taxPoint && errors.taxPoint}
                  </Form.Text>
                </Form.Group>
              </Row>
              {/* <br /> */}
              <Row className="g-3">
                <Form.Group as={Col} lg={6} controlId="email2">
                  <Form.Label>Defalt Purchased Transaction Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="DPTT"
                    value={values.DPTT}
                    name="DPTT"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Text className="text-danger ms-2">
                    {errors.DPTT && touched.DPTT && errors.DPTT}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="phone">
                  <Form.Label>Defalt ID Sales Transaction Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="DSTT"
                    value={values.DSTT}
                    name="DSTT"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Text className="text-danger ms-2">
                    {errors.DSTT && touched.DSTT && errors.DSTT}
                  </Form.Text>
                </Form.Group>
              </Row>
              {/* <br /> */}
              <Row className="g-3">
                <Form.Group as={Col} lg={6} controlId="email23">
                  <Form.Label>Base Currency</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="base currency"
                    value={values.baseCurrency}
                    name="baseCurrency"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Text className="text-danger ms-2">
                    {errors.baseCurrency &&
                      touched.baseCurrency &&
                      errors.baseCurrency}
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} lg={6} controlId="phone23">
                  <Form.Label>Current Budget</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="current budget"
                    value={values.currentBudget}
                    name="currentBudget"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Form.Text className="text-danger ms-2">
                    {errors.currentBudget &&
                      touched.currentBudget &&
                      errors.currentBudget}
                  </Form.Text>
                </Form.Group>
              </Row>
              {/* <br /> */}
              <Row className="g-3">
                <div>
                  <Form.Group
                    controlId="email2310"
                    className="d-flex justify-content-between align-items-center bg-white"
                  >
                    <Form.Label>Tax Opted</Form.Label>
                    <div className="mt-2 p-2 d-flex justify-content-center align-items-center">
                      <Form.Check
                        inline
                        label="Yes"
                        name="taxOpted"
                        type={"radio"}
                        id={`inline-radio-1`}
                        value="Yes"
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="taxOpted"
                        type={"radio"}
                        id={`inline-radio-2`}
                        value="No"
                      />
                    </div>
                  </Form.Group>
                  <Form.Text className="text-danger ms-2 float-end">
                    {errors.taxOpted && touched.taxOpted && errors.taxOpted}
                  </Form.Text>
                </div>
              </Row>
              <br />
              <div className="text-end">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Update
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default FinancialsInfo;
