import React, { useContext } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { AuthWizardContext } from '../../../../../context/Context';
import { ErrorMessage, Formik, Field } from 'formik';

export default function AmenityRules() {
  
    const { user, setUser, step, setStep } = useContext(AuthWizardContext);
    console.log(user);

    let obj = { ...user };
    console.log(obj);

    const customValidation = values => {
      // console.log(values);
      const errors = {};

      // if (!values.approval) {
      //   errors.approval = 'aproval mode is required!';
      // }
      if (!values.amenity_rules) {
        errors.amenity_rules = 'rules are required!';
      }
      // if (!values.max_book_length) {
      //   errors.max_book_length = 'please select maximum booking time!';
      // }
      // if (!values.volume_of_booking) {
      //   errors.volume_of_booking = 'booking volume option is required!';
      // }
      // if (values.volume_of_booking === 'Limit by Unit') {
      //   if (!values.limit_by_unit_value) {
      //     errors.limit_by_unit_value = 'number of times per value!';
      //   }
      //   if (!values.limit_by_unit_date) {
      //     errors.limit_by_unit_date = 'limit for booking is required!';
      //   }
      // }

      if (!values.max_guest_limit) {
        errors.max_guest_limit = 'maximum guest limit is required!';
      }
      if (!values.allow_same_booking) {
        errors.allow_same_booking = 'select this option also!';
      }
      if (values.allow_same_booking === 'Yes') {
        if (!values.multiple_bookings_on_time_slots) {
          errors.multiple_bookings_on_time_slots = 'select this option also!';
        }
      }

      return errors;
    };

    return (
      <div>
        <Formik
          initialValues={{
            approval: obj.approval || '',
            amenity_rules: obj.amenity_rules || '',
            // max_book_length: obj.max_book_length || '',
            allow_same_booking: obj.allow_same_booking || '',
            volume_of_booking: obj.volume_of_booking || '',
            limit_by_unit_value: obj.limit_by_unit_value || '',
            limit_by_unit_date: obj.limit_by_unit_date || '',
            booking_day: obj.booking_day || '',
            booking_start_date: obj.booking_start_date || '',
            booking_end_date: obj.booking_end_date || '',
            duration_in_hours: obj.duration_in_hours || '',
            max_guest_limit: obj.max_guest_limit || '',
            // allow_same_booking: obj.allow_same_booking || '',
            multiple_bookings_on_time_slots:
              obj.multiple_bookings_on_time_slots || ''
          }}
          validate={values => customValidation(values)}
          // validationSchema={()=>Y}
          onSubmit={(values, { setSubmitting }) => {
            // handleSubmit(values);
            setSubmitting(false);
            console.log(user);
            console.log(values);
            // return;
            setUser(() => Object.assign({ ...user, ...values }));
            setStep(step + 1);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            // setValues
            /* and other goodies */
          }) => (
            <div className="">
              <div className="">
                <form onSubmit={handleSubmit}>
                  {/* <div className="mb-3">
                    <label>Approval for this amenity</label> <br />
                    <label className="mx-3 ">
                      Auto Approved{' '}
                      <Field type="radio" name="approval" value="Auto" />
                    </label>
                    <label className="mx-3">
                      {' '}
                      Admin Approval Required{' '}
                      <Field type="radio" name="approval" value="By Admin" />
                    </label>
                    <ErrorMessage
                      name="approval"
                      component={'div'}
                      className="text-danger fs--1"
                    />
                  </div> */}
                  <div className="mb-3">
                    <label>Terms & Conditions *</label>
                    <textarea
                      rows={5}
                      type="text"
                      name="amenity_rules"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amenity_rules}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="amenity_rules"
                      component={'div'}
                      className="text-danger fs--1"
                    />
                  </div>
                  {/* <div className="mb-3">
                    <label>Max Book Length *</label>
                    <Field
                      component="select"
                      id="max_book_length"
                      name="max_book_length"
                      multiple={false}
                      className="form-select"
                    >
                      <option value="">Select duration</option>
                      <option value="30m">30 Minutes</option>
                      <option value="45m">45 Minutes</option>
                      <option value="1h">1 Hour</option>
                      <option value="3h">3 Hours</option>
                      <option value="5h">5 Hours</option>
                    </Field>
                    <ErrorMessage
                      name="max_book_length"
                      component={'div'}
                      className="text-danger fs--1"
                    />
                  </div> */}
                  {/* <div className="mb-3">
                    <label>Volume of Booking *</label>{' '}
                    <label className="mx-3 ">
                      No Limit{' '}
                      <Field
                        type="radio"
                        name="volume_of_booking"
                        value="No Limit"
                      />
                    </label>
                    <label className="mx-3">
                      {' '}
                      Limit by unit{' '}
                      <Field
                        type="radio"
                        name="volume_of_booking"
                        value="Limit by Unit"
                      />
                    </label>
                    <ErrorMessage
                      name="volume_of_booking"
                      component={'div'}
                      className="text-danger fs--1"
                    />
                  </div>
                  {values.volume_of_booking === 'Limit by Unit' && (
                    <Row className="mb-3">
                      <Col md={4}>
                        <Field
                          type="text"
                          name="limit_by_unit_value"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="limit_by_unit_value"
                          component={'div'}
                          className="text-danger fs--1"
                        />
                      </Col>
                      <Col className="text-center">times per</Col>
                      <Col md={4} className="ps-0">
                        <Field
                          component="select"
                          id="limit_by_unit_date"
                          name="limit_by_unit_date"
                          multiple={false}
                          className="form-select"
                        >
                          <option value="">select</option>
                          <option value="1 Day">Day</option>
                          <option value="1 Week">Week</option>
                          <option value="1 Month">Month</option>
                          <option value="1 Year">Year</option>
                        </Field>
                        <ErrorMessage
                          name="limit_by_unit_date"
                          component={'div'}
                          className="text-danger fs--1"
                        />
                      </Col>
                    </Row>
                  )} */}
                  <br />

                  <Row className="mb-3">
                    <Col md={8}>
                      <label>Maximum guest limit per Booking *</label>
                    </Col>
                    <Col md={4}>
                      <input
                        type="text"
                        name="max_guest_limit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.max_guest_limit}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="max_guest_limit"
                        component={'div'}
                        className="text-danger fs--1"
                      />
                    </Col>
                  </Row>
                  <div className="mb-3">
                    <label>Allow multiple bookings for this amenity ?</label>{' '}
                    {/* <br /> */}
                    <label className="mx-3 ">
                      Yes{' '}
                      <Field
                        type="radio"
                        name="allow_same_booking"
                        value="Yes"
                      />
                    </label>
                    <label className="mx-3">
                      {' '}
                      No{' '}
                      <Field
                        type="radio"
                        name="allow_same_booking"
                        value="No"
                      />
                    </label>
                    <ErrorMessage
                      name="allow_same_booking"
                      component={'div'}
                      className="text-danger fs--1"
                    />
                  </div>
                  {values.allow_same_booking === 'Yes' && (
                    <div className="mb-4 row">
                      <Col md={8}>
                        <label>
                          Number of Simultaneous Bookings for each time slot *
                        </label>
                      </Col>
                      <Col md={4}>
                        <input
                          type="text"
                          name="multiple_bookings_on_time_slots"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.multiple_bookings_on_time_slots}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="multiple_bookings_on_time_slots"
                          component={'div'}
                          className="text-danger fs--1"
                        />
                      </Col>
                    </div>
                  )}
                  <div className='d-flex justify-content-between align-items-center'>
                    <Button
                      variant="secondary"
                      onClick={() => setStep(step - 1)}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="primary"
                      className=" float-end"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  
}
