import Modal from "react-bootstrap/Modal";
import { Badge, Table } from "react-bootstrap";
import { formatLogInfo } from "helpers/utils";

function BookingDetailsModel(props) {
  const { booking } = props;

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-1">
          {booking.amenityName || "title here"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive hover style={{ fontSize: "14px" }}>
          <tbody>
            <tr>
              <td>Booking ID</td>
              <td>{booking._id?.slice(0, 8) || "id"}</td>
            </tr>
            <tr>
              <td>Amenity Name</td>
              <td>{booking.amenityName || "name"}</td>
            </tr>
            {booking.payment_type !== "No Fee" && (
              <tr>
                <td>Booking Amount</td>
                <td>{`$${booking.amenity_fee || "price"}.00`}</td>
              </tr>
            )}
            {booking.payment_type === "No Fee" && (
              <tr>
                <td>Booking Amount</td>
                <td>{"No Fee"}</td>
              </tr>
            )}
            <tr>
              <td>Status</td>
              <td>
                {booking.status !== undefined ? (
                  <Badge className={`bg-${booking.status.type || "info"}`}>
                    {booking.status.title || "status"}
                  </Badge>
                ) : (
                  "status"
                )}
              </td>
            </tr>
            {booking.amenityType === "Daily" && (
              <>
                <tr>
                  <td>Start Date</td>
                  <td>{formatLogInfo(booking.start_time, true) || "type"}</td>
                </tr>
                {Object.hasOwnProperty.call(booking, "end_time") && (
                  <tr>
                    <td>End Date</td>
                    <td>{formatLogInfo(booking.end_time, true) || "type"}</td>
                  </tr>
                )}
              </>
            )}
            {booking.amenityType === "Hotel Style" && (
              <>
                <tr>
                  <td>Check-In Date</td>
                  <td>{formatLogInfo(booking.start_time, true) || "type"}</td>
                </tr>
                <tr>
                  <td>Check-Out Date</td>
                  <td>{formatLogInfo(booking.end_time, true) || "type"}</td>
                </tr>
              </>
            )}
            {booking.amenityType === "Time Slots" && (
              <tr>
                <td>Booking Date</td>
                <td>{formatLogInfo(booking.start_time, true) || "type"}</td>
              </tr>
            )}
            {booking.amenityType === "Hotel Style" && (
              <>
                <tr>
                  <td>Check-In Time</td>
                  <td>{booking.timeslot?.split("-")[0] || "type"}</td>
                </tr>
                <tr>
                  <td>Check-Out Time</td>
                  <td>{booking.timeslot?.split("-")[1] || "type"}</td>
                </tr>
              </>
            )}
            {booking.amenityType !== "Hotel Style" && (
              <>
                <tr>
                  <td>Start Time</td>
                  <td>{booking.timeslot?.split("-")[0] || "type"}</td>
                </tr>
                <tr>
                  <td>End Time</td>
                  <td>{booking.timeslot?.split("-")[1] || "type"}</td>
                </tr>
              </>
            )}
            {/* <tr>
              <td>Description</td>
              <td style={{ fontSize: "12px" }}>
                {booking.amenitydescription || "des"}
              </td>
            </tr> */}
            <tr>
              <td>Unit Number</td>
              <td>{booking.unit_num || "des"}</td>
            </tr>
            {/* <tr className={booking.payment_type === "No Fee" && "d-none"}>
              <td>Payment Option</td>
              <td>{booking.payment_option || "des"}</td>
            </tr> */}
            {/* <tr>
              <td>Payment Type</td>
              <td>{booking.payment_type || "des"}</td>
            </tr> */}
            {booking.payment_option === "Check" && (
              <tr className={booking.payment_type === "No Fee" && "d-none"}>
                <td>Instrutions for Check</td>
                <td>{booking.check_description || "-"}</td>
              </tr>
            )}
            {booking.payment_option === "Cash" && (
              <tr className={booking.payment_type === "No Fee" && "d-none"}>
                <td>Instrutions for Cash</td>
                <td>{booking.cash_description || "-"}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

export default BookingDetailsModel;
