import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SoftBadge from "../../../components/common/SoftBadge";
import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import CardDropdown from "../../../components/common/CardDropdown";
import { startCase } from "lodash";

const TableRow = ({ data, propLength, userData, addPropertyToRecents }) => {
  try {
    return (
      <tr
        className="align-middle"
        style={{ height: propLength === 1 ? "100px" : "" }}
      >
        <td className="text-center">{data.property_id || "id"}</td>
        <td
          className="text-center text-primary cursor-pointer"
          // to={`/property/management/buildings/${data._id}/${data.property_name}/units`}

          onClick={() =>
            addPropertyToRecents(data._id, "units", data.property_name)
          }
        >
          {startCase(data.property_name) || "name"}
        </td>
        <td className="text-center">{data.address || "address"}</td>
        <td className="text-center">
          <SoftBadge pill bg={data.status.type}>
            {data.status.title}
            <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
          </SoftBadge>
        </td>
        {userData.role === "Site-Administrators" && (
          <td className="text-center">
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  className="text-primary"
                  onClick={() =>
                    addPropertyToRecents(
                      data._id,
                      "profile",
                      data.property_name
                    )
                  }
                >
                  Edit
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </td>
        )}
      </tr>
    );
  } catch (e) {
    // console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

const PropertyTable = ({
  list,
  selectBuilding,
  userData,
  addPropertyToRecents,
}) => {
  try {
    return (
      <Table
        responsive
        striped
        hover
        bordered
        style={{ fontSize: "14px" }}
        // className="bg-info"
      >
        <thead>
          <tr>
            <th scope="col" className="text-center">
              ID
            </th>
            <th scope="col" className="text-center">
              Name
            </th>
            <th scope="col" className="text-center">
              Address
            </th>
            <th scope="col" className="text-center">
              Status
            </th>
            {userData.role === "Site-Administrators" && (
              <th scope="col" className="text-center">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {list?.map((property, index) => (
            <TableRow
              // style={{height:'400px'}}
              data={property}
              key={index}
              selectBuilding={selectBuilding}
              propLength={list.length}
              userData={userData}
              addPropertyToRecents={addPropertyToRecents}
            />
          ))}
          {/* {list?.length === 1 && <TableRow />} */}
        </tbody>
      </Table>
    );
  } catch (e) {
    // console.log(e);
    return <p className="text-danger fs-1">{e.message}</p>;
  }
};

export default PropertyTable;
