import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
import SoftBadge from "components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
import CardDropdown from "components/common/CardDropdown";
import { useParams } from "react-router-dom";

const TableRow = ({ data, propLength, index }) => {
  const { unit_number } = useParams();

  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td className="text-center cursor-pointer">{index + 1 || "id"}</td>
      <td className="text-center cursor-pointer">{data.unit_number || "id"}</td>

      <td className="text-center">
        <SoftBadge pill bg={index === 0 ? "success" : "info"}>
          {index === 0 ? "Default" : "Not Default"}
          <FontAwesomeIcon icon={"check"} className="ms-2" />
        </SoftBadge>
      </td>
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            {data.unit_number !== unit_number && (
              <Dropdown.Item
                className="text-primary"
                href={`/owner-portal/all-units/${data._id}/${data.unit_number}`}
              >
                Switch
              </Dropdown.Item>
            )}
            <Dropdown.Item className="text-info">Make Default</Dropdown.Item>
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

const MailOutsTable = ({
  properties = [],
  selectBuilding,
  handleShowDetail,
}) => {
  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            #
          </th>
          <th scope="col" className="text-center">
            Unit #
          </th>
          <th scope="col" className="text-center">
            Order
          </th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {properties?.map((property, index) => (
          <TableRow
            data={property}
            key={index}
            selectBuilding={selectBuilding}
            index={index}
            handleShowDetail={handleShowDetail}
            propLength={properties.length}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default MailOutsTable;
