import React from "react";

import { Form as BSForm, Button, Row, Col, Image } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { toast } from "react-toastify";

import { AuthWizardContext } from "context/Context";
import { CloudUpload } from "@mui/icons-material";

const FILE_SIZE = 1024 * 1024; // 1 MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const EventDetailsPage = ({ from = "", event = {}, updateEvent }) => {
  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const handleSubmit = (data) => {
    console.log(data);
    setUser(Object.assign({}, user, data));

    setStep(step + 1);
  };

  return (
    <div className={`${from === "Edit Event Page" && "card"}`}>
      {from === "Edit Event Page" && (
        <div className="card-header">Edit Details</div>
      )}

      <div className={`${from === "Edit Event Page" && "card-body bg-light"}`}>
        <Formik
          initialValues={{
            event_name:
              from === "Edit Event Page"
                ? event.event_name
                : user.event_name || "",
            event_description:
              from === "Edit Event Page"
                ? event.event_description
                : user.event_description || "",
            // event_start_date: user.event_start_date || "",
            // event_end_date: user.event_end_date || "",
            // event_start_time: user.event_start_time || "",
            // event_start_timezone: user.event_start_timezone || "",
            // event_end_time: user.event_end_time || "",
            // event_end_timezone: user.event_end_timezone || "",
            // event_registration_deadline: user.event_registration_deadline || "",
            event_cover_image:
              from === "Edit Event Page"
                ? event.image_url === "Error"
                  ? ""
                  : event.image_url
                : user.event_cover_image || "",
          }}
          validationSchema={Yup.object({
            event_name: Yup.string().required("event name is required!"),
            event_description: Yup.string().required(
              "description is required!"
            ),
            // event_start_date: Yup.string().required("start date is required!"),
            // event_end_date: Yup.string().required("end date is required!"),
            // event_start_time: Yup.string().required("start time is required!"),
            // event_start_timezone: Yup.string().required(
            //   "timezone is required!"
            // ),
            // event_end_time: Yup.string().required("end time is required!"),
            // event_end_timezone: Yup.string().required("timezone is required!"),
            // event_registration_deadline: Yup.string().required(
            //   "registration is required!"
            // ),
            event_cover_image: Yup.mixed()
              .required("Event cover image is required!")
              .test(
                "fileSize",
                "File size is too large. Maximum size is 1 MB",
                (value) => value && value.size <= FILE_SIZE
              )
              .test(
                "fileFormat",
                "Unsupported file format. Must be jpg, jpeg, or png",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
              ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <BSForm.Group className="mb-3">
                <BSForm.Label>Event Title</BSForm.Label>
                <Field
                  name="event_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter event name here"
                />
                <ErrorMessage
                  name="event_name"
                  component={"div"}
                  className="fs--1 text-danger"
                />
              </BSForm.Group>
              <BSForm.Group className="mb-3">
                <BSForm.Label>Event Description</BSForm.Label>
                <BSForm.Control
                  placeholder="Enter event description here..."
                  as={"textarea"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={5}
                  value={values.event_description}
                  name="event_description"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="event_description"
                  component={"div"}
                  className="fs--1 text-danger"
                />
              </BSForm.Group>

              <BSForm.Group className="mb-3 mt-4">
                <div className="d-flex justify-content-between align-items-center">
                  <BSForm.Label className="text-primary mb-1">
                    Upload Event Cover Image
                  </BSForm.Label>
                  {values.event_cover_image && (
                    <Button
                      variant="falcon-danger"
                      size="sm"
                      onClick={() => setFieldValue("event_cover_image", "")}
                    >
                      Delete
                    </Button>
                  )}
                </div>
                {values.event_cover_image && (
                  <div
                    className="mt-3"
                    style={{
                      borderRadius: "3px",
                      // border: "2px solid silver",
                      boxShadow: "0px 0px 3px ",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={
                        from === "Edit Event Page"
                          ? values.event_cover_image instanceof File
                            ? URL.createObjectURL(values.event_cover_image)
                            : values.event_cover_image
                          : URL.createObjectURL(values.event_cover_image)
                      }
                      // src={values.event_cover_image}
                      fluid
                      rounded
                    />
                  </div>
                )}
                {!values.event_cover_image && (
                  <label
                    htmlFor="event_cover_image"
                    className="d-flex justify-content-center align-items-center fs-2 mt-3 cursor-pointer"
                    style={{
                      display: "block",
                      height: "170px",
                      borderRadius: "20px",
                      // backgroundColor:'silver',
                      border: "2px dashed #dee2ef",
                    }}
                  >
                    <input
                      onChange={(e) => {
                        console.log(e.target.files);
                        setFieldValue("event_cover_image", e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                      value={values.event_cover_image}
                      id="event_cover_image"
                      name="event_cover_image"
                      type="file"
                      accept="image/*"
                      hidden
                      className="form-control"
                    />
                    <div>
                      <CloudUpload fontSize="large" /> Click to upload
                    </div>
                  </label>
                )}
                <ErrorMessage
                  name="event_cover_image"
                  component={"div"}
                  className="fs--1 text-danger"
                />
              </BSForm.Group>
              <br />

              {/* <BSForm.Group className="mb-3">
              <Field
                name="rules_accepted"
                type="checkbox"
                className="form-check-input"
              />
              <BSForm.Label className="ms-3">
                {" "}
                Agreed to terms and conditions!
              </BSForm.Label>
              <ErrorMessage
                name="rules_accepted"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group> */}
              {from === "Edit Event Page" && (
                <div className="text-end">
                  <Button variant="primary" onClick={() => updateEvent(values)}>
                    Update
                  </Button>
                </div>
              )}
              {from !== "Edit Event Page" && (
                <div className="text-end">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventDetailsPage;
