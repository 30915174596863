import React from "react";

import { useState, useEffect } from "react";

import Flex from "components/common/Flex";
import { Card, Col, Row } from "react-bootstrap";

import MailOutsTable from "./BookedAmenitiesTable";

import PayPaymentModal from "components/pages/sharedUI/PayReceivePaymentsModel";
import { capitalize } from "lodash";

import axios from "axios";

import { Link } from "react-router-dom";

import CancelBookingModal from "components/pages/Features/Amenities/CancelAmenityModal";

import PaymentReceipt from "components/pages/sharedUI/PaymentReceipt";
import BookingDetailsModel from "components/pages/sharedUI/BookingDetailsModel";

import PaginateComponentCustom from "components/common/PaginateComponentCustom";
import Loader from "components/common/Loader";
import CustomSearchbar from "components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

const MyBookings = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [showBookingDetailsModal, setShowBookingDetailsModal] =
    React.useState(false);
  const handleShowBookingDetailsModal = () => setShowBookingDetailsModal(true);
  const handleCloseBookingDetailsModal = () =>
    setShowBookingDetailsModal(false);

  const [showPayNowModal, setShowPayNowModal] = React.useState(false);
  const handleClosePayNowModal = () => setShowPayNowModal(false);

  const [showPaymentRecceiptModal, setShowPaymentReceiptModal] =
    React.useState(false);
  const handleClosePaymentReceiptModal = () =>
    setShowPaymentReceiptModal(false);

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // paynow

  const [booking_id, setBookingID] = useState("");

  const [amenity, setAmenity] = useState({});

  const cancelBooking = (booking_id) => {
    console.log(booking_id);
    setBookingID(booking_id);
    handleShow();
  };

  useEffect(() => {
    setLoader(true);
    getMyBookedAmenitiesData();
  }, []);

  const handleSetShowPayNowModal = (id) => {
    let amenity = propertiesData?.filter((v) => v._id === id);
    let data = amenity[0];
    data.loadType = "Pay Payment Owner";
    setAmenity(data);

    setShowPayNowModal(true);
  };

  const handlePaymentReceipt = (data) => {
    data.loadType = "Owner";
    setAmenity(data);
    setShowPaymentReceiptModal(true);
  };

  const functionHandleShowBookingDetailsModel = (data) => {
    setAmenity(data);
    handleShowBookingDetailsModal();
  };

  const getMyBookedAmenitiesData = (p_id, user_id) => {
    p_id = userData.propid;
    user_id = userData.data;
    axios
      .post(
        `${hostname}/api/amenity/MyBookings`,
        {
          p_id,
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        let arr = res.data.data?.map((v) => {
          let obj = { ...v };
          delete obj.booking;
          let obj1 = { ...v.booking };
          let finalObj = { ...obj, ...obj1 };
          finalObj.status = {
            type:
              v.booking.status === "Confirmed"
                ? "success"
                : v.booking.status === "Cancelled"
                ? "danger"
                : v.booking.status === "Payment Pending"
                ? "warning"
                : "info",
            title: v.booking.status,
            icon: "check",
          };
          return { ...finalObj };
        });
        console.log(arr);

        setPropertiesData(arr);
        setPropertiesDataTemp(arr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getAllPropertiesData = (c_id) => {
    console.log("working");
    console.log(c_id);
    // return;
    if (c_id) {
      axios
        .post(
          `${hostname}/api/property/Allproperties`,
          {
            c_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          let properties = res.data.data.map((p) => {
            console.log(p.address);
            return Object.assign(
              { address: p.address || "-" },
              p.property_info
            );
          });
          setLoader(false);

          properties = properties.map((p) => {
            p.property_name = capitalize(p.property_name);
            return Object.assign(
              {
                status: {
                  title: "Active",
                  type: "success",
                  icon: "check",
                },
              },
              p
            );
          });
          setPropertiesData(properties);
          setPropertiesDataTemp(properties);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Link className="text-secondary" to={"/"}>
                  Home
                </Link>
                {" / "}
                <Link to={"#!"}>My Bookings</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>My Bookings</h4>
            </Flex>
            <br />

            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomSearchbar
                  searchFlags={[
                    "amenityName",
                    "amenityPrice",
                    "unit_num",
                    "cash_description",
                    "check_description",
                    "payment_option",
                    "status.title",
                  ]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  placeholders={["name", "type", "price"]}
                />
              </Col>
            </Row>

            <PaginateComponentCustom
              dataList={propertiesData}
              TableElement={MailOutsTable}
              itemsPerPage={20}
              getAllPropertiesData={getAllPropertiesData}
              // handleShowDetail={handleShowDetail}
              userData={userData}
              cancelBooking={cancelBooking}
              handleSetShowPayNowModal={handleSetShowPayNowModal}
              handlePaymentReceipt={handlePaymentReceipt}
              functionHandleShowBookingDetailsModel={
                functionHandleShowBookingDetailsModel
              }
            />
            <br />
            <CancelBookingModal
              show={show}
              handleClose={handleClose}
              getAmenitiesData={getMyBookedAmenitiesData}
              booking_id={booking_id}
              userData={userData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
            <PayPaymentModal
              show={showPayNowModal}
              handleClose={handleClosePayNowModal}
              getAmenitiesData={getMyBookedAmenitiesData}
              booking_id={booking_id}
              amenity={amenity}
              userData={userData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
            <PaymentReceipt
              show={showPaymentRecceiptModal}
              handleClose={handleClosePaymentReceiptModal}
              getAmenitiesData={getMyBookedAmenitiesData}
              booking={amenity}
              userData={userData}
            />

            <BookingDetailsModel
              show={showBookingDetailsModal}
              handleClose={handleCloseBookingDetailsModal}
              booking={amenity}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default MyBookings;
