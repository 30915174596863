import React from "react";
import PasswordResetForm from "../../../components/authentication/PasswordResetForm";
import { useTranslation } from "react-i18next";

const PasswordReset = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h5 className="text-center">{t("reset_ps.reset_password")}</h5>
      <br />
      <PasswordResetForm />
    </div>
  );
};

export default PasswordReset;
