import Modal from "react-bootstrap/Modal";

import CreateEventWizard from "./wizard/Wizard";
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import React from "react";

export default function EventsModal({
  show,
  handleClose,
  payload = {},
  getAllData,
  setPayload,
  userData,
  hostname,
  jwtToken,
}) {
  const handleDeleteEvent = () => {
    axios
      .post(
        `${hostname}/api/property/DeleteEvent`,
        {
          event_id: payload._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData();

        toast.success("Event Deleted", { theme: "colored" });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handleCloseM = () =>{
    handleClose();
  }

  return (
    <>
      <Modal
        show={show}
        onHide={()=>handleClose()}
        backdrop="static"
        keyboard={false}
        size={payload.loadType === "Delete Event" ? "md" : "lg"}
        centered
      >
        <Modal.Header closeButton>
          <div
            className="d-flex justify-content-between"
            style={{ width: "90%" }}
          >
            <Modal.Title>{payload.loadType}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "Create Event" && (
            <CreateEventWizard
              handleClose={handleCloseM}
              getAllData={getAllData}
              userData={userData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )}
          {payload.loadType === "Delete Event" && (
            <>
              <p className="">
                Are you sure you want to delete this event ?
                {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
              </p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={handleDeleteEvent}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
