import React, { useState } from 'react';
import { Modal, Row, Col, Card } from 'react-bootstrap';
// import { Offcanvas, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import Wizard from './wizardRemastered/Wizard';
import Compose from '../../../../components/app/email/compose/Compose';
import FalconCloseButton from '../../../../components/common/FalconCloseButton';
import FalconCardHeader from '../../../../components/common/FalconCardHeader';
// import axios from 'axios';
// import { toast } from 'react-toastify';

export default function CreateNewMailModal(props) {
  // const [validated, setValidated] = useState(false);
  // const [formData, setFormData] = useState({
  //   property_id: '',
  //   property_name: '',
  //   property_url: '',
  //   phone: '',
  //   address: '',
  //   c_id: props.userData.compid
  // });
  const [field, setField] = useState('');
  // const handleSubmit = event => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     event.preventDefault();
  //     console.log(formData);
  //     setValidated(true);
  //     // return;
  //     axios
  //       .post('http://localhost:5000/api/property/propertyregister', {
  //         ...formData
  //       })
  //       .then(res => {
  //         console.log(res.data);
  //         toast.success(
  //           <span className="text-white">Registration Successful!</span>
  //         );
  //         props.getAllPropertiesData(props.userData.compid);
  //         props.handleClose();
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         props.getAllPropertiesData(props.userData.compid);
  //         toast.error(<span className="text-white">Already registrated!</span>);
  //       });
  //   }
  // };

  // const handleChange = e => {
  //   let data = { ...formData };
  //   data[e.target.name] = e.target.value;
  //   setFormData(data);
  // };

  const getMailContent = data => {
    console.log(data);
  };

  const selectField = field => {
    console.log(field);
    setField(field);
  };

  let fieldsOfMail = [
    'First_Name',
    'Last-Name',
    'Unit-no',
    'Budget',
    'Start-Date',
    'End_Date'
  ];

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
      fullscreen
    >
      <Modal.Header>
        <Modal.Title>Compose new Mail</Modal.Title>
        <FalconCloseButton onClick={props.handleClose} />
      </Modal.Header>
      <Modal.Body>
        {/* <Wizard
          handleClose={props.handleClose}
          validation={true}
          progressBar={true}
          getAllPropertiesData={props.getAllPropertiesData}
        /> */}
        <div className="p-3">
          <Row>
            <Col md={9}>
              <Compose getMailContent={getMailContent} field={field}/>
            </Col>
            <Col md={3}>
              <Card>
                <FalconCardHeader title="Select Fields" />
                <Card.Body style={{ height: '70vh' }} className="bg-light">
                  <ul style={{ listStyleType: 'none', marginLeft: '20px' }}>
                    {fieldsOfMail.map((v, i) => (
                      <li
                        key={i}
                        className="mb-3 fs-1 cursor-pointer"
                        onClick={() => selectField(v)}
                      >
                        [ {v} ]
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// export default CreatePropertyModal;
