import React from 'react';
import { Card, Table } from 'react-bootstrap';
import FalconCardHeader from '../../../../../components/common/FalconCardHeader';
// import { Image } from 'react-bootstrap';
import BSSliderCustom from '../../../../../components/common/BSSliderCustom';

import HotelStyle from './bookingtypesReviews/HotelStyle';
import NoRestrictions from './bookingtypesReviews/NoRestrictions';
import TimeSlots from './bookingtypesReviews/TimeSlots';
import Daily from './bookingtypesReviews/Daily';
import DailyNew from './bookingtypesReviews/DailyNew';
// import Image from '../../../../../components/pages/Management/Image';

// const ReportTable = ({ blackouts }) => {
//   return (
//     <div>
//       <Table striped bordered hover className="text-center">
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>Start Time</th>
//             <th>End Time</th>
//           </tr>
//         </thead>
//         <tbody>
//           {blackouts?.map(({ start, end }, i) => (
//             <tr key={i}>
//               <td>{i + 1}</td>
//               <td>{start}</td>
//               <td>{end}</td>
//             </tr>
//           ))}
//           {blackouts.length < 1 && (
//             <tr>
//               <td colSpan={3} className="text-center">
//                 No Times found!
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

export default function AmenityReview({ user }) {
  // return <p>oj</p>;
  try {
    let obj = { ...user };
    let availableday = {};
    let blackoutday = {};
    let timeSlotsAll = [];
    // console.log(user.timeslotsReviewUser);
    let timeSlotsAllDaily = [];

    if (
      Object.hasOwnProperty.call(obj, 'input') &&
      user.booking_type === 'Time Slots'
    ) {
      availableday = { ...obj.input.availableday };
      blackoutday = { ...obj.input.blackoutday };
      timeSlotsAll = [
        {
          day: 'Sunday',
          start: availableday.Sunday.start || '',
          end: availableday.Sunday.end || '',
          blackouts: [blackoutday.Sunday]
        },
        {
          day: 'Monday',
          start: availableday.Monday.start,
          end: availableday.Monday.end,
          blackouts: [blackoutday.Monday]
        },
        {
          day: 'Tuesday',
          start: availableday.Tuesday.start,
          end: availableday.Tuesday.end,
          blackouts: [blackoutday.Tuesday]
        },
        {
          day: 'Wednesday',
          start: availableday.Wednesday.start,
          end: availableday.Wednesday.end,
          blackouts: [blackoutday.Wednesday]
        },
        {
          day: 'Thursday',
          start: availableday.Thursday.start,
          end: availableday.Thursday.end,
          blackouts: [blackoutday.Thursday]
        },
        {
          day: 'Friday',
          start: availableday.Friday.start,
          end: availableday.Friday.end,
          blackouts: [blackoutday.Friday]
        },
        {
          day: 'Saturday',
          start: availableday.Saturday.start,
          end: availableday.Saturday.end,
          blackouts: [blackoutday.Saturday]
        }
      ];
    }
    // if (
    //   Object.hasOwnProperty.call(obj, 'booking_type') &&
    //   obj.booking_type === 'Daily'
    // ) {
    //   let days = [
    //     'Sunday',
    //     'Monday',
    //     'Tuesday',
    //     'Wednesday',
    //     'Thursday',
    //     'Friday',
    //     'Saturday'
    //   ];
    //   ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].forEach((v, i) => {
    //     timeSlotsAllDaily.push({
    //       day: days[i],
    //       start: obj[`daily_start_time_${v}`],
    //       end: obj[`daily_end_time_${v}`],
    //       start_zone: obj[`daily_start_zone_${v}`],
    //       end_zone: obj[`daily_end_zone_${v}`]
    //     });
    //   });
    // }

    return (
      <>
        <Card className="mb-3">
          <FalconCardHeader title="Basic" />
          <Card.Body className="bg-light">
            <Table striped hover>
              <tbody>
                <tr>
                  <td>Amenity Name</td>
                  <td colSpan={2}>{user.amenity_name || 'Name  here'} </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td colSpan={2}>
                    {user.description || 'fksdhfsdfksdkdlfjldsfjldsf'}{' '}
                  </td>
                </tr>
                <tr>
                  <td>Booking Type</td>
                  <td colSpan={2}>{user.booking_type || 'type'} </td>
                </tr>
                {/* <tr>
                  <td>Amenity Image</td>
                  <td colSpan={2}>{'imgs are shown below'} </td>
                </tr> */}
                {/* {user.imgType === 'file' && (
                <tr>
                  <td>
                    <Image
                      fluid
                      src={user.files[0].preview}
                      alt={user.files[0].path}
                    />
                  </td>
                  <td>
                    <Image
                      fluid
                      src={user.files[1].preview}
                      alt={user.files[1].path}
                    />
                  </td>
                  <td>
                    <Image
                      fluid
                      src={user.files[2].preview}
                      alt={user.files[2].path}
                    />
                  </td>
                </tr>
              )}
              {user.imgType === 'url' && (
                <tr>
                  <td>
                    <Image fluid src={user.linkImg1} alt={user.linkImg1} />
                  </td>
                  <td>
                    <Image fluid src={user.linkImg2} alt={user.linkImg2} />
                  </td>
                  <td>
                    <Image fluid src={user.linkImg3} alt={user.linkImg3} />
                  </td>
                </tr>
              )} */}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <FalconCardHeader title="Images" />
          <Card.Body>
            {user.amenity_images?.length === 0 ? (
              <p className="text-info text-center">No Images Provided</p>
            ) : (
              <BSSliderCustom
                items={user.amenity_images?.filter(v => v !== '')}
                fromReviewAmenity
              />
            )}
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <FalconCardHeader title="Rules" />
          <Card.Body className="bg-light">
            <Table striped hover>
              <tbody>
                <tr>
                  <td>Terms</td>
                  <td colSpan={2} style={{whiteSpace:'pre-wrap'}}>{user.amenity_rules || 'rules here'} </td>
                </tr>
                {/* <tr>
                  <td>Approval for this amenity</td>
                  <td colSpan={2}>{user.approval || 'admin'}</td>
                </tr>
                <tr>
                  <td>Maximum Booking Length</td>
                  <td colSpan={2}>{user.max_book_length || '2 hours'}</td>
                </tr> */}
                {/* <tr>
                  <td>Volume of Booking</td>
                  <td colSpan={2}>{user.volume_of_booking || 'No Limit'}</td>
                </tr> */}
                {/* {user.volume_of_booking === 'Limit by Unit' && ( */}
                  <tr>
                    <td>Maximum Guest Limit</td>
                    <td colSpan={2}>{user.max_guest_limit || '10'}</td>
                  </tr>
                {/* // )} */}
                <tr>
                  <td>Allow Multiple booking for this amenity</td>
                  <td colSpan={2}>{user.allow_same_booking || 'Yes'}</td>
                </tr>
                {user.allow_same_booking === 'Yes' && (
                  <tr>
                    <td>Number of simultenous bookings for each time slot</td>
                    <td colSpan={2}>
                      {user.multiple_bookings_on_time_slots || '0'}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <FalconCardHeader title="Payment" />
          <Card.Body className="bg-light">
            <Table striped hover>
              <tbody>
                <tr>
                  <td>Payment type</td>
                  <td colSpan={2}>{user.payment_type || 'Flat Rate'}</td>
                </tr>
                {user.payment_type !== 'No Fee' && (
                  <>
                    <tr>
                      <td>Amenity Price</td>
                      <td colSpan={2}>${user.amenity_price || '50.0'}.00</td>
                    </tr>
                    {/* <tr>
                      <td>Payment Method</td>
                      <td colSpan={2}>
                        {user.payment_method?.map(v => (
                          <span key={v + '2'} className="pe-3">
                            {v}
                          </span>
                        )) || 'Online payment'}
                      </td>
                    </tr> */}
                  </>
                )}
                {user.payment_instructions_check && (
                  <tr>
                    <td>Payment Instructions for Check</td>
                    <td colSpan={2}>
                      {user.payment_instructions_check || 'info_check'}
                    </td>
                  </tr>
                )}
                {user.payment_instructions_cash && (
                  <tr>
                    <td>Payment Instructions for Cash</td>
                    <td colSpan={2}>
                      {user.payment_instructions_cash || 'info_cash'}
                    </td>
                  </tr>
                )}
                {/* <tr>
                  <td>Allow Cancel</td>
                  <td colSpan={2}>{user.allow_cancel || '24Hours'}</td>
                </tr> */}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        {user.booking_type === 'Time Slots' && (
          <TimeSlots user={user} timeSlotsAll={timeSlotsAll} />
        )}
        {user.booking_type === 'Hotel Style' && <HotelStyle user={user} />}
        {user.booking_type === 'Daily' && (
          <DailyNew user={user} />
          // <Daily user={user} timeSlotsAll={timeSlotsAllDaily} />
        )}
        {user.booking_type === 'No Restrictions' && (
          <NoRestrictions user={user} />
        )}
      </>
    );
  } catch (e) {
    console.log(e);
    return <p>{e.message}</p>;
  }
}
