import React from "react";

import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";

import CardDropdown from "components/common/CardDropdown";


const TableRow = ({ data, propLength, updateItem, deleteItem }) => {
  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td className="text-center">
        {data.pet_type === "Other"
          ? data.other_pet_type
          : data.pet_type || "id"}
      </td>
      <td className="text-center">{data.name || "id"}</td>
      <td className="text-center">{data.description || "id"}</td>
      <td className="text-center">{data.weight || "id"}</td>
      <td className="text-center">{data.comments || "-"}</td>
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              className="text-info"
              style={{ fontWeight: "bold" }}
              onClick={() => updateItem(data)}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="text-warning"
              style={{ fontWeight: "bold" }}
              onClick={() => deleteItem(data)}
            >
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

const MailOutsTable = ({ list=[], updateItem, deleteItem }) => {
  // console.log(list);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Pet Type
          </th>
          <th scope="col" className="text-center">
            Name
          </th>
          <th scope="col" className="text-center">
            Description
          </th>
          <th scope="col" className="text-center">
            Weight (kg)
          </th>
          <th scope="col" className="text-center">
            Comments
          </th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {list?.map((item, index) => (
          <TableRow
            data={item}
            key={index}
            propLength={list.length}
            updateItem={updateItem}
            deleteItem={deleteItem}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default MailOutsTable;
