import React from "react";
import FalconCardHeader from "components/common/FalconCardHeader";
import { Card, Button } from "react-bootstrap";
import { Avatar } from "@mui/material";
import { startCase } from "lodash";

const ProfileImage = ({
  userProfileImage = {},
  userData,
  CancelButton,
  showButton,
  uploadedFileName,
  handleClick,
  handleCancel,
  handleSave,
  handleDelete,
}) => {
  console.log(userProfileImage);

  const calculateAndShowDeleteBtn = () => {
    //case 1
    if (
      (userProfileImage.link || userProfileImage.img) &&
      !showButton &&
      !CancelButton
    ) {
      return (
        <Button variant="warning" onClick={handleDelete}>
          Delete
        </Button>
      );
    }
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Profile Image" />
      <Card.Body className="bg-light">
        <div className="text-center">
          <div className=" d-flex justify-content-center">
            {userProfileImage?.img || userProfileImage.link ? (
              <Avatar
                sx={{
                  bgcolor: "transparent",
                  fontSize: "2rem",
                  width: "100px",
                  height: "100px",
                }}
                className="img-thumbnail"
                src={userProfileImage.link || userProfileImage.img}
              ></Avatar>
            ) : (
              <Avatar
                sx={{
                  bgcolor: userData?.bgColor,
                  fontSize: "2rem",
                  width: "100px",
                  height: "100px",
                }}
                className="img-thumbnail"
              >
                {startCase(userData?.firstname[0]) +
                  startCase(userData?.lastname[0])}
              </Avatar>
            )}
          </div>
          <h5 className="mb-4 mt-3">
            <strong>{userData?.firstname + " " + userData?.lastname}</strong>
          </h5>
          <div className="d-flex align-items-center justify-content-center">
            <button
              style={{ marginRight: "8px", padding: "4px 8px" }}
              className={`btn btn-outline-${
                uploadedFileName ? "success" : "primary"
              }`}
              onClick={handleClick}
            >
              Upload
            </button>
            {calculateAndShowDeleteBtn()}
            {/* {(userProfileImage?.img || userProfileImage.link) && (
              <Button variant="warning" onClick={handleDelete}>
                Delete
              </Button>
            )} */}
            {/* {!showButton || !CancelButton ? (
              <Button variant="warning" onClick={handleDelete}>
                Delete
              </Button>
            ) : userProfileImage?.img || userProfileImage.link ? (
              <Button variant="warning" onClick={handleDelete}>
                Delete
              </Button>
            ) : (
              ""
            )} */}
            {showButton && (
              <button
                style={{ marginRight: "8px", padding: "4px 8px" }}
                className={`btn btn-outline-${
                  uploadedFileName ? "success" : "primary"
                }`}
                onClick={handleSave}
              >
                Save
              </button>
            )}
            {CancelButton && <Button onClick={handleCancel}>Cancel</Button>}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfileImage;
