import React from "react";
import BookedAmenities from "components/pages/Features/Amenities/Amenities";

export default function UnitBookedAmenities() {
  return (
    <>
      <BookedAmenities from="singleUnit" />
    </>
  );
}
