import { AuthWizardContext } from "context/Context";
import Lottie from "lottie-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import celebration from "./lottie/celebration.json";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
// const Success = ({ reset }) => {
const Success = ({ getAllData }) => {
  const { user } = useContext(AuthWizardContext);
  // const navigate = useNavigate();
  // const emptyData = () => {
  //   setStep(1);
  //   setUser({});
  //   reset();
  // };
  console.log(user);
  const cookies = new Cookies();

  const userData = cookies.get("userData");
  const { property_id } = useParams();

  const navigate = useNavigate();

  // useEffect(() => {
  //   // console.log(user.siteName);
  //   setTimeout(() => {
  //     handleSubmit();
  //   }, 200);
  // }, []);

  // const handleSubmitFinal = () => {
  //   try {
  //     console.log("ok");
  //     let eventReq = { ...user };

  //     eventReq.generated_id = userData.data;
  //     // if(userData?.role)
  //     eventReq.p_id = property_id;

  //     if (eventReq.event_ticket_type === "Free Ticket")
  //       eventReq.ticket_options = [];

  //     console.log(eventReq);

  //     axios
  //       .post("http://localhost:5000/api/property/CreateEvent", { ...eventReq })
  //       .then((res) => {
  //         console.log(res.data);
  //         if (eventReq.event_cover_image !== "") {
  //           addCoverImageAndShowSuccess(res.data.Data);
  //         } else {
  //           toast.success("Event created successfully", { theme: "colored" });
  //           getAllData();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (e) {
  //     console.log(e);
  //     toast.error("Something went wrong", { theme: "colored" });
  //   }
  // };

  // const addCoverImageAndShowSuccess = (event) => {
  //   let e_data = {};
  //   e_data.event_id = event._id;
  //   e_data.req_images = user.event_cover_image;

  //   console.log(e_data);
  //   axios
  //     .post(
  //       "http://localhost:5000/api/property/AddEventImage",
  //       { ...e_data },
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       toast.success("Event created successfully", { theme: "colored" });
  //       getAllData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Something went wrong", { theme: "colored" });
  //     });
  // };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Success</h4>
          <p className="fs-0">Mails are successfully sent.</p>
          <Button
            variant="primary"
            className="px-5 my-3 btn btn-primary"
            // onClick={()=>handleClose(false)}
          >
            Go to Mailouts list
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default Success;
