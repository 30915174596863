import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import FalconCardHeader from "components/common/FalconCardHeader";
import SoftBadge from "components/common/SoftBadge";
import { formatLogInfo } from "helpers/utils";
import React from "react";

import {
  Button,
  Card,
  CloseButton,
  Col,
  Form,
  Row,
  Table,
} from "react-bootstrap";

import { useParams } from "react-router-dom";
import MailDetailsModal from "./MailDetailsModal";
import Loader from "components/common/Loader";

export default function MailOutDetail({
  handleCloseDetailPage,
  payload,
  setPayload,
  userData,
  hostname,
  jwtToken,
}) {
  // console.log(payload);

  const [budgetsData, setBudgetsData] = React.useState([]);

  const [show, setShow] = React.useState({ shown: false, type: "" });

  const { property_id } = useParams();

  React.useEffect(() => {
    // setLoader(true);
    getBudgetsData();
    getAllDataOfSingleMail();
    // getPropertyLetterHead();
  }, []);

  const getBudgetsData = () => {
    axios
      .post(
        `${hostname}/api/property/ReadAllBudgets`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let arr = res.data.Data?.map((v) => {
          let obj = { ...v };
          obj.start_of_fiscal_year = formatLogInfo(
            obj.start_of_fiscal_year,
            true
          );
          obj.end_of_fiscal_year = formatLogInfo(obj.end_of_fiscal_year, true);
          return obj;
        });

        arr = arr.filter((v) => v._id === payload.budget_id);
        console.log(arr);
        setBudgetsData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [mailData, setMailData] = React.useState([]);
  const [mailDataTmp, setMailDataTmp] = React.useState([]);
  const [updatedLetterHead, setUpdatedLetterHead] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  const getAllDataOfSingleMail = () => {
    setLoader(true);

    axios
      .post(
        `${hostname}/api/property/GetMailoutStatus`,
        {
          mailout_id: payload._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        let arr = [...res.data.Data];

        setMailData(arr);
        setMailDataTmp(arr);
        setUpdatedLetterHead(res.data.letterhead);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [payload]);

  const showMailDetailsTable = () => {
    setShow({ shown: true, type: "Mail Details Table" });
  };
  const showLetterPreview = () => {
    setShow({ shown: true, type: "Letter Preview" });
  };

  // if (loader) return <Loader />;

  return (
    <div>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center">
              <h5>{payload.mail_subject}</h5>
              <CloseButton onClick={() => handleCloseDetailPage()} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={7}>
          <Card>
            <FalconCardHeader title="Mail Details" />
            <Card.Body style={{ minHeight: "380px" }} className="bg-light">
              <Table>
                <tbody>
                  <tr>
                    <td className=" fw-bold">Mail Type:</td>
                    <td>{payload.mail_type}</td>
                  </tr>
                  {payload.mail_type === "Budget Letter" && (
                    <tr>
                      <td className=" fw-bold">Budget Year:</td>
                      <td>
                        {budgetsData[0]?.start_of_fiscal_year} -{" "}
                        {budgetsData[0]?.end_of_fiscal_year}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className=" fw-bold">Sent To:</td>
                    <td>
                      {payload.groups?.map((v, i) => (
                        <span
                          style={{
                            fontSize: payload.groups.length > 1 ? "14px" : "",
                          }}
                          key={v}
                        >
                          {v}
                          {payload.groups.length > 1 && i === 0 ? ", " : ""}
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Total Emails Delivered:</td>
                    <td>
                      {loader && (
                        <div className="d-flex align-items-end">
                          <Loader noHeight />
                          <span className="ms-3">loading...</span>
                        </div>
                      )}
                      {!loader && (
                        <Button
                          className="p-0"
                          style={{ textDecoration: "underline" }}
                          variant="link"
                          onClick={showMailDetailsTable}
                        >
                          View Details
                        </Button>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className=" fw-bold">Status:</td>
                    <td>
                      <SoftBadge pill bg={payload.status.type}>
                        {payload.status.title}
                        <FontAwesomeIcon
                          icon={payload.status.icon}
                          className="ms-2"
                        />
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td className=" fw-bold">Created On:</td>
                    <td>{formatLogInfo(payload.createdAt)}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Card>
            <FalconCardHeader title="Print Paper Copies" />
            <Card.Body style={{ minHeight: "380px" }} className="bg-light">
              <br />
              <br />

              <div>
                <p>Select file to print: : </p>
                <h6 className="text-primary">{payload.mail_subject}.pdf</h6>
                {payload.draft_letter && (
                  <h6 className="text-primary">
                    {payload.letter_filename || "no name"}.pdf
                  </h6>
                )}
              </div>
              <br />
              <div>
                <p>Letter Drafted: {payload.draft_letter ? "Yes" : "No"}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <FalconCardHeader
              title="Email Compose"
              endEl={
                payload.draft_letter && (
                  <Button size="sm" onClick={showLetterPreview}>
                    Preview Letter
                  </Button>
                )
              }
            />

            <Card.Body style={{ minHeight: "500px" }} className="bg-light">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: payload.mail_content,
                  }}
                  // style={{whiteSpace:'pre-line'}}
                >
                  {/* {payload.mail_content} */}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <MailDetailsModal
        show={show}
        handleClose={setShow}
        payload={payload}
        mailData={mailData}
        setMailData={setMailData}
        mailDataTmp={mailDataTmp}
        setMailDataTmp={mailDataTmp}
        updatedLetterHead={updatedLetterHead}
        // propertyLetterhead={propertyLetterhead}
        
      />
    </div>
  );
}
