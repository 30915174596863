import React from 'react';
import ForgetPasswordForm from '../../../components/authentication/ForgetPasswordForm';
import { useTranslation } from 'react-i18next';

const ForgetPassword = () => {
  const {t} = useTranslation();
  return (
    <div className="text-center">
      <h5 className="mb-0">{t('forgot_ps.forgot_your_password')}</h5>
      <small>{t('forgot_ps.reset_link_para')}</small>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
