import React from "react";
import VoilationTrackingSettingsModel from "./VoilationTrackingSettingsModel";
import CustomSearchbar from "components/common/CustomSearchbar";

import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Dropdown,
  Breadcrumb,
} from "react-bootstrap";
import Flex from "components/common/Flex";
import { Link, useParams } from "react-router-dom";
import CardDropdown from "components/common/CardDropdown";
import axios from "axios";

export default function VoilationsTrackingSettings({
  setShowSettingsPage,
  userData,
  hostname,
  jwtToken,
}) {
  // VoilationTrackingSettingsList

  const { property_id, property_name } = useParams();

  const [VoilationTrackingSettingsList, setVoilationTrackingSettingsList] =
    React.useState([]);
  const [
    VoilationTrackingSettingsListTemp,
    setVoilationTrackingSettingsListTemp,
  ] = React.useState([]);

  const [showModel, setShowModel] = React.useState(false);

  const handleClose = () => setShowModel(false);
  const handleShow = () => setShowModel(true);

  const [loader, setLoader] = React.useState(false);
  const [payload, setPayload] = React.useState(false);

  let arr = [{ type: "Home Pipe", _id: 456789 }];

  React.useEffect(() => {
    getVoilationTrackingSettingsListData();
    setVoilationTrackingSettingsList(arr);
  }, []);

  const getVoilationTrackingSettingsListData = () => {
    axios
      .post(
        `${hostname}/api/property/GetViolationSettings`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        console.log(res.data);
        let arr = res.data.Data;
        setVoilationTrackingSettingsList(arr);
        setVoilationTrackingSettingsListTemp(arr);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const addNewtype = () => {
    let obj = {};
    obj.loadType = "Add New Type";
    obj.p_id = property_id;
    obj.userData = userData;
    setPayload(obj);
    handleShow();
  };
  const editType = (obj = {}) => {
    obj.loadType = "Edit Type";
    obj.p_id = property_id;
    obj.userData = userData;
    setPayload(obj);
    handleShow();
  };
  const deleteType = (obj = {}) => {
    obj.loadType = "Delete Type";
    obj.p_id = property_id;
    obj.userData = userData;
    setPayload(obj);
    handleShow();
  };

  return (
    <div>
      {userData.role === "Site-Administrators" && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link className="text-secondary" to={"/"}>
                      Home
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      className="text-secondary"
                      to={"/property/management/buildings"}
                    >
                      Properties
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link className="text-secondary" to={"#!"}>
                      {property_name}
                    </Link>
                  </Breadcrumb.Item>{" "}
                  <Breadcrumb.Item active>
                    <Link to={"#!"}>Violation Tracking Settings</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {userData.role === "unit_owner" && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link className="text-secondary" to={"/"}>
                      Home
                    </Link>
                  </Breadcrumb.Item>{" "}
                  <Breadcrumb.Item active>
                    <Link to={"#!"}>Violation Tracking Settings</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Violations Tracking Settings</h4>
            <div>
              <Button
                variant="falcon-primary"
                size="sm"
                className="me-3"
                onClick={() => setShowSettingsPage(false)}
              >
                Back To Table
              </Button>
              <Button variant="primary" size="sm" onClick={addNewtype}>
                Add New Type
              </Button>
            </div>
          </Flex>
          <br />

          <Row className="flex-end-center">
            <Col xs="auto" sm={6} lg={4}>
              <CustomSearchbar
                dataListTemp={VoilationTrackingSettingsListTemp}
                stateUpdator={setVoilationTrackingSettingsList}
                searchFlags={["violation_type"]}
                placeholders={["violation type"]}
              />
            </Col>
          </Row>

          <br />
          <Table
            responsive
            striped
            hover
            bordered
            style={{ fontSize: "14px" }}
            className="pb-5"
          >
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  Violation Type
                </th>

                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {VoilationTrackingSettingsList?.map((voilation) => (
                <tr
                  key={voilation?._id}
                  className="align-middle"
                  style={{
                    height:
                      VoilationTrackingSettingsList.length === 1 ? "100px" : "",
                  }}
                >
                  <td className="text-center">
                    {voilation.violation_type || "name"}
                  </td>
                  <td className="text-center">
                    <CardDropdown>
                      <div className="py-2">
                        <Dropdown.Item
                          className="text-primary"
                          onClick={() => editType(voilation)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-danger"
                          // style={{ fontWeight: "bold" }}
                          onClick={() => deleteType(voilation)}
                        >
                          Delete
                        </Dropdown.Item>
                      </div>
                    </CardDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <VoilationTrackingSettingsModel
            show={showModel}
            handleClose={handleClose}
            payload={payload}
            getAllData={getVoilationTrackingSettingsListData}
            userData={userData}
        hostname={hostname}
        jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
