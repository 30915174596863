import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Section from '../../../components/common/Section';
// import Logo from 'components/common/Logo';
import Wizard from './wizard/Wizard';
// import { Link } from 'react-router-dom';

const UserRegistration = () => {
  return (
    <Section className="py-0">
      <Row className="justify-content-center pt-3">
        <Col sm={10} lg={7} className="col-xxl-5">
          {/* <Logo width={45} textClass="fs-4" /> */}
          <h4 className="text-center text-primary">Condozak</h4>
          <h5 className="text-center fw-light mb-3">
            User Registration Form
          </h5>
          <Wizard validation={true} progressBar={true}/>
        </Col>
      </Row>
    </Section>
  );
};

export default UserRegistration;
