import React, { useState, useEffect } from "react";

import FalconCardHeader from "components/common/FalconCardHeader";
import {
  Card,
  Row,
  Col,
  Button,
  Image,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import { Check, Launch, Clear } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import iconPayment1 from "assets/img/icon-payment 1.png";
import iconPayment2 from "assets/img/icon-payment 2.png";
import iconPayment3 from "assets/img/icon-payment 3.png";
import iconPayment4 from "assets/img/icon-payment 4.png";

import "./PaymentProcessor.css";
import { Link } from "react-router-dom";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

export default function PaymentProessor() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // console.log(error);
  const { property_id, property_name } = useParams();
  const [editField, showEditField] = React.useState(false);
  const [hasStripeAccount, setHasStripeAccount] = useState(false); // Add this state
  const [instructionForStripe, setInstructionsForStripe] = React.useState([]);
  const [prpty_name, setPropertyName] = React.useState([]);
  // console.log(prpty_name);
  const [emailStatus, setEmailStatus] = useState("Loading"); // Default to "Loading" until the API call completes
  const [emailValue, setEmail] = useState("Loading");
  const [newEmail, setNewEmail] = useState("");
  // const cookies = new Cookies();
  // let userData = cookies.get("userData");

  useEffect(() => {
    // Check if the user already has a Stripe account when the component mounts
    checkStripeAccount();
  }, []);

  const checkStripeAccount = () => {
    let user_id = userData.data;
    let p_id = property_id;

    // Replace this URL with the appropriate endpoint to check if the user has a Stripe account
    // console.log(user_id);
    axios
      .post(
        `${hostname}/api/pay/CheckStripe`,
        {
          email: userData.email,
          p_id,
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((response) => {
        // console.log("from checkstripe");
        // console.log(response?.data);
        setHasStripeAccount(response?.data?.hasStripeAccount);
        setInstructionsForStripe(response?.data?.instruction_for_stripe);
        setPropertyName(response?.data?.property_name || "Loading ...");
        setEmailStatus(response?.data?.email_status || "Locked");
        setEmail(response?.data?.email || "Loading ...");
      })
      .catch((err) => {
        console.error("API Error:", err);
        if ("response" in err) {
          toast.error(err.response.data.error || "Something went wrong!", {
            theme: "colored",
          });
        }
        toast.error("Something went wrong!", { theme: "colored" });
        setError(err);
      });
  };
  const handleUpdateEmail = () => {
    setEmail(newEmail);
    showEditField(false);
    axios
      .post(
        `${hostname}/api/pay/updateEmail`,
        {
          email: newEmail,
          p_id: property_id,
          user_id: userData.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        setEmail(newEmail);
        showEditField(false);
        toast.success("Email updated successfully", { theme: "colored" });
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error(error.response?.data?.error || "Something went wrong!", {
          theme: "colored",
        });
      });
  };

  const handleConnectStripe = () => {
    if (hasStripeAccount) {
      // User already has a Stripe account
      toast.info("You already have an active Stripe account.", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      // const cookies = new Cookies();

      // let userData = cookies.get("userData", { path: "/" });
      let p_id = property_id;
      const user_id = userData.data;
      axios
        .post(
          `${hostname}/api/pay/StripeSetup`,
          {
            email: emailValue,
            url: window.location.host,
            p_id,
            user_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          window.open(response.data, "_blank");
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("API Error:", err);
          toast.error(err.response.data.error || "Something went wrong!", {
            theme: "colored",
          });
          setError(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link className="text-secondary" to={"/"}>
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    className="text-secondary"
                    to={"/property/management/buildings"}
                  >
                    Properties
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    className="text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                  >
                    {property_name}
                  </Link>
                </Breadcrumb.Item>{" "}
                <Breadcrumb.Item active>
                  <Link to={"#!"}>Payment Processor</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={7}>
          <Card style={{ minHeight: "90vh" }}>
            <FalconCardHeader title="Stripe Connection" />
            <Card.Body className="bg-light">
              <div className="ms-3 mt-3">
                <div>
                  <span>Company Name:</span>
                  <span
                    className="fw-bold resSpan"
                    style={{ marginLeft: "70px" }}
                  >
                    {userData?.company_name || "Delta Corporation"}
                  </span>
                </div>

                <br />
                <div>
                  <span>Property Name:</span>
                  <span
                    className="fw-bold  resSpan"
                    style={{ marginLeft: "83px" }}
                  >
                    {
                      //userData?.property_name || "Delta Corporation"
                    }
                    {property_name}
                  </span>
                </div>

                <br />
                <div className="d-flex">
                  <span>Stripe Email:</span>
                  <span
                    className="fw-bold resSpan"
                    style={{ marginLeft: "109px" }}
                  >
                    {emailStatus === "Available" ? (
                      editField ? (
                        <div className="">
                          <Form.Control
                            style={{ width: "300px" }}
                            name="subject"
                            className="mb-3 form-control"
                            placeholder="enter new email address here..."
                            value={newEmail} // Bind the input value to the state
                            onChange={(e) => setNewEmail(e.target.value)} // Update the new email state
                          />
                          <div className="d-flex mb-3">
                            <Button
                              variant="falcon-default"
                              size="sm"
                              onClick={() => handleUpdateEmail()}
                            >
                              <Check fontSize="small" />
                            </Button>
                            <Button
                              variant="falcon-default"
                              size="sm"
                              className="ms-2"
                              onClick={() => showEditField(false)}
                            >
                              <Clear fontSize="small" />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <p
                          className={`mb-1 d-inline-block`}
                          onClick={() => showEditField(true)}
                        >
                          {emailValue}
                        </p>
                      )
                    ) : (
                      <p className="mb-1 d-inline-block">{emailValue}</p>
                    )}
                  </span>
                </div>
              </div>

              <br />
              <br />

              <ul>
                {instructionForStripe?.map((v) => (
                  <li className="mb-3" key={v}>
                    {v}
                  </li>
                ))}
                {/* <li className="mb-3">
                  Click on "Connect with Stripe" and it redirect you to Stripe
                  login page
                </li>
                <li>
                  Add your company info there and setup your payment processing
                  account
                </li> */}
              </ul>
              <br />

              <Button
                variant="primary"
                onClick={handleConnectStripe} // Call the function on button click
                disabled={isLoading || hasStripeAccount} // Disable the button when loading
                className=" ms-3 d-flex justify-content-between align-items-center"
                style={{ width: "240px" }}
              >
                {hasStripeAccount
                  ? "Already Active"
                  : isLoading
                  ? "Connecting..."
                  : "Connect with Stripe"}
                <Launch fontSize="small" />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Card style={{ minHeight: "90vh" }}>
            <FalconCardHeader title="Stripe Information" />

            <Card.Body className="bg-light">
              <div className="mt-3">
                <ul>
                  <li className="mb-3">
                    Connect or Create an account with Stripe to accept credit
                    card payments from the residents.
                  </li>
                  <li className="mb-3">
                    Stripe allows you to process secure & encrypted payments
                  </li>
                  <li className="mb-3">Fast, predictable transfers.</li>
                </ul>
              </div>
              <br />
              <div>
                <h5 className="ms-2">Credit Cards</h5>
                <br />
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <Image src={iconPayment1} />
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <Image src={iconPayment2} />
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <Image src={iconPayment3} />
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <Image src={iconPayment4} />
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
