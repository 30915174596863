import React from 'react';
import BookingHours1 from '../../../../../../components/pages/Features/AmenityBookings/wizardRemastered/BookingHours';
// import { Card } from 'react-bootstrap';
export default function BookingHours({ updateAmenityTime, amenityCurrent }) {
  try {
    // if (amenityCurrent.type === 'Time Slots') {
    //   console.log(amenityCurrent.availableday);

    // }
    return (
      <div>
        {/* <Card style={{minHeight:'80vh'}}>
        <Card.Body> */}
        {/* <br /> */}
        <BookingHours1
          fromEdit
          updateAmenityTime={updateAmenityTime}
          amenityCurrent={amenityCurrent}
        />
        {/* </Card.Body>
      </Card> */}
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
