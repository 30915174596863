// import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function NoRestrictions() {
  return (
    <>
      <p className="text-info mb-2">
        Residents can book this amenity at 24 hours period a day
      </p>

      <Row className="mb-3">
        <Col>
          <label>Start Time</label>
          <input
            type="text"
            disabled
            defaultValue="12:00:00 AM"
            className="form-control"
          />
          {/* <Field
            name="start_time_for_no_restrictions"
            type="text"
            className="form-control"
            placeholder="HH:mm"
          />
          <ErrorMessage
            name="start_time_for_no_restrictions"
            className="fs--1 text-danger"
            component={'div'}
          /> */}
          {/* <div className="d-flex justify-content-end mt-3">
            <label className="mx-3 ">
              <span className="h6">AM</span>{' '}
              <Field
                type="radio"
                name="start_time_zone_for_no_restrictions"
                value="AM"
              />
            </label>
            <label className="mx-3">
              {' '}
              <span className="h6">PM</span>{' '}
              <Field
                type="radio"
                name="start_time_zone_for_no_restrictions"
                value="PM"
              />
            </label>
          </div>
          <div className="text-end">
            <ErrorMessage
              name="start_time_zone_for_no_restrictions"
              component={'div'}
              className="text-danger fs--1"
            />
          </div> */}
        </Col>
        <Col>
          <label>End Time</label>
          <input
            type="text"
            disabled
            defaultValue="11:59:59 PM"
            className="form-control"
          />

          {/* <Field
            name="end_time_for_no_restrictions"
            type="text"
            className="form-control"
            placeholder="HH:mm"
          />
          <ErrorMessage
            name="end_time_for_no_restrictions"
            className="fs--1 text-danger"
            component={'div'}
          />
          <div className="d-flex justify-content-end mt-3">
            <label className="mx-3 ">
              <span className="h6">AM</span>{' '}
              <Field
                type="radio"
                name="end_time_zone_for_no_restrictions"
                value="AM"
              />
            </label>
            <label className="mx-3">
              {' '}
              <span className="h6">PM</span>{' '}
              <Field
                type="radio"
                name="end_time_zone_for_no_restrictions"
                value="PM"
              />
            </label>
          </div>
          <div className="text-end">
            <ErrorMessage
              name="end_time_zone_for_no_restrictions"
              component={'div'}
              className="text-danger fs--1"
            />
          </div> */}
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <div className="mb-3">
            <label>Amenity unavailable for certain days</label>
            <Field
              name="amenity_unavailable_for_no_restrictions"
              type="date"
              className="form-control"
            />
            <ErrorMessage
              name="amenity_unavailable_for_no_restrictions"
              component={'div'}
              className="text-danger fs--1"
            />
          </div>
        </Col>
      </Row> */}
    </>
  );
}
