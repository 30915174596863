import React from "react";

import {
  Form as BSForm,
  Button,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { toast } from "react-toastify";

import { AuthWizardContext } from "context/Context";
import { ChangeCircle, CloudUpload, DeleteForever } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

const VisitorDetails = ({ from = "", event = {}, updateEvent }) => {
  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  console.log(acceptedFiles);
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleSubmit = (data) => {
    console.log(data);
    setUser(Object.assign({}, user, data));
    setStep(step + 1);
  };

  const handleFileUpload = (e, setFieldValue) => {
    setFieldValue("visitor_profile_img", e.target.files[0]);
    e.target = null;
  };

  return (
    <div>
      <Formik
        initialValues={{
          visitor_name: user.visitor_name || "",
          visitor_category: user.visitor_category || "",
          visitor_email: user.visitor_email || "",
          visitor_phone: user.visitor_phone || "",
          visitor_profile_img: user.visitor_profile_img || "",
        }}
        validationSchema={Yup.object({
          visitor_name: Yup.string().required("this option is required!"),
          visitor_category: Yup.string().required("this option is required!"),
          visitor_email: Yup.string()
            .email("invalid email address!")
            .required("this option is required!"),
          visitor_phone: Yup.string().required("this option is required!"),
          // visitor_profile_img: Yup.string().required(
          //   "this option is required!"
          // ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Row className="mb-3">
              <Col md={7}>
                <BSForm.Group className="mb-3">
                  <BSForm.Label>Visitor Name</BSForm.Label>
                  <Field
                    name="visitor_name"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="visitor_name"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
                <BSForm.Group>
                  <BSForm.Label>Visitor Category</BSForm.Label>
                  <Field
                    component="select"
                    id="visitor_category"
                    name="visitor_category"
                    className="form-select"
                  >
                    <option value="">Select</option>

                    {["Family / Friend", "Contractor", "Other"]?.map((v) => (
                      <option value={v} key={v}>
                        {v}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="visitor_category"
                    component={"div"}
                    className="fs--1 text-danger"
                  />
                </BSForm.Group>
              </Col>
              <Col md={5}>
                <BSForm.Group>
                  {!values.visitor_profile_img && (
                    <>
                      <BSForm.Label>Upload Profile Image</BSForm.Label>
                      <label
                        style={{
                          border: "1px dashed silver",
                          height: "120px",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          name="visitor_profile_img"
                          accept="image/*"
                          type="file"
                          onChange={(e) => handleFileUpload(e, setFieldValue)}
                          hidden
                        />
                        <CloudUpload
                          fontSize="large"
                          className="text-primary"
                        />{" "}
                        Upload
                      </label>
                    </>
                  )}
                  {values.visitor_profile_img && (
                    <>
                      <BSForm.Label>
                        Upload Profile Image{" "}
                        {/* <OverlayTrigger
                          placement="top"
                          delay={{ show: 150, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              Change
                            </Tooltip>
                          )}
                        >
                          <span
                            className="mx-3 text-warning cursor-pointer"
                            onClick={() =>
                              setFieldValue("visitor_profile_img", "")
                            }
                          >
                            <ChangeCircle />
                          </span>
                        </OverlayTrigger> */}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 150, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              Delete
                            </Tooltip>
                          )}
                        >
                          <span
                            className=" ms-3 text-danger cursor-pointer"
                            onClick={() =>
                              setFieldValue("visitor_profile_img", "")
                            }
                          >
                            <DeleteForever />
                          </span>
                        </OverlayTrigger>
                      </BSForm.Label>

                      <div>
                        <img
                          src={URL.createObjectURL(values.visitor_profile_img)}
                          style={{ width: "120px" }}
                        />
                      </div>
                    </>
                  )}
                </BSForm.Group>
              </Col>
            </Row>

            <BSForm.Group className="mb-3">
              <BSForm.Label>Visitor Email</BSForm.Label>
              <Field
                name="visitor_email"
                type="text"
                className="form-control"
              />
              <ErrorMessage
                name="visitor_email"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-3">
              <BSForm.Label>Visitor Contact #</BSForm.Label>
              <Field
                name="visitor_phone"
                type="text"
                className="form-control"
              />
              <ErrorMessage
                name="visitor_phone"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>

            <br />
            <div className="text-end">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VisitorDetails;
