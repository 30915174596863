import { AuthWizardContext } from "../../../../../context/Context";
import Lottie from "lottie-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import celebration from "./lottie/celebration.json";
import AmenityReview from "./AmenityReview";
import axios from "axios";
// import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import Loader from "../../../../../components/common/Loader";
import { useParams } from "react-router-dom";

const Success = ({
  handleClose,
  getAmenitiesData,
  hostname,
  jwtToken,
  userData,
}) => {
  const { setStep, step, user, setUser } = useContext(AuthWizardContext);

  const [showSuccess, setShowSuccess] = React.useState("user");
  const [hold, setHold] = React.useState(false);

  const { property_id } = useParams();
  const finalAmenityCreate = async () => {
    try {
      // let imgsfinal = user.amenity_images.map(v => v.img);
      // imgsfinal = imgsfinal?.map(v => v.main_obj);
      //setHold(true);
      console.log(userData);
      console.log(user.input);
      // return;
      let amenityData = {};
      // let aarr = user.files.map(v => v.preview);
      // let aarr = user.files;
      amenityData.p_id = property_id;
      amenityData.amenityname = user.amenity_name;
      amenityData.availableday = { ...user.input.availableday };
      // amenityData.availableday = { ...user.timeslotsReviewUser };
      // amenityData.availableday = JSON.stringify(amenityData.availableday);
      amenityData.blackoutday = { ...user.input.blackoutday };
      // amenityData.blackoutday = {};
      amenityData.unavailableday = [];
      amenityData.type = user.input.type;
      amenityData.duration = user.input.duration;
      amenityData.am_id = uuidv4();
      // amenityData.amenityimages = [...imgsfinal];
      let remdata = { ...user };
      delete remdata.timeslotsReviewUser;
      delete remdata.input;
      let arr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

      // if (user.booking_type !== 'Time Slots')
      arr.forEach((v) => {
        [1, 2, 3, 4, 5].forEach((i) => {
          delete remdata[`blackout_${v}_start_${i}`];
          delete remdata[`blackout_${v}_end_${i}`];
          delete remdata[`blackout_${v}_start_zone_${i}`];
          delete remdata[`blackout_${v}_end_zone_${i}`];
        });
      });
      // if (user.booking_type === 'Daily')
      arr.forEach((v) => {
        delete remdata[`daily_start_time_${v}`];
        delete remdata[`daily_end_time_${v}`];
        delete remdata[`daily_start_zone_${v}`];
        delete remdata[`daily_end_zone_${v}`];
        // });

        // arr.forEach(v => {
        delete remdata[`start_time_${v}`];
        delete remdata[`end_time_${v}`];
        delete remdata[`start_zone_${v}`];
        delete remdata[`end_zone_${v}`];
      });
      // amenityData.remainingData = { ...remdata };
      amenityData = Object.assign({ ...remdata }, amenityData);
      // amenityData = JSON.stringify(amenityData);
      // let res
      // user.imgType === 'file'
      //   ? imgsfinal
      //   : [user.linkImg1, user.linkImg2, user.linkImg3];
      //
      console.log({ ...amenityData });
      // return;
      // const formData = new FormData({...amenityData});
      // return;
      // await axios
      //   .post(
      //     '${hostname}/api/amenity/Createamenity',
      //     {...amenityData},
      //     // formData,
      //     {
      //       headers: {
      //         'Content-Type': 'multipart/form-data'
      //       }
      //     }
      //   )
      //   .then(res => {
      //     console.log(res.data);
      //     getAmenitiesData(userData.compid);
      //     toast.success('Created Successfully', { theme: 'colored' });
      //     setShowSuccess('success');
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
      // amenityData = JSON.stringify(amenityData);

      axios
        .post(
          `${hostname}/api/amenity/Createamenity`,
          { ...amenityData },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          // return res.data.id;
          if (user.amenity_images?.length > 0) {
            imagesUpload(res.data.id);
          } else {
            setShowSuccess("success");
            setHold(false);
            getAmenitiesData(userData.compid);
            toast.success("Created Successfully", { theme: "colored" });
          }
        })
        .catch((e) => {
          setHold(false);
          console.log(e);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const imagesUpload = (id) => {
    console.log(id);
    try {
      let imgs = [];
      imgs = user.amenity_images.map((v) => v.img);
      if (imgs.length)
        axios
          .post(
            `${hostname}/api/amenity/Imageupload`,
            {
              amenityimages: [...imgs],
              id,
              p_id: userData.compid,
              amenityname: user.amenity_name,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            setShowSuccess("success");
            setHold(false);
            getAmenitiesData(userData.compid);
            toast.success("Created Successfully", { theme: "colored" });
          })
          .catch((e) => {
            console.log(e);
          });
    } catch (e) {
      console.log(e);
    }
  };
  //
  // const handleAmenityCreate = () => {
  //   // setHold(true);
  //   let amenity_id = finalAmenityCreate();
  //   if (amenity_id);
  //   imagesUpload(amenity_id);

  //   getAmenitiesData(userData.compid);
  //   toast.success('Created Successfully', { theme: 'colored' });
  // };
  const emptyData = () => {
    setUser({});
    setStep(1);
    handleClose();
  };

  if (hold) return <Loader />;
  return (
    <>
      {showSuccess === "success" && (
        <Row>
          <Col className="text-center">
            <div className="wizard-lottie-wrapper">
              <div className="wizard-lottie mx-auto">
                <Lottie animationData={celebration} loop={true} />
              </div>
            </div>
            <h4 className="mb-1">Amenity Creation Success.</h4>
            <p className="fs-0">Now, it is available to use.</p>
            <Button
              color="primary"
              className="px-5 my-3"
              onClick={() => emptyData()}
            >
              Go to Amenities List
            </Button>
          </Col>
        </Row>
      )}

      {showSuccess === "user" && (
        <>
          <AmenityReview user={user} />
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <Button
              variant="secondary"
              className=""
              onClick={() => setStep(step - 1)}
            >
              Previous
            </Button>
            <Button
              color="primary"
              className=""
              // onClick={() => handleAmenityCreate()}
              onClick={() => finalAmenityCreate()}
              disabled={hold}
            >
              Done
            </Button>
          </div>
        </>
      )}
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default Success;
