import React from "react";
// import { Image } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function InboxModal({ show, handleClose, payload }) {
  console.log(payload);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={payload.size || "lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "Preview File" && (
            <div style={{ minHeight: "90vh" }}>
              {(payload.mimetype === "image/png" ||
                payload.mimetype === "image/jpeg" ||
                payload.mimetype === "image/jpg") && (
                <img
                  // src={`data:${payload.mimetype};base64,${payload.data}`}
                  src={payload.data}
                  alt="raw-png"
                  // width={330}
                  className="img-fluid"
                />
              )}
              {payload.mimetype === "text/plain" && (
                <object
                aria-label="mail attachment"

                  // data={`data:${payload.mimetype};base64,${payload.data}`}
                  data={payload.data}
                  style={{ height: "100vh", width: "100%", fontSize: "20px" }}
                ></object>
              )}
              {(payload.mimetype === "application/pdf" ||
                payload.mimetype ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                <object
                  aria-label="mail attachment"
                  // data={`data:${payload.mimetype};base64,${payload.data}`}
                  data={payload.data}
                  style={{ height: "300vh", width: "100%" }}
                ></object>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
