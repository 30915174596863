import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LCSText = ({ count, text }) => (
  <span className="text-700 me-1" to="#!">
    {count} {text}
    {count !== 1 && "s"}
  </span>
  // <Link className="text-700 me-1" to="#!">
  //   {count} {text}
  //   {count !== 1 && "s"}
  // </Link>
);

const FeedReactions = ({ post }) => {
  return (
    <div className="border-bottom border-200 fs--1 py-3">
      <LCSText count={post.like_count || 0} text="like" />

      <>
        • <LCSText count={post.comment_count || 0} text="comment" />{" "}
      </>
    </div>
  );
};

LCSText.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};
FeedReactions.propTypes = {
  like: PropTypes.number,
  comment: PropTypes.number,
  share: PropTypes.number,
};

export default FeedReactions;
