import React from "react";
import Flex from "components/common/Flex";
import { Breadcrumb, Button, Card, Col, Row } from "react-bootstrap";
import numberToWords from "number-to-words";
import VoilationTrackingTable from "./VoilationTrackingTable";
import VoilationTrackingModel from "./VoilationTrackingModel/VoilationTrackingModel";

// import { capitalize } from "lodash";
import axios from "axios";
// import Cookies from "universal-cookie";
import { Link, useParams } from "react-router-dom";
import Loader from "components/common/Loader";
import CustomSearchbar from "components/common/CustomSearchbar";
import VoilationsTrackingSettings from "./VoilationsTrackingSettings";
import { formatLogInfo } from "helpers/utils";
import CustomFilterbar from "components/common/CustomFilterbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

const VoilationTracking = ({ fromSingleUnit = false }) => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, unit_number, unit_id } = useParams();

  const [VoilationTrackingList, setVoilationTrackingList] = React.useState([]);
  const [VoilationTrackingListTemp, setVoilationTrackingListTemp] =
    React.useState([]);

  // create  modal

  const [showModel, setShowModel] = React.useState(false);

  const handleClose = () => {
    getVoilationTrackingListData();
    setShowModel(false);
  };
  const handleShow = () => setShowModel(true);

  const [loader, setLoader] = React.useState(false);
  const [payload, setPayload] = React.useState(false);
  const [violationsTypeList, setViolationsTypeList] = React.useState([]);

  const [showSettingsPage, setShowSettingsPage] = React.useState(false);

  React.useEffect(() => {
    // setLoader(true);
    // getVoilationTypesListData();
    getVoilationTrackingListData();
  }, []);

  const getVoilationTrackingListData = async () => {
    setLoader(true);

    const ViolationListRes = await axios.post(
      `${hostname}/api/property/ReadAllViolations`,
      {
        p_id: property_id,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    const ViolationTypesRes = await axios.post(
      `${hostname}/api/property/GetViolationSettings`,
      {
        p_id: property_id,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    // type data
    // console.log(ViolationTypesRes.data);
    let arrType = ViolationTypesRes.data.Data;
    setViolationsTypeList(arrType);

    // list data
    // console.log(ViolationListRes.data);
    let arr = ViolationListRes.data.Data?.map((v) => {
      let obj = { ...v };
      // console.log(v);
      let arr1 = arrType?.filter((j) => j._id === obj.violation_type)[0];
      console.log(arr1);
      obj.violation_type = arr1?.violation_type;
      obj.violation_type_id = arr1?._id;
      obj.violation_type_des = arr1?.violation_description;
      obj.createdAtPure = v.createdAt;
      obj.createdAt = formatLogInfo(v.createdAt);
      //##Abdullah
      if (typeof obj.violation_stage === "number") {
        let word = numberToWords.toWordsOrdinal(obj.violation_stage);
        console.log("checking");
        obj.violation_stage =
          word.charAt(0).toUpperCase() + word.slice(1) + " Notice";
      }
      if (obj.violation_stage === "First Notice")
        obj.stage = { type: "primary", title: "First Notice" };
      if (obj.violation_stage === "Second Notice")
        obj.stage = { type: "warning", title: "Second Notice" };
      if (obj.violation_stage === "Third Notice")
        obj.stage = { type: "danger", title: "Third Notice" };
      if (
        obj.violation_stage !== "First Notice" &&
        obj.violation_stage !== "Second Notice" &&
        obj.violation_stage !== "Third Notice" &&
        obj.violation_stage !== "Resolved"
      )
        obj.stage = { type: "danger", title: obj.violation_stage };
      if (obj.violation_stage === "Resolved")
        obj.stage = { type: "success", title: "Resolved" };
      return obj;
    });
    console.log(arr);
    arr.sort((a, b) =>
      a.createdAtPure < b.createdAtPure
        ? 1
        : b.createdAtPure < a.createdAtPure
        ? -1
        : 0
    );
    if (fromSingleUnit) {
      arr = arr?.filter((v) => v?.unit_id?._id === unit_id);
    }
    setVoilationTrackingList(arr);
    setVoilationTrackingListTemp(arr);
    setLoader(false);
  };

  const addVoilation = () => {
    setPayload({});
    let obj = {};
    obj.loadType = "Add New Violation";
    obj.property_id = property_id;
    obj.userData = userData;
    setPayload(obj);
    handleShow();
    console.log(obj);
  };

  const viewVoilation = (obj = {}) => {
    setPayload({});
    console.log(obj);
    obj.loadType = "View Violation";
    obj.property_id = property_id;
    obj.userData = userData;

    axios
      .post(
        `${hostname}/api/property/ReadUniqueViolation`,
        {
          violation_id: obj._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let data = res.data.Data;
        obj.attachments = data.attachments;
        console.log(data.comments);
        obj.comments = data.comments?.map((v) => {
          let inObj = {};
          inObj.text = v.text;
          inObj.comment_id = v._id;
          inObj.user_id = v.user_id._id;
          inObj.firstname = v.user_id.firstname;
          inObj.lastname = v.user_id.lastname;
          inObj.bgColor = v.user_id.bgColor;
          inObj.time = formatLogInfo(v.updatedAt);
          inObj.isEdited = v.isEdited;
          inObj.comment_type = v.comment_type || "comment";

          return inObj;
        });
        console.log(obj);
        // return;
        setPayload(obj);
        handleShow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loader) {
    return <Loader />;
  }

  if (showSettingsPage) {
    return (
      <VoilationsTrackingSettings
        setShowSettingsPage={setShowSettingsPage}
        userData={userData}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    );
  }

  return (
    <>
      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") &&
        !fromSingleUnit && (
          <Row className="g-3 mb-3">
            <Col>
              <Card>
                <Card.Body className="">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link className="text-secondary" to={"/"}>
                        Home
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        className="text-secondary"
                        to={"/property/management/buildings"}
                      >
                        Properties
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link className="text-secondary" to={"#!"}>
                        {property_name}
                      </Link>
                    </Breadcrumb.Item>{" "}
                    <Breadcrumb.Item active>
                      <Link to={"#!"}>Violation Tracking</Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") &&
        fromSingleUnit && (
          <Row className="g-3 mb-3">
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center scrollbar">
                  <Link className="me-2 text-secondary" to="/dashboard">
                    Home
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to="/property/management/buildings"
                  >
                    Properties
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                  >
                    {property_name}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/units`}
                  >
                    Units
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/vehicle-info`}
                    to="#!"
                  >
                    {unit_number}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-primary"
                    // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                    to={"#!"}
                  >
                    Voilations Tracking
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      {userData.role === "unit_owner" && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link className="text-secondary" to={"/"}>
                      Home
                    </Link>
                  </Breadcrumb.Item>{" "}
                  <Breadcrumb.Item active>
                    <Link to={"#!"}>Violation Tracking</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Violations Tracking</h4>
            <div>
              <Button
                variant="primary"
                size="sm"
                className="me-3"
                onClick={addVoilation}
              >
                + Add New Violation
              </Button>
              {!fromSingleUnit && (
                <Button
                  variant="falcon-primary"
                  size="sm"
                  onClick={() => setShowSettingsPage(true)}
                >
                  Settings
                </Button>
              )}
            </div>
          </Flex>
          <br />

          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomFilterbar
                filterFlags={["Resolved", "Not Resolved"]}
                dataListTemp={VoilationTrackingListTemp}
                stateUpdator={setVoilationTrackingList}
                from="Violations Tracking Table"
              />

              <CustomSearchbar
                dataListTemp={VoilationTrackingListTemp}
                stateUpdator={setVoilationTrackingList}
                searchFlags={["violation_type", "unit_id.unit_number", "_id"]}
                placeholders={["type", "unit #", "violation id"]}
              />
            </Col>
          </Row>

          <br />

          <VoilationTrackingTable
            list={VoilationTrackingList}
            viewVoilation={viewVoilation}
          />
          <br />
          <VoilationTrackingModel
            show={showModel}
            handleClose={handleClose}
            payload={payload}
            setPayload={setPayload}
            getAllData={getVoilationTrackingListData}
            fromSingleUnit={fromSingleUnit}
            unit_number_of_single_unit={unit_number}
            unit_number_of_single_unit_id={unit_id}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default VoilationTracking;
