import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigatePrevIcon from '@mui/icons-material/NavigateBefore';
export default function PaginateComponentCustom({
  itemsPerPage,
  TableElement,
  dataList,
  ...rest
}) {
  // console.log(rest);
  try {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = dataList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(dataList.length / itemsPerPage);

    const handlePageClick = event => {
      const newOffset = (event.selected * itemsPerPage) % dataList.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };

    return (
      // <div className="position-relative" style={{'height':'90vh'}}>
      <div>
        {/* <PropertyTable
        properties={currentItems}
        selectBuilding={selectBuilding}
      /> */}
        <TableElement list={currentItems} {...rest} />
        <br />
        {/* <span className='position-absolute bottom-50 end-50'> */}
        <span className="">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<NavigateNextIcon />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={<NavigatePrevIcon />}
            renderOnZeroPageCount={null}
            containerClassName="list-group list-group-horizontal d-flex justify-content-center list-unstyled fw-bold"
            pageLinkClassName="h-100 list-group-item list-group-item-action"
            previousLinkClassName="list-group-item list-group-item-action fw-bold"
            nextLinkClassName="list-group-item list-group-item-action fw-bold"
            activeLinkClassName="active"
          />
        </span>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
