import React from "react";
// import ImageUnit from './ImageUnit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../../components/common/SoftBadge";
import { Table, Dropdown } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import CardDropdown from "../../../../components/common/CardDropdown";
// import Avatar from '../../../../components/common/Avatar';
// import './UserTable.css';
// import { isArray } from 'lodash';
// import BSSliderCustom from '../../../../components/common/BSSliderCustom';
// import GroupMember from '../../../../components/app/kanban/GroupMember';

// import img1 from 'assets/img/products/poolroom.jpg';
// import img2 from 'assets/img/products/partyroom.jpg';
// import img3 from 'assets/img/products/gymroom.jpg';
// import StackedImagesComponent from '../../../../components/common/StackedImagesComponent';
// import libDate from 'date-and-time';
import { formatLogInfo } from "../../../../helpers/utils";

const TableRow = ({
  data,
  propLength,
  cancelBooking,
  receivePayment,
  receiptOfPayment,
  functionHandleShowBookingDetailsModel,
}) => {
  console.log(data.amenityName);
  // let date = libDate.format(new Date(data.date), 'YYYY/MM/DD HH:mm:ss');
  try {
    // let sampleAmenities = [img1, img2, img3];
    return (
      <tr className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
      
      >
        <td
          className="text-nowrap text-center text-primary cursor-pointer"
          onClick={() => functionHandleShowBookingDetailsModel(data)}

        >
          {data._id.slice(0, 8)}
        </td>
        <td className="text-nowrap text-center">{data.amenityName}</td>
        <td className="text-nowrap text-center">{data.userName}</td>
        <td className="text-nowrap text-center">{data.unit_num || "-"}</td>
        <td className="text-nowrap text-center">
          {formatLogInfo(data.date, true)} <br />
          {data.amenityType === "Hotel Style"
            ? data.timeslot?.split("-")[0]
            : data.timeslot || "timeslot"}
        </td>
        <td className="text-nowrap text-center">
          {formatLogInfo(data.createdAt)}
        </td>
        <td className="text-center">
          {/* {data.status.title || 'status'} */}
          {/* {data.status || "status"} */}
          <SoftBadge pill bg={data.status.type}>
            {data.status.title}
            <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
          </SoftBadge>
        </td>
        <td className="text-center">
          <CardDropdown>
            <div className="py-2">
              {data.status.title === "Payment Pending" && (
                <>
                  {/* <Dropdown.Item
                    onClick={() => receivePayment(data)}
                    className="text-success"
                  >
                    Receive Payment
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => cancelBooking(data._id)}
                    className="text-danger"
                  >
                    Cancel Booking
                  </Dropdown.Item>
                </>
              )}
              {data.status.title === "Confirmed" && (
                <>
                  {/* <Dropdown.Item
                    onClick={() => receiptOfPayment(data)}
                    className="text-success"
                  >
                    Payment Receipt
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => cancelBooking(data._id)}
                    className="text-danger"
                  >
                    Cancel Booking
                  </Dropdown.Item>
                </>
              )}
              {data.status.title === "Cancelled" && (
                <Dropdown.Item
                  // onClick={() => removeAmenityImages(data)}
                  className="text-danger"
                >
                  Delete
                </Dropdown.Item>
              )}
            </div>
          </CardDropdown>
        </td>
      </tr>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={8}>Nothing Found!</td>
    </tr>
  );
};

const BookedAmenitiesTable = ({
  list,
  cancelBooking,
  receivePayment,
  receiptOfPayment,
  functionHandleShowBookingDetailsModel,
}) => {
  try {
    return (
      <Table
        responsive
        striped
        hover
        bordered
        style={{ fontSize: "14px" }}
        className="text-center"
      >
        <thead>
          <tr>
            <th scope="col">Booking ID</th>
            <th scope="col">Amenity</th>
            <th scope="col">Booked By</th>
            <th scope="col">Unit #</th>
            <th scope="col text-center">Booking Date & Time</th>
            <th scope="col">Created On</th>
            <th scope="col" className="text-center">
              Status
            </th>
            <th scope="col" className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list?.map((amenity) => (
              <TableRow
                data={amenity}
                propLength={list.length}
                key={amenity._id}
                cancelBooking={cancelBooking}
                receivePayment={receivePayment}
                receiptOfPayment={receiptOfPayment}
                functionHandleShowBookingDetailsModel={
                  functionHandleShowBookingDetailsModel
                }
              />
            ))
          ) : (
            <EmptyRow />
          )}
        </tbody>
      </Table>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default BookedAmenitiesTable;
